import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  Tooltip,
  Box,
  IconButton,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

import { useGetAllOrganizationsSelectQuery } from "../../../middleware/apiOrganizations";
import { getEquipmentOptions, getUserRoleOptions, selectOrganization } from "../ResponsibleOrganizationsList/helpers";
import { getAllOrganization } from "./helpers";
import { useGetFacFireCompSelectElementsQuery } from "../../../middleware/facilitiesApi";
import { useGetEquipmentSystemsQuery } from "../../../middleware/equipmentsApi";
import { useGetUserRolesSelectQuery } from "../../../middleware/usersApi";
import { sideBarOptionsNewVersion } from "../../../data";

import { PossibilityOfViewingForm } from "./AddEmployee/Sections/PossibilityOfViewingForm";
import SelectSimple from "../../components/SelectSimple";
import SelectSimpleSearch from "../../components/SelectSimple/SelectSimpleSearch";
import SelectGroupingMultipleCheckmarks from "../../components/SelectGroupingMultipleCheckmarks";
import InputMaskPhone from "../../components/InputMaskPhone";
import InputPassword from "../../components/InputPassword";
import InputText from "../../components/InputText";
import { BasicFrom } from "./AddEmployee/Sections/BasicForm";
import { FunctionalForm } from "./AddEmployee/Sections/FunctiolForm";
import SelectMultipleChipDeletable from "../../components/SelectMultipleChipDeletable";
import { SelectMultipleChipDeletableWithSearchField } from "../../components/SelectMultipleChipDeletableWithSearchField";
import CheckboxSimple from "../../components/CheckboxSimple";

export const formFields = {
  admin_employee: "admin_employee",

  fullName: "fullName",
  jobTitle: "jobTitle",
  phoneNumber: "phoneNumber",
  email: "email",
  login: "login",
  password: "password",
  roleId: "roleId",
  passwordRepeat: "passwordRepeat",
  organizationWorkingFor: "organizationWorkingFor",
  protectionObjectsIds: "protectionObjectsIds",
  equipmentKindsIds: "equipmentKindsIds",
  isContactPerson: "isContactPerson",
  objects_to_test: "Поставить на ТО",

  organization_add_edit: "Создание/Редактирование организаций",
  all_organizations: "Чтение всех организаций",
  added_organizations: "Чтение добавленных организаций",

  availableOrganizations: "availableOrganizations",
  permissionsIds: "permissionsIds",
};

export const formFieldsTable = {
  name: "name",
  executiveUsers: "executiveUsers",
  organizations: "organizations",
  equipmentKindsAndTypes: "equipmentKindsAndTypes",
  tasks: "tasks",
  employeeLogin: "employeeLogin",
  employeeEmail: "employeeEmail",
  edit: "edit",
  protectionObjectId: "ProtectionObjectId",
  isActive: "isActive",
  searchParams: "searchParams",
  isVerified: "isVerified",
  equipmentKindId: "EquipmentKindId",

  employeeRoleName: "employeeRoleName",
  avatarLink: "avatarLink",
  employeeEquipmentKinds: "employeeEquipmentKinds",
  employeeFacilities: "employeeFacilities",
  employeeFullName: "employeeFullName",
  employeePosition: "employeePosition",
  organizationType: "organizationType",
};

export const allColumns = [
  { name: formFieldsTable.avatarLink, showName: "avatar", value: true },
  {
    name: formFieldsTable.employeeFullName,
    value: true,
    showName: "FIO_employee",
  },
  { name: formFieldsTable.employeePosition, showName: "position", value: true },
  // { name: formFieldsTable.employeeLogin, showName: "login", value: true },
  {
    name: formFieldsTable.employeeFacilities,
    showName: "objects_available_employee",
    value: true,
  },
  {
    name: formFieldsTable.employeeEquipmentKinds,
    showName: "objectsTypes",
    value: true,
  },
  {
    name: formFieldsTable.organizationType,
    showName: "organizationType",
    value: true,
  },
  {
    name: formFieldsTable.organizations,
    showName: "organizationName",
    value: true,
  },
  { name: formFieldsTable.isActive, showName: "isActive", value: true },
  { name: formFieldsTable.isVerified, showName: "isVerified", value: true },
];

export const getConfigFormModalSelect = (columns) =>
  columns?.map(({ name }) => ({
    name,
    label: `tableHeader:${name}`,
    type: "checkbox",
    rules: {},
    flexBasis: "50%",
    input: (props) => <CheckboxSimple {...props} />,
  }));
const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5 + ITEM_PADDING_TOP,
    },
  },
};

const SimpleSelect = ({
  options,
  label,
  onChange,
  error,
  value,
  searchValue,
  setSearchValue,
  disabled = false,
  setValue,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <FormControl fullWidth error={!!error} {...rest}>
      <InputLabel>{t(label)}</InputLabel>
      <Select
        value={value || ""}
        input={<OutlinedInput label={t(label)} />}
        onChange={onChange}
        MenuProps={MenuProps}
        sx={{
          ".MuiSelect-select": { height: 0 },
          ".MuiListItemText-root": { m: 0 },
        }}
        disabled={disabled}
      >
        {options.map((option, i) => (
          <MenuItem key={i} value={option?.value || ""} sx={{ height: ITEM_HEIGHT }}>
            <ListItemText primary={<Typography>{option.name?.toString() || option || ""}</Typography>} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const info = {
  Документы: "documentation",
  Планирование: "planning",
  "Создание/Редактирования оборудования": "addEditEquip",
};

function getTopLevelTitles(arr) {
  return arr.map((item) => item.title);
}

const topLevelTitles = getTopLevelTitles(sideBarOptionsNewVersion).filter((item) => item !== "Задачи");
const elementIndex = topLevelTitles.findIndex((item) => item === "Эксплуатация");
topLevelTitles.splice(elementIndex, 1);
topLevelTitles.push("Документы");
topLevelTitles.push("Создание/Редактирования оборудования");
topLevelTitles.push("Планирование");
const getTopLevelTooltip = (fieldTitle) => {
  const foundedElement = sideBarOptionsNewVersion.find((item) => item.title === fieldTitle)?.name || "";
  if (!foundedElement) {
    return info[fieldTitle];
  }
  return foundedElement;
};

export const ObjectsSelect = (props) => {
  const [searchValue, setSearchValue] = useState("");
  const { data: facFireCompSelect = [] } = useGetFacFireCompSelectElementsQuery(searchValue);
  const { data: firstData = [] } = useGetFacFireCompSelectElementsQuery("");

  return (
    <SelectMultipleChipDeletableWithSearchField
      setSearchValue={setSearchValue}
      isIds={true}
      firstData={firstData}
      searchValue={searchValue}
      options={facFireCompSelect}
      {...props}
    />
  );
};

export const EquipmentKindSelect = (props) => {
  const { data: equipmentSelectElements = [] } = useGetEquipmentSystemsQuery("");

  const selectedOptions = useMemo(() => getEquipmentOptions(equipmentSelectElements), [equipmentSelectElements]);

  return <SelectMultipleChipDeletable {...props} options={selectedOptions} />;
};

export const OrganizationsSelectMultipleChipDeletable = (props) => {
  const [searchValue, setSearchValue] = useState("");
  const { data: organizationsSelect = [] } = useGetAllOrganizationsSelectQuery(searchValue);
  const { data: firstorganizationsSelect = [] } = useGetAllOrganizationsSelectQuery("");

  const selectedOptions = useMemo(() => getAllOrganization(organizationsSelect), [organizationsSelect]);
  return (
    <SelectGroupingMultipleCheckmarks
      {...props}
      error={null}
      firstdata={firstorganizationsSelect}
      options={selectedOptions}
      setSearchValue={setSearchValue}
      searchValue={searchValue}
    />
  );
};

export const OrganizationsSelect = (props) => {
  const [searchValue, setSearchValue] = useState("");
  const { data: organizationsSelect = [] } = useGetAllOrganizationsSelectQuery(searchValue);

  return (
    <SelectSimpleSearch
      {...props}
      sx={{ ".MuiTypography-root": { padding: 0 } }}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      options={selectOrganization(organizationsSelect)}
    />
  );
};

const UserRolesSelect = (props) => {
  const { data: OrganizationsSelectOptions = [] } = useGetUserRolesSelectQuery();

  return (
    <SelectSimple
      {...props}
      options={getUserRoleOptions(OrganizationsSelectOptions)}
      sx={{
        ".MuiTypography-root": { maxWidth: "250px", overflowX: "hidden" },
      }}
    />
  );
};

export const basicInformationConfig = [
  {
    id: 1,
    name: formFields.organizationWorkingFor,
    label: "title_page:organizations",
    type: "select-text",
    flexBasis: "49%",
    displayEmpty: true,
    placeholder: "placeholder:organization",
    rules: {
      required: true,
    },
    input: (props) => <OrganizationsSelect {...props} />,
  },
  {
    id: 2,
    name: formFields.isContactPerson,
    label: "label:isContactPerson",
    type: "checkbox",
    flexBasis: "49%",
    input: (props) => <CheckboxSimple {...props} />,
  },
  {
    id: 3,
    name: formFields.protectionObjectsIds,
    type: "select-multi",
    displayEmpty: true,
    placeholder: "placeholder:objects",
    rules: {},
    label: "label:objects",
    input: (props) => <ObjectsSelect {...props} />,
  },
  {
    id: 4,
    name: formFields.equipmentKindsIds,
    label: "label:equipmentKindEployee",
    type: "select-multi",
    displayEmpty: true,
    placeholder: "placeholder:systemPPZ",
    rules: {
      required: true,
    },
    input: (props) => <EquipmentKindSelect {...props} />,
  },
  {
    id: 5,
    name: formFields.fullName,
    label: "label:fullName",
    placeholder: "placeholder:fullName",
    type: "text",
    firstUpperCase: true,
    flexBasis: "32%",
    additionalClassName: "contacts_form",
    rules: {
      required: true,
      pattern: {
        value: /^[\p{L}\s'-]+ [\p{L}\s'-]+ [\p{L}\s'-]+$/u,
        message: "enter_full_name_correct",
      },
    },
    input: (props) => <InputText {...props} />,
  },
  {
    id: 6,
    name: formFields.jobTitle,
    label: "label:position",
    placeholder: "placeholder:position",
    type: "text",
    flexBasis: "32%",
    additionalClassName: "contacts_form",
    rules: {
      required: false,
    },
    input: (props) => <InputText {...props} />,
  },
  {
    id: 7,
    name: formFields.phoneNumber,
    label: "label:phone",
    placeholder: "placeholder:phone",
    flexBasis: "32%",
    additionalClassName: "contacts_form",
    rules: {
      required: false,
      validate: (value) => {
        const formattedPhone = String(parseInt(value.replace(/[+() ]/g, "", "")) || "");
        return !formattedPhone.length || formattedPhone === "7" || formattedPhone?.length === 11;
      },
    },
    input: (props) => <InputMaskPhone {...props} />,
  },
  {
    id: 8,
    name: formFields.email,
    label: "label:email",
    type: "text",
    flexBasis: "49%",
    placeholder: "placeholder:email",
    additionalClassName: "contacts_form",
    rules: {
      required: false,
      pattern: /[\w!#$%&'*+/=?`{|}~^-]+(?:\.[\w!#$%&'*+/=?`{|}~^-]+)*@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,}$/g,
      validate: (v, fields) => {
        return !!v?.length || !!fields[formFields.login]?.length;
      },
      deps: [formFields.login],
    },
    input: (props) => <InputText {...props} />,
  },
  {
    id: 9,
    name: formFields.login,
    label: "label:loginOrMail",
    placeholder: "placeholder:email_or_login",
    type: "text",
    flexBasis: "49%",
    additionalClassName: "contacts_form",
    rules: {
      required: false,
      validate: (v, fields) => !!v?.length || !!fields[formFields.email]?.length,
      deps: [formFields.email],
    },
    input: (props) => <InputText {...props} />,
  },
  {
    id: 10,
    name: formFields.password,
    label: "label:password",
    placeholder: "placeholder:password",
    type: "password",
    flexBasis: "49%",
    additionalClassName: "contacts_form",
    rules: {
      required: true,
      validate: {
        lengthCheck: (value) => value.length >= 6 || "password_length",
      },
    },
    input: (props) => <InputPassword {...props} />,
  },
  {
    id: 11,
    name: formFields.passwordRepeat,
    label: "label:PasswordRepeat",
    type: "password",
    placeholder: "placeholder:confirm_password",
    flexBasis: "49%",
    additionalClassName: "contacts_form",
    rules: {
      required: true,
      validate: {
        matchPassword: (value, formValues) => value === formValues[formFields.password] || "password_not_match",
      },
    },
    input: (props) => <InputPassword {...props} />,
  },
];

export const functionalFormConfig = topLevelTitles.map((item) => {
  return ({
    name: `${item}`,
    label: `${item}`,
    type: "checkbox",
    rules: {},
    flexBasis: "25%",
    input: (props) => {
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            paddingRight: "20px",
          }}
        >
          <Box
            sx={{
              width: item !== "Создание/Редактирования оборудования" ? "193px" : "400px",
              ml: "10px",
            }}
          >
            <CheckboxSimple {...props} />
          </Box>
          <Tooltip
            title={t(`tooltip:addEmployeeForm:${getTopLevelTooltip(item)}`)}
            componentsProps={{
              tooltip: {
                sx: {
                  color: "var(--black)",
                  backgroundColor: "var(--white)",
                  borderRadius: 0,
                  maxWidth: 300,
                },
              },
            }}
          >
            <IconButton className="icon-btn" sx={{ height: "24px", width: "24px", padding: "0" }}>
              <InfoIcon sx={{ color: "var(--main)" }} />
            </IconButton>
          </Tooltip>
        </Box>
      );
    },
  })
});

export const possibilityOfViewingFormConfig = [
  {
    name: formFields.organization_add_edit,
    label: "label:add_edit_organizations",
    type: "checkbox",
    rules: {},
    flexBasis: "100%",
    sx: { ml: "10px" },
    input: (props) => <CheckboxSimple {...props} />,
  },
  {
    name: formFields.availableOrganizations,
    type: "select-multi",
    label: "title_page:organizations",
    displayEmpty: true,
    placeholder: "placeholder:organizations",
    rules: {},
    width: "100%",
    input: (props) => <OrganizationsSelectMultipleChipDeletable {...props} />,
  },
];

export const objectsToTestConfigWithoutRole = [
  {
    id: 2,
    name: formFields.objects_to_test,
    label: "label:objects_to_test",
    type: "checkbox",
    rules: {},
    flexBasis: "100%",
    sx: { ml: "10px", mb: "24px" },
    input: (props) => <CheckboxSimple {...props} />,
  },
];

export const objectsToTestConfig = [
  {
    id: 1,
    name: formFields.roleId,
    label: "label:role",
    type: "select-text",
    flexBasis: "49%",
    displayEmpty: true,
    placeholder: "placeholder:role",
    rules: {
      required: true,
    },
    input: (props) => <UserRolesSelect {...props} />,
  },
  {
    id: 2,
    name: formFields.objects_to_test,
    label: "label:objects_to_test",
    type: "checkbox",
    rules: {},
    flexBasis: "100%",
    sx: { ml: "10px", mb: "24px" },
    input: (props) => <CheckboxSimple {...props} />,
  },
];

export const sections = [
  {
    id: 1,
    title: "basic_information",
    Component: BasicFrom,
    config: basicInformationConfig,
  },
  {
    id: 2,
    title: "possibility_of_viewing",
    Component: PossibilityOfViewingForm,
    config: possibilityOfViewingFormConfig,
  },
  {
    id: 3,
    title: "role",
    Component: FunctionalForm,
    config: functionalFormConfig,
  },
];
