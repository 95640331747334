import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from "react-router-dom";
import { PATHS } from "../constants";
import { useAction } from "../hooks/actions";
import { persistor } from "../redux/store";

export default function AlertNewVersion({header,message,open,setOpen}) {
  
    const navigate = useNavigate();
    const { setAuthStep } = useAction();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(true);
  };

  const refreshData = async () =>{
    setAuthStep(0);
    window.location.reload();
    console.log("cookies", persistor)
    localStorage.clear();
  }
  return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {header}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
         
          <Button onClick={refreshData} autoFocus>
            Выход
          </Button>
        </DialogActions>
      </Dialog>
  );
}