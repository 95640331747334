import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useGetSystemOverallAnalyticsQuery } from "../../../middleware/analyticsApi";
import { useAction } from "../../../hooks/actions";
import { ratingConfig } from "../../pages/ObjectsOnMap/config";
import { PATHS } from "../../../constants";
import { ArrowInWidgets } from "../icons/NewArrowIcons/ArrowInWidgets";
import { CircularProgress } from "@mui/material";
import useScreenSize from "../../../hooks/useScreenSize";
import "./style.css";
import "./media.css";

const CountOfTasksWidget = () => {
  const { data: ratingData, isLoading, error: ratingError } = useGetSystemOverallAnalyticsQuery();
  const { setIsErrorRequest } = useAction();
  const navigate = useNavigate();
  const screenSize = useScreenSize()

  const handleClick = (nameforLink) => {
    if (nameforLink === "totalJob") navigate(PATHS.LIST_TASKS + `?OnlyOpenTasks=${false}`);
    if (nameforLink !== "totalJob" && nameforLink !== "objects") navigate(PATHS.LIST_TASKS + `?status=${nameforLink}`);
  };
  const error = ratingError;

  useEffect(() => {
    setIsErrorRequest(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const numberFormat = new Intl.NumberFormat("ru-RU");
  const renderedRatingData = ratingConfig(ratingData);
  const totalCountOfTasksData = renderedRatingData.shift();

  return !isLoading ? (
    <div className="tasks-count-widget">
      <div className="tasks-count-widget-header">
        <div className="tasks-count-widget-title-container">
          <p className="tasks-count-widget-title">{totalCountOfTasksData.name}</p>
          <div className="tasks-count-widget-count">{numberFormat.format(totalCountOfTasksData.amount)}</div>
        </div>
        <Link to={PATHS.LIST_TASKS}>
          Все задачи
          <ArrowInWidgets />
        </Link>
      </div>

      <div className="tasks-count-widget-list">
        {renderedRatingData.map((item, index) => {
          return (
            <React.Fragment key={`index-${item.id}`}>
              <div
                className="tasks-count-widget-item"
                key={`task-${item.id}`}
                onClick={() => handleClick(item.nameforLink)}
              >
                <p>{item.name}</p>
                <span>{numberFormat.format(item.amount)}</span>
              </div>
              {index !== 3 && (
                <div
                  className="separator"
                  key={`separator-${index}`} 
                />
              )}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  ) : (
    <div className="tasks-count-widget tasks-count-widget-loading">
      <CircularProgress />
    </div>
  );
};

export default CountOfTasksWidget;
