import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import CloseIcon from "../../../components/icons/CloseIcon";
import FormItem from "../../../components/FormItem";
import { addEquipmentExcelFile, addObjectBaseFormConfig } from "./form-config";
import { useImportEquipmentListMutation } from "../../../../middleware/equipmentsApi";

const ImportEquipmentDialog = ({ open, setOpen }) => {
  const [importEquipmentList, {}] = useImportEquipmentListMutation()

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const handleClose = () => {
    setOpen(false);
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        const base64String = reader.result.toString().replace("data:", "").replace(/^.+,/, "");
        resolve(base64String);
      };

      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  async function onSubmit(data) {
    const base64StringFromExcel = await convertFileToBase64(data.importData.filesToAdd[0]);

    const newData = {
      ...data,
      importData: base64StringFromExcel,
    };

    importEquipmentList(newData).then((responseImport) => {
      console.log("responseImport", responseImport)
    });
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box className="tariff" width="676px">
        <Box className="tariff-title">
          <Typography>Импорт оборудования</Typography>
          <Box className="tariff-close-btn" onClick={handleClose}>
            <CloseIcon />
          </Box>
        </Box>

        <DialogContent sx={{ padding: "16px 0 0px 0", width: "100%" }}>
          <form id="myform" onSubmit={handleSubmit(onSubmit)}>
            {addObjectBaseFormConfig.map(({ name, label, input, ...rest }) => (
              <FormItem
                key={name}
                control={control}
                errors={errors}
                name={name}
                label={label}
                input={input}
                setValue={setValue}
                {...rest}
              />
            ))}

            <Box>
              {addEquipmentExcelFile.map(({ name, label, input, ...rest }) => (
                <FormItem
                  key={name}
                  control={control}
                  errors={errors}
                  name={name}
                  label={label}
                  input={input}
                  setValue={setValue}
                  {...rest}
                />
              ))}
            </Box>
          </form>
        </DialogContent>
        <DialogActions sx={{ padding: "0" }}>
          <Button
            form="myform"
            type="submit"
            // disabled={isLoadingFile || isLoadingAdd}
            className="btn btn-primary-fill magazine_events-select_container-btn"
            sx={{ margin: "auto" }}
          >
            Сохранить
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default ImportEquipmentDialog;
