import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useGetOperativeInfoQuery } from "../../../../middleware/usersApi";
import EditPen from "../../../components/icons/Editpen";
import EditOperativeInfoModal from "../EditNewsModal/EditOperativeInfo";
import FullInfoOperativeNewsModal from "./FullInfoOperativeNewsModal";
import { OpenInNew } from "@material-ui/icons";
import { useAppSelector } from "../../../../hooks/redux";

const OperativeInfo = ({ isRefetchFlag }) => {
  const [isShowForecast, setIsShowForecast] = useState("1");
  const { roleId } = useAppSelector((state) => state.users.info);
  const [isVisibleEditOperationNewsModal, setIsVisibleEditOperationNewsModal] = useState(false);
  const [isVisibleFullInfoOperationNewsModal, setIsVisibleFullInfoOperationNewsModal] = useState(false);

  const changeOperativeInfoType = (isChooseInfoType) => {
    setIsShowForecast(isChooseInfoType);
    refetchOperativeInfo();
  };

  const { data: operativeInfo, refetch: refetchOperativeInfo } = useGetOperativeInfoQuery({
    type: isShowForecast,
  });

  useEffect(() => {
    refetchOperativeInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefetchFlag]);

  return (
    <>
      <Box className="operative-info">
        <p className="operative-info-title">Оперативная информация МЧС</p>
        <Box className="operative-info-btn-container">
          <Button
            className={`operative-info-btn ${isShowForecast === "1" ? "orange" : "gray"}`}
            onClick={() => changeOperativeInfoType("1")}
          >
            Прогноз
          </Button>
          <Button
            className={`operative-info-btn ${isShowForecast !== "1" ? "green" : "gray"}`}
            onClick={() => changeOperativeInfoType("2")}
          >
            На контроле
          </Button>
        </Box>
        <Box className="operative-info-text" onClick={() => setIsVisibleFullInfoOperationNewsModal(true)}>
          {isShowForecast === "1" ? (
            <>
              <p>
                {new Date(operativeInfo?.date).toLocaleDateString("ru-RU")}{" "}
                {roleId === 1 && (
                  <Box
                    className="news-panel-edit"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsVisibleEditOperationNewsModal(true);
                    }}
                  >
                    <EditPen />
                  </Box>
                )}
                <Box className="news-panel-open-icon">
                  <OpenInNew />
                </Box>
              </p>
              <Typography
                className="news-panel-text operative-info-content"
                dangerouslySetInnerHTML={{ __html: operativeInfo?.content }}
              />
            </>
          ) : (
            <>
              <p>
                {new Date(operativeInfo?.date).toLocaleDateString("ru-RU")}
                {roleId === 1 && (
                  <Box
                    className="news-panel-edit"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsVisibleEditOperationNewsModal(true);
                    }}
                  >
                    <EditPen />
                  </Box>
                )}
              </p>
              <Typography
                className="news-panel-text operative-info-content"
                dangerouslySetInnerHTML={{ __html: operativeInfo?.content }}
              />
            </>
          )}
        </Box>
      </Box>

      {isVisibleFullInfoOperationNewsModal && (
        <FullInfoOperativeNewsModal
          isVisible={isVisibleFullInfoOperationNewsModal}
          handleClose={() => setIsVisibleFullInfoOperationNewsModal(false)}
          newsData={operativeInfo}
        />
      )}

      {isVisibleEditOperationNewsModal && (
        <EditOperativeInfoModal
          isVisible={isVisibleEditOperationNewsModal}
          handleClose={() => setIsVisibleEditOperationNewsModal(false)}
          newsDataForEdit={operativeInfo}
          refetchOperativeInfo={refetchOperativeInfo}
        />
      )}
    </>
  );
};

export default OperativeInfo;
