import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  IconButton,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { formConfigSecondStep } from "../form-config";
import { useGetUsersByPasswordMutation, useLazyGetProfileInfoQuery } from "../../../../middleware/usersApi";
import { useAppSelector } from "../../../../hooks/redux";
import { PATHS } from "../../../../constants";

import ProfileItemView from "../../../components/ProfileItemView";
import FormItem from "../../../components/FormItem";

const SecondStep = ({ setAuthStep }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [getUserByPassword] = useGetUsersByPasswordMutation();
  const [getProfileInfo] = useLazyGetProfileInfoQuery();

  const { login, firstName, lastName, isActive } = useAppSelector(
    (state) => state.users.info
  );

  const {
    control,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = async (data) => {
    const response = await getUserByPassword({ ...data, login });
    if (response.error?.status === 404) {
      setError("password", { type: "custom", message: "incorrect_password" });
      return;
    }
    getProfileInfo(response.data.userToken)
    // Код ниже не работает. Скорее всего больше не нужен. Пережитки прошлого
    // if (response.isActive) {
    //   navigate(PATHS.MY_ORGANIZATIONS);
    // }
  };

  return (
    <Box>
      <Box
        className="account-found-block"
        sx={{ display: "flex", mb: "15px", columnGap: "10px" }}
      >
        <IconButton
          className="default-label-text"
          sx={{ p: 0 }}
          onClick={() => setAuthStep(0)}
          size="small"
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography className="default-label-text strong">
          {t("label:accountHasBeenFound")}
        </Typography>
      </Box>
      <Box sx={{ mb: "30px" }}>
        <ProfileItemView />
      </Box>
      {isActive ? (
        <form
          id="loginForm"
          className="object-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          {formConfigSecondStep.map(({ name, label, input, ...rest }) => (
            <FormItem
              key={name}
              control={control}
              errors={errors}
              name={name}
              label={label}
              input={input}
              {...rest}
            />
          ))}
          <Button
            sx={{ mt: "30px" }}
            fullWidth
            className="btn btn-primary-fill"
            variant="contained"
            type="submit"
          >
            {t("button:login")}
          </Button>
        </form>
      ) : (
        <Alert severity="error">
          <AlertTitle>
            {t("label:accountBlocked", {
              userName: `${firstName} ${lastName}`,
            })}
          </AlertTitle>
          {t("label:supportRequest")}
        </Alert>
      )}
    </Box>
  );
};

export default SecondStep;
