import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import BigGridIcon from "../../../components/icons/Cabinet/BigGridIcon";
import SmallGridIcon from "../../../components/icons/Cabinet/SmallGridIcon";
import "./styles.css";
import useScreenSize from "../../../../hooks/useScreenSize";

const GridType = ({ gridTypeValue, handleChangeGridType }) => {
  const { t } = useTranslation();
  const screenSize = useScreenSize();
  return (
    <Box sx={{ alignSelf: "end" }}>
      <Typography mb={"8px"}>{t("label:type")}</Typography>
      <Box className="grid-type-container">
        <Box
          className={`grid_type_tab ${gridTypeValue === 0 && "grid_type_tab_active"}`}
          onClick={() => handleChangeGridType(0)}
        >
          <BigGridIcon isActive={gridTypeValue === 0} />
          {!(screenSize.width < 1725) && (
            <Typography className="pay_box_label" color={gridTypeValue === 0 ? "var(--main)" : "#4D5C6E"}>
              Подробно
            </Typography>
          )}
        </Box>
        <Box
          className={`grid_type_tab ${gridTypeValue === 1 && "grid_type_tab_active"}`}
          onClick={() => handleChangeGridType(1)}
        >
          <SmallGridIcon isActive={gridTypeValue === 1} />
          {!(screenSize.width < 1725) && (
            <Typography className="pay_box_label" color={gridTypeValue === 1 ? "var(--main)" : "#4D5C6E"}>
              Списком
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default GridType;
