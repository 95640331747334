import React, { useEffect, useMemo, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useForm, useWatch } from "react-hook-form";
import "./styles/style.css";
import { formFieldsTable as formFields, getConfigFormModalSelect } from "./form-config";
import { useNavigate } from "react-router";
import { PATHS } from "../../../constants";
import { useAction } from "../../../hooks/actions";
import ObjectDialog from "../ObjectsList/ObjectDialog";
import MyOrganizationDetails from "../MyOrganizationDetails";
import { EmployeeDialog } from "./EmployeeDialog";
import { getColumns } from "./columns";
import { useAppSelector } from "../../../hooks/redux";
import EditButton from "../../components/EditButton";
import { useLazyGetAllEmployeesQuery } from "../../../middleware/employeeApi";
import TableBasic from "../../components/TableBasic";
import { ModalSelectColumnsServer } from "../../components/Modals/ModalSelectColumnsServer";
import {
  useGetUserGridSettingQuery,
  useLazyGetUserGridSettingQuery,
  useConfirmVerificationMutation,
} from "../../../middleware/usersApi";
import { getDataToChart } from "../../../helpers/helper";
import settingsicon from "../../../image/icons/settingsButtonWhite.png";
import plusicon from "../../../image/icons/plusBtnWhite.png";
export const EmployeeList = () => {
  const { t } = useTranslation();

  const {
    searchNameStorage,
    loginStorage,
    emailStorage,
    searchRoleStorage,
    employeePositionStorage,
    organizationSearchStorage,
    protectionObjectIdStorage,
    isActiveStorage,
    employeeRoleNameStorage,
    equipmentKindIdStorage,
    organizationTypeStorage,
    isVerifiedStorage,
    pageNumberStorage
  } = JSON.parse(sessionStorage.getItem("employeeListSearchParams")) || {};

  const { control, setValue, watch, reset } = useForm({
    mode: "onChange"
  });
  const navigate = useNavigate();
  const params = useWatch({
    control,
    name: formFields.searchParams,
  });

  const { allEmployees } = useAppSelector((state) => state.employee);
  const { setIsErrorRequest } = useAction();

  const [openObjectDialog, setOpenObjectDialog] = useState(false);
  const [selectedObjectId, setSelectedObjectId] = useState(null);
  const [pageNumber, changePageNumber] = useState(pageNumberStorage || 0);
  const [searchName, setSearchName] = useState(searchNameStorage || "");
  const [employeePosition, setEmployeePosition] = useState(employeePositionStorage || "");
  const [organizationSearch, setOrganizationSearch] = useState(organizationSearchStorage || "");
  const [login, setLogin] = useState(loginStorage || "");
  const [email, setEmail] = useState(emailStorage || "");
  const [searchRole, setSearchRole] = useState(searchRoleStorage || "");
  const [isFirstStartup, setIsFirstStartup] = useState(true)

  const [organizationTypeId, setOrganizationTypeId] = useState("");
  const [activeValue, changeactiveValue] = useState("");
  const [verifiedValue, changeverifiedValue] = useState("");

  const [openDiteilsPopUp, setOpenDiteilsPopUp] = useState(false);
  const [openSelectModal, setOpenSelectModal] = useState(false);
  const [openEmployeeDialog, setOpenEmployeeDialog] = useState(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedOrgTypeId, setSelectedOrgTypeId] = useState(null);

  useEffect(() => {
    setValue(`${formFields.searchParams}.${formFields.protectionObjectId}`, protectionObjectIdStorage || "")
    setValue(`${formFields.searchParams}.${formFields.isActive}`, isActiveStorage || "")
    setValue(`${formFields.searchParams}.${formFields.employeeRoleName}`, employeeRoleNameStorage || "")
    setValue(`${formFields.searchParams}.${formFields.organizationType}`, organizationTypeStorage || "")
    setValue(`${formFields.searchParams}.${formFields.isVerified}`, isVerifiedStorage || "")
    setValue(`${formFields.searchParams}.${formFields.employeeLogin}`, loginStorage || "")
    setValue(`${formFields.searchParams}.${formFields.employeeFullName}`, searchNameStorage || "")
    setValue(`${formFields.searchParams}.${formFields.equipmentKindId}`, equipmentKindIdStorage || "")
  }, [])

  function savePageNumberToSessionStorage(currentPageNumber) {
    const getStorage = JSON.parse(sessionStorage.getItem("employeeListSearchParams")) || {};
    const searchParamsForLocalStorage = {
      ...getStorage,
      pageNumberStorage: currentPageNumber,
    };
    sessionStorage.setItem("employeeListSearchParams", JSON.stringify(searchParamsForLocalStorage));
  }

  const {
    data: gridcolumns,
    // error: errorgridColumns,
    // isLoading: isLoadingerrorColumns,
  } = useGetUserGridSettingQuery({ gridSettingName: "EmployeeList" });

  const [getUserGrid] =
    useLazyGetUserGridSettingQuery();
  const [getAllEmployees, { isLoading, error }] = useLazyGetAllEmployeesQuery();
  const [verifyUser] = useConfirmVerificationMutation();

  // const handleOpenTaskDialogState = (id) => {
  //   setOpenTaskDialog(true);
  //   setSelectedTaskId(id);
  // };

  function writeSearchDataToLocalStorage(newSearchParam, searchValue) {
    const getStorage = JSON.parse(sessionStorage.getItem("employeeListSearchParams")) || {};
    const searchParamsForLocalStorage = {
      ...getStorage,
      [newSearchParam]: searchValue,
    };
    sessionStorage.setItem("employeeListSearchParams", JSON.stringify(searchParamsForLocalStorage));
  }

  function clearFiltersHandler() {
    setSearchName("");
    setEmployeePosition("");
    setOrganizationSearch("");
    setLogin("");
    setEmail("");
    changePageNumber(0)
    reset();
    sessionStorage.removeItem("employeeListSearchParams");
  }

  const handleOpenEmployeeDialogState = (id) => {
    if (!openEmployeeDialog) {
      setOpenEmployeeDialog(true);
      setSelectedEmployeeId(id);
      return;
    }
    setOpenEmployeeDialog(false);
    setSelectedEmployeeId(null);
  };

  const handleOpenObjectDialogState = (id) => {
    if (!openObjectDialog) {
      setOpenObjectDialog(true);
      setSelectedObjectId(id);
      return;
    }
    setOpenObjectDialog(false);
    setSelectedObjectId(null);
  };
  const handleOpenDetails = ({ id, typeId }) => {
    setSelectedId(id);
    setSelectedOrgTypeId(typeId);
    setOpenDiteilsPopUp(!!id);
  };

  const handleLink = (id) => navigate(PATHS.GET_EDIT_EMPLOYEE(id));
  const verifyEmployee = (id) => {
    verifyUser({ id: id }).then((result) => {
      if ("error" in result) {
        return;
      }
      getAllEmployees({
        ...params,
        OrganizationTypeId: params.organizationType,
        RoleName: params.employeeRoleName,
        pageNumber: pageNumber,
      });
    });
  };

  const rows = useMemo(() => {
    const { data } = allEmployees || {};
    return data?.length
      ? data.map(
          ({
            employeeId,
            avatarLink,
            employeeEquipmentKinds,
            employeeFacilities,
            enableForEdit,
            employeeFullName,
            employeeEmail,
            employeeLogin,
            employeePosition,
            organizationType,
            employeeRoleName,
            organizations,
            isActive,
            enableConfirmVerification,
            jobChart,
            isVerified,
          }) => ({
            employeeId,
            avatarLink,
            employeeFacilities: employeeFacilities?.length
              ? employeeFacilities.map(({ id, name }) => ({
                  id,
                  name,
                  action: () => handleOpenObjectDialogState(id),
                }))
              : [],
            employeeFullName: {
              name: employeeFullName,
              isActive: isActive,
              action: () => employeeId && handleOpenEmployeeDialogState(employeeId),
            },
            employeePosition,
            organizationType,
            employeeEquipmentKinds,
            employeeEmail,
            employeeLogin,
            employeeRoleName,
            organizations: organizations?.length
              ? organizations.map(({ id, name, typeId }) => ({
                  id,
                  name,
                  action: () => handleOpenDetails({ id, typeId }),
                }))
              : [],
            edit: enableForEdit ? <EditButton handleClick={() => handleLink(employeeId)} /> : <></>,
            isActive: isActive ? (isVerified ? "Активен" : "Активен/требуется подтверждение") : "Заблокирован",
            isVerified:
              enableConfirmVerification && !isVerified ? (
                <Button className="btn btn-primary-fill" variant="contained" onClick={() => verifyEmployee(employeeId)}>
                  {t("button:confirm")}
                </Button>
              ) : (!enableConfirmVerification && isVerified) || (enableConfirmVerification && isVerified) ? (
                "Подтвержден"
              ) : (
                "Не подтвержден"
              ),
            isActiveBool: isActive,
            tasks: getDataToChart({
              jobChart,
              action: `?responsibleCustomerOrExecutiveUserId=${employeeId}`,
            }),
            isVerifiedBool: isVerified,
          })
        )
      : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allEmployees]);

  const columnsFromStorage = (() => {
    const storageData = gridcolumns?.columnSettings || [];
    return storageData?.reduce((acc, { name, visible }) => ({ ...acc, [name]: visible }), {});
  })();

  const handleChange = (value) => {
    setOrganizationTypeId(value);
  };

  const columns = useMemo(
    () =>
      getColumns({
        control,
        columnsFromStorage,
        organizationTypeId,
        handleChange,
        activeValue,
        changeactiveValue,
        verifiedValue,
        changeverifiedValue,
        searchName,
        setsearchName: setSearchName,
        organizationSearch,
        setorganizationSearch: setOrganizationSearch,
        employeePosition,
        setemployeePosition: setEmployeePosition,
        login,
        setLogin,
        email,
        setEmail,
        searchRole,
        setsearchRole: setSearchRole,
        rows,
        setValue,
        writeSearchDataToLocalStorage,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnsFromStorage]
  );

  const recordsCount = useMemo(() => allEmployees && allEmployees?.recordsCount, [allEmployees]);

  const handleOpenSelectColumnsModal = () => {
    setOpenSelectModal(true);
  };

  const protectionObjectId = watch(`${formFields.searchParams}.${formFields.protectionObjectId}`);
  const equipmentKindId = watch(`${formFields.searchParams}.${formFields.equipmentKindId}`);
  
  useEffect(() => {
    writeSearchDataToLocalStorage("protectionObjectIdStorage", protectionObjectId);
    setValue(`${formFields.searchParams}.${formFields.protectionObjectId}`, protectionObjectId || "");
  }, [protectionObjectId, setValue]);

  useEffect(() => {
    writeSearchDataToLocalStorage("equipmentKindIdStorage", equipmentKindId);
    setValue(`${formFields.searchParams}.${formFields.equipmentKindId}`, equipmentKindId || "");
  }, [equipmentKindId, setValue]);

  useEffect(() => {
    setValue(`${formFields.searchParams}.${formFields.employeeFullName}`, searchName);
  }, [searchName, setValue]);

  useEffect(() => {
    setValue(`${formFields.searchParams}.${formFields.employeeLogin}`, login);
  }, [login, setValue]);

  useEffect(() => {
    setValue(`${formFields.searchParams}.${formFields.employeeEmail}`, email);
  }, [email, setValue]);

  useEffect(() => {
    setValue(`${formFields.searchParams}.${formFields.employeePosition}`, employeePosition);
  }, [employeePosition, setValue]);

  useEffect(() => {
    writeSearchDataToLocalStorage("organizationSearchStorage", organizationSearch);
    setValue(`${formFields.searchParams}.${formFields.organizations}`, organizationSearch);
  }, [organizationSearch, setValue]);

  useEffect(() => {
    if(!params) return
    if (!isFirstStartup) {
      changePageNumber(0);
    }
    getAllEmployees({
      ...params,
      OrganizationTypeId: params.organizationType,
      RoleName: params.employeeRoleName,
      pageNumber: 0,
    }); 
    setIsFirstStartup(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if(!params) return
    getAllEmployees({
      OrganizationTypeId: params.organizationType,
      RoleName: params.employeeRoleName,
      pageNumber: pageNumber,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  useEffect(() => {
    setIsErrorRequest(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  // const getBgColor = (row, index) => {
  //   const { isActiveBool, isVerifiedBool } = row || {};
  //   return (!isActiveBool && !isVerifiedBool) || (!isActiveBool && isVerifiedBool)
  //     ? "rgba(255,0,0,0.6)"
  //     : isActiveBool && !isVerifiedBool
  //       ? "rgba(255,255,0,0.6)"
  //       : "rgba(51,255,51,0.6)";
  // };

  return (
    <Box className="template_page magazine_events">
      {openObjectDialog && (
        <ObjectDialog open={openObjectDialog} onClose={handleOpenObjectDialogState} objectId={selectedObjectId} />
      )}

      {openEmployeeDialog && (
        <EmployeeDialog
          open={openEmployeeDialog}
          onClose={handleOpenEmployeeDialogState}
          employeeId={selectedEmployeeId}
        />
      )}

      <Box className="template_page-header">
        <Typography className="title-page">{t("title_page:employees")}</Typography>
        <Box sx={{ display: "flex", gap: "16px" }}>
          <Button className="btn btn-primary-fill" onClick={clearFiltersHandler}>
            Очистить все фильтры
          </Button>

          <Button
            className="btn btn-primary-fill"
            variant="outlined"
            onClick={() => {
              navigate(PATHS.ADD_EMPLOYEE);
            }}
          >
            <Box component="img" src={plusicon} sx={{ marginRight: "16px" }} />
            {t("button:add")}
          </Button>

          <Button
            className="btn btn-primary-fill magazine_events-select_container-btn"
            variant="outlined"
            onClick={handleOpenSelectColumnsModal}
            sx={{ height: "52px" }}
          >
            <Box component="img" src={settingsicon} sx={{ marginRight: "16px" }} />
            {t("button:customize_fields_to_display")}
          </Button>
        </Box>
      </Box>

      {/* <Box className="magazine_events-select_container">
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.eventsFilter}`}
            label={t("label:events")}
            type={"select-text"}
            options={eventsOptions}
            input={(props) => (
              <Box className="medium_container">
                <SelectSimple {...props} />
              </Box>
            )}
          />
        </Box>
            */}
      <TableBasic
        columns={columns}
        rows={rows}
        handleClickCell={() => {}}
        withSearchField={false}
        isLoading={isLoading}
        pageNumber={pageNumber}
        changePageNumber={changePageNumber}
        count={recordsCount}
        savePageNumberToSessionStorage={savePageNumberToSessionStorage}
        minHeight={"49vh"}
        maxHeight={"70vh"}
      />
      <MyOrganizationDetails
        open={openDiteilsPopUp}
        setOpen={setOpenDiteilsPopUp}
        id={selectedId}
      />

      <ModalSelectColumnsServer
        open={openSelectModal}
        setOpen={setOpenSelectModal}
        data={columnsFromStorage}
        refetch={() => getUserGrid({ gridSettingName: "EmployeeList" })}
        nameStorage={"EmployeeList"}
        config={getConfigFormModalSelect(gridcolumns?.columnSettings || [])}
      />
    </Box>
  );
};
