import React from "react";

const CloseIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L17 17" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M1 17L17 1.00002" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
};

export default CloseIcon;
