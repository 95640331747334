import InputText from "../../../../components/InputText";

export const formFields = {
  availableEquipment: "availableEquipment",
  individualPeriodMonths: "individualPeriodMonths",
  individualPeriodDays:"individualPeriodDays",
  techSupportPrice: "techSupportPrice",
  price: "price"
};

export const customTarrifField = [
  {
    id: 1,
    name: formFields.availableEquipment,
    type: "number",
    placeholder: "placeholder:equipment_count",
    label:"label:equipment_count",
    rules: {
      required: true,
      integer: (v) => !!Number(v) && !/\s/g.test(v) && !/\./g.test(v),
    },
    sx: { input: { width: "calc(100% - 60px)" },margin:"12px 0px" },
    input: (props) => <InputText {...props} />,
  },
  {
    id: 2,
    name: formFields.individualPeriodMonths,
    type: "number",
    placeholder: "placeholder:months_count",
    label:"label:month_count",
    rules: {
      required: true,
      integer: (v) => !!Number(v) && !/\s/g.test(v) && !/\./g.test(v),
    },
    sx: { input: { width: "calc(100% - 60px)" } ,margin:"12px 0px"},
    input: (props) => <InputText {...props} />,
  },
  {
    id: 4,
    name: formFields.techSupportPrice,
    type: "number",
    placeholder: "placeholder:tech_price",
    label:"label:tech_price",
    rules: {
      required: true,
      integer: (v) => !!Number(v) && !/\s/g.test(v) && !/\./g.test(v),
    },
    sx: { input: { width: "calc(100% - 60px)"},margin:"12px 0px" },
    input: (props) => <InputText {...props} />,
  },
  {
    id: 5,
    name: formFields.price,
    type: "number",
    placeholder: "placeholder:service_price",
    label:"label:service_price",
    rules: {
      required: true,
      integer: (v) => !!Number(v) && !/\s/g.test(v) && !/\./g.test(v),
    },
    sx: { input: { width: "calc(100% - 60px)"},margin:"12px 0px" },
    input: (props) => <InputText {...props} />,
  },
];
