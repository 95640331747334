export const checkboxValues = {
  "Объекты": 1,
  "Организации": 2,
  "Оборудование": 3,
  "Задачи": 4,
  "Журналы": 5,
  "Аналитика": 6,
  "Сотрудники": 7,
  "Поставить на ТО": 8,
  "Создание/Редактирование организаций": 9,
  "Чтение всех организаций": 10,
  "Чтение добавленных организаций": 11,
  "Календарный план": 12,
  "Документы": 13,
  "Пульты": 14,
  "Создание/Редактирования оборудования":15,
  "Планирование":16
};

export const ReversecheckboxValues = {
  1: "Объекты",
  2: "Организации",
  3: "Оборудование",
  4: "Задачи",
  5: "Журналы",
  6: "Аналитика",
  7: "Сотрудники",
  8: "Поставить на ТО",
  9: "Создание/Редактирование организаций",
  10: "Чтение всех организаций",
  11: "Чтение добавленных организаций",
  12: "Календарный план",
  13: "Документы",
  14: "Пульты",
  15: "Создание/Редактирования оборудования",
  16: "Планирование"
};
