import React from "react";
import { useTranslation } from "react-i18next";
import {
  ListItemText,
  OutlinedInput,
  Tooltip,
  Typography,Box
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import selectArrowUp from "../../../image/icons/selectArrowUp.png";
import selectArrowDown from "../../../image/icons/selectArrowDown.png"

const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 8;

const SelectSimpleFieldArray = ({
  optionsActs,
  label,
  onChange,
  error,
  value,
  disabled = false,
  setValue,
  optionDisabled,
  ...rest
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: optionsActs?.length>=4 ? (ITEM_HEIGHT+ ITEM_PADDING_TOP) * 4 : (ITEM_HEIGHT+ ITEM_PADDING_TOP) * optionsActs?.length ,
      },
    },
  };
  
  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <FormControl fullWidth error={!!error && !disabled} {...rest}>
      <InputLabel>{t(label)}</InputLabel>
      <Select
        value={value || ""}
        input={<OutlinedInput label={t(label)} />}
        onChange={onChange}
        MenuProps={{
          ...MenuProps,
          variant: "menu",
          shouldFocus: false,
          autoFocus: false,
          disableAutoFocusItem: true,
          disableListFocus: true,
        }}
        sx={{
          ".MuiSelect-select": { height: 0 },
          ".MuiListItemText-root": { m: 0 },
        }}
        disabled={disabled}
        open={isOpen}
          onOpen={handleOpen}
          onClose={handleClose}
          IconComponent={() =>
            <Box sx={{ mr: "24px",cursor:"pointer" }} onClick={()=>{setIsOpen((prev)=> !prev)}}>
              {isOpen ? (
                <img src={selectArrowUp} />
              ) : (
                <img src={selectArrowDown} />
              )
              }
            </Box>}
      >
        {optionsActs?.map((option, i) => (
          <MenuItem
            key={i}
            disabled={optionDisabled}
            value={ option?.value || value?.name || option?.name || option || ""}
            sx={{ height: ITEM_HEIGHT+ITEM_PADDING_TOP }}
          >
            <Tooltip key={i} title={option?.hint || ""}>
              <ListItemText
                primary={
                  <Typography>
                    {option.name?.toString() || option || ""}
                  </Typography>
                }
              />
            </Tooltip>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectSimpleFieldArray;
