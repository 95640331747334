import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Button, Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useAction } from "../../../../hooks/actions";
import { WarningValidationForm } from "../../../components/Notifications";
import { sections, formFields } from "../form-config";
import AccordionSection from "../../../components/AccordionSection";
import { DEFAULT_VALUE } from "../constants";
import { useAddNewUserMutation, useGetAbleToAddUsersQuery } from "../../../../middleware/usersApi";
import { formatPhoneNumber } from "../../ResponsibleOrganizationsList/helpers";
import { PATHS } from "../../../../constants";
import { useAppSelector } from "../../../../hooks/redux";
import { checkboxValues } from "./constants";
import { useGetAllOrganizationsSelectQuery } from "../../../../middleware/apiOrganizations";
import {
  useGetEquipmentKindSelectElementsQuery,
  useLazyGetEquipmentKindSelectElementsQuery,
  useLazyGetEquipmentSystemsQuery,
} from "../../../../middleware/equipmentsApi";

export const AddEmployee = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { token } = useAppSelector((state) => state.users);
  const { setIsErrorSubmit, setIsSuccessSubmit } = useAction();
  const { data: isHaveAccessByRole } = useGetAbleToAddUsersQuery();

  const [addEmployee, { error: errorAdd, isSuccess: isSuccessAdd, isLoading }] = useAddNewUserMutation();

  const { data: organizationsSelect = [] } = useGetAllOrganizationsSelectQuery();

  const [getEquipmentKindSelectElements, { error: errorGet, isSuccess: isSuccessGet }] =
    useLazyGetEquipmentKindSelectElementsQuery();
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    mode: "onChange",
    defaultValues: DEFAULT_VALUE,
  });
  const isCheckedEditOrganization = watch(`${[formFields.permissionsIds]}.Создание/Редактирование организаций`);

  useEffect(() => {
    if (isCheckedEditOrganization) {
      setValue(`${[formFields.permissionsIds]}.Организации`, true);
    }
  }, [isCheckedEditOrganization]);

  const isWarningValidation = !!Object.keys(errors).length;
  const error = errorAdd;
  const isSuccess = isSuccessAdd;

  const onSubmit = async (data) => {
    const { fullName, permissionsIds, organizationWorkingFor, equipmentKindsIds, ...rest } = data || {};

    const trueElements = Object.keys(data[formFields.permissionsIds]).filter(
      (key) => data[formFields.permissionsIds][key] === true
    );

    const fio = fullName.split(" ");
    const firstName = fio[1] || "";
    const surname = fio[2] || "";
    const lastName = fio[0] || "";

    const requestData = {
      permissionsIds: [...trueElements.map((item) => checkboxValues[item]), 4],
      ...rest,
      phoneNumber: formatPhoneNumber(data.phoneNumber),
      organizationWorkingFor: organizationsSelect.find((item) => item.id === organizationWorkingFor),
      firstName: firstName,
      surname: surname,
      lastName: lastName,
      equipmentKindsIds: equipmentKindsIds,
    };

    addEmployee({ token, data: requestData }).then((result) => {
      if ("error" in result) {
        return;
      }
      navigate(PATHS.LIST_EMPLOYEES);
    });
  };

  const handleCancel = () => navigate(-1);

  useEffect(() => {
    setIsErrorSubmit(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    setIsSuccessSubmit(isSuccess);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);
  useEffect(() => {
    getEquipmentKindSelectElements().then(({ data, isSuccess }) => {
      let equipResArr = [];
      data.map(({ id }) => {
        equipResArr.push(id);
      });
      setValue(formFields.equipmentKindsIds, equipResArr);
    });

    sections.map(({ title, config }) => {
      if (title == "role") {
        config.map(({ name }) => {
          setValue(`${[formFields.permissionsIds]}.${name}`, true);
        });
      }
    });
  }, [sections, getEquipmentKindSelectElements]);
  return (
    <>
      {isHaveAccessByRole ? (
        <Box className="template_page employee">
          <Box className="template_page-header">
            <Typography className="title-page">{t("title_page:add_employee")}</Typography>
            <Box className="template_page-header-buttons">
              <Button className="btn btn-outlined-grey" onClick={handleCancel}>
                {t("button:comeback")}
              </Button>
              <Button
                form="myform"
                className="btn btn-primary-fill"
                type="submit"
                disabled={(isSubmitSuccessful && !error) || isLoading}
              >
                {t("button:save")}
              </Button>
            </Box>
          </Box>
          <form
            className="scrolled-form"
            id="myform"
            onSubmit={handleSubmit(onSubmit)}
            style={{ display: "flex", flexDirection: "column", gap: "32px" }}
          >
            {sections.map(({ id, title, Component, config }) =>
              title ? (
                <AccordionSection key={id} title={title}>
                  {Component && <Component control={control} setValue={setValue} errors={errors} config={config} />}
                </AccordionSection>
              ) : (
                <Box key={id}>
                  {Component && <Component control={control} setValue={setValue} errors={errors} config={config} />}
                </Box>
              )
            )}
          </form>

          {isWarningValidation && <WarningValidationForm isWarning={isWarningValidation} />}
        </Box>
      ) : (
        <Typography className="title-page" sx={{ textAlign: "center" }}>
          У вас нет прав доступа к этой странице
        </Typography>
      )}
    </>
  );
};
