import React from "react";
import { Layer,Group, Rect } from "react-konva";

export const ScrollCanvas = ({
    getequipmentsInRegion,
    widthWorkplace,
    heightWorkplace,
    canvasRef,
    image,
    scale,
    stageposition,
    setstageposition,
    transformCanvasData,

}) => {

    const handleScrollbarMouseUp = (e, typeScroll) => {
        if (typeScroll == "vertical") {
            setstageposition({ ...stageposition, y: -e.target.y() * scale });
        } else {
            setstageposition({ ...stageposition, x: -e.target.x() * scale });
        }
        const canvas = canvasRef.current;

        const coordinates = [-canvas.x() / scale, -canvas.y() / scale, (widthWorkplace / scale - canvas.x() / scale), (heightWorkplace / scale - canvas.y() / scale)];
        getequipmentsInRegion(coordinates, null);
    };

    const handleScrollbarMouseMove = (e, typeScroll) => {
        const canvas = canvasRef.current
        if (typeScroll == "vertical") {
            canvas.position({ x: canvas.x(), y: -e.target.y() * scale })
        } else {
            canvas.position({ y: canvas.y(), x: -e.target.x() * scale })
        }
    };


    return (

        <Layer>
            <Group>
                {image?.height * scale > heightWorkplace &&
                    <Rect
                        width={25}
                        height={heightWorkplace - (image.height * scale - heightWorkplace) / scale}
                        fill="#1876D2"
                        opacity={0.8}
                        x={widthWorkplace - 15 - 20}
                        y={-(transformCanvasData.y) / scale}
                        draggable
                        dragBoundFunc={(pos) => {
                            pos.x = widthWorkplace - 15 - 20;
                            const offsetY = heightWorkplace - (image.height * scale - heightWorkplace) / scale
                            if (pos.y + offsetY > heightWorkplace) pos.y = heightWorkplace - offsetY - 5
                            if (pos.y < 5) pos.y = 5
                            return pos;
                        }}
                        onMouseEnter={e => {
                            const container = e.target.getStage().container();
                            container.style.cursor = 'pointer';

                        }}
                        onMouseLeave={e => {

                            const container = e.target.getStage().container();
                            container.style.cursor = 'grab';

                        }}
                        onDragMove={(e) => {
                            handleScrollbarMouseMove(e, "vertical")
                        }}
                        onDragEnd={(e) => { handleScrollbarMouseUp(e, "vertical") }}
                    />
                }
                {image?.width * scale > widthWorkplace &&
                    <Rect
                        width={widthWorkplace - 20 - (image.width * scale - widthWorkplace - 20) / scale}
                        height={25}
                        fill="#1876D2"
                        opacity={0.8}
                        y={heightWorkplace - 20 - 15}
                        x={-(transformCanvasData.x) / scale}
                        draggable
                        dragBoundFunc={(pos) => {
                            pos.y = heightWorkplace - 20 - 15;
                            const offsetX = widthWorkplace - (image.width * scale - widthWorkplace) / scale
                            if (pos.x + offsetX > widthWorkplace) pos.x = widthWorkplace - offsetX - 5
                            if (pos.x < 5) pos.x = 5
                            return pos;
                        }}
                        onMouseEnter={e => {
                            const container = e.target.getStage().container();
                            container.style.cursor = 'pointer';

                        }}
                        onMouseLeave={e => {

                            const container = e.target.getStage().container();
                            container.style.cursor = 'grab';

                        }}
                        onDragMove={(e) => {
                            handleScrollbarMouseMove(e, "horizontal")
                        }}
                        onDragEnd={(e) => { handleScrollbarMouseUp(e, "horizontal") }}
                    />
                }
            </Group>

        </Layer>
    );
};
