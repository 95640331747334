import { useState } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import EquipmentCountIcon from "../../../../components/icons/Cabinet/EquipmentCountIcon";
import CreditIcon from "../../../../components/icons/Cabinet/CreditIcon";
import DropdownIcon from "../../../../components/icons/Cabinet/DropdownIcon";
import DefaultObjectIcon from "../../../../components/icons/Cabinet/DefaultObjectIcon";
import DeleteIcon from "../../../../components/icons/DeleteIcon";
import EmployeeCard from "../EmployeeCard";
import ChangeTariffModal from "../ChangeTariffModal";
import { DeleteObjectModal } from "../DeleteObjectModal";
import "./styles.css";
import RatingCell from "./RatingCell";
import { getRiskCategory } from "./helper";
import { EmployeeDialog } from "../../../EmployeeList/EmployeeDialog";

const ObjectPanel = ({ gridTypeValue, facility, activeObjectId, isOpen, onOpenPanel }) => {
  const { t } = useTranslation();
  const {
    address,
    availableEquipment,
    equipmentCount,
    name,
    photoLink,
    price,
    users,
    isBlocked,
    id,
    overallRating,
    riskCategory,
    isPersonalTariff,
    paymentDate,
    individualPeriodMonths,
  } = facility;
  const [isVisibleTariffModal, setIsVisibleTariffModal] = useState(false);
  const [isVisibleDeleteModal, setIsVisibleDeleteModal] = useState(false);
  const [isVisibleUserInfoModal, setIsVisibleUserInfoModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState();

  function handleCloseTariffModal() {
    setIsVisibleTariffModal(false);
  }

  function handleCloseDeleteModal() {
    setIsVisibleDeleteModal(false);
  }

  function handleOpenDeleteModal() {
    setIsVisibleDeleteModal(true);
  }

  function onClickOpenPanel(id) {
    if (id !== activeObjectId) {
      onOpenPanel(id);
    } else {
      onOpenPanel(-1);
    }
  }

  function openUserProfileModal(id) {
    setIsVisibleUserInfoModal(true);
    setSelectedUserId(id);
  }

  function convertData(isoDate) {
    const normalDate = new Date(isoDate);
    return normalDate.toLocaleDateString("ru-Ru");
  }

  return (
    <>
      <Box
        className="panel"
        sx={{ color: isBlocked && "var(--placeholder) !important", gap: gridTypeValue === 1 ? "9px" : "16px" }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box className="panel-header-wrapper">
            <Box className="panel-header">
              {photoLink ? (
                <Box className="panel-header-image">
                  <img style={{ filter: isBlocked && "grayscale(1)" }} src={photoLink} alt="Изображение объекта" />
                </Box>
              ) : (
                <Box className="default-object-icon">
                  <DefaultObjectIcon />
                </Box>
              )}
              <Box className="panel-header-text" sx={{ width: gridTypeValue === 1 ? "210px" : "222px" }}>
                <Typography>{name}</Typography>
                <Typography sx={{ fontSize: "14px", height: "40px" }} className="panel-header-hint">
                  {address}
                </Typography>
              </Box>
            </Box>

            {gridTypeValue === 1 && (
              <Box sx={{ display: "flex" }}>
                <Box
                  className={`${!isBlocked && "cursor"}`}
                  sx={{ margin: "auto 15px auto 0" }}
                  onClick={() => (isBlocked ? () => {} : onClickOpenPanel(id))}
                >
                  <DropdownIcon rotateDegree={!isOpen ? 0 : 180} />
                </Box>
                <RatingCell percent={overallRating || 0} isSmall={true} />
              </Box>
            )}
          </Box>

          {gridTypeValue === 0 && (
            <>
              <Box className="panel-item" sx={{ marginTop: "16px" }}>
                <Box className="panel-elements-count">
                  <EquipmentCountIcon />
                  <Typography>{equipmentCount} /</Typography>
                  <Typography className="panel-header-hint" sx={{ marginLeft: "-7px" }}>
                    {availableEquipment}
                  </Typography>
                </Box>
                <Box className="panel-elements-count" sx={{color: "var(--mainText)"}} >
                  <CreditIcon />
                  {isPersonalTariff ? (
                    <Tooltip title={`Следующая дата списания ${paymentDate ? convertData(paymentDate) : ""}`}>
                      <Box>
                        {price} ₽ за {individualPeriodMonths} мес.
                      </Box>
                    </Tooltip>
                  ) : (
                    <Typography>{price} ₽/мес</Typography>
                  )}
                </Box>
              </Box>

              {!isOpen && (
                <Box
                  className={`panel-item ${!isBlocked && "cursor"}`}
                  onClick={() => (isBlocked ? () => {} : onClickOpenPanel(id))}
                >
                  <Typography sx={{ fontWeight: "500" }}>Раскрыть</Typography>
                  <DropdownIcon />
                </Box>
              )}
            </>
          )}

          {isOpen && (
            <>
              <Box
                className="panel-item cursor"
                sx={gridTypeValue && { marginTop: "16px" }}
                onClick={() => setIsVisibleTariffModal(true)}
              >
                <Typography sx={{ fontWeight: "500" }}>Изменить тариф</Typography>
                <DropdownIcon rotateDegree={-90} />
              </Box>
              <Box className="panel-item cursor delete-btn" onClick={handleOpenDeleteModal}>
                {t("button:remove")} <DeleteIcon />
              </Box>
              <Box className="panel-item employees">
                <Typography sx={{ fontWeight: "500" }}>Сотрудники:</Typography>
                <Box className="panel-employees-wrapper">
                  {users.map((user) => (
                    <EmployeeCard key={user.id} user={user} openUserProfileModal={openUserProfileModal} />
                  ))}
                </Box>
              </Box>

              {riskCategory && (
                <Box className="panel-item employees">
                  <Typography sx={{ fontWeight: "500" }}>Категория риска:</Typography>
                  {getRiskCategory(riskCategory)}
                </Box>
              )}

              <Box className="panel-item cursor bottom" onClick={() => onClickOpenPanel(id)}>
                <Typography sx={{ fontWeight: "500" }}>Свернуть</Typography>
                <DropdownIcon rotateDegree={180} />
              </Box>
            </>
          )}
        </Box>
        {gridTypeValue === 0 && <RatingCell percent={overallRating || 0} />}
      </Box>

      {isVisibleTariffModal && (
        <ChangeTariffModal isVisible={isVisibleTariffModal} handleClose={handleCloseTariffModal} facility={facility} />
      )}

      {isVisibleDeleteModal && (
        <DeleteObjectModal
          isVisible={isVisibleDeleteModal}
          handleClose={handleCloseDeleteModal}
          facilityId={facility.id}
        />
      )}

      {isVisibleUserInfoModal && (
        <EmployeeDialog
          open={isVisibleUserInfoModal}
          onClose={() => setIsVisibleUserInfoModal(false)}
          employeeId={selectedUserId}
        />
      )}
    </>
  );
};

export default ObjectPanel;
