import React, { useMemo, useEffect, useRef, useState } from "react";
import { Box, Typography, Tooltip, Button, IconButton } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { ArrowIconLeftBlue } from "../../../components/icons/ArrowIcon";
import Switcher from "../../../components/Switcher";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";
import Fade from "@mui/material/Fade";
import closeBtn from "../../../../image/icons/closeInputFile.png";
import {
  useLazyGetRoomsColorsWithCurrentMsgQuery,
} from "../../../../middleware/equipmentsApi";
export const ListEvents = ({
  setselectedListItem,
  selectedListItem,
  logsMessagess,
  blinkingItem,
  setBlinkingItem,
  setisSetselectedSchemeItem,
  setLineColor,
  loadScheme,
  setValue,
  setScale,
  setstageposition,
  floor,
  StartTO,
  handleTO,
  setopenEventSide,
}) => {
  const dateFormat = "DD.MM.YYYY HH:mm";
  const [getRoomsColorsWithCurrentMsg] = useLazyGetRoomsColorsWithCurrentMsgQuery();

  const getRoomColorFromServer = async (itemm) => {
    const { messageId, floorInfoId } = itemm;
    const { data } = await getRoomsColorsWithCurrentMsg({ messageId, floorId: floorInfoId });
    return data;
  };

  const startBlinking = (itemm) => {
    const { id, borderColor, color } = itemm || {};
    setBlinkingItem(id);

  };

  const stopBlinking = () => {
    setLineColor("");

    setBlinkingItem(null);
  };


  const handleListItemClick = (itemm) => async (e) => {

    setselectedListItem(null);
    stopBlinking();
    if (blinkingItem !== itemm?.id) {

      if (floor != itemm.floorInfoId) {
        setValue('object', itemm.protectionObjectId);
        setValue('floor', itemm.floorInfoId);

      }
      console.log("itemm", itemm)
      const data = await getRoomColorFromServer(itemm);
      const copyData = { ...itemm, color: data?.[0].color, borderColor: data?.[0].borderColor };
      setselectedListItem(copyData);
      setisSetselectedSchemeItem(false)
      stopBlinking();
      startBlinking(copyData);
      loadScheme(true)
    }

  };
  return (
    <Box
      sx={{
        width: window.innerWidth <= 600 ? "100%" : "200px",
        border: "1px solid var(--border)",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#f8f9fd",
        borderRadius: "16px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          height: "30px",
          padding: "15px 0",
          alignSelf: "center",
        }}
      >
        <Typography
          sx={{
            textAlign: "center",
            placeSelf: "center",
            fontSize: 16,
            fontFamily: "var(--INTER)",
          }}
        >
          События
        </Typography>
        <Box sx={{ width: "48px", placeSelf: "center", marginLeft: "20px" }}>
          <Switcher
            value={StartTO}
            onChange={handleTO}
            sx={{}}
            options={[
              { status: true, name: "" },
              { status: false, name: "" },
            ]}
          />
        </Box>
        <IconButton
          sx={{ marginLeft: "12px" }}
          onClick={() => setopenEventSide(false)}
        >
          <Box component="img" src={closeBtn} sx={{}} />
        </IconButton>
      </Box>

      <List
        sx={{
          width: window.innerWidth <= 600 ? "100%" : "200px",
          maxHeight: "100%",
          overflowY: "auto",
          overflowX: "hidden",
          borderRadius: "4px",
        }}
      >
        {StartTO ? (
          logsMessagess && logsMessagess.length > 0 ? (
            logsMessagess?.map((item) => {
              return (
                <Tooltip
                  followCursor
                  placement={"right"}
                  title={`Дата: ${dayjs(item?.reactionDateTime).format(dateFormat).split(" ")}`}
                >
                  <ListItem
                    sx={{
                      border:
                        selectedListItem?.id == item?.id
                          ? `3px solid gray`
                          : item?.borderColor,
                      backgroundColor: item?.color,
                    }}
                    component="div"
                    disablePadding
                    onClick={handleListItemClick(
                      item,
                      item?.roomAddressId ? false : true
                    )}
                  >
                    <ListItemButton
                      onMouseEnter={(e) => {
                        if (item?.roomAddressName != "Зона не определена") {
                          e.target.style.cursor = "pointer";
                        }
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.cursor = "default";
                      }}
                    >
                      <Typography
                        sx={{
                          width: window.innerWidth <= 600 ? "90%" : "180px",
                        }}
                      >{`${item?.messageCodeName},${item?.roomAddressPlume}`}</Typography>
                    </ListItemButton>
                  </ListItem>
                </Tooltip>
              );
            })
          ) : (
            <Typography
              sx={{ fontSize: "20px", textAlign: "center", mt: "10px" }}
            >
              Нет событий
            </Typography>
          )
        ) : (
          <></>
        )}
      </List>
    </Box>
  );
};
