import InputText from "../../components/InputText";

export const DEFAULT_VALUE1 = {
  isBlocked: true,
  billingRateId: 0,
  billingPeriod: "",
};

export const DEFAULT_VALUE2 = {
  organizationBillingId: "",
  amount: null,
};

export const editDiscount = [
  {
    name: "discount",
    label: "Скидка",
    placeholder: "Введите значение",
    type: "number",
    shouldSetCoordinates: true,
    rules: { required: true },
    input: (props) => <InputText {...props} />,
  },
];
