import React from "react";

export const GraduationIcon = () => {
  return (
    <svg width="52" height="46" viewBox="0 0 52 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.0017 1.07898L1.85882 11.5395L26.0017 22L50.1445 11.5395L26.0017 1.07898Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.1914 16.3115L39.1675 27.6928C39.1675 27.6928 35.1815 32.9678 26.0039 32.9678C16.8263 32.9678 12.8189 27.6928 12.8189 27.6928L12.8215 16.3115"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M45.7031 35.3762V13.4968" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M45.7025 44.2604C43.2492 44.2604 41.2604 42.2718 41.2604 39.8185C41.2604 37.3652 43.2492 35.3762 45.7025 35.3762C48.1558 35.3762 50.1445 37.3652 50.1445 39.8185C50.1445 42.2718 48.1558 44.2604 45.7025 44.2604Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
