import React from "react";

export const ConsolesIcon = () => {
  return (
    <svg width="28" height="30" viewBox="0 0 28 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.9109 1H25.2666C25.2666 1 26.9999 1 26.9999 2.86668V15.9332C26.9999 15.9332 26.9999 17.7999 25.2666 17.7999H18.9109C18.9109 17.7999 17.1777 17.7999 17.1777 15.9332V2.86668C17.1777 2.86668 17.1777 1 18.9109 1Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.9999 12.8225H17.1777"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3333 21.5567V24.6442C18.3333 25.3314 17.8159 25.8886 17.1778 25.8886H2.15553C1.51733 25.8886 1 25.3316 1 24.6442V14.9998C1 14.3126 1.51733 13.7554 2.15553 13.7554H13.6545"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.77746 25.8889L5.33301 29"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5557 25.8889L14.0001 29"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.84668 8.31382C4.86836 6.89149 7.47988 6.89149 9.50157 8.31382"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3492 5.1056C8.23531 2.78706 4.11389 2.78706 1 5.1056"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
