export const getRequestData = (data) => {
  const {
    tariff,
    availableEquipment,
    individualPeriodDays,
    individualPeriodMonths,
    techSupportPrice,
    price,
  } = data;

  const preparedData = {
    ...data,
    availableEquipment: +availableEquipment,
    individualPeriodDays: +individualPeriodDays,
    individualPeriodMonths: +individualPeriodMonths,
    techSupportPrice: +techSupportPrice,
    price: +price,
    tariff: tariff === "До 500" ? 0 : tariff,
  };
  
  return preparedData;
};

export const getRequestDataFilesForAdd = (entityId, entityType, file) => {
  const formData = new FormData();

  if (!file) {
    return formData;
  }

  formData.append(`entityFileList[0].EntityId`, entityId);
  formData.append(`entityFileList[0].EntityType`, entityType);
  formData.append(`entityFileList[0].File`, file);
  formData.append(`entityFileList[0].ActionType`, "Add");

  return formData;
};
