import React, { useState, useEffect, useMemo } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";

import "./styles/style.css";

import { PATHS } from "../../../../constants";
import { useAction } from "../../../../hooks/actions";
import {
  useGetOrganizationQuery,
  useEditOrganizationMutation,
  useRemoveOrganizationMutation,
} from "../../../../middleware/apiOrganizations";
import { getRequestDataFilesForAdd } from "../../ResponsibleOrganizationsList/helpers";

import { useAddFileMutation } from "../../../../middleware/apiUploadFile";
import { getRequestData } from "../../ResponsibleOrganizationsList/helpers";

import BasicInformationResponsibleOrganizations from "../../ResponsibleOrganizationsList/BasicInformation";
import BasicInformation from "../BasicInformation";
import ModalRemove from "../../../components/Modals/ModalRemove";
import { WarningValidationForm } from "../../../components/Notifications";
import { TYPE_ID } from "../constants";
import BankDetails from "../../ResponsibleOrganizationsList/BankDetails";
import { useEditResponsibleOrganizationMutation } from "../../../../middleware/responsibleOrganizationsApi";
import { useDeleteResponsibleOrganizationMutation } from "../../../../middleware/responsibleOrganizationsApi";
import { useManipulateFilesMutation } from "../../../../middleware/apiUploadFile";
import { useDeleteOrganizationMutation } from "../../../../middleware/apiOrganizations";
import { useSendResponsibleOrganizationDeletionRequestMutation } from "../../../../middleware/responsibleOrganizationsApi";
import { getUpdatedResponsibleContracts } from "../helpers";
import { useWatchBicNumber } from "../AddOrganizationPage/hooks";

const EditOrganizationPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id, typeId } = useParams();

  const {
    changeTitlePage,
    setIsErrorRequest,
    setIsErrorSubmit,
    setIsSuccessSubmit,
  } = useAction();

  const {
    data: organization,
    isLoading: isLoadingOrganization,
    isError: isErrorGetOrganization,
    error: errorGetOrganization,
    refetch: refetchOrganization,
  } = useGetOrganizationQuery({ id, typeId });

  const [editOrganization, { isError: isErrorEdit, isSuccess: isSuccessEdit }] =
    useEditOrganizationMutation();
  const [
    editResponsibleOrganization,
    { isError: isErrorResponsibleEdit, isSuccess: isSuccessResponsibleEdit },
  ] = useEditResponsibleOrganizationMutation();
  const [deleteOrganization] = useDeleteOrganizationMutation();
  const [
    sendResponsibleOrganizationDeletionRequest,
    {
      isError: isErrorResponsibleRemove,
      isSuccess: isSuccessResponsibleRemove,
    },
  ] = useSendResponsibleOrganizationDeletionRequestMutation();
  const [
    removeOrganization,
    { isError: isErrorRemove, isSuccess: isSuccessRemove },
  ] = useRemoveOrganizationMutation();
  const [manipulateFiles, { isError: isErrorFile, isSuccess: isSuccessFile }] =
    useManipulateFilesMutation();
  const [deleteResponsibleOrganization] =
    useDeleteResponsibleOrganizationMutation();
  const [addFile] = useAddFileMutation();
  const isError =
    isErrorEdit ||
    isErrorRemove ||
    isErrorResponsibleRemove ||
    isErrorResponsibleEdit ||
    isErrorFile;
  const isSuccess =
    (isSuccessEdit || isSuccessResponsibleEdit) && isSuccessFile;
  const isRemoved = isSuccessRemove || organization?.isDeletionRequestSent;
  const isRemovedResponsible =
    isSuccessResponsibleRemove || organization?.deleted;

  const {
    control,
    reset,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    mode: "onChange",
  });

  const { BankInfo } = useWatchBicNumber({
    errors,
    control,
    setValue,
    typeId,
  });

  const isWarningValidation = !!Object.keys(errors).length;
  const [openModalRemove, setOpenModalRemove] = useState(false);

  const responsibleOrganizationSubmit = async (data) => {
    const { entities, files } = getRequestData(data);

    editResponsibleOrganization(entities).then((responseAdd) => {
      if (responseAdd.error) {
        return;
      }
      const formData = getRequestDataFilesForAdd(responseAdd?.data, files);

      manipulateFiles(formData).then((responseFiles) => {
        if (responseFiles.error) {
          deleteResponsibleOrganization(responseAdd?.data?.id);
          return;
        }
        navigate(PATHS.MY_ORGANIZATIONS);
      });
    });
  };

  const organizationSubmit = async (data) => {
    data.organizationAddresses = data.organizationAddresses.map((item) => {
      item = {
        ...item,
        ...item.address,
      };
      delete item.address;
      return item;
    });
    const {
      file,
      typeId,
      licenseNumber,
      licenseExpirationDate,
      organizationResponsibleBankDetails,
      organizationResponsibleAddresses,
      organizationResponsibleContracts,
      ...rest
    } = data || {};

    const requestData = {
      typeId: TYPE_ID[typeId],
      ...rest,
    };

    editOrganization(requestData).then((result) => {
      if ("error" in result) {
        return;
      } else if (!file.filesToAdd?.length) {
        navigate(PATHS.MY_ORGANIZATIONS);
        return;
      }
      const formData = new FormData();
      // заглушка (ожиается изменение на стороне сервера)
      file.filesToAdd.forEach((fileItem) => formData.append(`File`, fileItem));
      formData.append("OrganizationId", result?.data?.id);
      addFile(formData).then((response) => {
        if ("error" in response) {
          deleteOrganization(result?.data?.id);
          return;
        }
        navigate(PATHS.MY_ORGANIZATIONS);
      });
    });
  };

  const onSubmit = async (data) => {
    if (typeId === "2") {
      await responsibleOrganizationSubmit(data);
      return;
    }
    await organizationSubmit(data);
  };

  const handleCancel = () => navigate(-1);

  const handleRemove = () => setOpenModalRemove(true);

  const sendDeletionRequest = () => {
    sendResponsibleOrganizationDeletionRequest(id).then(() =>
      refetchOrganization(id)
    );
  };

  useEffect(() => {
    changeTitlePage(organization?.name || "");
  }, [organization, changeTitlePage]);

  useEffect(() => () => changeTitlePage(""), [changeTitlePage]);

  useEffect(() => {
    setIsErrorRequest(errorGetOrganization || isErrorGetOrganization);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorGetOrganization, isErrorGetOrganization]);

  useEffect(() => {
    setIsErrorSubmit(isError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    setIsSuccessSubmit(isSuccess);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (organization) {
      
      const updatedOrganization = typeId === "2"
        ? {
            ...organization,
            typeId: 2,
            address:organization?.organizationResponsibleAddresses[0]?.address,
            bankDetails:organization?.organizationResponsibleBankDetails,
            organizationResponsibleContracts: getUpdatedResponsibleContracts(organization?.organizationResponsibleContracts),
          }
        : {
          ...organization,
          address:organization?.organizationAddresses[0]?.address,
        }
        console.log("updatedOrganization",updatedOrganization)
      reset({
        ...updatedOrganization,
        file: {
          filesPrev: organization?.ogrnNumberFileName
            ? [{ fileName: organization?.ogrnNumberFileName, fileLink: organization?.ogrnNumberFileId }]
            : [{ fileName: "Без имени", fileLink: organization?.ogrnNumberFileId || "" }],
          filesToAdd: [],
          filesToDelete: [],
        },
      });
      console.log("addd",getValues(`address`))
      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization, reset]);

  const renderedComponent = useMemo(
    () =>
      typeId === "2" ? (
        <>
          <BasicInformationResponsibleOrganizations
            okved={organization?.okvedCode}
            control={control}
            errors={errors}
            watch={watch}
            setValue={setValue}
            isEditMode={true}
          />
           {/*<ContractEntities control={control} errors={errors} />*/}
          <BankDetails control={control} errors={errors} />
        </>
      ) : (
        <>
          <BasicInformation
              okved={organization?.okvedCode}
              control={control}
              errors={errors}
              watch={watch}
              setValue={setValue}
          />
        </>
      ),
    [control, errors, typeId]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { refetchOrganization() }, []);

  return (
    <Box className="legal-entity">
      {isRemoved && (
        <Typography className="legal-entity-banner">
          {t("notification:banner_removed")}
        </Typography>
      )}
      {isRemovedResponsible && (
        <Typography className="legal-entity-banner">
          {t("notification:banner_removed_responsible")}
        </Typography>
      )}
      <Box className="legal-entity-header">
        <Typography className="title-page">
          {organization?.name || ""}
        </Typography>
        <Box className="legal-entity-header-buttons">
          <Button
            className="btn btn-remove"
            variant="outlined"
            onClick={handleRemove}
            disabled={isRemovedResponsible || isRemoved}
          >
            {t("button:remove")}
          </Button>
          <Button className="btn btn-outlined-grey" onClick={handleCancel}>
            {t("button:comeback")}
          </Button>
          <Button
            form="myform"
            className="btn btn-primary-fill"
            type="submit"
            disabled={isSubmitSuccessful && !isError}
          >
            {t("button:save")}
          </Button>
        </Box>
      </Box>

      {!isLoadingOrganization && (
        <form
          className="scrolled-form"
          id="myform"
          onSubmit={handleSubmit(onSubmit)}
        >
          {renderedComponent}
        </form>
      )}

      <ModalRemove
        open={openModalRemove}
        setOpen={setOpenModalRemove}
        action={
          typeId === "2" ? sendDeletionRequest : () => removeOrganization(id)
        }
        text="remove_legal_entity"
      />

      {isWarningValidation && (
        <WarningValidationForm isWarning={isWarningValidation} />
      )}
    </Box>
  );
};

export default EditOrganizationPage;
