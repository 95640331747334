import React, { useState, useEffect, useRef } from "react";
import { Stage, Layer, Line, Group, Image, Text } from "react-konva";
import { useWatch } from "react-hook-form";
import { Box } from "@mui/material";

import { loadImg } from "../../../helpers/helper";
import { getParamsText } from "./helpers";
import { ICON_SIZE } from "../../../constants";
import { getEquipmentIcon } from "../../../helpers/getEquipmentIcon";
import { useChartsIcons } from "../../pages/EquipmentDetails/hooks";

import alarm from "../../components/icons/alarm.svg";
import ModalFullScreen from "../../pages/Equipments/ImageSchema/ModalFullScreen";
import DonutChart from "../DonutChart";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../../constants";
import { useAction } from "../../../hooks/actions";
import { useAppSelector } from "../../../hooks/redux";

export const SchemaWithEquipmentsModal = ({
  name,
  width,
  height,
  schemaImg,
  roomAddresses = [],
  setValue,
  control,
  draggable = false,
  title,
  data,
  xyGroup,
  setxyGroup,
  fullscreen = false,
  setFullScreen,
}) => {
  const canvasRef = useRef(null);
  
  const [image, setImage] = useState(null);
  const [icons, setIcons] = useState(null);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const navigate = useNavigate();
  const { SCHEMA_CANVAS } = useAppSelector((state) => state.settings);
  const { setCanvasSize } = useAction();

  const [ChartsList, getSrcSVG] = useChartsIcons({
    Chart: DonutChart,
    equipments: data,
  });

  const drawIcons = async (
    data,
    height = SCHEMA_CANVAS.HEIGHT,
    width = SCHEMA_CANVAS.WIDTH
  ) => {
    if (data?.length) {
      const response = await Promise.all(
        data.map(
          async (
            {
              id,
              floorSchemeCoordinateX: x,
              floorSchemeCoordinateY: y,
              typeId,
              workingCorrectly,
            },
            index
          ) => {
            return loadImg(getEquipmentIcon(typeId)).then(async (icon) => {
              const scaleX = width / SCHEMA_CANVAS.WIDTH;
              const scaleY = height / SCHEMA_CANVAS.HEIGHT;
              const scaleSize = ((width * icon.width) / 800) / icon.width;
              icon.width *= scaleSize;
              icon.height *= scaleSize;
              const sizeIcon = icon.width;
              return loadImg(getSrcSVG(index)).then(async (chartIcons) => {
                  return loadImg(alarm).then(async (alarmIcon) => {
                  alarmIcon.width *= scaleSize;
                  alarmIcon.height *= scaleSize;
                  const iconWithChart = {
                    id,
                    x: x * scaleX,
                    y: y * scaleY,
                    img: icon,
                    chart: {
                      x: sizeIcon - sizeIcon / 5 - 2,
                      y: -(sizeIcon*2 / 5),
                      img: chartIcons,
                      width: 17 * scaleSize,
                      height: 17 * scaleSize,
                    },
                  };
  
                  const alarm = {
                    x: -(alarmIcon.width / 2),
                    y: sizeIcon - alarmIcon.width / 1.5,
                    img: alarmIcon,
                  };
  
                  return !workingCorrectly
                    ? { ...iconWithChart, alarm }
                    : iconWithChart;
                });
              });
            });
          }
        )
      );
      setIcons(response);
    } else {
      setIcons([]);
    }
  };

  const drawScheme = async (schemaImg, height, width) => {
    await loadImg(schemaImg).then((image) => {
      setCanvasSize({
        WIDTH: image.width,
        HEIGHT: image.height,
      });
      image.width = width;
      image.height = height;
      setImage(image);
    });
  };

  useEffect(() => {
    schemaImg && drawScheme(schemaImg, height, width);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schemaImg, height, width]);

  useEffect(() => {
    if (data) {
      const newData = data?.filter(({ floorSchemeCoordinateX, floorSchemeCoordinateY }) =>
        floorSchemeCoordinateX !== null && floorSchemeCoordinateY !== null
      );
      SCHEMA_CANVAS.WIDTH && drawIcons(newData, height, width);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, height, width, SCHEMA_CANVAS]);

  const handleMouseOver = () => {
    if (draggable) document.body.style.cursor = "pointer";
  };

  const handleMouseOut = () => {
    document.body.style.cursor = "default";
  };

  const handleDragEnd = ({ id }) => {
    const { x, y } = position || {};
    const scaleX = SCHEMA_CANVAS.WIDTH / width;
    const scaleY = SCHEMA_CANVAS.HEIGHT / height;

    const requestData = data.map((item) => {
      return item.id === id
        ? {
            ...item,
            floorSchemeCoordinateX: x * scaleX,
            floorSchemeCoordinateY: y * scaleY,
          }
        : item;
    });

    setValue && setValue(name, requestData);
  };
  const findValueById = (dataArray, id, coor) => {
    const foundObject = dataArray?.find(item => item.id === id);
    return foundObject ? coor=="x" ? foundObject.x : foundObject.y : 0; // Возвращает значение, если объект найден, иначе возвращает null или другое значение по умолчанию
  };
  return (
    <Box sx={{ position: "relative" }}>
      <ChartsList />

      {!!setFullScreen && (
        <ModalFullScreen
          open={fullscreen}
          setOpen={setFullScreen}
          title={title || ""}
          canvasRef={canvasRef.current?.canvas._canvas || null}
          SCHEMA_CANVAS={SCHEMA_CANVAS}
        />
      )}
      <Stage width={width} height={height}>
        <Layer ref={canvasRef}>
          <Image image={image} x={0} y={0} />
          {roomAddresses?.map(({ id, coordinates, name, plumes }, index) => (
            <Group key={id || index}
            x={findValueById(xyGroup,id,"x")}
            y={findValueById(xyGroup,id,"y")}
            >
              <Line
                points={coordinates}
                stroke="red"
                fill="#f1b8bc80"
                closed={true}
              />
              <Text {...getParamsText({ coordinates, text: name, line: 0 })} />
              <Text {...getParamsText({ coordinates, text: plumes?.[0], line: 1 })} />
            </Group>
          ))}
          {!!icons?.length &&
            icons.map(({ id, x, y, img, chart, alarm }, index) => (
              <Group
                key={id || index}
                draggable={draggable}
                onDragEnd={handleDragEnd.bind(null, { id })}
                onMouseOut={handleMouseOut}
                onMouseOver={handleMouseOver}
                dragBoundFunc={(pos) => {
                  if (pos.y <= 0) pos.y = 10;
                  if (pos.y > height - ICON_SIZE.HEIGHT)
                    pos.y = height - ICON_SIZE.HEIGHT;

                  if (pos.x <= 0) pos.x = 1;
                  if (pos.x > width - ICON_SIZE.WIDTH)
                    pos.x = width - ICON_SIZE.WIDTH;
                  
                  setPosition(pos);
                }}
                x={x}
                y={y}
              >
                <Image image={img} x={0} y={0} onClick={()=>{navigate(PATHS.GET_EQUIPMENT_DETAILS(id))}} />
                <Image
                  image={chart.img}
                  onClick={()=>{navigate(PATHS.LIST_TASKS+`?equip=${id}`)}}
                  x={chart.x}
                 
                  y={chart.y}
                  width={chart.width}
                  height={chart.height}
                />
                {!!alarm && <Image image={alarm.img} x={alarm.x} y={alarm.y} />}
              </Group>
            ))}
        </Layer>
      </Stage>
    </Box>
  );
};
