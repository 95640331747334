import React, { useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { getDataToChart } from "../../../../helpers/helper";
import FormItem from "../../../components/FormItem";
import { useTranslation } from "react-i18next";
import TaskStateList from "../../../components/TaskStateList/TaskStateList";
import { manyPlumesConfig, settingsEquipment } from "../form-config";
import ManyPlumes from "../ManyPlumes";

const BasicInformationForm = ({ control, errors, config, setValue, getValues, data, watch }) => {
  const { t } = useTranslation();
  const protectionObjectId = getValues("facilityId");
  const isEnableMultiPlume = watch("isEnableMultiPlume");

  const dataToolTip = useMemo(
    () =>
      data
        ? getDataToChart({
            jobChart: data?.jobChart,
            action: `?equipmentId=${data?.id}&equipmentName=${data?.name}`,
          })
        : [],
    [data]
  );

  const renderComponentField = ({ name, ...props }, i) => (
    <Box key={i} className="resizing_basic_forms">
      {props.title && <Typography className="label">{t(props.title)}</Typography>}
      <FormItem {...props} name={name} />
    </Box>
  );

  const renderCustomComponent = ({ Component, ...props }, i) =>
    Component && <Component key={i} data={data} control={control} setValue={setValue} />;

  const shouldRenderField = ({ name }, i) => {
    if (!data) return;
    const integrationFields = ["plume", "integrationNumber", "keyType", "technicalConnection", "isEnableMultiPlume"];
    const plumeFields = ["plume", "keyType"];
    const isIntegrationField = integrationFields.includes(name);
    const isPlumeField = plumeFields.includes(name);

    if (isEnableMultiPlume && isPlumeField) {
      return false;
    }

    if (!data.hasIntegration && isIntegrationField) {
      return false;
    }

    return true;
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          alignItems: "baseline",
          gap: "20px",
        }}
      >
        {config.map((configItem, i) => {
          if (shouldRenderField(configItem, i)) {
            if (configItem.Component) {
              return renderCustomComponent(configItem, i);
            }

            return renderComponentField(
              {
                ...configItem,
                control,
                errors,
                setValue,
                getValues,
                protectionObjectId,
                ...configItem.rest,
              },
              i
            );
          }
        })}
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        {isEnableMultiPlume && <ManyPlumes data={data} control={control} setValue={setValue} watch={watch} />}

        {!isEnableMultiPlume &&
          settingsEquipment.map(
            ({ name, title, label, input, Component, flexBasis, ...rest }, i) =>
              (Component ? (
                <Component key={i} data={data} control={control} setValue={setValue} />
              ) : (
                (name != "technicalConnection" && name != "generateTasks") ||
                ((name == "generateTasks" || name == "technicalConnection") && data?.hasIntegration)
              )) && (
                <Box key={i} sx={{ width: "100%", ml: "10px" }}>
                  {!!title && <Typography className="label">{t(title)}</Typography>}
                  <FormItem
                    control={control}
                    errors={errors}
                    name={name}
                    setValue={setValue}
                    getValues={getValues}
                    label={label}
                    input={input}
                    {...rest}
                  />
                </Box>
              )
          )}
      </Box>
      <TaskStateList
        data={dataToolTip}
        lineHeight="24px"
        height="8px"
        width="8px"
        fontWeight="500"
        isShowAlternativeName={false}
      />
    </>
  );
};

export default BasicInformationForm;
