import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useAction } from "../../../../hooks/actions";
import { ArrowInfoCenter } from "../../../components/icons/ArrowInfoCenter";
import OperativeInfo from "./OperativeInfo";
import NewsPanel from "./NewsPanel";
import {
  useDeleteNewsMutation,
  useEditNewsPriorityMutation,
  useGetNewsListQuery,
} from "../../../../middleware/usersApi";
import EditNewsModal from "../EditNewsModal";
import NewsModal from "../NewsModal";
import ConfirmDeleteNewsModal from "./ConfirmDeleteNewsModal";
import "./styles.css";

const NewsTape = ({
  isRefetchFlag,
  isRefetchOperativeNewsFlag,
}) => {
  const containerRef = useRef(null);
  const [isOpenNewsModal, setIsOpenNewsModal] = useState(false);
  const [isOpenEditNewsModal, setIsOpenEditNewsModal] = useState(false);
  // const [savedDataInfo, setSaveDataInfo] = useState()
  const [isOpenConfirmDeleteNewsModal, setIsOpenConfirmDeleteModal] =
    useState(false);
  const [newsDataForEdit, setNewsDataForEdit] = useState({});
  const [newsDataForModal, setNewsDataForModal] = useState({});
  const [pageNumber, setIsPageNumber] = useState(0);
  const { data: newListData, refetch: refetchNewsList } = useGetNewsListQuery({
    pageNumber,
    type: 0,
  });

  // useEffect(() => {
  //   setSaveDataInfo(newListData)
  // }, [newListData])
  const { setIsErrorSubmit, setIsSuccessSubmit } = useAction();
  const [editNewsPriority] = useEditNewsPriorityMutation();
  const [deleteNews] = useDeleteNewsMutation();

  const handleWheel = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();

    const delta = event.deltaY || event.detail || event.wheelDelta;
    containerRef.current.scrollLeft -= delta * 0.8;

    // Проверяем, достигли ли мы конца списка
    if (
      containerRef.current.scrollWidth - containerRef.current.clientWidth <=
      containerRef.current.scrollLeft + 1
    ) {
      if (event.currentTarget.childElementCount < 15) {
        return;
      }
      setIsPageNumber(+containerRef.current.id + 1);
      containerRef.current.scrollLeft = 0;
    }

    // Проверяем, достигли ли мы начала списка
    if (containerRef.current.scrollLeft <= 0 && containerRef.current.id !== "0") {
      setIsPageNumber(+containerRef.current.id - 1);
      containerRef.current.scrollLeft = 0;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Это небходимо, чтобы повесить на слушатель вот эту настройку { passive: false }
  useEffect(() => {
    const handleWheelEvent = (event) => {
      handleWheel(event);
    };

    if (containerRef.current) {
      containerRef.current.addEventListener("wheel", handleWheelEvent, {
        passive: false,
      });
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener("wheel", handleWheelEvent, {
          passive: false,
        });
      }
    };
  }, [handleWheel]);

  const handleVisibleNewsModal = (isVisible, newsInfo) => {
    setIsOpenNewsModal(isVisible);
    if (newsInfo) {
      setNewsDataForModal(newsInfo);
    }
  };

  const handleCloseEditNewsModal = () => {
    setIsOpenEditNewsModal(false);
    refetchNewsList();
  };

  const handleChangeNewsPriority = (e, prevPriority, newsId) => {
    e.stopPropagation();
    const changedData = {
      id: newsId,
      priority: prevPriority === 1 ? 2 : 1,
    };
    editNewsPriority(changedData).then((response) => {
      if ("error" in response) {
        setIsErrorSubmit(response.error);
      } else {
        setIsSuccessSubmit(true);
        refetchNewsList();
      }
    });
  };

  const handleClickOnArrow = (direction) => {
    const scrollDistance = direction === "left" ? -0.3 : 0.4;
    const animationDuration = 300; // Время анимации в миллисекундах
  
    const animateScroll = () => {
      let currentScrollLeft = containerRef.current.scrollLeft;
      let targetScrollLeft =
        currentScrollLeft + scrollDistance * containerRef.current.offsetWidth;
  
      const intervalId = setInterval(() => {
        currentScrollLeft +=
          scrollDistance * (containerRef.current.offsetWidth / 20);
        containerRef.current.scrollLeft = currentScrollLeft;
  
        // Проверяем, достигли ли мы конца списка
        if (direction === "right") {
          if (
            containerRef.current.scrollWidth - 
            containerRef.current.clientWidth <= 
            currentScrollLeft + 1
          ) {
            if (newListData.data.length < 15) {
              return;
            }
            setIsPageNumber(prevCount => prevCount + 1);
            containerRef.current.scrollLeft = 0;
            clearInterval(intervalId);
          }
        }
  
        if (
          Math.abs(targetScrollLeft - currentScrollLeft) <=
          containerRef.current.offsetWidth / 20
        ) {
          clearInterval(intervalId);
        }
      }, 16); // 60 fps
  
      return intervalId;
    };
  
    const animationId = animateScroll();
  
    setTimeout(() => {
      clearInterval(animationId);
    }, animationDuration);
  };

  const renderNewsList = () => {
    if (!newListData?.data?.length) {
      return (
        <Typography sx={{ margin: "auto" }}>Нет новых новостей</Typography>
      );
    }

    return (
      <>
        {newListData.data.map((news) => (
          <NewsPanel
            key={news.id}
            news={news}
            handleVisibleNewsModal={handleVisibleNewsModal}
            handleDeleteNews={handleDeleteNews}
            handleEditNews={handleEditNews}
            handleChangeNewsPriority={handleChangeNewsPriority}
          />
        ))}
      </>
    );
  };

  const deleteNewsAfterConfirm = (id) => {
    deleteNews(id).then((response) => {
      if ("error" in response) {
        setIsErrorSubmit(response.error);
      } else {
        setIsSuccessSubmit(true);
        setIsOpenConfirmDeleteModal(false);
        refetchNewsList();
      }
    });
  };

  const handleDeleteNews = (e, news) => {
    e.stopPropagation();
    setIsOpenConfirmDeleteModal(true);
    setNewsDataForModal(news);
  };

  const handleEditNews = (e, news) => {
    e.stopPropagation();
    setIsOpenEditNewsModal(true);
    setNewsDataForEdit(news);
  };

  const showOtherNews = (action, currentNewsId) => {
    const currentElementIndex = newListData.data.findIndex((element) => {
      return element.id === currentNewsId;
    });
    if (
      currentElementIndex === -1 ||
      (currentElementIndex === 0 && action === "prev") ||
      (newListData.data.length - 1 === currentElementIndex && action === "next")
    )
      return;
    if (action === "next") {
      setNewsDataForModal(newListData.data[currentElementIndex + 1]);
    } else {
      setNewsDataForModal(newListData.data[currentElementIndex - 1]);
    }
  };

  useEffect(() => {
    refetchNewsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefetchFlag]);

  return (
    <>
      <Box className="news-container">
        <Box className="news-list-container">
          <Box className="news-list-header">
            <Typography>Новости</Typography>
            <Box className="news-list-arrows">
              <Box onClick={() => handleClickOnArrow("left")}>
                <ArrowInfoCenter rotate={180} />
              </Box>
              <Box onClick={() => handleClickOnArrow("right")}>
                <ArrowInfoCenter />
              </Box>
            </Box>
          </Box>
          <Box
            id={pageNumber}
            ref={containerRef}
            className="news-panel-container"
            onWheel={handleWheel}
          >
            {renderNewsList()}
          </Box>
        </Box>

        <OperativeInfo
          isRefetchFlag={isRefetchOperativeNewsFlag}
        />
      </Box>

      {isOpenConfirmDeleteNewsModal && (
        <ConfirmDeleteNewsModal
          isVisible={isOpenConfirmDeleteNewsModal}
          handleClose={() => setIsOpenConfirmDeleteModal(false)}
          newsData={newsDataForModal}
          deleteNews={deleteNewsAfterConfirm}
        />
      )}

      {isOpenNewsModal && (
        <NewsModal
          isVisible={isOpenNewsModal}
          handleClose={() => handleVisibleNewsModal(false)}
          newsDataForModal={newsDataForModal}
          showOtherNews={showOtherNews}
        />
      )}

      {isOpenEditNewsModal && (
        <EditNewsModal
          isVisible={isOpenEditNewsModal}
          handleClose={handleCloseEditNewsModal}
          newsDataForEdit={newsDataForEdit}
        />
      )}
    </>
  );
};

export default NewsTape;
