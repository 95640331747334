import React from "react";
import "./style.css";
import "./media.css";


const NewsWidget = () => {
  return (
    <div className="news-widget">
      {/* <div className="news-widget-dote-container">
        <div className="news-widget-dote active"></div>
        <div className="news-widget-dote"></div>
        <div className="news-widget-dote"></div>
        <div className="news-widget-dote"></div>
        <div className="news-widget-dote"></div>
        <div className="news-widget-dote"></div>
        <div className="news-widget-dote"></div>
      </div> */}
      <div className="news-widget-info">
        <p className="news-widget-title">Последние новости</p>
        <p className="news-widget-date">24.01.2025, Новый раздел «Инфоцентр»</p>
        <p className="news-widget-text">
          Добавлен новый раздел, в котором представлены новости в области ПБ,
          ежедневные сводки МЧС и видео-уроки по работе с системой ВДПО PRO.
        </p>
      </div>
    </div>
  );
};

export default NewsWidget;
