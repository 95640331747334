import React from "react";
import { Box, Button, Slider } from "@mui/material";

import { config, formFields } from "../form-config";

import PageheaderNav from "../../../components/PageheaderNav";
import FormItem from "../../../components/FormItem";
import SelectSimple from "../../../components/SelectSimple";
import { useTranslation } from "react-i18next";

export const ToolbarPlans = ({
    control,
    isFloorScheme,
    getequipmentsInRegion,
    $container,
    reloadScheme,
    openEventSide,
    setopenEventSide,
    scale,
    scaleScheme,
    setScale,
    setstageposition,
    stageposition,
    minScale,
    changeScrollsPositionandSize,
    canvasRef,
    handlestagingTO,
    widthWorkplace,
    heightWorkplace,
    screenRelatedWidth,
    screenRelatedHeight,
    searchObject,
    setsearchObject,

}) => {

    const { t } = useTranslation();
    const ITEMS_NAV = [
        { text: "plume_and_address", permissions: "facility_plan" },
        { text: "func_tests", permissions: "documentation" },
        { text: "documents", permissions: "documentation" },
        { text: "planning", permissions: "planning" },
    ];

    const handleChangeSliderCommitted = (event, newValue) => {
        const canvas = canvasRef.current;

        const centerX = (widthWorkplace - 20) / 2
        const centerY = (heightWorkplace - 20) / 2
        const pointTo = {
            x: (centerX - canvas.x()) / scaleScheme,
            y: (centerY - canvas.y()) / scaleScheme,
        };
        const ppointTo = {
            x: centerX - (pointTo.x * newValue),
            y: centerY - (pointTo.y * newValue),
        };

        if (newValue <= 1) {
            reloadScheme();
        } else if (newValue > 1 && newValue < minScale) {
            reloadScheme(false);
            setScale(newValue);
        } else {
            setScale(newValue);
            setstageposition({
                x: -Math.abs(ppointTo.x),
                y: -Math.abs(ppointTo.y),
            });
            const coordinates = [
                -ppointTo.x / scaleScheme,
                -ppointTo.y / scaleScheme,
                (widthWorkplace - ppointTo.x) / scaleScheme,
                (heightWorkplace - ppointTo.y) / scaleScheme,
            ];
            getequipmentsInRegion(coordinates, null);
        }
    };

    const handleChangeSlider = (event, newValue) => {
        const canvas = canvasRef.current;
        let centerX = 0
        let centerY = 0
        let pointX = 0
        let pointY = 0
        let ppointX = 0
        let ppointY = 0

        if (widthWorkplace > screenRelatedWidth * scaleScheme) {
            centerX = (widthWorkplace - 20) / 2;
            pointX = (centerX - canvas.x())
            ppointX = 0
        } else {
            centerX = (widthWorkplace - 20) / 2;
            pointX = (centerX - canvas.x()) / scaleScheme
            ppointX = centerX - (pointX * newValue)
        }

        if (heightWorkplace > screenRelatedHeight * scaleScheme) {
            centerY = (heightWorkplace - 20) / 2;
            pointY = (centerY - canvas.y()) / scaleScheme
            ppointY = 0
        }
        else {
            centerY = (heightWorkplace - 20) / 2;
            pointY = (centerY - canvas.y()) / scaleScheme
            ppointY = centerY - (pointY * newValue)
        }


        const ppointTo = {
            x: ppointX,
            y: ppointY,
        };
        console.log("(widthWorkplace - 20)-screenRelatedWidth * scaleScheme", ppointTo)
        changeScrollsPositionandSize()
        setScale(newValue);
        setstageposition({
            x: -Math.abs(ppointTo.x),
            y: -Math.abs(ppointTo.y),
        });
    };

    const zoomOutClick = () => {
        let newScaleScheme = scaleScheme - minScale;
        
        const canvas = canvasRef.current;
        let centerX = 0
        let centerY = 0
        let pointX = 0
        let pointY = 0
        let ppointX = 0
        let ppointY = 0

        if (widthWorkplace > screenRelatedWidth * scaleScheme) {
            centerX = (widthWorkplace - 20) / 2;
            pointX = (centerX - canvas.x())
            ppointX = -screenRelatedWidth / scaleScheme * newScaleScheme / 2
        } else {
            centerX = (widthWorkplace - 20) / 2;
            pointX = (centerX - canvas.x()) / scaleScheme
            ppointX = centerX - (pointX * newScaleScheme)
        }

        if (heightWorkplace > screenRelatedHeight * scaleScheme) {
            centerY = (heightWorkplace - 20) / 2;
            pointY = (centerY - canvas.y()) / scaleScheme
            ppointY = -screenRelatedHeight / scaleScheme * newScaleScheme / 2
        }
        else {
            centerY = (heightWorkplace - 20) / 2;
            pointY = (centerY - canvas.y()) / scaleScheme
            ppointY = centerY - (pointY * newScaleScheme)
        }
        if (newScaleScheme <= 1) {
            newScaleScheme = 1
            ppointX = 0
            ppointY = 0
          }
        const ppointTo = {
            x: ppointX,
            y: ppointY,
        };


        if (newScaleScheme <= 1) {
            reloadScheme();
        } else if (newScaleScheme > 1 && newScaleScheme < minScale) {
            reloadScheme(false);
            setScale(newScaleScheme);
        } else {
            setstageposition(ppointTo);
            setScale(newScaleScheme);
            const coordinates = [
                -ppointTo.x / newScaleScheme,
                -ppointTo.y / newScaleScheme,
                widthWorkplace / newScaleScheme - ppointTo.x / newScaleScheme,
                heightWorkplace / newScaleScheme - ppointTo.y / newScaleScheme,
            ];
            getequipmentsInRegion(coordinates, null);
        }
    };

    const zoomInClick = () => {
        const canvas = canvasRef.current;
        let newScaleScheme = scaleScheme + minScale;

        if (newScaleScheme > 20) newScaleScheme = 20

        let centerX = 0
        let centerY = 0
        let pointX = 0
        let pointY = 0
        let ppointX = 0
        let ppointY = 0

        if (widthWorkplace >= screenRelatedWidth * scaleScheme) {
            centerX = (widthWorkplace - 20) / 2;
            pointX = (centerX - canvas.x())
            ppointX = -screenRelatedWidth / scaleScheme * newScaleScheme / 2
        } else {
            centerX = (widthWorkplace - 20) / 2;
            pointX = (centerX - canvas.x()) / scaleScheme
            ppointX = centerX - (pointX * newScaleScheme)
        }

        if (heightWorkplace >= screenRelatedHeight * scaleScheme) {
            centerY = (heightWorkplace - 20) / 2;
            pointY = (centerY - canvas.y()) / scaleScheme
            ppointY = -screenRelatedHeight / scaleScheme * newScaleScheme / 2
        }
        else {
            centerY = (heightWorkplace - 20) / 2;
            pointY = (centerY - canvas.y()) / scaleScheme
            ppointY = centerY - (pointY * newScaleScheme)
        }

        const ppointTo = {
            x: ppointX,
            y: ppointY,
        };

        if (newScaleScheme >= minScale) {
            const coordinates = [
                -ppointTo.x / newScaleScheme,
                -ppointTo.y / newScaleScheme,
                widthWorkplace / newScaleScheme - ppointTo.x / newScaleScheme,
                heightWorkplace / newScaleScheme - ppointTo.y / newScaleScheme,
            ];

            getequipmentsInRegion(coordinates, null);
        } else if (newScaleScheme > 1 && newScaleScheme < minScale) {
            reloadScheme(false);
        }
        setstageposition(ppointTo);
        setScale(newScaleScheme);
        changeScrollsPositionandSize()
    };

    return (

        <Box ref={$container}>
            <Box
                className="plume-form_wrapper-select_container"
                sx={{
                    display: "flex",
                    marginBottom: "24px",
                    alignItems: "end",
                    justifyContent: "space-between",
                }}
            >
                <PageheaderNav itemsNav={ITEMS_NAV} />
                <Box sx={{ display: "flex", gap: "16px" }}>
                    {!openEventSide && (
                        <Box
                            className="full_screen_icon-wrapper"
                            sx={{ alignItems: "end" }}
                        >
                            <Box
                                className="list"
                                onClick={() => setopenEventSide(true)}
                            />
                        </Box>
                    )}
                </Box>
            </Box>

            <Box
                sx={{
                    display: "flex",
                    gap: "16px",
                    mb: "40px",
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                }}
            >
                <Box sx={{ display: 'flex', gap: '16px' }}>
                    {config.map(
                        ({ id, name, label, type, isCanDisabled, ...rest }) => (
                            <FormItem
                                key={id}
                                name={name}
                                control={control}
                                label={label}
                                type={type}
                                searchObject={searchObject}
                                setsearchObject={setsearchObject}
                                input={(props) => <SelectSimple {...props} />}
                                {...rest}
                            />
                        )
                    )}
                </Box>
                <Box sx={{ display: 'flex', gap: '16px', alignItems: 'flex-end' }}>
                    {isFloorScheme ? (
                        <Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    gap: "16px",
                                    border: "1px solid #EBEBF2",
                                    borderRadius: "50px",
                                    padding: "5px 14px",
                                    alignItems: "center",
                                }}
                            >
                                <Box
                                    className="less"
                                    onClick={() => {
                                        zoomOutClick();
                                    }}
                                />
                                <Slider
                                    value={scaleScheme}
                                    step={0.1}
                                    min={1}
                                    max={20}
                                    onChange={handleChangeSlider}
                                    onChangeCommitted={handleChangeSliderCommitted}
                                    sx={{ color: "#4339F2", width: "200px" }}
                                />

                                <Box
                                    className="more"
                                    onClick={() => {
                                        zoomInClick();
                                    }}
                                />
                            </Box>
                        </Box>
                    ) : (
                        <Box></Box>
                    )}
                    <Button
                        className="btn btn-primary-fill magazine_events-select_container-btn"
                        variant="outlined"
                        sx={{ height: "52px" }}
                        onClick={handlestagingTO}
                    >
                        {t("button:staging_to_TO")}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};
