import React, { useEffect, useRef, useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import "./styles/style.css";
import { formFields } from "./form-config";
import { useAction } from "../../../hooks/actions";
import {
  useLazyGetFloorOptionsSelectByFacilityQuery,
  useLazyGetFacilitySelectElementsWithSearchQuery,

} from "../../../middleware/facilitiesApi";
import {

  useGetEquipmentsCoordinatesPostMutation,
  useLazyGetRoomsColorsQuery,

} from "../../../middleware/equipmentsApi";
import { useAppSelector } from "../../../hooks/redux";
import { ModalTableSetOnTo } from "../Acts/ModalTableSetOnTo";
import { useLazyGetListEventMessagesQuery } from "../../../middleware/logsApi";
import EquipmentDialog from "../EquipmentList/EquipDialog";
import Header from "../Acts/Header";
import { ListEvents } from "./ImageSchema/ListEvents";
import { MissingFloorSchemaForm } from "./ImageSchema/MissingFloorSchemaForm";
import { useCalculateFloorSchemeParamsMutation } from "../../../middleware/equipmentsApi";
import { StagingToCanvas } from "../../components/DrawCanvas/StagingToCanvas";
import { ToolbarPlans } from "./ImageSchema/Toolbar";
let interval;
export const Plume = () => {
  let intervalId;
  const stageRef = useRef(null);
  const canvasRef = useRef(null);
  const imageRef = useRef(null);

  const { t } = useTranslation();

  const ITEMS_NAV = [
    { text: "plume_and_address", permissions: "facility_plan" },
    { text: "func_tests", permissions: "documentation" },
    { text: "documents", permissions: "documentation" },
    { text: "planning", permissions: "planning" },
  ];

  const { clearFloors, setIsErrorSubmit, setIsSuccessSubmit, setIsErrorRequest, setErrorNotificationParams } =
    useAction();
  const [getFloorOptions, { isError: isErrorGetFloorOptions, isLoading: isLoadingGetFloorOptions }] =
    useLazyGetFloorOptionsSelectByFacilityQuery();


  const [
    getFacilitySelectElems,
    { isError: isErrorgetFacilitySelectElems, isLoading: isLoadinggetFacilitySelectElems },
  ] = useLazyGetFacilitySelectElementsWithSearchQuery();


  const [
    getEquipmentsCoordinatesPost,
    { isError: isErrorCoordinatesPost, isSuccess: isSuccessCoordinatesPost, error: errorCoordinatesPost },
  ] = useGetEquipmentsCoordinatesPostMutation();

  //const { data: facilitySelectElements, error: error } = useGetFacilitySelectElementsWithoutCacheQuery();
  const {
    control,
    formState: { isSubmitSuccessful, errors },
    setValue,
    getValues,
    watch,
  } = useForm();

  const schemepage = useRef(null);
  const [floorScheme, setFloorScheme] = useState("");
  const [isfirstRender, setisfirstRender] = useState(true);
  const [searchObject, setsearchObject] = useState("");

  const [openModalSetObject, setOpenModalSetObject] = useState(false);
  const [lineColor, setLineColor] = useState("");
  const [StartTO, setStartTO] = useState(true);
  const [minScale, setminScale] = useState(5);
  const [transformCanvasData, setTransformCanvasData] = useState({
    x: 5,
    y: 5
  })
  const [openEventSide, setopenEventSide] = useState(true);
  const [roomsColors, setroomsColors] = useState([]);

  const [scaleScheme, setScale] = useState(1);
  const [stageposition, setstageposition] = useState({ x: 0, y: 0 });
  const [selectedListItem, setselectedListItem] = useState(null);
  const [isSelectedSchemeItem, setisSetselectedSchemeItem] = useState(false);
  const [isChangeFloor, setisChangeFloor] = useState(false);
  const [logsMessagess, setlogsMessages] = useState(null);
  const isErrorRequest = isErrorGetFloorOptions;
  // const isSuccessSubmit = isSuccessUpdate || isSuccessUpdateRoom;
  const isLoading = isLoadingGetFloorOptions;
  const [openEquipDialog, setopenEquipDialog] = useState(false);
  const [blinkingItem, setBlinkingItem] = useState(null);
  const [intervals, setIntervals] = useState({});
  const [selectedEquipId, setSelectedEquipId] = useState(null);
  const facilityId = watch(formFields.object);
  const floor = watch(formFields.floor);

  const last_scheme_object = localStorage.getItem("currentPlan") ? JSON.parse(localStorage.getItem("currentPlan"))?.object : null
  const last_scheme_floor = localStorage.getItem("currentPlan") ? localStorage.getItem("currentPlan")?.floor : null
  const { SCHEMA_CANVAS, openSidebar } = useAppSelector(
    (state) => state.settings
  );
  let wwwidth = openSidebar ? window.innerWidth - 317 : window.innerWidth - 96;

  const $container = useRef(null);
  const [fullscreen, setFullScreen] = useState(false);
  const [maxScale, setmaxScale] = useState(5);
  const [inputScale, setinputScale] = useState("");

  const [optimalscale, setoptimalscale] = useState(1);
  const [optimaloffsetAddEquip, setoptimaloffsetAddEquip] = useState({
    x: 0,
    y: 0,
  });

  const [image, setImage] = useState(null);

  const [widthWorkplace, setwidthWorkplace] = useState();
  const [heightWorkplace, setheightWorkplace] = useState();

  const [calculateFloorScheme, { }] = useCalculateFloorSchemeParamsMutation();
  const [GetListEventMessages] = useLazyGetListEventMessagesQuery();
  const [GetRoomsColors] = useLazyGetRoomsColorsQuery();
  const { setCanvasSize } = useAction();

  useEffect(() => {
    let change = 0;
    if (openEventSide) change += 220;
    if (openSidebar) change += 350;
    else change += 110;
    change += 80;
    setwidthWorkplace(() => window.innerWidth - change);
  }, [openSidebar, openEventSide, schemepage?.current?.clientWidth]);
  console.log(
    "schemepage?.current?.clientWidth",
    schemepage?.current?.clientWidth
  );
  useEffect(() => {
    let heightButtonBlock = $container?.current?.clientHeight + 40;

    setheightWorkplace(
      () => schemepage?.current?.clientHeight - heightButtonBlock
    );
  }, [schemepage?.current?.clientHeight, $container?.current?.clientHeight]);

  const {
    width: screenRelatedWidth,
    height: screenRelatedHeight,
    scale,
  } = useMemo(() => {
    let width = 0;
    let height = 0;

    if (SCHEMA_CANVAS.HEIGHT > SCHEMA_CANVAS.WIDTH) {
      height = heightWorkplace;
      width = heightWorkplace / (SCHEMA_CANVAS.HEIGHT / SCHEMA_CANVAS.WIDTH);
    } else {
      width = widthWorkplace;
      height = widthWorkplace / (SCHEMA_CANVAS.WIDTH / SCHEMA_CANVAS.HEIGHT);
      if (
        (height > window.innerHeight - 544 && wwwidth >= 1386) ||
        (height > window.innerHeight - 464 && wwwidth < 1386)
      ) {
        height = heightWorkplace;
        width = heightWorkplace / (SCHEMA_CANVAS.HEIGHT / SCHEMA_CANVAS.WIDTH);
      }
    }
    return SCHEMA_CANVAS.WIDTH
      ? {
        width,
        height,
        scale: {
          x: width / SCHEMA_CANVAS.WIDTH,
          y: height / SCHEMA_CANVAS.HEIGHT,
        },
      }
      : {};
  }, [SCHEMA_CANVAS]);

  const getListEvent = () => {
    if (facilityId && floor) {
      console.log("facilityId", floor)
      const params = (facilityId === 'все') ? "" : floor === 'все' ? `&FacilityId=${facilityId}` : `&FloorInfoId=${floor}`

      GetListEventMessages({
        FloorInfoId: params
      }).then(({ data, isSuccess }) => {
        setlogsMessages(data);
      });
    }
  }
  const getColorsRooms = () => {
    if (facilityId && floor && facilityId !== 'все' && floor !== 'все') {

      const params = `?floorId=${floor}`
      GetRoomsColors({
        floorId: params,
      }).then((data) => {
        setroomsColors(data.data);
      });
    }
  }

  const loadScheme = (resetOffset = true) => {
    if (floor !== 'все')
      getEquipmentsCoordinatesPost({
        id: floor,
        returnEquipments: false,
        returnRooms: true
      }).then(
        ({ data }) => {
          console.log("dda", data)
          if (!data?.length) {
            const { equipmentCoordinates, floorSchemeFileLink, roomAddresses } = data || {};

            setFloorScheme(floorSchemeFileLink);
            setValue(formFields.roomAddresses, roomAddresses);
            setValue(formFields.equipmentsCoordinates, []);
            if (resetOffset) {
              setstageposition({ x: 1, y: 1 });
              setScale(1);
            }
            setisSetselectedSchemeItem(false)

          }
        }
      );
  };


  const handlestagingTO = () => {
    setOpenModalSetObject(true);
  };

  const changeScrollsPositionandSize = () => {
    const canvas = canvasRef.current;
    const newX = canvas.x();
    const newY = canvas.y();

    // Ограничиваем перемещение по горизонтали
    const maxX = 0;
    const minX = -(screenRelatedWidth * scaleScheme - widthWorkplace);

    // Ограничиваем перемещение по вертикали
    const maxY = 0;
    const minY = -(screenRelatedHeight * scaleScheme - heightWorkplace);

    // Если новые координаты выходят за границы, оставляем их на границе
    const clampedX = Math.min(Math.max(newX, minX), maxX);
    const clampedY = Math.min(Math.max(newY, minY), maxY);

    setTransformCanvasData({
      x: clampedX,
      y: clampedY,
    });
  };

  useEffect(() => {
    getFacilitySelectElems({ name: searchObject }).then(({ data, isSuccess }) => {
      if (!!data?.length) {
        if (isfirstRender && last_scheme_object) {
          setValue(formFields.object, last_scheme_object);
        }
      }
    });
  }, [searchObject]);


  useEffect(() => {
    if (selectedListItem) return;
    if (StartTO) {
      intervalId = setInterval(() => {
        getListEvent()
        getColorsRooms()
      }, 10000);
      return () => clearInterval(intervalId);
    } else {
      clearInterval(intervalId);
    }
  }, [StartTO, selectedListItem]);

  const handleTO = () => {
    setStartTO((prevState) => !prevState);
  };


  useEffect(() => {
    if (facilityId) {
      if (facilityId !== "все") {
        getFloorOptions(facilityId).then(({ data, isSuccess }) => {
          if (!!data?.length) {
            if (isfirstRender) {
              if (last_scheme_floor)
                setValue(formFields.floor, last_scheme_floor);
              setisfirstRender(false);
            }
            if (!selectedListItem) setValue(formFields.floor, 'все');
          }
        });

      } else {
        clearFloors();
        setValue(formFields.floor, 'все');
      }
    }
    getColorsRooms()
    getListEvent()
  }, [facilityId]);

  useEffect(() => {
    if (floor) {
      console.log("Setting", floor)
      if (selectedListItem) {
        if (blinkingItem !== null && blinkingItem !== selectedListItem?.id) {
          // Clear the previous blinking interval and effect
          setLineColor("");
          clearInterval(intervals[blinkingItem]);
        }
      } else {
        floor !== 'все' && setisChangeFloor(true);
      }
      loadScheme();
      getColorsRooms()
      localStorage.setItem("currentPlan", JSON.stringify({ object: facilityId, floor: floor }));
      getListEvent()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [floor]);

  const calculateFloorOptions = () => {
    if (image) {
      if (
        image?.width != null &&
        image?.height != null &&
        image?.width != 0 &&
        image?.height != 0
      ) {
        calculateFloorScheme({
          FloorInfoId: floor,
          CanvasHeight: parseInt(heightWorkplace),
          CanvasWidth: parseInt(widthWorkplace),
          MinimumDistance: 30,
        }).then(({ data }) => {
          setoptimalscale(data.optimalScale);
          setminScale(data.optimalScale);
        });
      }
    }
  };


  useEffect(() => {
    setIsErrorRequest(isErrorRequest);

  }, [isErrorRequest]);

  useEffect(() => {
    setIsErrorRequest(isErrorRequest);

  }, [isErrorRequest]);

  useEffect(() => {
    
    if (!selectedListItem) {
      return; // Не устанавливаем новый интервал, если на паузе.
    }

    const { borderColor, color } = selectedListItem || {};

    if (isSelectedSchemeItem || !blinkingItem) {
      clearInterval(interval);
      setLineColor(
        (prevColor) =>
          `${borderColor}/${color}`
      );
      return;
    }
   
    // Устанавливаем интервал
    interval = setInterval(() => {
      setLineColor(
        (prevColor) =>
          prevColor.split("/")[0] === borderColor || prevColor === "" ? "transparent" : `${borderColor}/${color}`
      );
    }, 600);

    // Очищаем интервал при размонтировании и при изменении состояния
    return () => {
      clearInterval(interval);
    };
  }, [isSelectedSchemeItem, blinkingItem, selectedListItem]);

  const getequipmentsInRegion = (coordinates, roomid) => {

    const evenIndexValues = coordinates.filter((_, index) => index % 2 === 0); // Четные по индексу элементы
    const oddIndexValues = coordinates.filter((_, index) => index % 2 !== 0); // Нечетные по индексу элементы

    const minEvenIndex = Math.min(...evenIndexValues); // Минимальное значение четных по индексу элементов
    const maxEvenIndex = Math.max(...evenIndexValues); // Максимальное значение четных по индексу элементов
    const minOddIndex = Math.min(...oddIndexValues); // Минимальное значение нечетных по индексу элементов
    const maxOddIndex = Math.max(...oddIndexValues);
    const diffX = maxEvenIndex - minEvenIndex;
    const diffY = maxOddIndex - minOddIndex;
    console.log("coordinates", selectedListItem)
    const messageId = selectedListItem?.roomAddressId == roomid ? selectedListItem?.messageId : null
    const roomAdressId = selectedListItem?.roomAddressId == roomid ? null : roomid
    getEquipmentsCoordinatesPost({
      id: floor,
      returnEquipments: true,
      returnRooms: true,
      messageId,
      roomAddressId: roomAdressId,
      minPoint: roomid ? null : {
        x: minEvenIndex / scale.x,
        y: minOddIndex / scale.x,
      },
      maxPoint: roomid ? null : {
        x: maxEvenIndex / scale.x,
        y: maxOddIndex / scale.x,
      },
    }).then(({ data }) => {
      if (data) {

        const { equipmentCoordinates, floorSchemeFileLink, roomAddresses } = data || {};
        setFloorScheme(floorSchemeFileLink);
        setisSetselectedSchemeItem(false)
        const coordinates = equipmentCoordinates.map((item) => ({
          ...item,
          floorInfoId: floor,
        }));
        setValue(formFields.equipmentsCoordinates, coordinates);
        setValue(formFields.roomAddresses, roomAddresses);
        if (roomid) {
          let scaleStage = minScale;
          const diffWidth = (widthWorkplace - diffX * scaleStage) / 2;
          const diffHeight = (heightWorkplace - diffY * scaleStage) / 2;
          let mincoorX = (minEvenIndex - (diffWidth / scaleStage))
          let mincoorY = (minOddIndex - (diffHeight / scaleStage))
          setScale(scaleStage);
          const offsetX = mincoorX * scaleStage;
          const offsetY = mincoorY * scaleStage;

          setstageposition((prev) => ({ x: 0 - offsetX, y: 0 - offsetY }));
          setisSetselectedSchemeItem(true)
        }

      }
    });
  };

  useEffect(() => {
    if (isChangeFloor) {
      calculateFloorOptions();
      setisChangeFloor(false)
    }
  }, [image]);

  return (
    <Box>
      {openModalSetObject && (
        <ModalTableSetOnTo open={openModalSetObject} setOpen={setOpenModalSetObject} protectionObjectId={facilityId} />
      )}
      {openEquipDialog && (
        <EquipmentDialog
          open={openEquipDialog}
          setOpen={setopenEquipDialog}
          equipId={selectedEquipId}
          refetch={() => { }}
        />
      )}
      <Box className="template_page plume">
        <Header nameForm="plume" />
        <Box ref={schemepage} className="plume-image_container" sx={{ display: "block", width: '100%' }}>

          <Box sx={{ display: "flex", maxHeight: window.innerHeight - 330 }}>
            <Box sx={{ position: "relative  ", width: "100%" }}>
              <ToolbarPlans
                control={control}
                isFloorScheme={floorScheme}
                getequipmentsInRegion={getequipmentsInRegion}
                $container={$container}
                reloadScheme={loadScheme}
                openEventSide={openEventSide}
                setopenEventSide={setopenEventSide}
                scaleScheme={scaleScheme}
                scale={scale}
                setScale={setScale}
                minScale={minScale}
                changeScrollsPositionandSize={changeScrollsPositionandSize}
                setstageposition={setstageposition}
                stageposition={stageposition}
                canvasRef={canvasRef}
                imageRef={imageRef}
                handlestagingTO={handlestagingTO}
                widthWorkplace={widthWorkplace}
                heightWorkplace={heightWorkplace}
                screenRelatedWidth={screenRelatedWidth || 0}
                screenRelatedHeight={screenRelatedHeight || 0}
                searchObject={searchObject}
                setsearchObject={setsearchObject}
              />
              <Box sx={{ display: "flex", gap: '10px', height: heightWorkplace }}>
                {logsMessagess && openEventSide && (
                  <ListEvents
                    selectedListItem={selectedListItem}
                    setselectedListItem={setselectedListItem}
                    isSelectedSchemeItem={isSelectedSchemeItem}
                    setisSetselectedSchemeItem={setisSetselectedSchemeItem}
                    logsMessagess={logsMessagess}
                    blinkingItem={blinkingItem}
                    setBlinkingItem={setBlinkingItem}
                    intervals={intervals}
                    setIntervals={setIntervals}
                    setLineColor={setLineColor}
                    loadScheme={loadScheme}
                    setScale={setScale}
                    setstageposition={setstageposition}
                    setValue={setValue}
                    floor={floor}
                    StartTO={StartTO}
                    handleTO={handleTO}
                    setopenEventSide={setopenEventSide}
                  />
                )}

                {floor === 'все' ? <></> : floorScheme ? (
                  <StagingToCanvas
                    // name={`${formFields.equipmentsCoordinates}`}
                    width={screenRelatedWidth || 0}
                    height={screenRelatedHeight || 0}
                    schemaImg={floorScheme}
                    isChangeFloor={isChangeFloor}
                    // setValue={setValue}
                    formFields={formFields}
                    getValues={getValues}
                    loadScheme={loadScheme}
                    getequipmentsInRegion={getequipmentsInRegion}
                    roomsColors={roomsColors}
                    widthWorkplace={widthWorkplace}
                    heightWorkplace={heightWorkplace}
                    setopenEquipDialog={setopenEquipDialog}
                    minScale={minScale}
                    setSelectedEquipId={setSelectedEquipId}
                    transformCanvasData={transformCanvasData}
                    setTransformCanvasData={setTransformCanvasData}
                    changeScrollsPositionandSize={changeScrollsPositionandSize}
                    canvasRef={canvasRef}
                    imageRef={imageRef}
                    stageRef={stageRef}
                    selectedEvent={selectedListItem}
                    scale={scaleScheme}
                    image={image}
                    setImage={setImage}
                    // maxScale={maxScale}
                    scalecanvas={scale}
                    setScale={setScale}
                    stageposition={stageposition}
                    setstageposition={setstageposition}
                    control={control}
                    lineColor={lineColor}
                    fullscreen={fullscreen}
                    setFullScreen={setFullScreen}
                  />
                ) : getValues(formFields.object) !== "все" ? (
                  <Box className="plume-image_container-schema">
                    <MissingFloorSchemaForm
                      floor={floor}
                      setFloorScheme={setFloorScheme}
                    />
                  </Box>
                ) : (
                  <Box></Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Backdrop sx={{ color: "var(--white)", zIndex: 9999 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>

  );
};
