import React, { useMemo, useEffect } from "react";
import { Box } from "@mui/material";
import { formConfigSection } from "./AddReglament/form-config";
import FormItem from "../../components/FormItem";
import { formFields } from "./AddReglament/options";
import { WarningValidationForm } from "../../components/Notifications";
import { useForm } from "react-hook-form";

const BasicForm = ({ regulation, onSubmit, isErrorRequest, style }) => {
    const {
        control,
        handleSubmit,
        formState: { errors, isSubmitSuccessful },
        setValue,
        watch,
        reset,
        trigger,
        resetField,
    } = useForm({
        mode: "onSubmit",
    });

    useEffect(() => {
        if (regulation) {
            const {
                techMapFileName,
                techMapLink
            } = regulation || {};
            const gettechCard = () => {
                let fileName = techMapFileName;
                let fileLink = techMapLink;

                if (fileName === "default.png") {
                    fileName = "";
                    fileLink = "/unnamed.png";
                }

                return {
                    filesPrev: [{ fileName, fileLink }],
                    filesToAdd: [],
                    filesToDelete: [],
                };
            };
            reset({
                ...regulation,
                techcardFiles: gettechCard(),
            });
        }
        else {
            setValue(formFields.isActive, true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [regulation, reset]);
    const isWarningValidation = !!Object.keys(errors).length;
    //Get Object and Floor
    const protectionObjectId = watch(formFields.protectionObjectId);
    const equipmentKindsIds = watch(formFields.equipmentKindsIds);
    const equipmentName = watch(formFields.equipmentName);
    const configForm = useMemo(
        () =>
            formConfigSection(
                setValue,
                regulation?.disableStandardRegulation,
                protectionObjectId
            ),
        [setValue, resetField, regulation?.disableStandardRegulation]
    );
    return (
        <>
            <form id="editRegulationForm"
                onSubmit={handleSubmit(onSubmit)} className="scrolled-form" style={style}>
                <Box sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "flex-start",
                    alignItems: "flex-end",
                    columnGap: "15px",
                }}>
                    {configForm &&
                        configForm?.map(
                            ({ id,
                                name,
                                label,
                                input,
                                expended,
                                isCanDisabled,
                                CustomFormItem,
                                ...rest }) =>
                                CustomFormItem ? (
                                    <CustomFormItem
                                        key={id}
                                        control={control}
                                        errors={errors}
                                        name={name}
                                        {...rest}
                                    />
                                ) : (
                                    <FormItem
                                        control={control}
                                        errors={errors}
                                        name={name}
                                        objectId={protectionObjectId}
                                        equipmentKindsIds={equipmentKindsIds}
                                        equipmentGroupId={equipmentName}
                                        regulation={regulation}
                                        setValue={setValue}
                                        disabled={isCanDisabled ? !protectionObjectId || isErrorRequest : false}
                                        label={label}
                                        input={input}
                                        trigger={trigger}
                                        {...rest}
                                    />
                                )
                        )}
                </Box>
            </form>
            {isWarningValidation && (
                <WarningValidationForm isWarning={isWarningValidation} />
            )}
        </>

    );
};

export default BasicForm;
