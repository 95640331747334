import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useAppSelector } from "../../../hooks/redux";
import { parseJsonToken } from "../../../helpers/helper";

import { ModalTableSetOnTo } from "./ModalTableSetOnTo";

const Header = ({nameForm}) => {
  const { t } = useTranslation();
  const token = useAppSelector((state) => state.users.token);
  const [openModalSetObject, setOpenModalSetObject] = useState(false);
  const [permissions, setPermissions] = useState([]);

  const facility = "facilities";
  const action = "put-in-test";

  const handleOpenObjectOnTO = () => {
    setOpenModalSetObject(true);
  };

  useEffect(() => {
    token && parseJsonToken({ token, setPermissions });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <>
      {openModalSetObject && (
        <ModalTableSetOnTo
          open={openModalSetObject}
          setOpen={setOpenModalSetObject}
        />
      )}
      <Box className="acts-header">
        <Box sx={{ display: "flex", justifyContent: "space-between",alignItems:"center" }}>
          <Typography className="title-page">
            {t("sidebar:acts")}
          </Typography>
          {nameForm == "acts" &&
            <Button
              className="btn btn-primary-fill magazine_events-select_container-btn"
              sx={{ height: "49px" }}
              variant="outlined"
              onClick={handleOpenObjectOnTO}
            >
              {t("button:staging_to_TO")}
            </Button>
          }
        </Box>
      </Box>
    </>
  );
};

export default Header;
