import { useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../../hooks/redux";
import { chatBotApi } from "../../../middleware/chatBotApi";
import chatBotIcon from "../../../image/ChatBot/chatBotIcon.png";
import botImg from "../../../image/ChatBot/bot-img.jpg";
import like from "../../../image/ChatBot/like.svg";
import dislike from "../../../image/ChatBot/dislike.svg";
import copy from "../../../image/ChatBot/copy.svg";
import CloseIcon from "../icons/CloseIcon";
import CustomTextarea from "./Components";
import "./styles.css";

const ChatBot = ({ setIsOpenChat }) => {
  const { openSidebar } = useAppSelector((state) => state.settings);
  const [chatInfoData, setChatInfoData] = useState([]);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [newQuestion, setNewQuestion] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const scrollRef = useRef();
  const observerRef = useRef(null);
  const imagesRef = useRef([]);

  useEffect(() => {
    if (!chatInfoData.length || !chatInfoData[chatInfoData.length - 1]?.isPending) return;

    const lastMessage = chatInfoData[chatInfoData.length - 1];

    chatBotApi(lastMessage.question).then((data) => {
      const updatedChatData = [...chatInfoData];
      const currentMessage = updatedChatData[updatedChatData.length - 1];

      currentMessage.response = data.response;
      currentMessage.isPending = false;

      setChatInfoData(updatedChatData);
      setIsLoading(false);
    });
  }, [chatInfoData]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const img = entry.target;
            img.src = img.dataset.src;
            img.onload = () => {
              setImagesLoaded((prev) => prev || img === imagesRef.current[0]);
            };
          }
        });
      },
      { rootMargin: "100px" }
    );

    if (observerRef.current) {
      imagesRef.current.forEach((img) => observer.observe(img));
    }

    return () => observer.disconnect();
  }, []);

  const addPersonQuestion = () => {
    const newChatDataItem = {
      id: Date.now(),
      question: newQuestion,
      isPending: true,
      response: "",
    };
    setChatInfoData((prev) => [...prev, newChatDataItem]);
    setNewQuestion("");
  };

  const handleSendMessage = () => {
    if (!newQuestion) return;
    setIsLoading(true);
    addPersonQuestion();
  };

  const handleCopy = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      console.error("Ошибка при копировании:", err);
    }
  };

  useEffect(() => {
    if (scrollRef.current && chatInfoData.length > 0) {
      const checkScroll = () => {
        const { scrollHeight } = scrollRef.current;
        if (chatInfoData[chatInfoData.length - 1].isPending) {
          requestAnimationFrame(checkScroll);
        } else {
          scrollRef.current.scrollTop = scrollHeight;
        }
      };
      requestAnimationFrame(checkScroll);
    }
  }, [chatInfoData]);

  return (
    <div className={openSidebar ? "chat-container" : "chat-container closed-sidebar"}>
      <div className="header">
        <p>ИИ ассистент</p>

        <div className="chat-close" onClick={() => setIsOpenChat(false)}>
          <CloseIcon />
        </div>
      </div>

      <div
        ref={scrollRef}
        className="messages-container"
        style={{ justifyContent: !chatInfoData.length > 0 ? "center" : "start" }}
      >
        {chatInfoData.length > 0 ? (
          chatInfoData.map((item) => (
            <div className="message-group" key={item.id}>
              <div className="user-message">{item.question}</div>
              {!item.isPending ? (
                <>
                  <div className="bot-message">
                    <img src={botImg} alt="Иконка бота" />
                    <p>{item.response}</p>
                  </div>
                  <div className="bot-equipments">
                    <img src={copy} onClick={() => handleCopy(item.response)} alt="Иконка бота" />
                    <img src={like} alt="Иконка бота" />
                    <img src={dislike} alt="Иконка бота" />
                  </div>
                </>
              ) : (
                <div className="typing-indicator">
                  <span>Мария печатает</span>
                  <div className="dots">
                    <div className="dot">•</div>
                    <div className="dot">•</div>
                    <div className="dot">•</div>
                  </div>
                </div>
              )}
            </div>
          ))
        ) : (
          <div className="chat-hello">
            <div className="chat-hello-img">
              <img src={chatBotIcon} alt="Иконка бота" />
            </div>
            <p>
              Привет, я <span>Маша</span>
            </p>
            <p>Задайте свой вопрос по пожарной безопасности</p>
          </div>
        )}
      </div>

      <div className="input-container">
        <CustomTextarea
          value={newQuestion}
          onChange={setNewQuestion}
          handleSendMessage={handleSendMessage}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default ChatBot;
