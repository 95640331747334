import { t } from "i18next";

export const fields = {
  highty: "Высокий",
  middle: "Средний",
  lower: "Низкий",
  completely: "Выполнено",
  inprogress: "К выполнению",
  onreview: "На проверке",
  inwork: "В работе",
  organization_customer: "organization_customer",
  organization_contractor: "organization_contractor",
  outside_organization: "outside_organization",
  alarm: "alarm",
  malfunction: "malfunction",
  maintenance: "maintenance",
  malfunctions_IP: "malfunctions_IP",
};

export const STATUS_ID = {
  [fields.inwork]: 4,
  [fields.onreview]: 3,
  [fields.completely]: 2,
  [fields.inprogress]: 1,
};

export const STATUS_NAME = {
  [STATUS_ID[fields.onreview]]: t(`${fields.onreview}`),
  [STATUS_ID[fields.completely]]: t(`${fields.completely}`),
  [STATUS_ID[fields.inprogress]]: t(`${fields.inprogress}`),
  [STATUS_ID[fields.inwork]]: t(`${fields.inwork}`),
}

export const PRIORITY_ID = {
  [fields.highty]: 3,
  [fields.middle]: 2,
  [fields.lower]: 1,
};

export const NAV_ITEMS = [
  { text: "add_equipment_lite", permissions: "equipmentsadd-edit" },
  { text: "list_equipment", permissions: "equipments" },
  { text: "equipment_reglaments", permissions: "equipments" },
];