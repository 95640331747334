import { useTranslation } from "react-i18next";

export const getRequestData = (data) => {
  const { files, facilitySystems } = data || {};

  const entities = {
    ...data,
    ...data.organizations,
    facilitySystems: {
      ...facilitySystems,
      autoFireAlarmSysEquipAmount: Number(facilitySystems?.autoFireAlarmSysEquipAmount) || null,
      autoFireSuppressionSysEquipAmount: Number(facilitySystems?.autoFireSuppressionSysEquipAmount) || null,
      notifAndEvacControlSysEquipAmount: Number(facilitySystems?.notifAndEvacControlSysEquipAmount) || null,
    },
  };
  delete entities.files;

  return { entities, files };
};

// const newName = {
//   "СПС": "Система пожарной сигнализации (СПС)",
//   "СОУЭ": "Система оповещения и управления эвакуацией (СОУЭ)",
//   "ВПВ": "Внутренний противопожарный водопровод (ВПВ)",
//   "Водяные и пенные установки пожаротушения": "Автоматическая система пожаротушения (АСПТ)",
//   "Установка газового пожаротушения": "Автоматическая система пожаротушения (АСПТ)",
//   "Установка порошкового пожаротушения": "Автоматическая система пожаротушения (АСПТ)",
//   "Установка аэрозольного пожаротушения": "Автоматическая система пожаротушения (АСПТ)",
//   "Средства первичные пожаротушения": "Первичные средства пожаротушения (ПСП)",
//   "Система противодымной вентиляции": "Система противодымной вентиляции (ПВ)",
//   "Система передачи извещения о пожаре и диспетчеризация оборудования": "Система передачи извещений о пожаре (СПИ)",
//   "Деревянные конструкции с огнезащитой": "Деревянные конструкции с огнезащитой",
//   "Заполнение проемов в противопожарных преградах": "Заполнение проемов в противопожарных преградах",
//   "Пожарные лестницы и ограждения кровли": "Пожарные лестницы и ограждения кровли",
//   "Водоисточники наружного пожаротушения": "Водоисточники наружного пожаротушения",
//   "Дымоход": "Дымоход",
//   "Вентиляция производственная": "Вентиляция производственная",
//   "Средства индивидуальной защиты": "Средства индивидуальной защиты",
// }

export const convertToValuesArray = (equipmentSystems) => {
  return equipmentSystems.map((equipment) => ({
    equipmentKindId: equipment.id,
    name: equipment.name,
    isAvailable: false,
  }));
};

export const getPositiveKeys = (object) => {
  const keys = Object.keys(object);
  console.log("keys", keys);
  const positiveKeys = keys.filter((key) => object[key] > 0 || (key === "АСПТ" && !!object[key]));

  return positiveKeys;
};

export const updateValuesWithFilter = (values, filter) => {
  for (const value of values) {
    if (filter.includes(value.name)) {
      value.isAvailable = true;
    }

    const equipmentIdsToEnable = [4, 5, 6, 7];
    if (equipmentIdsToEnable.includes(value.equipmentKindId)) {
      value.isAvailable = filter.includes("АСПТ") ? true : false;
    }
  }

  console.log("values", values);
  return values;
};

export const getRequestNewData = (data) => {
  const {
    files,
    facilitySystems,
    buildingCharacteristics: { fireCompartments, fireHosesCount },
  } = data || {};
  //const detectorsTypeStr = facilitySystems?.detectorsType.join(",");
  const facilitySystemsFiles = [
    facilitySystems?.snmeContractFile,
    facilitySystems?.afaContractFile,
    facilitySystems?.afasysContractFile,
  ]
    .filter((value) => value !== undefined)
    .flat();

  console.log("files", files);

  const fireFiles = fireCompartments.map(({ floors }, i) =>
    floors?.reduce((filesList, floor) => {
      const fileItem = { ...floor.floorSchema, floorNumber: floor.floorNumber, index: i };
      return filesList ? [...filesList, fileItem] : floor.floorSchema ? [fileItem] : [];
    }, null)
  );

  if (data?.integrationSystem) {
    delete data?.integrationSystem.protectionObjectIntegrNumbersAlts;
  }

  const entities = {
    ...data,
    buildingPassport: {
      ...data.buildingPassport,
      ...data?.integrationSystem,
      facilityOptionId: data.buildingPassport.facilityOptionId || null,
    },
    buildingCharacteristics: {
      ...data?.buildingCharacteristics,
      fireHosesCount: fireHosesCount || null,
    },
    facilitySystems: {
      ...facilitySystems,
      // detectorsType: detectorsTypeStr,
      notifAndEvacSysContractType: facilitySystems?.notifAndEvacSysContractType || null,
      automaticFireAlarmSysContractType: facilitySystems?.automaticFireAlarmSysContractType || null,
      automaticFireSubSysContractType: facilitySystems?.automaticFireSubSysContractType || null,

      notificationAndEvacSysContractNumber: facilitySystems?.notificationAndEvacSysContractNumber || null,
      automaticFireAlarmSysContractNumber: facilitySystems?.automaticFireAlarmSysContractNumber || null,
      automaticFireSupSysContractNumber: facilitySystems?.automaticFireSupSysContractNumber || null,
      notifAndEvacSysContractOrgId: facilitySystems?.notifAndEvacSysContractOrgId || null,
      automaticFireAlarmSysContractExecOrgId: facilitySystems?.automaticFireAlarmSysContractExecOrgId || null,
      automaticFireSupSysContractExecOrgId: facilitySystems?.automaticFireSupSysContractExecOrgId || null,
      autoFireAlarmSysEquipAmount: Number(facilitySystems?.autoFireAlarmSysEquipAmount) || null,
      autoFireSuppressionSysEquipAmount: Number(facilitySystems?.autoFireSuppressionSysEquipAmount) || null,
      notifAndEvacControlSysEquipAmount: Number(facilitySystems?.notifAndEvacControlSysEquipAmount) || null,
    },
    fireCompartments,
    fireCompartments,
  };
  delete entities.files;
  delete entities.facilitySystems.snmeContractFile;
  delete entities.facilitySystems.afaContractFile;
  delete entities.facilitySystems.afasysContractFile;
  delete entities.integrationSystem;
  const fireCompartmentsFiles = fireFiles.flat();

  return { entities, files: { fireCompartmentsFiles, facilitySystemsFiles, ...files } };
};

export const getRequestDataFilesForAdd = (id, fireCompartments, files) => {
  const formData = new FormData();

  if (!files) {
    return formData;
  }

  let fileConfigIndex = 0;
  Object.entries(files)
    ?.filter(([, value]) => value)
    .forEach(([key, { filesToAdd, filesToDelete }]) => {
      if (filesToAdd?.length) {
        filesToAdd.forEach((file) => {
          formData.append(`entityFileList[${fileConfigIndex}].EntityId`, id);
          formData.append(`entityFileList[${fileConfigIndex}].EntityField`, key);
          formData.append(`entityFileList[${fileConfigIndex}].EntityType`, "Facility");
          formData.append(`entityFileList[${fileConfigIndex}].File`, file);
          formData.append(`entityFileList[${fileConfigIndex}].ActionType`, "Add");
          fileConfigIndex++;
        });
      }
      if (filesToDelete?.length) {
        filesToDelete.forEach((file) => {
          if (file.id) {
            formData.append(`entityFileList[${fileConfigIndex}].EntityId`, id);
            formData.append(`entityFileList[${fileConfigIndex}].EntityField`, key);
            formData.append(`entityFileList[${fileConfigIndex}].EntityType`, "Facility");
            formData.append(`entityFileList[${fileConfigIndex}].id`, file.id);
            formData.append(`entityFileList[${fileConfigIndex}].ActionType`, "Delete");
          }
          fileConfigIndex++;
        });
      }
    });
  /*files?.facilitySystemsFiles
    .forEach(({ filesToAdd, filesToDelete }) => {   
        if(filesToDelete?.length) {
          filesToDelete.forEach(() => {
              formData.append(`entityFileList[${fileConfigIndex}].EntityId`, id);
              formData.append(`entityFileList[${fileConfigIndex}].EntityType`, "facilitySystems");
              formData.append(`entityFileList[${fileConfigIndex}].ActionType`, "Delete");
          })
          fileConfigIndex++;
        }
        if(filesToAdd?.length) {
            filesToAdd.forEach((file) => {
                formData.append(`entityFileList[${fileConfigIndex}].EntityId`, id);
                formData.append(`entityFileList[${fileConfigIndex}].EntityType`, "facilitySystems");
                formData.append(`entityFileList[${fileConfigIndex}].File`, file);
                formData.append(`entityFileList[${fileConfigIndex}].ActionType`, "Add");
            })
            fileConfigIndex++
        }
    });*/
  fireCompartments &&
    files?.fireCompartmentsFiles.forEach(({ floorNumber, index, filesToAdd, filesToDelete }) => {
      const floorEntityId = fireCompartments[index]?.floors.find(
        (floor) => floor.floorNumber === Number(floorNumber)
      )?.id;
      if (filesToDelete?.length) {
        filesToDelete.forEach(() => {
          formData.append(`entityFileList[${fileConfigIndex}].EntityId`, floorEntityId);
          formData.append(`entityFileList[${fileConfigIndex}].EntityType`, "FloorInfo");
          formData.append(`entityFileList[${fileConfigIndex}].ActionType`, "Delete");
        });
        fileConfigIndex++;
      }
      if (filesToAdd?.length) {
        filesToAdd.forEach((file) => {
          formData.append(`entityFileList[${fileConfigIndex}].EntityId`, floorEntityId);
          formData.append(`entityFileList[${fileConfigIndex}].EntityType`, "FloorInfo");
          formData.append(`entityFileList[${fileConfigIndex}].File`, file);
          formData.append(`entityFileList[${fileConfigIndex}].ActionType`, "Add");
        });
        fileConfigIndex++;
      }
    });

  return formData;
};

const convertData = (stringDate) => {
  const date = new Date(stringDate);
  return date.toLocaleDateString();
};

export const getTooltipByStatus = (item, status, statusName) => {
  const getReactionDateTime = (dateTimeString) => {
    return dateTimeString ? `Дата: ${convertData(dateTimeString)}` : "";
  };

  const getRoomAddressName = () => {
    return item?.statusMessage?.roomAddressName ? `В помещении ${item?.statusMessage?.roomAddressName}` : "";
  };

  if (status === 6 || status === 0) {
    return statusName;
  }

  if (status === 4) {
    return `${item.inTestData.isInTestUserFullName} Поставил на ТО, ${getReactionDateTime(item.inTestData.isInTestTime)}`;
  }

  return `${statusName} помещение ${getRoomAddressName()}, ${getReactionDateTime(item?.statusMessage?.reactionDateTime)}`;
};
