import React, { useMemo, useState, useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useAppSelector } from "../../../hooks/redux";
import { useLazyGetAllOrganizationsQuery } from "../../../middleware/apiOrganizations";
import { useGetUserGridSettingQuery, useLazyGetUserGridSettingQuery } from "../../../middleware/usersApi";
import { useAction } from "../../../hooks/actions";
import { getRowsData } from "./helpers";
import { PATHS } from "../../../constants";
import TableBasic from "../../components/TableBasic";
import { getColumns } from "./columns";
import { getConfigFormModalSelect } from "./form-config";
import { ModalSelectColumnsServer } from "../../components/Modals/ModalSelectColumnsServer";
import settingsicon from "../../../image/icons/settingsButtonWhite.png";
import plusicon from "../../../image/icons/plusBtnWhite.png";
import MyOrganizationDetails from "../MyOrganizationDetails";
import EditBalanceModal from "./EditBalanceModal";
import ConfirmUnblockedOrganizationModal from "./ConfirmModal";
import { useBlockUnblockOrganizationMutation } from "../../../middleware/billingApi";
import useScreenSize from "../../../hooks/useScreenSize";
const MyOrganizations = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const dataFromStorage = JSON.parse(sessionStorage.getItem("organizationListSearchParams")) || {};
  const { searchInnStorage, searchNameStorage, searchAddressStorage, orgTypeFilterStorage } = dataFromStorage;
  const { allOrganizations } = useAppSelector((state) => state.organizations);
  const { roleId } = useAppSelector((state) => state.users.info);
  const { setIsErrorRequest, setIsSuccessSubmit } = useAction();
  const { control } = useForm();
  const [selectedId, setSelectedId] = useState(null);
  const [typeId, setTypeId] = useState(null);
  const [openDiteilsPopUp, setOpenDiteilsPopUp] = useState(false);
  const [pageNumber, changePageNumber] = useState(0);
  const [orgTypeFilter, setOrgTypeFilter] = useState(orgTypeFilterStorage || "");
  const [searchInn, setSearchInn] = useState(searchInnStorage || "");
  const [searchName, setSearchName] = useState(searchNameStorage || "");
  const [searchAddress, setSearchAddress] = useState(searchAddressStorage || "");
  const [openSelectModal, setOpenSelectModal] = useState(false);
  const [openBalanceModal, setopenBalanceModal] = useState(false);
  const [isClearFilters, setIsClearFilters] = useState(false);
  const [isChoosenFirstPage, setIsChoosenFirstPage] = useState(false);
  const [isShowBlockedColumn, setIsShowBlockedColumn] = useState(false);
  const [openConfirmUnblockedModal, setOpenConfirmUnblockedModal] = useState(false);
  const [selectedOrganizationId, setSelectedOrganizationId] = useState({});
  const screenSize = useScreenSize();

  const { data: gridcolumns } = useGetUserGridSettingQuery({ gridSettingName: "OrganizationList" });
  const [getUserGrid] = useLazyGetUserGridSettingQuery();
  const [getOrganizationsTable, { error, isLoading }] = useLazyGetAllOrganizationsQuery();

  const [
    blockUnblockOrganization,
    { isError: blockUnblockOrganizationError, isSuccess: blockUnblockOrganizationIsSucces },
  ] = useBlockUnblockOrganizationMutation();

  const changeBlockedOrganizationStatus = () => {
    blockUnblockOrganization(selectedOrganizationId).then(() => {
      setSelectedOrganizationId({});
      setOpenConfirmUnblockedModal(false);
      refreshTable();
    });
  };

  useEffect(() => {
    setIsSuccessSubmit(blockUnblockOrganizationIsSucces);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockUnblockOrganizationIsSucces]);

  function writeSearchDataToLocalStorage(newSearchParam, searchValue, isClearFilter = false) {
    const getStorage = JSON.parse(sessionStorage.getItem("organizationListSearchParams")) || {};
    const searchParamsForLocalStorage = {
      ...getStorage,
      [newSearchParam]: searchValue,
    };
    sessionStorage.setItem("organizationListSearchParams", JSON.stringify(searchParamsForLocalStorage));
    isClearFilter && setIsClearFilters(true);
  }

  function clearFiltersHandler() {
    setSearchName();
    setSearchInn();
    setSearchAddress();
    setOrgTypeFilter();
    setIsClearFilters(true);
    changePageNumber(0);
    sessionStorage.removeItem("organizationListSearchParams");
  }

  const handleChange = (value) => {
    setOrgTypeFilter(value);
    writeSearchDataToLocalStorage("orgTypeFilterStorage", value);
  };

  const columnsFromStorage = (() => {
    const storageData = gridcolumns?.columnSettings || [];
    return storageData?.reduce((acc, { name, visible }) => ({ ...acc, [name]: visible }), {});
  })();

  const columns = useMemo(
    () =>
      getColumns({
        control,
        columnsFromStorage,
        orgTypeFilter,
        searchInn,
        setsearchInn: setSearchInn,
        searchName,
        setsearchName: setSearchName,
        searchAddress,
        isShowBlockedColumn,
        setsearchAddress: setSearchAddress,
        handleChange,
        writeSearchDataToLocalStorage,
        isAdmin: roleId === 1
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnsFromStorage]
  );

  const handleSelectedOrganizationId = (organizationId, block) => {
    setSelectedOrganizationId({ organizationId, block: !block });
    setOpenConfirmUnblockedModal(true);
  };

  const handleOpenDetails = ({ id, typeId }) => {
    setSelectedId(id);
    setTypeId(typeId);
    setOpenDiteilsPopUp(!!id);
  };
  const handleOpenEditBalance = (id) => {
    setSelectedId(id);
    setopenBalanceModal(true);
  };
  const handleLink = (id, typeId) => navigate(PATHS.GET_EDIT_MY_ORGANIZATION(id, typeId));

  const rows = useMemo(
    () => {
      const { data } = allOrganizations;
      data?.length && setIsShowBlockedColumn(data[0].showBlocked);
      return getRowsData(
        data,
        handleLink,
        handleOpenDetails,
        handleOpenEditBalance,
        handleSelectedOrganizationId,
        screenSize.width < 1560
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allOrganizations]
  );

  const recordsCount = useMemo(() => allOrganizations && allOrganizations?.recordsCount, [allOrganizations]);

  useEffect(() => {
    setIsErrorRequest(error || blockUnblockOrganizationError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const refreshTable = () => {
    setopenBalanceModal(false);
    const dataFromStorage = Object.values(
      JSON.parse(sessionStorage.getItem("organizationListSearchParams")) || {}
    ).filter(Boolean);
    const city = searchAddress?.city ? `&AddressFilter.City=${searchAddress?.city}` : "";
    const street = searchAddress?.street ? `&AddressFilter.Street=${searchAddress?.street}` : "";
    const building = searchAddress?.building ? `&AddressFilter.Building=${searchAddress?.building}` : "";
    const address = city + street + building; //+emptyDTO
    if (dataFromStorage.length && pageNumber === 0 && !isChoosenFirstPage) return;
    getOrganizationsTable({
      searchValue: "",
      pageNumber,
      InnNumber: searchInn || "",
      address: address,
      name: searchName || "",
      orgTypeFilter: orgTypeFilter || "",
    });
  };

  useEffect(() => {
    const city = searchAddressStorage?.city ? `&AddressFilter.City=${searchAddressStorage?.city}` : "";
    const street = searchAddressStorage?.street ? `&AddressFilter.Street=${searchAddressStorage?.street}` : "";
    const building = searchAddressStorage?.building ? `&AddressFilter.Building=${searchAddressStorage?.building}` : "";
    const address = city + street + building; //+emptyDTO
    if (pageNumber === 0 && !Object.values(dataFromStorage).filter(Boolean).length && !isClearFilters) return;
    getOrganizationsTable({
      searchValue: "",
      pageNumber: 0,
      InnNumber: searchInnStorage || "",
      address: address,
      name: searchNameStorage || "",
      orgTypeFilter: orgTypeFilterStorage || "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInn, searchAddress, searchName, orgTypeFilter]);

  useEffect(() => {
    const dataFromStorage = Object.values(
      JSON.parse(sessionStorage.getItem("organizationListSearchParams")) || {}
    ).filter(Boolean);
    const city = searchAddress?.city ? `&AddressFilter.City=${searchAddress?.city}` : "";
    const street = searchAddress?.street ? `&AddressFilter.Street=${searchAddress?.street}` : "";
    const building = searchAddress?.building ? `&AddressFilter.Building=${searchAddress?.building}` : "";
    const address = city + street + building; //+emptyDTO
    if (dataFromStorage.length && pageNumber === 0 && !isChoosenFirstPage) return;
    getOrganizationsTable({
      searchValue: "",
      pageNumber,
      InnNumber: searchInn || "",
      address: address,
      name: searchName || "",
      orgTypeFilter: orgTypeFilter || "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  const handleOpenSelectColumnsModal = () => {
    setOpenSelectModal(true);
  };

  return (
    <>
      <Box className="legal-entity">
        <Box className="legal-entity-header">
          <Typography className="title-page">{t("title_page:legal_entities")}</Typography>
          <Box sx={{ display: "flex", gap: "16px" }}>
            <Button className="btn btn-primary-fill" onClick={clearFiltersHandler}>
              Очистить все фильтры
            </Button>
            <Button
              className="btn btn-primary-fill "
              variant="outlined"
              onClick={() => {
                navigate(PATHS.ADD_MY_ORGANIZATION);
              }}
            >
              <Box component="img" src={plusicon} sx={{ marginRight: "16px" }} />
              {t("button:add")}
            </Button>

            <Button
              className="btn btn-primary-fill magazine_events-select_container-btn"
              variant="outlined"
              onClick={handleOpenSelectColumnsModal}
              sx={{ height: "52px" }}
            >
              <Box component="img" src={settingsicon} sx={{ marginRight: "16px" }} />
              {t("button:customize_fields_to_display")}
            </Button>
          </Box>
        </Box>

        <TableBasic
          columns={columns}
          rows={rows || []}
          handleClickCell={() => {}}
          pageNumber={pageNumber}
          withSearchField={false}
          changePageNumber={changePageNumber}
          isLoading={isLoading}
          count={recordsCount}
          setIsChoosenFirstPage={setIsChoosenFirstPage}
          minHeight="52vh"
          maxHeight="60vh"
        />
      </Box>

      {openSelectModal && (
        <ModalSelectColumnsServer
          open={openSelectModal}
          setOpen={setOpenSelectModal}
          data={columnsFromStorage}
          refetch={() => getUserGrid({ gridSettingName: "OrganizationList" })}
          nameStorage={"OrganizationList"}
          config={getConfigFormModalSelect(gridcolumns?.columnSettings || [])}
        />
      )}

      {openBalanceModal && (
        <EditBalanceModal
          open={openBalanceModal}
          setOpen={setopenBalanceModal}
          orgId={selectedId}
          refetch={refreshTable}
        />
      )}

      {openDiteilsPopUp && (
        <MyOrganizationDetails open={openDiteilsPopUp} setOpen={setOpenDiteilsPopUp} id={selectedId} />
      )}

      {openConfirmUnblockedModal && (
        <ConfirmUnblockedOrganizationModal
          open={openConfirmUnblockedModal}
          setOpen={setOpenConfirmUnblockedModal}
          changeBlockedOrganizationStatus={changeBlockedOrganizationStatus}
        />
      )}
    </>
  );
};

export default MyOrganizations;
