import React, { useMemo, useEffect, useState } from "react";
import { contentFormConfig } from "./form-config";
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { getRequestData } from "./helpers";
import { getRequestDataFilesForAdd } from "./helpers";
import { useForm } from "react-hook-form";
import {
  useReplaceEquipmentMutation,
  useLazyGetEquipmentKindSelectElementsQuery,
  useValidateEquipmentNumbersMutation,
} from "../../../../middleware/equipmentsApi";
import { useManipulateFilesMutation } from "../../../../middleware/apiUploadFile";
import { useAction } from "../../../../hooks/actions";
import FormItem from "../../../components/FormItem";
import FormItemCheckboxControlled from "../../../components/FormItem/FormItemCheckboxControlled";
import { t } from "i18next";
import { formFields } from "./form-config";

const ReplaceEquipmentModal = ({ setOpen, isOpen, equipdata, modalClose, refreshEquipment }) => {
  const { setIsErrorSubmit, setIsSuccessSubmit, setErrorNotificationParams } = useAction();
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
    register,
    unregister,
    setValue,
    watch,
    getValues,
    trigger,
    reset,
    resetField,
  } = useForm({
    mode: "onChange",
  });

  const [searchObject, setsearchObject] = useState("");
  const [equipTypesitems, setequipTypesitems] = useState([]);
  const [equipTypesnames, setequipTypesnames] = useState([]);
  const facId = watch(formFields.floorMain);
  const [manipulateFiles, { error: errorFile, isSuccess: isSuccessFile, isLoading: loadingFiles }] =
    useManipulateFilesMutation();
  const [validateEquipmentNumbers, { error: validateError, data: isSuccessValidate }] =
    useValidateEquipmentNumbersMutation();
  const [replaceEquipment, { error: errorreplace, isSuccess: isSuccessReplace, isLoading: loadingreplaceEquipment }] =
    useReplaceEquipmentMutation();
  const [GetEquipmentKindSelectElements] = useLazyGetEquipmentKindSelectElementsQuery();

  const isError = errorreplace || errorFile || validateError;
  const isLoadingRequest = loadingreplaceEquipment || loadingFiles;
  const Equipmentype = watch(formFields.equipmentTypeId);
  const hasEquipmentGroup = watch(formFields.hasEquipmentGroup);
  const equipmentTypeId = watch(formFields.equipmentTypeId);

  const EquipmentName = watch(formFields.name);

  useEffect(() => {
    setValue(formFields.manufactureDate, equipdata?.manufactureDate);
    setValue(formFields.installationDate, new Date());
    setValue(formFields.workingCorrectly, equipdata?.workingCorrectly);
    setValue(formFields.number, equipdata?.number);
    setValue(formFields.plume, equipdata?.plume);
    setValue(
      formFields.equipmentTypeId,
      JSON.stringify({
        parentId: equipdata?.kindId,
        name: `${equipdata?.kindName}, ${equipdata?.typeName}`,
        id: equipdata?.typeId,
        hasIntegration: equipdata?.hasIntegration,
      })
    );
  }, [equipdata]);

  const replaceEquipmentFormItems = useMemo(
    () =>
      contentFormConfig(
        equipdata?.isActive,
        equipdata?.regulations,
        equipdata?.roomAddressId,
        isSuccessReplace,
        equipdata?.facilityId,
        setValue,
        getValues,
        resetField
      )[0],
    [equipdata?.isActive, isSuccessReplace, equipdata?.facilityId, equipdata?.name, getValues, setValue, resetField]
  );

  const addEquip = (data) => {
    const { entities, files } = getRequestData(data, false);
    const newEntities = {
      equipment: {
        ...entities,
        protectionObjectId: equipdata?.facilityId,
        floorInfoId: equipdata?.floorInfoId,
      },
      replaceEquipmentId: equipdata?.id,
    };
    replaceEquipment(newEntities).then(({ data, error }) => {
      if (!error) {
        const resData = data;
        if (!resData?.length) {
          return;
        }
        const entityId = resData[0].id;
        const entityType = "Equipment";
        const requestDataFiles = getRequestDataFilesForAdd(entityId, entityType, files);
        if (modalClose) {
          modalClose(false);
          refreshEquipment(false, true);
        }
        manipulateFiles(requestDataFiles).then(() => {
          if (error) {
            const allItemsIds = resData.map(({ id }) => id);
            for (const itemId of allItemsIds) {
            }
          }
          setOpen(false);
          reset();
          resetField(formFields.objectMain);
          resetField(formFields.floorMain);
        });
      }
    });
  };

  const onSubmit = async (data) => {
    let isErrorUniq = false;
    const addEquipdata = {
      ...data,
    };
    const equipmentsCountValue = getValues(formFields.equipmentsCount);
    if (getValues(formFields.hasEquipmentGroup)) {
      validateEquipmentNumbers({
        id: equipdata.id,
        number: getValues(formFields.startNumber),
        EquipmentsCount: Number(getValues(formFields.equipmentsCount) || 1),
        ProtectionObjectId: equipdata?.facilityId,
      }).then((data) => {
        if (data?.error) {
          isErrorUniq = true;
          setError("root.serverError", {
            typeMulti: data?.error.data?.StatusCode,
            messageMulti: data?.error.data?.Message,
          });
        } else {
          addEquip(addEquipdata);
          setOpen(false);
        }
      });
    } else {
      if (!equipmentsCountValue) {
        setValue(formFields.equipmentsCount, 1);
      }
      validateEquipmentNumbers({
        id: equipdata.id,
        number: getValues(formFields.number),
        protectionObjectId: equipdata?.facilityId,
      }).then((data) => {
        if (data?.error) {
          isErrorUniq = true;
          setError("root.serverError", {
            type: data?.error.data?.StatusCode,
            message: data?.error.data?.Message,
          });
        } else {
          addEquip(addEquipdata);
          setOpen(false);
        }
      });
    }
  };

  useEffect(() => {
    setIsErrorSubmit(isError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    setIsSuccessSubmit(isSuccessReplace || isSuccessFile || isSuccessValidate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessReplace, isSuccessFile, isSuccessValidate]);
  const handleClose = () => {
    reset();
    resetField(formFields.objectMain);
    resetField(formFields.floorMain);

    setOpen(false);
  };

  return (
    <Dialog sx={{ padding: 10 }} fullWidth fullScreen className="modal" open={isOpen}>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <DialogTitle>Замена оборудования</DialogTitle>
        <DialogTitle className="modal-title-right">
          <IconButton onClick={() => handleClose()}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
      </Box>
      <DialogContent>
        <form id="replaceEquipmentForm" onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-start",
              alignItems: "flex-end",
              columnGap: "15px",
            }}
          >
            <Box sx={{}}>
              <Typography sx={{ fontSize: 18, marginBottom: "20px" }}>
                {`Объект: ${equipdata?.facilityName}`}
              </Typography>
              <Typography sx={{ fontSize: 18, marginBottom: "20px" }}>{`Этаж: ${equipdata?.floorInfoText}`}</Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-start",
              alignItems: "baseline",
              columnGap: "15px",
            }}
          >
            {replaceEquipmentFormItems.items.map(
              ({ id, name, label, input, expended, flexBasis, CustomFormItem, ...rest }) =>
                name != "fireCompartmentId" &&
                (CustomFormItem ? (
                  <Box key={id} className="resizing_basic_forms">
                    <CustomFormItem
                      key={name || id}
                      control={control}
                      errors={errors}
                      errorTyp={errors}
                      register={register}
                      unregister={unregister}
                      setValue={setValue}
                      name={name}
                      label={label}
                      input={input}
                      expended={expended}
                      trigger={trigger}
                      {...rest}
                    />
                  </Box>
                ) : expended && rest.type === "checkbox" ? (
                  <FormItemCheckboxControlled
                    key={name}
                    control={control}
                    errors={errors}
                    errorTyp={errors}
                    register={register}
                    unregister={unregister}
                    setValue={setValue}
                    name={name}
                    label={label}
                    input={input}
                    expended={expended}
                    trigger={trigger}
                    {...rest}
                  />
                ) : (
                  ((!hasEquipmentGroup && name == "number") ||
                    ((equipmentTypeId ? JSON.parse(equipmentTypeId)?.hasIntegration : false) &&
                      (name == "plume" || name == "integrationNumber")) ||
                    (name != "number" && name != "plume" && name != "integrationNumber")) && (
                    <Box key={id} sx={{ flexBasis: flexBasis, alignSelf: "flex-start !important" }}>
                      <FormItem
                        key={name}
                        control={control}
                        errors={errors}
                        errorTyp={errors}
                        objectId={equipdata?.facilityId}
                        setequipTypesitems={setequipTypesitems}
                        setequipTypesnames={setequipTypesnames}
                        selectedKind={equipdata?.kindId}
                        selectedGroup={equipdata?.name}
                        Equipmentype={Equipmentype}
                        islite={true}
                        name={name}
                        disabled={name == "fireCompartmentId" ? true : false}
                        label={label}
                        input={input}
                        trigger={trigger}
                        {...rest}
                      />
                    </Box>
                  )
                ))
            )}
          </Box>
        </form>
      </DialogContent>

      <Button
        form="replaceEquipmentForm"
        className="btn btn-primary-fill"
        sx={{
          width: "150px",
          alignSelf: "end",
          margin: "20px",
          marginRight: "40px",
        }}
        type="submit"
      >
        {t("button:save")}
      </Button>
    </Dialog>
  );
};

export default ReplaceEquipmentModal;
