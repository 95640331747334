import React,{useState}  from "react";
import { useTranslation } from "react-i18next";
import { ListItemText, OutlinedInput, Typography,Box } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import selectArrowUp from "../../../image/icons/selectArrowUp.png";
import selectArrowDown from "../../../image/icons/selectArrowDown.png"

const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 8;

const SelectSimpleColored = ({
  options,
  label,
  onChange,
  disabled = false,
  error,
  value,
  setValue,
  ...rest
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: options?.length>=4 ? (ITEM_HEIGHT+ ITEM_PADDING_TOP) * 4 : (ITEM_HEIGHT+ ITEM_PADDING_TOP) * options?.length ,
      },
    },
  };
  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  const getBackgroundColor = (option) => {
    switch (option) {
      case "К выполнению":
        return "rgba(222, 247, 236)";
      case "Выполнено":
        return "rgba(164, 168, 252, 0.5)";
      case "На проверке":
        return "rgba(245, 158, 11, 0.3)";
      default:
        return "rgba(255, 255, 255, 0.2)";
    }
  };

  return (
    <Box sx={{ position: "relative" }} className="input_text">
      <InputLabel sx={{ mb: "8px" }}>
        {t(label)}
      </InputLabel>
    <FormControl fullWidth error={!!error} {...rest}>
      <Select
        value={value || ""}
        onChange={onChange}
        MenuProps={{
          ...MenuProps,
          variant: "menu",
          shouldFocus: false,
          autoFocus: false,
          disableAutoFocusItem: true,
          disableListFocus: true,
        }}
        disabled={disabled}
        open={isOpen}
        onOpen={handleOpen}
        onClose={handleClose}
        IconComponent={() =>
          <Box sx={{ mr: "24px",cursor:"pointer" }} onClick={()=>{setIsOpen((prev)=> !prev)}}>
            {isOpen ? (
              <img src={selectArrowUp} />
            ) : (
              <img src={selectArrowDown} />
            )
            }
          </Box>}
        sx={{
          ".MuiSelect-select": {
            display: "flex",
            alignItems: "center",
            height: 0,
          },
          backgroundColor: getBackgroundColor(value),
          color: "black",
        }}
      >
        {options.map((option, i) => (
          <MenuItem
            key={i}
            value={option.value || option || ""}
            sx={{ height: ITEM_HEIGHT }}
          >
            <ListItemText
              primary={
                <Typography>
                  {option.name?.toString() || option || ""}
                </Typography>
              }
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
    </Box>
  );
};

export default SelectSimpleColored;
