import { t } from "i18next";
import OrganizationIcon from "./views/components/icons/Sidebar/OrganizationIcon";
import ObjectsIcon from "./views/components/icons/Sidebar/ObjectsIcon";
import EquipmentIcon from "./views/components/icons/EquipmentIcon";
import EmployeesIcon from "./views/components/icons/Sidebar/EmployeesIcon";
import TasksIcon from "./views/components/icons/Sidebar/TasksIcon";
import ReglamentIcon from "./views/components/icons/ReglamentIcon";
import MagazinesIcon from "./views/components/icons/Sidebar/MagazinesIcon";
import AnalyticsIcon from "./views/components/icons/AnalyticsIcon";
import GostsIcon from "./views/components/icons/GostsIcon";
import IntegrationIcon from "./views/components/icons/IntegrationIcon";
import BillingIcon from "./views/components/icons/BillingIcon";
import { SettingsIcon } from "./views/components/icons/SettingsIcon";

import ResponsibleIcon from "./views/components/icons/ResponsibleIcon";
import {
  ANALYTICS,
  DIGITIZATION,
  MAGAZINES,
  OBJECTS,
  ORGANIZATIONS,
  RESPONSIBLE,
  EMPLOYEES,
  TASKS,
  ACTS,
  MAIN
} from "./constants";
import { PATHS } from "./constants";
import { TimeTableIcon } from "./views/components/icons/Sidebar/TimeTableIcon";
import PlumeIcon from "./views/components/icons/Sidebar/PlumeIcon";
import { DocumentsIcon } from "./views/components/icons/Sidebar/DocumentsIcon";
import { ConsolesIcon } from "./views/components/icons/Sidebar/ConsolesIcon";

export const BIG_SCREEN = 800;

export const sideBarOptions = [
  {
    id: 1,
    title: t("sidebar:organizations"),
    icon: OrganizationIcon,
    expanded: true,
    role: "",
    rootPath: ORGANIZATIONS,
    path: PATHS.MY_ORGANIZATIONS,
    level: 1,
    subItems: [
      {
        id: 11,
        title: "Мои юридические лица",
        icon: null,
        expanded: false,
        role: "",
        path: PATHS.MY_ORGANIZATIONS,
        level: 2,
      },
      {
        id: 12,
        title: "Ответственные",
        icon: null,
        expanded: false,
        role: "",
        path: PATHS.MY_ORGANIZATIONS_RESPONSIBLE,
        level: 2,
      },
    ],
  },
  {
    id: 2,
    title: "Объекты",
    icon: ObjectsIcon,
    expanded: true,
    role: "",
    rootPath: OBJECTS,
    path: PATHS.LIST_OBJECTS,
    level: 1,
    subItems: [
      {
        id: 21,
        title: "Список объектов",
        icon: null,
        expanded: false,
        role: "",
        path: PATHS.LIST_OBJECTS,
        level: 2,
      },
      {
        id: 22,
        title: "Объекты на карте",
        icon: null,
        expanded: false,
        role: "",
        path: PATHS.OBJECTS_MAP,
        level: 2,
      },
    ],
  },
  {
    id: 3,
    title: "Ответственные",
    icon: ResponsibleIcon,
    expanded: true,
    role: "",
    rootPath: RESPONSIBLE,
    path: PATHS.RESPONSIBLE_ORGANIZATIONS,
    level: 1,
    subItems: [
      {
        id: 31,
        title: "Ответственные организации",
        icon: null,
        expanded: false,
        role: "",
        path: PATHS.RESPONSIBLE_ORGANIZATIONS,
        level: 2,
      },
      {
        id: 32,
        title: "Исполнители",
        icon: null,
        expanded: false,
        role: "",
        path: PATHS.PERFORMERS,
        level: 2,
      },
      {
        id: 33,
        title: "Добавить ответственного",
        icon: null,
        expanded: false,
        role: "",
        path: PATHS.ADD_RESPONSIBLE_ORGANIZATION,
        level: 2,
      },
    ],
  },
  {
    id: 4,
    title: "Оборудование",
    icon: EquipmentIcon,
    expanded: false,
    role: "",
    path: PATHS.EQUIPMENT,
    level: 1,
    subItems: [],
  },
  {
    id: 5,
    title: "Сотрудники",
    icon: EmployeesIcon,
    expanded: false,
    role: "",
    path: PATHS.EMPLOYEES,
    level: 1,
    subItems: [],
  },
  
  {
    id: 7,
    title: "Регламенты",
    icon: ReglamentIcon,
    expanded: false,
    role: "",
    path: PATHS.REGLAMENTS,
    level: 1,
    subItems: [],
  },
  {
    id: 8,
    title: "Журналы",
    icon: MagazinesIcon,
    expanded: true,
    role: "",
    rootPath: MAGAZINES,
    path: PATHS.MAGAZINES_SYSTEMS,
    level: 1,
    subItems: [
      {
        id: 81,
        title: "Журнал эксплуатации систем противопожарной защиты",
        icon: null,
        expanded: false,
        role: "",
        path: PATHS.MAGAZINES_SYSTEMS,
        level: 2,
      },
      {
        id: 82,
        title: "Журнал учета технических средств",
        icon: null,
        expanded: false,
        role: "",
        path: PATHS.MAGAZINES_TECH,
        level: 2,
      },
      {
        id: 83,
        title: "Журнал регистрации извещений от автоматики",
        icon: null,
        expanded: false,
        role: "",
        path: PATHS.MAGAZINES_NOTIFICATIONS,
        level: 2,
      },
      {
        id: 84,
        title: "Журнал учета назначений",
        icon: null,
        expanded: false,
        role: "",
        path: PATHS.MAGAZINES_ACCOUNTING,
        level: 2,
      },
      {
        id: 85,
        title: "Журнал событий",
        icon: null,
        expanded: false,
        role: "",
        path: PATHS.MAGAZINES_EVENTS,
        level: 2,
      },
    ],
  },
  {
    id: 9,
    title: "Аналитика",
    icon: AnalyticsIcon,
    expanded: true,
    role: "",
    rootPath: ANALYTICS,
    path: PATHS.REPORT_EMPLOYEE,
    level: 1,
    subItems: [
      {
        id: 91,
        title: "Отчет по сотрудникам",
        icon: null,
        expanded: false,
        role: "",
        path: PATHS.REPORT_EMPLOYEE,
        level: 2,
      },
      {
        id: 92,
        title: "Отчет по исполнителям",
        icon: null,
        expanded: false,
        role: "",
        path: PATHS.REPORT_REGLAMENT,
        level: 2,
      },
      {
        id: 93,
        title: "Отчет по закрытым задачам",
        icon: null,
        expanded: false,
        role: "",
        path: PATHS.REPORT_CLOSED_TASKS,
        level: 2,
      },
      {
        id: 94,
        title: "Отчеты по объектам",
        icon: null,
        expanded: false,
        role: "",
        path: PATHS.REPORT_OBJECTS,
        level: 2,
      },
    ],
  },
  {
    id: 10,
    title: "ГОСТы",
    icon: GostsIcon,
    expanded: false,
    role: "",
    path: PATHS.GOSTS,
    level: 1,
    subItems: [],
  },
  {
    id: 11,
    title: "Интеграции",
    icon: IntegrationIcon,
    expanded: false,
    role: "",
    path: PATHS.INTEGRATION,
    level: 1,
    subItems: [],
  },
  {
    id: 12,
    title: "Биллинг",
    icon: BillingIcon,
    expanded: false,
    role: "",
    path: PATHS.BILLING,
    level: 1,
    subItems: [],
  },
  {
    id: 13,
    title: "Настройки",
    icon: SettingsIcon,
    expanded: false,
    role: "",
    path: PATHS.SETTINGS,
    level: 1,
    subItems: [],
  },
];

export const sideBarOptionsNewVersion = [
  {
    id: 1,
    name: "organizations",
    title: t("sidebar:organizations"),
    icon: OrganizationIcon,
    expanded: true,
    role: "",
    rootPath: ORGANIZATIONS,
    path: PATHS.MY_ORGANIZATIONS,
    level: 1,
    subItems: [
      {
        id: 11,
        title: t("sidebar:list_organizations"),
        icon: null,
        name: "organizations",
        expanded: false,
        role: "",
        path: PATHS.MY_ORGANIZATIONS,
        level: 2,
      },
      {
        id: 12,
        title: t("sidebar:add_organization"),
        icon: null,
        name: "organizations",
        expanded: false,
        role: "",
        path: PATHS.ADD_MY_ORGANIZATION,
        level: 2,
      },
    ],
  },
  {
    id: 3,
    name: "users",
    title: t("sidebar:employees"),
    icon: EmployeesIcon,
    expanded: true,
    role: "",
    rootPath: EMPLOYEES,
    path: PATHS.LIST_EMPLOYEES,
    level: 1,
    subItems: [
      {
        id: 21,
        title: t("sidebar:list_employees"),
        icon: null,
        name: "users",
        expanded: false,
        role: "",
        path: PATHS.LIST_EMPLOYEES,
        level: 2,
      },
      {
        id: 22,
        title: t("sidebar:add_employee"),
        icon: null,
        name: "users",
        expanded: false,
        role: "",
        path: PATHS.ADD_EMPLOYEE,
        level: 2,
      },
    ],
  },
  {
    id: 2,
    name: "facilities",
    title: t("sidebar:objects"),
    icon: ObjectsIcon,
    expanded: true,
    role: "",
    rootPath: OBJECTS,
    path: PATHS.OBJECTS_MAP,
    level: 1,
    subItems: [
      /* {
        id: 21,
        title: t("sidebar:objects_map"),
        icon: null,
        expanded: false,
        role: "",
        path: PATHS.OBJECTS_MAP,
        level: 2,
      }, */
      {
        id: 22,
        title: t("sidebar:objects_list"),
        icon: null,
        name: "facilities",
        expanded: false,
        role: "",
        path: PATHS.LIST_OBJECTS,
        level: 2,
      },
      {
        id: 13,
        title: t("sidebar:add_object"),
        icon: null,
        name: "facilities",
        expanded: false,
        role: "",
        path: PATHS.ADD_OBJECT,
        level: 2,
      },
      /* {
        id: 14,
        title: t("sidebar:list_fire_compartments"),
        icon: null,
        expanded: false,
        role: "",
        path: PATHS.LIST_FIRE_COMPARTMENTS,
        level: 2,
      }, */
      // {
      //   id: 15,
      //   title: t("sidebar:add_fire_compartment"),
      //   icon: null,
      //   expanded: false,
      //   role: "",
      //   path: PATHS.ADD_FIRE_COMPARTMENT,
      //   level: 2,
      // },
    ],
  },

  // {
  //   id: 3,
  //   title: "Подрядчики",
  //   icon: ResponsibleIcon,
  //   expanded: true,
  //   role: "",
  //   rootPath: RESPONSIBLE,
  //   path: PATHS.RESPONSIBLE_ORGANIZATIONS,
  //   level: 1,
  //   subItems: [
  //     {
  //       id: 31,
  //       title: "Список подрядчиков",
  //       icon: null,
  //       expanded: false,
  //       role: "",
  //       path: PATHS.RESPONSIBLE_ORGANIZATIONS,
  //       level: 2,
  //     },
  //     {
  //       id: 32,
  //       title: "Исполнители",
  //       icon: null,
  //       expanded: false,
  //       role: "",
  //       path: PATHS.PERFORMERS,
  //       level: 2,
  //     },
  //   ],
  // },
  {
    id: 4,
    name: "equipments",
    title: t("sidebar:equipment"),
    icon: PlumeIcon,
    expanded: true,
    role: "",
    rootPath: DIGITIZATION,
    path: PATHS.LIST_EQUIPMENT,
    level: 1,
    subItems: [
      {
        id: 41,
        title: t("sidebar:add_equipment_lite"),
        name: "equipmentsadd-edit",
        icon: null,
        expanded: false,
        role: "",
        path: PATHS.ADD_EQUIPMENT_LITE,
        level: 2,
      },
     /* {
        id: 42,
        title: t("sidebar:add_equipment"),
        icon: null,
        expanded: false,
        role: "",
        path: PATHS.ADD_EQUIPMENT,
        level: 2,
      },*/
      {
        id: 43,
        title: t("sidebar:list_equipment"),
        icon: null,
        name: "equipments",
        expanded: false,
        role: "",
        path: PATHS.LIST_EQUIPMENT,
        level: 2,
      }, 
      {
        id: 44,
        title: t("sidebar:equip_reglaments"),
        icon: null,
        name: "equipments",
        expanded: false,
        role: "",
        path: PATHS.EQUIPMENT_REGLAMENTS,
        level: 2,
      },
    ],
  },
  {
    id: 5,
    name: "tasks",
    title: t("sidebar:tasks"),
    icon: TasksIcon,
    expanded: true,
    role: "",
    rootPath: TASKS,
    path: PATHS.ADD_TASK,
    level: 1,
    subItems: [
      {
        id: 51,
        title: t("sidebar:add_task"),
        icon: null,
        expanded: false,
        name: "tasks",
        role: "",
        path: PATHS.ADD_TASK,
        level: 2,
      },
      
      {
        id: 53,
        title: t("sidebar:list_tasks"),
        icon: null,
        expanded: false,
        name: "tasks",
        role: "",
        path: PATHS.LIST_TASKS,
        level: 2,
      },
      {
        id: 54,
        title: t("sidebar:group_tasks"),
        icon: null,
        expanded: false,
        name: "tasks",
        role: "",
        path: PATHS.GROUP_TASKS,
        level: 2,
      },
    ],
  },
  {
    id: 6,
    name: "logs",
    title: t("sidebar:magazines"),
    icon: MagazinesIcon,
    expanded: true,
    role: "",
    rootPath: MAGAZINES,
    path: PATHS.MAGAZINES_EVENTS,
    level: 1,
    subItems: [
      {
        id: 61,
        title: t("sidebar:magazines_events"),
        icon: null,
        expanded: false,
        name: "logs",
        role: "",
        path: PATHS.MAGAZINES_EVENTS,
        level: 2,
      },
      {
        id: 62,
        title: t("sidebar:magazines_finished_tasks"),
        icon: null,
        expanded: false,
        name: "logs",
        role: "",
        path: PATHS.MAGAZINES_FINISHED_TASKS,
        level: 2,
      },
      {
        id: 7777,
        title: t("sidebar:magazines_system_events"),
        icon: null,
        expanded: false,
        name: "logs",
        role: "",
        path: PATHS.MAGAZINES_SYSTEM_EVENTS,
        level: 2,
      },
    ],
  },
  {
    id: 7,
    name: "exploitation",
    title: t("sidebar:acts"),
    icon: DocumentsIcon,
    expanded: true,
    role: "",
    rootPath: ACTS,
    path: PATHS.ACTS,
    level: 1,
    subItems: [
      {
        id: 43,
        title: t("sidebar:plume_and_address"),
        icon: null,
        name: "facility_plan",
        expanded: false,
        role: "",
        path: PATHS.PLUME_AND_ADDRESS,
        level: 2,
      },
      {
        id: 44,
        title: t("sidebar:func_tests"),
        icon: null,
        name: "documentation",
        expanded: false,
        role: "",
        path: PATHS.FUNC_TESTS,
        level: 2,
      },
      {
        id: 45,
        title: t("sidebar:documents"),
        icon: null,
        name: "documentation",
        expanded: false,
        role: "",
        path: PATHS.DOCUMENTS,
        level: 2,
      },
      {
        id: 46,
        title: t("sidebar:planning"),
        icon: null,
        name: "planning",
        expanded: false,
        role: "",
        path: PATHS.PLANNING,
        level: 2,
      },
    ],
  },
  // {
  //   id: 8,
  //   name: "analytics",
  //   title: t("sidebar:analytics"),
  //   icon: AnalyticsIcon,
  //   expanded: false,
  //   role: "",
  //   path: PATHS.ANALYTICS,
  //   level: 1,
  //   subItems: [],
  // },
  // {
  //   id: 9,
  //   name: "calendar-plan",
  //   title: t("sidebar:time_table"),
  //   icon: TimeTableIcon,
  //   expanded: false,
  //   role: "",
  //   path: PATHS.TIMETABLE,
  //   level: 1,
  //   subItems: [],
  // },
  {
    id: 17,
    name: "control-console",
    title: t("sidebar:controllers"),
    icon: ConsolesIcon,
    expanded: false,
    role: "",
    path: PATHS.CONTROLLERS,
    level: 1,
    subItems: [],
  },
];
