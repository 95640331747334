import useScreenSize from "../../../../hooks/useScreenSize";
import { screenSizes } from "../../../../constants";
import { Box, IconButton, Menu } from "@mui/material";
import React, { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import { menuItems } from "./constants";
import InfoIcon from "@mui/icons-material/Info";

const AdditionalInfo = () => {
  const isSmallDescktop = useScreenSize().width < 1560;
  const sx = {
    fontFamily: "var(--INTER)",
    fontWeight: 500,
    fontSize: isSmallDescktop ? "var(--titleBlock)" : "var(--standartVeryLarge)",
    lineHeight: "24px",
    color: "var(--text-color)",
    cursor: "pointer",
    textDecoration: "none",
    textTransform: "none",
  };

  const { width } = useScreenSize();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (width <= screenSizes.tablet) {
    return (
      <>
        <IconButton aria-expanded={anchorEl} onClick={handleClick} sx={{ color: "var(--main)" }}>
          <InfoIcon />
        </IconButton>
        <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
          {menuItems.map(({ id, Component, ...props }) => (
            <MenuItem key={id}>
              <Component sx={sx} {...props} />
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  }
  return (
    <>
      {menuItems.map(({ id, Component, ...props }) => (
        <Box key={id} sx={sx}>
          <Component sx={sx} {...props} />
        </Box>
      ))}
    </>
  );
};

export default AdditionalInfo;
