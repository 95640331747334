import { fileEntityFields } from "./constants";

export const getRequestData = (data, isFullForm) => {
  const fileFields = ["workContract", "equipmentPassportFile"];
  const files = {};
  let placement = "";
  switch (data.placement) {
    case "Запотолочное пространство":
      placement = 0;
      break;
    case "Подпольное пространство":
      placement = 1;
      break;
    case "Основное помещение":
      placement = 2;
      break;
    default:
      placement = Number(data.placement);
      break;
  }
  fileFields.forEach((fieldName) => {
    if (data[fieldName]) {
      files[fieldName] = data[fieldName];
    }
  });
  if (data.startNumber) {
    data.number = data.startNumber;
    delete data.startNumber;
  }
  delete data.equipmentPassportFile;
  delete data.workContract;
  data.placement = placement;
  if (isFullForm) data.fireCompartmentId = JSON.parse(data.fireCompartmentId).id;
  else {
    data.floorInfoId = data.floorMain;
    delete data.fireCompartmentId;
    delete data.floor;
    delete data.floorMain;
  }
  data.equipmentTypeId = data.typeId = JSON.parse(data.equipmentTypeId)?.id;
  data.equipmentsCoordinates = data.equipmentsCoordinates
    ? data.equipmentsCoordinates.map(
        ({ id, workingCorrectly, floorSchemeCoordinateX, floorSchemeCoordinateY, floorInfoId }) => {
          const item = {
            floorInfoId,
            floorSchemeCoordinateX,
            floorSchemeCoordinateY,
          };

          if (!id.includes("new")) {
            item.id = id;
          }

          if (workingCorrectly) {
            item.workingCorrectly = workingCorrectly;
          }

          return item;
        }
      )
    : [];

  const untouchedEquipmentsCount = data.equipmentsCount - data.equipmentsCoordinates.length;
  if (untouchedEquipmentsCount > 0) {
    const untouchedEquipmentsList = Array(untouchedEquipmentsCount).fill({ floorInfoId: data.floor });
    data.equipmentsCoordinates.push(...untouchedEquipmentsList);
  }
  if (data.equipmentContractFileId) {
    const prevWorkContractData = JSON.parse(data.equipmentContractFileId);
    if (prevWorkContractData) {
      const { id, contractDate, contractNumber } = prevWorkContractData;
      data.equipmentContractFileId = id || "";
      data.contractDate = contractDate || null;
      data.contractNumber = contractNumber || 0;
    }
  } else {
    data.equipmentContractFileId = null;
  }

  const entities = {
    ...data,
    responsibleOrganizationId: data?.responsibleOrganizationId || null,
  };

  return { entities, files };
};

export const getRequestDataFilesForAdd = (entityId, entityType, files) => {
  const formData = new FormData();

  if (!files) {
    return formData;
  }
  const getFilesKey = Object.keys(files)

  formData.append(`entityFileList[0].EntityId`, entityId);
  formData.append(`entityFileList[0].EntityType`, entityType);
  formData.append(`entityFileList[0].EntityField`, fileEntityFields[getFilesKey[0]]);
  formData.append(`entityFileList[0].File`, files.equipmentPassportFile.filesToAdd[0]);
  formData.append(`entityFileList[0].ActionType`, "Add");

  return formData;
};

export const getFacilityAndFireCompartmentOptionsGroup = (data) =>
  data.map((el) => ({
    id: el.id,
    name: el.name,
    items: el.fireCompartments,
    open: false,
  }));

export const getEquipmentTypeIdOptionsGroupAddEquip = (data) => {
  if (!Array.isArray(data)) {
    return [];
  }

  return data.map((el) => ({
    id: `${el?.id}`,
    name: el?.fullTitle,
    items: el?.equipmentTypes,
    open: false,
  }));
};
export const getEquipmentTypeIdOptionsGroup = (data) => {
  if (!Array.isArray(data)) {
    return [];
  }

  return data.map((el) => ({
    id: `parent${el?.id}`,
    name: el?.title,
    items: el?.equipmentTypes,
    open: false,
  }));
};
