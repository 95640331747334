import React, { useState, useEffect, useMemo } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ModalSelectColumnsServer } from "../../components/Modals/ModalSelectColumnsServer";
import { useAction } from "../../../hooks/actions";
import TableBasic from "../../components/TableBasic";
import { useForm, useWatch } from "react-hook-form";
import { useLazyGetGroupTasksListQuery } from "../../../middleware/groupJobsApi";
import { useAppSelector } from "../../../hooks/redux";
import { Bar } from "react-chartjs-2";
import GroupTaskDetails from "./GroupTaskDetailes/GroupTaskDetailes";
import { getColumns } from "./columns";
import { formFields, allColumns, getConfigFormModalSelect } from "./form-config";
import { useGetUserGridSettingQuery, useLazyGetUserGridSettingQuery } from "../../../middleware/usersApi";
import { useLazyGetPlanPeriodsSelectionQuery } from "../../../middleware/equipmentsApi";
import { useLazyGetEquipmentKindsFacilityQuery } from "../../../middleware/facilitiesApi";
import { RatingCell } from "../ObjectsList/Components";
import PageheaderNav from "../../components/PageheaderNav";
import settingsicon from "../../../image/icons/settingsButtonWhite.png";
const GroupTasks = () => {
  const { t } = useTranslation();
  const { control, setValue, watch } = useForm({
    defaultValues: {
      [formFields.searchParams]: {
        [formFields.protectionObjectId]: "",
      },
    },
  });
  const params = useWatch({
    control,
    name: formFields.searchParams,
  });
  const { setIsErrorRequest, setIsErrorSubmit } = useAction();
  const [openSelectModal, setOpenSelectModal] = useState(false);
  const [PageNumber, changePageNumber] = useState(0);
  const [ranOnce, setRanOnce] = useState(false);
  const [searchTaskName, setsearchTaskName] = useState("");
  const [searchresUser, setSearchresUser] = useState("");
  const [searchresCreator, setSearchresCreator] = useState("");
  const [openDetailsPopUp, setOpenDetailsPopUp] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedName, setSelectedName] = useState(null);
  const { grouptasksList } = useAppSelector((state) => state.grouptasks);
  const facilityId = watch(`${formFields.searchParams}.${formFields.protectionObjectId}`);
  const equipKindId = watch(`${formFields.searchParams}.${formFields.EquipmentKindId}`);
  const {
    data: gridcolumns,
    error: errorgridColumns,
    isLoading: isLoadingerrorColumns,
  } = useGetUserGridSettingQuery({ gridSettingName: "GroupJobList" });

  const [getUserGrid, { error: errorgrid, refetch: refetchUserGrid /* isLoading */ }] =
    useLazyGetUserGridSettingQuery();

  const [getGroupTasksListTable, { error, isLoading: isLoadingGroupTaskList, isFetching, refetch: refetchgrouptasks }] =
    useLazyGetGroupTasksListQuery();
  const [getEquipKinds, { error: isErrorgetEquipKinds, isLoading: isLoadinggetEquipKinds }] =
    useLazyGetEquipmentKindsFacilityQuery();
  const [getPeriodsSelect, { error: isErrorgetPeriodsSelect, isLoading: isLoadinggetPeriodsSelect }] =
    useLazyGetPlanPeriodsSelectionQuery();

  const columnsFromStorage = (() => {
    const storageData = gridcolumns?.columnSettings || [];
    return storageData?.reduce((acc, { name, visible }) => ({ ...acc, [name]: visible }), {});
  })();

  const columns = useMemo(
    () =>
      getColumns({
        control,
        columnsFromStorage,
        searchTaskName,
        setsearchTaskName,
        searchresUser,
        setSearchresUser,
        searchresCreator,
        setSearchresCreator,
        setValue,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnsFromStorage]
  );
  const handleOpenDetails = ({ id, taskname }) => {
    setSelectedId(id);
    setSelectedName(taskname);
    setOpenDetailsPopUp(true);
  };
  const getCellChartData = (completionProgress) => {
    const data = {
      labels: [""],
      datasets: [
        {
          data: [completionProgress],
          backgroundColor: "#4339F2",
          hoverBackgroundColor: "#4339F2",
        },
      ],
    };
    return data;
  };

  // const chartOptions = {
  //   indexAxis: "y",
  //   plugins: {
  //     legend: { display: false },
  //     datalabels: {
  //       display: true,
  //       color: "black",
  //       formatter: Math.round,
  //       anchor: "end",
  //       offset: 20,
  //       align: "start",
  //     },
  //   },
  //   scales: {
  //     y: {
  //       beginAtZero: true,
  //       grid: {
  //         display: false,
  //       },
  //     },
  //     x: {
  //       display: false,
  //       barPercentage: 0.5,
  //       categoryPercentage: 0.8,
  //     },
  //   },
  // };

  useEffect(() => {
    setValue(`${formFields.searchParams}.${formFields.TaskName}`, searchTaskName);
  }, [searchTaskName, setValue]);
  useEffect(() => {
    setValue(`${formFields.searchParams}.${formFields.ExecutiveUserName}`, searchresUser);
  }, [searchresUser, setValue]);
  useEffect(() => {
    setValue(`${formFields.searchParams}.${formFields.ResponsionCustomerUserName}`, searchresCreator);
  }, [searchresCreator, setValue]);

  const rows = useMemo(() => {
    const groupList = grouptasksList;
    return groupList?.data?.length
      ? groupList.data.map(
          ({
            completionProgress,
            createdAt,
            deadlineStatus,
            id,
            isAvailableToGenerateAct,
            taskName,
            planPeriodEndDate,
            planPeriodName,
            planPeriodStartDate,
            protectionObjectName,
            equipmentKindName,
            responsibleCustomerUsers,
            executiveUsers,
          }) => {
            return {
              id,
              taskName: {
                name: taskName,
                action: () => handleOpenDetails({ id, taskName }),
              },
              act: (
                <Button
                  className="btn btn-outlined-primary magazine_events-select_container-btn"
                  disabled={!isAvailableToGenerateAct}
                  variant="outlined"
                >
                  {t("button:create_act")}
                </Button>
              ),

              protectionObjectName,
              responsibleCustomerUsers,
              executiveUsers,
              equipmentKindName,
              periodDate: {
                periodName: planPeriodName,
                startPeriod: planPeriodStartDate,
                EndPeriod: planPeriodEndDate,
              },
              status: <div style={{ width: "230px" }}>{RatingCell(completionProgress)}</div>,

              // <Box style={{ display: "flex", padding: 0 }}>
              //   {/* <Box style={{ marginTop: "15px" }}>
              //     {`${completionProgress}%`}
              //   </Box> */}
              //   <Box style={{ height: "50px" }}>
              //     {/* <Bar
              //       data={getCellChartData(
              //         completionProgress
              //       )}
              //       options={{ ...chartOptions, maintainAspectRatio: false }}
              //     /> */}
              //     <div>{RatingCell(completionProgress)}</div>
              //   </Box>

              // </Box>,
            };
          }
        )
      : [];
    // eslint-disable-next-line
  }, [grouptasksList]);
  const recordsCount = useMemo(() => grouptasksList && grouptasksList?.recordsCount, [grouptasksList]);
  useEffect(() => {
    if (facilityId) {
      if (facilityId == "все") {
        setValue(`${formFields.searchParams}.${formFields.EquipmentKindId}`, "все");
      } else {
        getEquipKinds(facilityId).then(({ data, isSuccess }) =>
          setValue(`${formFields.searchParams}.${formFields.EquipmentKindId}`, "все")
        );
      }
    }
  }, [facilityId, getEquipKinds, setValue]);
  useEffect(() => {
    setIsErrorSubmit(isErrorgetPeriodsSelect || isErrorgetEquipKinds || error || errorgrid || errorgridColumns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorgetPeriodsSelect, isErrorgetEquipKinds, error, errorgrid, errorgridColumns]);

  useEffect(() => {
    if (equipKindId) {
      if (equipKindId == "все") {
        setValue(`${formFields.searchParams}.${formFields.PlanPeriodId}`, "все");
      } else {
        getPeriodsSelect({ protectionObjectId: facilityId, equipmentKindId: equipKindId }).then(
          ({ data, isSuccess, error }) => {
            console.log("isErrorSubmit", isSuccess, data, error);
            setValue(`${formFields.searchParams}.${formFields.PlanPeriodId}`, "все");
          }
        );
      }
    }
  }, [equipKindId, getPeriodsSelect, setValue]);
  useEffect(() => {
    if (!ranOnce) {
      setRanOnce(true);
      setValue(`${formFields.searchParams}.${formFields.protectionObjectId}`, "все");
      setValue(`${formFields.searchParams}.${formFields.EquipmentKindId}`, "все");
      setValue(`${formFields.searchParams}.${formFields.PlanPeriodId}`, "все");
    }
  }, []);
  const handleOpenSelectColumnsModal = () => {
    setOpenSelectModal(true);
  };

  useEffect(() => {
    let filtersRes = {};
    let res = "";
    if (params) {
      Object.keys(params)?.forEach((key) => {
        if (params[key] != "все" && params[key] != "")
          if (key == "equipmentKindId") {
            filtersRes[key] = JSON.parse(params[key]).id;
          } else if (key == "explotationExceeded" || key == "workingCorrectly") {
            filtersRes[key] = params[key] == "true" ? true : false;
          } else {
            filtersRes[key] = params[key];
          }
      });
    }
    res =
      "&" +
      Object.keys(filtersRes)
        .map((key) => `${key}=${filtersRes[key]}`)
        .join("&");
    getGroupTasksListTable({
      filters: res,
      PageNumber: PageNumber,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PageNumber]);
  useEffect(() => {
    let filtersRes = {};
    let res = "";
    if (params) {
      Object.keys(params)?.forEach((key) => {
        if (params[key] != "все" && params[key] != "")
          if (key == "equipmentKindId") {
            filtersRes[key] = JSON.parse(params[key]).id;
          } else if (key == "explotationExceeded" || key == "workingCorrectly") {
            filtersRes[key] = params[key] == "true" ? true : false;
          } else {
            filtersRes[key] = params[key];
          }
      });
    }
    res =
      "&" +
      Object.keys(filtersRes)
        .map((key) => `${key}=${filtersRes[key]}`)
        .join("&");
    changePageNumber(0);
    getGroupTasksListTable({
      filters: res,
      PageNumber: 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const ITEMS_NAV = [
    { text: "add_task", permissions: "tasks" },
    { text: "list_tasks", permissions: "tasks" },
    { text: "group_tasks", permissions: "tasks" },
  ];

  return (
    <Box className="template_page">
      <Typography className="title-page">{t("title_page:tasks")}</Typography>

      <Box className="template_page-header">
        <PageheaderNav itemsNav={ITEMS_NAV} />
        <Button
          className="btn btn-primary-fill magazine_events-select_container-btn"
          variant="outlined"
          onClick={handleOpenSelectColumnsModal}
          sx={{ height: "52px" }}
        >
          <Box component="img" src={settingsicon} sx={{ marginRight: "16px" }} />
          {t("button:customize_fields_to_display")}
        </Button>
      </Box>
      <TableBasic
        columns={columns}
        rows={rows}
        handleClickCell={() => {}}
        withSearchField={false}
        // isLoading={}
        pageNumber={PageNumber}
        changePageNumber={changePageNumber}
        count={recordsCount}
        minHeight={"60vh"}
        maxHeight={"70vh"}
      />
      {openDetailsPopUp && (
        <GroupTaskDetails
          open={openDetailsPopUp}
          setOpen={setOpenDetailsPopUp}
          taskId={selectedId}
          taskName={selectedName}
        />
      )}
      <ModalSelectColumnsServer
        open={openSelectModal}
        setOpen={setOpenSelectModal}
        data={columnsFromStorage}
        refetch={() => getUserGrid({ gridSettingName: "GroupJobList" })}
        nameStorage={"GroupJobList"}
        config={getConfigFormModalSelect(gridcolumns?.columnSettings || [])}
      />
    </Box>
  );
};

export default GroupTasks;
