import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import {
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import closeicon from "../../../../image/icons/closeInputFile.png";
import { useSetUserGridMutation } from "../../../../middleware/usersApi";
import FormItem from "../../FormItem";
import { useAction } from '../../../../hooks/actions';

export const ModalSelectColumnsServer = ({
  open,
  setOpen,
  data,
  nameStorage,
  config,
  refetch,
}) => {
  const { t } = useTranslation();

  const { control, handleSubmit, reset } = useForm();
  const [setGridMutation,{error,isError,isSuccess}] = useSetUserGridMutation();
  const { setIsErrorSubmit, setIsSuccessSubmit } = useAction();

  useEffect(() => {
    setIsErrorSubmit(error || isError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError,error]);

  useEffect(() => {
    setIsSuccessSubmit(isSuccess);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const onSubmit = async (data) => {
    const columnsData = Object.entries(data).map(([name, value]) => ({
      name,
      visible: value,
    }));
    setGridMutation({ name: nameStorage, columnSettings: columnsData }).then(
      (result) => {
        if ("error" in result) {
          return;
        }
        refetch();
        handleClose();
      }
    );
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getDividedConfig = (array) => {
    const middle = Math.round(array.length / 2);
    return array.reduce(
      (acc, item, i) => {
        i < middle ? acc[0].push(item) : acc[1].push(item);
        return acc;
      },
      [[], []]
    );
  };

  useEffect(() => reset(data), [data, reset]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        className="modal select_columns"
        maxWidth="md"
      >
        <DialogTitle className="modal-title-between border_bottom">
          <Box
            sx={{
              width: "100%",
              alignContent: "center",
              textAlign: "center",
              ml: "40px",
            }}
          >
            <Typography className="modal-title">
              {t("title:select_fields_header")}
            </Typography>
          </Box>

          <IconButton onClick={handleClose}>
            <Box component="img" src={closeicon} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ padding: "0 36px" }}>
          <form
            id="formModalSelect"
            className="modal select_columns-form"
            onSubmit={handleSubmit(onSubmit)}
          >
            {getDividedConfig(config).map((item, i) => (
              <Box key={i} sx={{ minWidth: "250px" }}>
                {item.map(({ name, label, input, ...rest }, i) => (
                  <FormItem
                    key={i}
                    control={control}
                    name={name}
                    label={label}
                    input={input}
                    {...rest}
                  />
                ))}
              </Box>
            ))}
          </form>
        </DialogContent>
        <DialogActions className="select_columns-btns modal-btn-field modal-btn-field-right">
          <Button className="btn btn-outlined-grey" onClick={handleClose}>
            {t("button:comeback")}
          </Button>
          <Button
            form="formModalSelect"
            className="btn btn-primary-fill"
            type="submit"
            autoFocus
          >
            {t("button:apply")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
