import { useEffect, useMemo, useState } from "react";
import { Box, CircularProgress, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../hooks/redux";
import { useLazyGetFacilitiesListOptionQuery } from "../../../../middleware/facilitiesApi";
import ObjectPanel from "./ObjectPanel";
import AddObjectModal from "./AddObjectModal";
import PlusIcon from "../../../components/icons/PlusIcon";
import useScreenSize from "../../../../hooks/useScreenSize";
import "./styles.css";

const ObjectsList = ({ gridTypeValue, headerRef, searchFacility }) => {
  const { t } = useTranslation();
  const { openSidebar } = useAppSelector((state) => state.settings);
  const [facilities, setFacilities] = useState([]);
  const [divideFacilities, setDivideFacilities] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [isVisibleAddObjectModal, setIsVisibleAddObjectModal] = useState(false);
  const [isAddedNewObject, setIsAddedNewObject] = useState(false);
  const [activeObjectId, setActiveObjectId] = useState(-1);
  const screenSize = useScreenSize();

  const [getFacilitiesListOption, { isLoading, refetch }] = useLazyGetFacilitiesListOptionQuery();

  const handleActiveObjectChange = (newActiveObjectId) => {
    setActiveObjectId(newActiveObjectId);
  };

  const handleCloseAddObjectModal = () => {
    setIsVisibleAddObjectModal(false);
  };

  const getCountOfColumnByScreenWidth = () => {
    const sidebarWidth = openSidebar ? 380 : 114;
    const paddings = 60;
    const panelWidth = 386;
    const scrollBarWidth = 15;
    const workWidth = screenSize.width - sidebarWidth - paddings - scrollBarWidth;
    const columnCount = Math.trunc(workWidth / panelWidth);
    return columnCount;
  };

  getCountOfColumnByScreenWidth();

  const splitIntoColumn = (arr) => {
    const columnsCount = getCountOfColumnByScreenWidth();

    return Object.values(
      arr.reduce((acc, item, i) => {
        const index = i % columnsCount;
        acc[index] = acc[index] || [];
        acc[index].push(item);
        return acc;
      }, {})
    );
  };

  useEffect(() => {
    getFacilitiesListOption({ facilityName: searchFacility, pageNumber: 0 })
      .then((res) => {
        setFacilities(res.data.data);
        setHasMore(res.data.hasMoreData);
      })
      .catch((error) => console.log(error));
  }, [searchFacility]);

  useEffect(() => {
    if (facilities.length === 0) {
      setDivideFacilities([[{ isAddedBlock: true }]]);
      return;
    }
    const dividedFacilities = splitIntoColumn(facilities);

    const arrayLength = dividedFacilities.length;
    dividedFacilities[arrayLength - 1].unshift({ isAddedBlock: true });

    setDivideFacilities(dividedFacilities);
  }, [facilities, screenSize.width, openSidebar]);

  const loadMoreFacilities = () => {
    getFacilitiesListOption({ facilityName: searchFacility, pageNumber: pageNumber + 1 })
      .then((res) => {
        setPageNumber((prev) => prev + 1);
        setFacilities((prev) => [...prev, ...res.data.data]);
        setHasMore(res.data.hasMoreData);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    if (!isAddedNewObject) return;

    for (let i = 0; i <= pageNumber; i++) {
      getFacilitiesListOption({ facilityName: searchFacility, pageNumber: i })
        .then((res) => {
          setPageNumber((prev) => prev + 1);
          setFacilities((prev) => [...prev, ...res.data.data]);
          setHasMore(res.data.hasMoreData);
        })
        .catch((error) => console.error(error));
    }
    setIsAddedNewObject(false);
  }, [isAddedNewObject]);

  const clearState = () => {
    setPageNumber(0);
    setFacilities([]);
  };

  const MemoizedColumns = useMemo(() => {
    const columns = [...divideFacilities];
    const lastColumn = columns.pop();

    return (
      <>
        <ul className="object-list-column">
          {lastColumn?.map((facility, index) => (
            <li key={index}>
              {facility.isAddedBlock ? (
                <Box
                  className="add-object"
                  sx={{ height: gridTypeValue === 0 ? "264px" : "144px" }}
                  onClick={() => setIsVisibleAddObjectModal(true)}
                >
                  <Typography sx={{ fontSize: "20px" }}>{t("button:add")}</Typography>
                  <PlusIcon />
                </Box>
              ) : (
                <ObjectPanel
                  key={facility.id}
                  gridTypeValue={gridTypeValue}
                  facility={facility}
                  activeObjectId={activeObjectId}
                  isOpen={activeObjectId === facility.id}
                  onOpenPanel={handleActiveObjectChange}
                />
              )}
            </li>
          ))}
        </ul>
        {columns.map((column, index) => (
          <ul key={index} className="object-list-column">
            {column.map((facility, itemIndex) => (
              <li key={itemIndex}>
                <ObjectPanel
                  key={facility.id}
                  gridTypeValue={gridTypeValue}
                  facility={facility}
                  activeObjectId={activeObjectId}
                  isOpen={activeObjectId === facility.id}
                  onOpenPanel={handleActiveObjectChange}
                />
              </li>
            ))}
          </ul>
        ))}
      </>
    );
  }, [divideFacilities, gridTypeValue, activeObjectId, setIsVisibleAddObjectModal, handleActiveObjectChange, t]);

  return (
    <>
      {!isLoading ? (
        <Box
          className="objects-list"
          sx={{
            gap: openSidebar ? "20px" : "30px",
            overflow: "auto",
            height: `calc(100vh - ${headerRef?.current?.offsetHeight}px - 200px)`,
          }}
        >
          {MemoizedColumns}
        </Box>
      ) : (
        <Box className="loader">
          <CircularProgress />
        </Box>
      )}
      {hasMore && (
        <Button
          className="btn btn-primary-fill"
          sx={{ margin: "24px 0px", width: "50%", placeSelf: "center" }}
          onClick={loadMoreFacilities}
        >
          Загрузить еще
        </Button>
      )}
      {isVisibleAddObjectModal && (
        <AddObjectModal
          isVisible={isVisibleAddObjectModal}
          handleClose={handleCloseAddObjectModal}
          isNeedRefetchData={() => setIsAddedNewObject(true)}
          clearState={clearState}
        />
      )}
    </>
  );
};

export default ObjectsList;
