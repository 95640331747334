import React from "react";
import { Line, Group } from "react-konva";


export const RoomCanvas = React.memo(({
    id,
    name,
    coordinates,
    index,
    timer,
    getequipmentsInRegion,
    loadScheme,
    selectedEvent = {},
    scale,
    setScale,
    roomsColors,
    lineColor,
    mouseMove,
    mouseOut
}) => {

    const handleClick = (coordinates, id,) => {
        if (!timer)
            timer = setTimeout(() => {
                clearTimeout(timer);
                getequipmentsInRegion(coordinates, id)
            }, 500);
    }

    const handleDbl = (e) => {
        if (timer !== null) {
            clearTimeout(timer);
            timer = null; // сбрасываем таймер
        }
        clearTimeout(timer);
        loadScheme()
        setScale(1);

    }

    const hexToRgbA = (hex, alpha) => {
        var c;
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split('');
            if (c.length == 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = '0x' + c.join('');
            return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + alpha + ')';
        }
        // throw new Error('Bad Hex');
    }

    let StrokeColor = "green"
    let FillColor = "green"
    if (selectedEvent) {
        if (selectedEvent?.roomAddressId == id) {
            StrokeColor = lineColor.split("/")[0]
            if (selectedEvent?.color === "white")
                FillColor = "rgba(212,212,212,0.5)"
            else FillColor = lineColor.split("/")[1]
        }
        else {
            StrokeColor = "#9a9a9a"
            FillColor = "rgba(234, 234, 234,0.3)"
        }
    } else {

        const ColorZone = roomsColors?.find((item) => item.id == id)
        if (ColorZone) {
            StrokeColor = ColorZone.borderColor
            FillColor = hexToRgbA(ColorZone.color, '0.5')
        }
        else {
            StrokeColor = "#9a9a9a"
            FillColor = "rgba(234, 234, 234,0.3)"
        }
    }
   // console.log("StrokeColor", StrokeColor, FillColor, roomsColors)
    return (

        <Group
            key={id || index}
            name={`group${id}`}
            id={`group${id}`}
            onDblClick={(e) => handleDbl(e)}
            onMouseEnter={e => {
                const container = e.target.getStage().container();
                container.style.cursor = 'pointer';
            }}
            onMouseLeave={e => {
                const container = e.target.getStage().container();
                container.style.cursor = 'grab';
            }}
            onMouseMove={(e) => mouseMove(e, `${name}`, coordinates)}
            onMouseOut={mouseOut}

            onClick={(e) => handleClick(coordinates, id)}
        >
            <Line
                points={coordinates}
                stroke={StrokeColor}
                fill={FillColor}
                closed={true}
                strokeWidth={3 / scale}
                width={3}
            />
        </Group>




    );
});
