import React from "react";

export const TelephoneIcon = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.996 6.71429C24.996 3.55829 22.4381 1 19.2826 1H18.9576C18.615 1.00006 18.2804 1.10276 17.9968 1.29485C17.7132 1.48695 17.4936 1.75963 17.3664 2.07771L15.9575 5.6C15.7913 6.01531 15.7507 6.47024 15.8406 6.90843C15.9305 7.34662 16.1469 7.74881 16.4631 8.06514L18.2211 9.82343C18.5028 10.1051 18.621 10.5149 18.5056 10.8966C17.9578 12.6918 16.9772 14.3249 15.6502 15.6521C14.3232 16.9793 12.6904 17.96 10.8955 18.508C10.5138 18.624 10.1042 18.5051 9.82252 18.2234L8.06453 16.4651C7.74824 16.1489 7.34613 15.9324 6.90801 15.8425C6.4699 15.7526 6.01504 15.7933 5.5998 15.9594L2.07753 17.3686C1.7595 17.4958 1.48686 17.7154 1.2948 17.999C1.10274 18.2827 1.00006 18.6174 1 18.96V19.2857C1 22.4417 3.55786 25 6.71333 25H6.99899C16.7745 25 24.7297 17.2046 24.9897 7.49029C24.9897 7.48743 24.9908 7.48457 24.9925 7.48286C24.9936 7.48192 24.9944 7.48078 24.995 7.4795C24.9956 7.47822 24.9959 7.47684 24.996 7.47543V6.71429Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  );
};
