import React from "react";
import { useTranslation } from "react-i18next";
import { FormControlLabel, Checkbox, Typography, Box, Tooltip } from "@mui/material";
import CheckBoxChecked from "../icons/CheckBoxChecked";
import CheckBox from "../icons/CheckBox";

const TooltipProps = {
  tooltip: {
    sx: {
      color: "var(--black)",
      backgroundColor: "var(--white)",
      borderRadius: 0,
      maxWidth: 200,
    },
  },
};

const CheckboxSimple = ({
  label,
  onChange,
  value,
  disabled,
  tLabel = true,
  className = "",
  tooltip,
  onChangeTechnicalPlume,
}) => {
  const { t } = useTranslation();

  // const onChangeCheckbox = () => {
  //   onChangeTechnicalPlume && ();
  // };

  return (
    <FormControlLabel
      className="checkbox"
      label={
        tooltip ? (
          <Tooltip title={tooltip} componentsProps={TooltipProps}>
            <Typography className={`checkbox-label ${className}`}>{tLabel ? t(label) : label}</Typography>
          </Tooltip>
        ) : (
          <Typography className={`checkbox-label ${className}`}>{tLabel ? t(label) : label}</Typography>
        )
      }
      control={
        <Checkbox
          checked={!!value}
          onChange={(e) => {
            onChange(e);
            onChangeTechnicalPlume && onChangeTechnicalPlume(e)
          }}
          disabled={disabled}
          // onClick={onChangeTechnicalPlume && onChangeTechnicalPlume}
          sx={{ borderRadius: 0, mr: "8px", "& .MuiCheckbox-root": { padding: "0px !important" } }}
          icon={
            <Box>
              <CheckBox />
            </Box>
          }
          checkedIcon={
            <Box sx={{ color: "var(--headers)" }}>
              <CheckBoxChecked />
            </Box>
          }
        />
      }
    />
  );
};

export default CheckboxSimple;
