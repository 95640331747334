import React from "react";
import {
  Dialog,
  DialogContent,
  IconButton,
  Box,
  Link,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { EmailIcon } from "../icons/ContactsModal/EmailIcon";
import { TelephoneIcon } from "../icons/ContactsModal/TelephoneIcon";
import qrcode from '../../../image/QRCode.png'
import "./styles.css";

export const Contacts = ({ open, setOpen }) => {
  const phone = "+7 (924) 293-53-83";
  const email = "vdpocenter@yandex.ru";

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className="details"
      maxWidth="lg"
      fullWidth
    >
      <DialogContent className="details-body">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: '40px',
            minHeight: "300px",
          }}
        >
          <Box sx={{ position: "absolute", right: "40px", top: "40px" }}>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>

          <p className="contacts-title">Контакты</p>

          <div className="contacts-list-item">
            <Link href={`tel:${phone}`} variant="subtitle1">
              <TelephoneIcon />
              {phone}
            </Link>
          </div>

          <div className="contacts-list-item">
            <Link href={`mailto:${email}`} variant="subtitle1">
              <EmailIcon />
              {email}
            </Link>
          </div>

          <div>
          <img src={qrcode} alt="QR коде на телеграмм чат" />
          </div>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
