import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Typography, Button, CircularProgress } from "@mui/material";
import { useForm, useWatch } from "react-hook-form";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import { useAppSelector } from "../../../hooks/redux";
import {
  useLazyExportEquipmentListQuery,
  useLazyGetEquipmentListQuery,
  useLazyPrintEquipmentListQuery,
} from "../../../middleware/equipmentsApi";
import { useLazyGetFloorOptionsSelectByFacilityQuery } from "../../../middleware/facilitiesApi";
import { useGetUserGridSettingQuery, useLazyGetUserGridSettingQuery } from "../../../middleware/usersApi";
import EquipmentDialog from "./EquipDialog";
import { ModalSelectColumnsServer } from "../../components/Modals/ModalSelectColumnsServer";
import TableBasic from "../../components/TableBasic";
import EditButton from "../../components/EditButton";
import { ModalEquipSchema } from "../../components/Modals/ModalEquipSchema";
import { getColumns } from "./columns";
import PageheaderNav from "../../components/PageheaderNav";
import { getDataToChart } from "../../../helpers/helper";
import { base64toPDF, getQueryString, printBase64 } from "../../../helpers/tableHelper";
import { formFields, getConfigFormModalSelect } from "./form-config";
import settingsicon from "../../../image/icons/settingsButtonWhite.png";
import exporticon from "../../../image/icons/exportBtnWhite.png";
import PrintIcon from "../../components/icons/PrintIcon";
import { getTableHieght } from "../../../helpers/getTableHieght";
import useScreenSize from "../../../hooks/useScreenSize";
import { dateFormat, dateFormatWithTimeWithoutA } from "../../../constants";
import { PATHS } from "../../../constants";
import { dateFormatForSubmit } from "../../../constants";
import { NAV_ITEMS } from "./constants";
import ImportEquipmentDialog from "./ImportEquipmentDialog/ImportEquipmentDialog";

const EquipmentList = () => {
  const [getParams, setSearchParams] = useSearchParams();
  const {
    searchNameStorage,
    searchReplacingEquipmentNameStorage,
    searchPlumeStorage,
    searchNumberStorage,
    lastServiceDateRangeStorage,
    replacementDateRangeStorage,
    searchRoomAddressNameStorage,
    protectionObjectIdStorage,
    searchObjectIntegrationNumberStorage,
    floorIdStorage,
    defectActNumberStorage,
    equipmentKindIdStorage,
    lifeTimeDateStorage,
    pageNumberStorage,
  } = JSON.parse(sessionStorage.getItem("equipmentsListSearchParams")) || {};

  const { t } = useTranslation();
  const screenSize = useScreenSize();
  const navigate = useNavigate();
  const { equipments } = useAppSelector((state) => state.equipments);
  const { roleId } = useAppSelector((state) => state.users.info);
  const protectionObjectIdSearchParam = getParams.get("protectionObjectId") || "";
  const equipmentGroupIdSearchParam = getParams.get("equipmentGroupId") || "";

  useEffect(() => {
    if (!protectionObjectIdSearchParam && !equipmentGroupIdSearchParam) return;
    if (protectionObjectIdSearchParam || equipmentGroupIdSearchParam) {
      clearFiltersHandler();
    }
    setValue(`${formFields.searchParams}.${formFields.equipmentGroupId}`, equipmentGroupIdSearchParam);
    setValue(`${formFields.searchParams}.${formFields.protectionObjectId}`, protectionObjectIdSearchParam);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [protectionObjectIdSearchParam, equipmentGroupIdSearchParam]);

  useEffect(() => {
    if (protectionObjectIdSearchParam || equipmentGroupIdSearchParam) {
      return;
    }
    setValue(`${formFields.searchParams}.${formFields.protectionObjectId}`, protectionObjectIdStorage);
    setValue(`${formFields.searchParams}.${formFields.explotationExceeded}`, lifeTimeDateStorage);
    setValue(`${formFields.searchParams}.${formFields.defectActNumber}`, defectActNumberStorage);
    setValue(`${formFields.searchParams}.${formFields.floorId}`, floorIdStorage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { control, setValue, watch, reset, resetField } = useForm({});
  const [pageNumber, setPageNumber] = useState(pageNumberStorage || 0);
  const [openSchema, setOpenSchema] = useState(false);
  const [openEquipDialog, setopenEquipDialog] = useState(false);
  const [selectedEquipId, setSelectedEquipId] = useState(null);
  const [isOpenImportDialog, setIsOpenImportDialog] = useState(false);

  const [searchName, setSearchName] = useState(searchNameStorage || "");
  const [searchReplacingEquipmentName, setSearchReplacingEquipmentName] = useState(
    searchReplacingEquipmentNameStorage || ""
  );
  const [searchPlume, setSearchPlume] = useState(searchPlumeStorage || "");
  const [searchNumber, setSearchNumber] = useState(searchNumberStorage || "");

  const [searchSystemPpz, setsearchSystemPpz] = useState("");
  const [searchRoomAddressName, setSearchRoomAddressName] = useState(searchRoomAddressNameStorage || "");
  const [searchObjectIntegrationNumber, setSearchObjectIntegrationNumber] = useState(
    searchObjectIntegrationNumberStorage || ""
  );
  const [lastServiceDateRange, setLastServiceDateRange] = useState(
    lastServiceDateRangeStorage?.filter(Boolean).length ? lastServiceDateRangeStorage : [null, null]
  );
  const [replacementDateRange, setReplacementDateRange] = useState(
    replacementDateRangeStorage?.filter(Boolean).length ? replacementDateRangeStorage : [null, null]
  );
  const [isFirstStartup, setIsFirstStartup] = useState(true);

  const [openSelectModal, setOpenSelectModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [printloading, setPrintLoading] = useState(false);

  const [getUserGrid] = useLazyGetUserGridSettingQuery();
  const [getEquipmentTable, { isLoading, isFetching }] = useLazyGetEquipmentListQuery();
  const [getFloorOptions] = useLazyGetFloorOptionsSelectByFacilityQuery();
  const [printEquipmentList] = useLazyPrintEquipmentListQuery();
  const [exportEquipmentList] = useLazyExportEquipmentListQuery();

  const { data: gridcolumns } = useGetUserGridSettingQuery({
    gridSettingName: "EquipmentList",
  });

  const params = useWatch({
    control,
    name: formFields.searchParams,
  });

  const protectionObjectId = watch(`${formFields.searchParams}.${formFields.protectionObjectId}`);
  const equipmentKindId = watch(`${formFields.searchParams}.${formFields.equipmentKindId}`);
  function writeSearchDataToSessionStorage(newSearchParam, searchValue) {
    const getStorage = JSON.parse(sessionStorage.getItem("equipmentsListSearchParams")) || {};
    const searchParamsForLocalStorage = {
      ...getStorage,
      [newSearchParam]: searchValue,
    };
    sessionStorage.setItem("equipmentsListSearchParams", JSON.stringify(searchParamsForLocalStorage));
  }

  function savePageNumberToSessionStorage(currentPageNumber) {
    const getStorage = JSON.parse(sessionStorage.getItem("equipmentsListSearchParams")) || {};
    const searchParamsForLocalStorage = {
      ...getStorage,
      pageNumberStorage: currentPageNumber,
    };
    sessionStorage.setItem("equipmentsListSearchParams", JSON.stringify(searchParamsForLocalStorage));
  }

  function clearFiltersHandler() {
    setSearchName("");
    setSearchReplacingEquipmentName("");
    setSearchPlume("");
    setSearchNumber("");
    setSearchRoomAddressName("");
    setLastServiceDateRange([null, null]);
    setReplacementDateRange([null, null]);
    setSearchParams({});
    setPageNumber(0);
    reset();
    sessionStorage.removeItem("equipmentsListSearchParams");
  }

  function exportEquipmentListHandler() {
    const filtersRes = {};
    if (!params) return;
    Object.keys(params)?.forEach((key) => {
      if (params[key] !== "все" && params[key] !== "" && params[key] != null)
        if (key === "equipmentKindId") {
          if (JSON.parse(params[key])?.isType) {
            filtersRes["kindId"] = Number(JSON.parse(params[key]).id.replace("parent", ""));
          } else {
            filtersRes["typeId"] = JSON.parse(params[key]).id;
          }
        } else if (key === "lifeTimeDate" || key === "workingCorrectly") {
          filtersRes[key] = params[key] == "true" ? true : false;
        } else if (key === "lastServiceDate") {
          if (lastServiceDateRange[0] != null && lastServiceDateRange[1] != null) {
            filtersRes["lastServiceDateMin"] = dayjs(lastServiceDateRange[0]).format(dateFormatForSubmit);
            filtersRes["lastServiceDateMax"] = dayjs(lastServiceDateRange[1]).format(dateFormatForSubmit);
          }
        } else if (key === "replacementDate") {
          if (lastServiceDateRange[0] != null && lastServiceDateRange[1] != null) {
            filtersRes["replacementDateMin"] = dayjs(lastServiceDateRange[0]).format(dateFormatForSubmit);
            filtersRes["replacementDateMax"] = dayjs(lastServiceDateRange[1]).format(dateFormatForSubmit);
          }
        } else {
          filtersRes[key] = params[key];
        }
    });

    exportEquipmentList(getQueryString(filtersRes)).then((data) => {
      base64toPDF(data.data.name, data.data.content, setLoading);
    });
  }

  useEffect(() => {
    const filtersRes = {};
    if (!params) return;
    Object.keys(params)?.forEach((key) => {
      if (params[key] !== "все" && params[key] !== "" && params[key] != null)
        if (key === "equipmentKindId") {
          if (JSON.parse(params[key])?.isType) {
            filtersRes["kindId"] = Number(JSON.parse(params[key]).id.replace("parent", ""));
          } else {
            filtersRes["typeId"] = JSON.parse(params[key]).id;
          }
        } else if (key === "lifeTimeDate" || key === "workingCorrectly") {
          filtersRes[key] = params[key] == "true" ? true : false;
        } else if (key === "lastServiceDate") {
          if (lastServiceDateRange[0] != null && lastServiceDateRange[1] != null) {
            filtersRes["lastServiceDateMin"] = dayjs(lastServiceDateRange[0]).format(dateFormatForSubmit);
            filtersRes["lastServiceDateMax"] = dayjs(lastServiceDateRange[1]).format(dateFormatForSubmit);
          }
        } else if (key === "replacementDate") {
          if (lastServiceDateRange[0] != null && lastServiceDateRange[1] != null) {
            filtersRes["replacementDateMin"] = dayjs(lastServiceDateRange[0]).format(dateFormatForSubmit);
            filtersRes["replacementDateMax"] = dayjs(lastServiceDateRange[1]).format(dateFormatForSubmit);
          }
        } else {
          filtersRes[key] = params[key];
        }
    });

    if (!isFirstStartup) {
      savePageNumberToSessionStorage(0);
      setPageNumber(0);
    }

    getEquipmentTable({
      PageNumber: pageNumber,
      filters: getQueryString(filtersRes),
    });
    setIsFirstStartup(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    const filtersRes = {};
    if (!params) return;
    Object.keys(params)?.forEach((key) => {
      if (params[key] !== "все" && params[key] !== "" && params[key] != null)
        if (key === "equipmentKindId") {
          if (JSON.parse(params[key])?.isType) {
            filtersRes["kindId"] = Number(JSON.parse(params[key]).id.replace("parent", ""));
          } else {
            filtersRes["typeId"] = JSON.parse(params[key]).id;
          }
        } else if (key === "explotationExceeded" || key === "workingCorrectly") {
          filtersRes[key] = params[key] === "true" ? true : false;
        } else if (key === "lastServiceDate") {
          if (lastServiceDateRange[0] != null && lastServiceDateRange[1] != null) {
            filtersRes["lastServiceDateMin"] = dayjs(lastServiceDateRange[0]).format(dateFormatForSubmit);
            filtersRes["lastServiceDateMax"] = dayjs(lastServiceDateRange[1]).format(dateFormatForSubmit);
          }
        } else if (key === "replacementDate") {
          if (lastServiceDateRange[0] != null && lastServiceDateRange[1] != null) {
            filtersRes["replacementDateMin"] = dayjs(lastServiceDateRange[0]).format(dateFormatForSubmit);
            filtersRes["replacementDateMax"] = dayjs(lastServiceDateRange[1]).format(dateFormatForSubmit);
          }
        } else {
          filtersRes[key] = params[key];
        }
    });

    getEquipmentTable({
      PageNumber: pageNumber,
      filters: getQueryString(filtersRes),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  useEffect(() => {
    writeSearchDataToSessionStorage("equipmentKindIdStorage", equipmentKindId);
  }, [equipmentKindId, setValue]);

  useEffect(() => {
    setValue(`${formFields.searchParams}.${formFields.searchNumber}`, searchNumber);
  }, [searchNumber, setValue]);

  useEffect(() => {
    setValue(`${formFields.searchParams}.${formFields.searchRoomAddressName}`, searchRoomAddressName);
  }, [searchRoomAddressName, setValue]);

  useEffect(() => {
    setValue(`${formFields.searchParams}.${formFields.searchObjectIntegrationNumber}`, searchObjectIntegrationNumber);
  }, [searchObjectIntegrationNumber, setValue]);

  useEffect(() => {
    if (!protectionObjectId) return;
    getFloorOptions(protectionObjectId);
    writeSearchDataToSessionStorage("protectionObjectIdStorage", protectionObjectId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [protectionObjectId]);

  useEffect(() => {
    writeSearchDataToSessionStorage("replacementDateRangeStorage", replacementDateRange);
    setValue(`${formFields.searchParams}.replacementDate`, replacementDateRange);
  }, [replacementDateRange, setValue]);

  useEffect(() => {
    writeSearchDataToSessionStorage("lastServiceDateRangeStorage", lastServiceDateRange);
    setValue(`${formFields.searchParams}.lastServiceDate`, lastServiceDateRange);
  }, [lastServiceDateRange, setValue]);

  useEffect(() => {
    setValue(`${formFields.searchParams}.${formFields.searchName}`, `${searchName}`);
  }, [searchName, setValue]);

  useEffect(() => {
    setValue(
      `${formFields.searchParams}.${formFields.searchReplacingEquipmentName}`,
      `${searchReplacingEquipmentName}`
    );
  }, [searchReplacingEquipmentName, setValue]);

  useEffect(() => {
    setValue(`${formFields.searchParams}.${formFields.searchPlume}`, `${searchPlume}`);
  }, [searchPlume, setValue]);

  const columnsFromStorage = (() => {
    const storageData = gridcolumns?.columnSettings || [];
    // Убрано поле интеграционного номера на клиенте. Потом переделать на бэке
    let filteredStorageData = [];
    if (storageData.length) {
      const filteredStorageData = storageData.filter((item) => item.name !== "integrationNumber");
      return filteredStorageData?.reduce((acc, { name, visible }) => ({ ...acc, [name]: visible }), {});
    }
    return filteredStorageData?.reduce((acc, { name, visible }) => ({ ...acc, [name]: visible }), {});
  })();

  const columns = useMemo(
    () =>
      getColumns({
        control,
        columnsFromStorage,
        searchNumber,
        setSearchNumber,
        searchRoomAddressName,
        setSearchRoomAddressName,
        searchObjectIntegrationNumber,
        lastServicedaterange: lastServiceDateRange,
        setlastServicedaterange: setLastServiceDateRange,
        replacementDatedaterange: replacementDateRange,
        setreplacementDatedaterange: setReplacementDateRange,
        setSearchObjectIntegrationNumber,
        searchName,
        setSearchName,
        setsearchSystemPpz,
        searchSystemPpz,
        searchReplacingEquipmentName,
        setSearchReplacingEquipmentName,
        searchPlume,
        setSearchPlume,
        writeSearchDataToLocalStorage: writeSearchDataToSessionStorage,
        equipmentKindIdStorage,
        setValue,
        resetField,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnsFromStorage, lastServiceDateRange, replacementDateRange, searchSystemPpz]
  );

  const rows = useMemo(() => {
    const { data } = equipments;
    return data?.length
      ? data.map(
          ({
            id,
            name,
            number,
            jobChart,
            lifeTimeDate,
            lefeTimeOutdated,
            objectIntegrationNumber,
            roomAddressName,
            floorNumber,
            floorComment,
            workingProperly,
            lastServiceDate,
            integrationNumber,
            plume,
            protectionObjectId,
            protectionObjectName,
            enableForEdit,
            equipmentKindName,
            replacementDate,
            replacingEquipmentId,
            replacingEquipmentName,
            isReplaced,
          }) => {
            return {
              id,
              integrationNumber,
              number,
              objectIntegrationNumber,
              roomAddressName,
              name: {
                id,
                name,
                action: () => handleOpenEquipment(id),
              },
              replacingEquipment: {
                replacingEquipmentId,
                replacingEquipmentName,
                action: () => handleOpenEquipment(replacingEquipmentId),
              },
              plume,
              floorNumber: {
                id,
                floorNumber,
                floorComment,
                action: () => handleOpenSchema(id),
              },
              protectionObjectName,
              lifeTimeDate: (
                <Typography sx={{ color: lefeTimeOutdated ? "var(--error)" : "black" }}>
                  {lifeTimeDate ? dayjs(lifeTimeDate).format(dateFormat) : ""}
                </Typography>
              ),
              lastServiceDate: lastServiceDate ? (
                <Typography sx={{ color: "var(--succes)" }}>{dayjs(lastServiceDate).format(dateFormat)}</Typography>
              ) : (
                <></>
              ),
              replacementDate: replacementDate ? (
                <Typography sx={{ color: "var(--error)" }}>
                  {dayjs(replacementDate).format(dateFormatWithTimeWithoutA)}
                </Typography>
              ) : (
                <></>
              ),
              workingProperly: workingProperly ? (
                <Typography sx={{ color: "var(--succes)" }}>Да</Typography>
              ) : (
                <Typography sx={{ color: "var(--error)" }}>Нет</Typography>
              ),
              isReplaced: isReplaced ? (
                <Typography sx={{ color: "var(--error)" }}>Да</Typography>
              ) : (
                <Typography sx={{ color: "var(--succes)" }}>Нет</Typography>
              ),
              equipmentKindName,
              edit: enableForEdit ? (
                <EditButton handleClick={() => navigate(PATHS.GET_EQUIPMENT_DETAILS(id))} />
              ) : (
                <></>
              ),
              tasks: getDataToChart({
                jobChart,
                action: `?facilityName=${protectionObjectId}&equipmentId=${id}&equipmentName=${name}`,
              }),
            };
          }
        )
      : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipments]);

  const handleOpenSchema = (id) => {
    setOpenSchema(true);
    setSelectedEquipId(id);
  };
  const handleOpenEquipment = (id) => {
    setopenEquipDialog(true);
    setSelectedEquipId(id);
  };

  const recordsCount = useMemo(
    () => equipments && equipments?.recordsCount,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [equipments]
  );
  const handleOpenSelectColumnsModal = () => {
    setOpenSelectModal(true);
  };

  const printEquipmentListWrapper = (isPrint) => {
    if (isPrint) setPrintLoading(true);
    else setLoading(true);
    if (params.equipmentKindId === "все" || !params.equipmentKindId) delete params.equipmentKindId;
    else if (JSON.parse(params.equipmentKindId)?.isType) {
      params["kindId"] = Number(JSON.parse(params.equipmentKindId).id.replace("parent", ""));
    } else {
      params["typeId"] = JSON.parse(params.equipmentKindId).id;
    }
    if (params.floorId === "все") delete params.floorId;
    if (params.protectionObjectId === "все" || !params.protectionObjectId) delete params.protectionObjectId;
    if (lastServiceDateRange[0] != null && lastServiceDateRange[1] != null) {
      params.lastServiceDateMin = dayjs(lastServiceDateRange[0]).format(dateFormatForSubmit);
      params.lastServiceDateMax = dayjs(lastServiceDateRange[1]).format(dateFormatForSubmit);
    }
    if (replacementDateRange[0] != null && replacementDateRange[1] != null) {
      params.replacementDateMin = dayjs(replacementDateRange[0]).format(dateFormatForSubmit);
      params.replacementDateMax = dayjs(replacementDateRange[1]).format(dateFormatForSubmit);
    }

    params.explotationExceeded === "true" ? (params.explotationExceeded = true) : (params.explotationExceeded = false);
    if (!params.floorId) {
      params.floorId = "";
    }

    delete params.lastServiceDate;
    delete params.replacementDate;

    printEquipmentList({
      ...params,
      pageNumber,
    }).then((data) => {
      // const res = data.data;
      if (isPrint) printBase64(data.data.content, setPrintLoading);
      else base64toPDF(data.data.name, data.data.content, setLoading);
    });
  };

  return (
    <>
      <Box className="template_page">
        <Typography className="title-page">{t("title_page:digitization")}</Typography>

        <Box className="template_page-header">
          <PageheaderNav itemsNav={NAV_ITEMS} />
          <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
            {printloading ? (
              <Box className="full_screen_icon-wrapper">
                <CircularProgress
                  className="full_screen_icon"
                  sx={{
                    width: "30px",
                    height: "30px",
                    marginRight: "20px",
                    marginTop: "5px",
                  }}
                />
              </Box>
            ) : (
              <Box className="full_screen_icon-wrapper">
                <div
                  onClick={() => printEquipmentListWrapper(true)}
                  style={{
                    marginTop: "5px",
                    border: "0px !important",
                  }}
                  className="full_screen_icon"
                >
                  <PrintIcon />
                </div>
              </Box>
            )}

            <Button
              className="btn btn-primary-fill magazine_events-select_container-btn"
              variant="outlined"
              onClick={() => printEquipmentListWrapper(false)}
              disabled={loading}
              sx={{ height: "49px" }}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <>
                  <Box component="img" src={exporticon} sx={{ mr: "16px" }} />
                  {t("button:create_act_pdf")}
                </>
              )}
            </Button>

            {roleId === 1 && (
              <>
                <Button className="btn btn-primary-fill" variant="outlined" onClick={() => setIsOpenImportDialog(true)}>
                  Импорт
                </Button>

                <Button className="btn btn-primary-fill" variant="outlined" onClick={exportEquipmentListHandler}>
                  Экспортировать
                </Button>
              </>
            )}

            <Button className="btn btn-primary-fill" onClick={clearFiltersHandler}>
              Очистить все фильтры
            </Button>
            <Button
              className="btn btn-primary-fill magazine_events-select_container-btn"
              variant="outlined"
              onClick={handleOpenSelectColumnsModal}
              sx={{ height: "52px" }}
            >
              <Box component="img" src={settingsicon} sx={{ mr: "16px" }} />
              {t("button:customize_fields_to_display")}
            </Button>
          </Box>
        </Box>

        <TableBasic
          columns={columns}
          rows={rows}
          handleClickCell={() => {}}
          withSearchField={false}
          isLoading={isLoading || isFetching}
          pageNumber={pageNumber}
          changePageNumber={setPageNumber}
          savePageNumberToSessionStorage={savePageNumberToSessionStorage}
          count={recordsCount}
          minHeight={getTableHieght(screenSize).min}
          maxHeight={getTableHieght(screenSize).max}
        />
        {openSelectModal && (
          <ModalSelectColumnsServer
            open={openSelectModal}
            setOpen={setOpenSelectModal}
            data={columnsFromStorage}
            refetch={() => getUserGrid({ gridSettingName: "EquipmentList" })}
            nameStorage={"EquipmentList"}
            config={getConfigFormModalSelect(
              gridcolumns?.columnSettings.filter((item) => item.name !== "integrationNumber") || []
            )}
          />
        )}
      </Box>

      {openEquipDialog && (
        <EquipmentDialog
          open={openEquipDialog}
          setOpen={setopenEquipDialog}
          equipId={selectedEquipId}
          refetch={() => getEquipmentTable({ pageNumber })}
        />
      )}

      {isOpenImportDialog && <ImportEquipmentDialog open={isOpenImportDialog} setOpen={setIsOpenImportDialog} />}

      {openSchema && (
        <ModalEquipSchema
          open={openSchema}
          setOpen={setOpenSchema}
          control={control}
          setValue={setValue}
          equipId={selectedEquipId}
          refetch={() => getEquipmentTable({ pageNumber })}
        />
      )}
    </>
  );
};

export default EquipmentList;
