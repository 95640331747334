import React from "react";

const PlumeIcon = () => {
  return (
    <svg width="26" height="30" fill="none" viewBox="0 0 26 30" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.3635 4.23096H8.63629C4.41888 4.23096 1 7.60605 1 11.7695V15.5387"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.1819 29H7.54557C6.3406 29 5.36377 28.0357 5.36377 26.8461V14.4614C5.36377 11.1902 8.05004 8.53833 11.3637 8.53833C14.6774 8.53833 17.3637 11.1902 17.3637 14.4614V26.8461C17.3637 28.0357 16.3868 29 15.1819 29Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3638 8.53867V4.23096"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.3638 5.00534V3.45624C17.3638 2.96206 17.7045 2.53131 18.1901 2.41147L23.7931 1.02863C23.8701 1.00961 23.9493 1 24.0287 1C24.5652 1 25.0001 1.42931 25.0001 1.95888V6.5027C25.0001 7.03227 24.5652 7.46157 24.0287 7.46157C23.9493 7.46157 23.8701 7.45197 23.7931 7.43295L18.1901 6.05011C17.7045 5.93027 17.3638 5.49952 17.3638 5.00534Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PlumeIcon;
