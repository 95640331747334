import React from "react";

export const DocumentsIcon = () => {
  return (
    <svg width="26" height="30" viewBox="0 0 26 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.3372 18.5736L16.1983 28.0667L12.1987 29.0002L12.9986 24.3327L21.1375 14.8347C21.5605 14.341 22.1344 14.0637 22.7326 14.0637C23.3308 14.0637 23.9045 14.341 24.3276 14.8347L24.3372 14.8459C24.5473 15.0904 24.714 15.381 24.8277 15.7007C24.9414 16.0206 25 16.3635 25 16.7098C25 17.056 24.9414 17.3989 24.8277 17.7188C24.714 18.0386 24.5473 18.3291 24.3372 18.5736Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.99918 25.2711H2.59984C2.17553 25.2711 1.7686 25.0743 1.46858 24.7242C1.16855 24.374 1 23.8992 1 23.4041V2.867C1 2.37184 1.16855 1.89697 1.46858 1.54683C1.7686 1.1967 2.17553 1 2.59984 1H13.9363C14.1464 0.999889 14.3545 1.04809 14.5486 1.14184C14.7428 1.23559 14.9192 1.37307 15.0679 1.54641L18.1321 5.11984C18.2806 5.2933 18.3985 5.4992 18.4788 5.72578C18.5591 5.95238 18.6005 6.19522 18.6004 6.44043V10.335"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.1323 7.53442L13.5321 10.3349L11.1323 13.1355"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.59946 7.53442L4.19971 10.3349L6.59946 13.1355"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
