import { fileTypeCheck } from "../../../../validation";
import { SelectSimpleObject } from "../Components";
import { Box } from "@mui/material";
import InputFileSingle from "../../../components/InputFile/InputFileSingle";

export const formFields = {
  protectionObjectId: "protectionObjectId",
  importData: "importData",
};

export const addEquipmentExcelFile = [
  {
    name: formFields.importData,
    type: "file",
    previewMode: false,
    rules: {
      validate: {
        type: (files) => {
          const availableFileTypes = [
            "application/vnd.ms-excel",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          ];
          return fileTypeCheck(files, formFields.buildingFacadePhoto, availableFileTypes);
        },
      },
    },
    input: (props) => (
      <Box sx={{ maxWidth: "100%", mb: "10px" }}>
        <InputFileSingle {...props} accept={".xlsx, .xls"} />
      </Box>
    ),
  },
];

export const addObjectBaseFormConfig = [
  {
    name: formFields.protectionObjectId,
    label: `label:protectionObjectId`,
    type: "select-text",
    rules: {
      required: true,
    },
    isAllExist: false,
    isCanDisabled: false,
    input: (props) => <SelectSimpleObject {...props} />,
  },
];
