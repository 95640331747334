export const checkboxValues = {
  "Объекты": 1,
  "Организации": 2,
  "Оборудование": 3,
  "Задачи": 4,
  "Журналы": 5,
  "Аналитика": 6,
  "Сотрудники": 7,
  "Поставить на ТО": 8,
  "Создание/Редактирование организаций": 9,
  "Чтение всех организаций": 10,
  "Чтение добавленных организаций": 11,
  "Календарный план": 12,
  "Документы": 13,
  "Пульты": 14,
  "Создание/Редактирования оборудования":15,
  "Планирование":16
};
