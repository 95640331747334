import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "./constants";

const formatAddresses = (baseQueryReturnValue) => {
  const organizationAddresses =
    baseQueryReturnValue?.organizationAddresses?.map((item) => {
      const { city, street, building } = item;
      const address = { city, street, building };
      delete item.city;
      delete item.street;
      delete item.building;
      return {
        ...item,
        address,
      };
    });

  const organizationResponsibleAddresses =
    baseQueryReturnValue?.organizationResponsibleAddresses?.map((item) => {
      const { city, street, building } = item;
      const address = { city, street, building };
      delete item.city;
      delete item.street;
      delete item.building;
      return {
        ...item,
        address,
      };
    });

  return {
    ...baseQueryReturnValue,
    organizationAddresses,
    organizationResponsibleAddresses,
  };
};

export const organizationsApi = createApi({
  reducerPath: "legalEntities/api",
  tagTypes: ["Organizations"],
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const { token } = getState().users;
      headers.set("Content-Type", "application/json");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (build) => ({
    getOrganization: build.query({
      query: ({ id, typeId }) => ({
        url: `/api/organizations/get/${id}`,
      }),
      providesTags: (result, error, arg) => [
        { type: "Organizations", id: arg.id },
      ],
      transformResponse: formatAddresses,
    }),
    getOrganizationsSelect: build.query({
      query: (searchValue="") => ({
        url: `/api/organizations/get-organization-select-elements?searchValue=${searchValue}&types=1&types=2&types=3&types=4`,
      }),
      providesTags: ["Organizations"],
      transformResponse: (baseQueryReturnValue) =>
        baseQueryReturnValue.map(({ organizationId, organizationName }) => ({
          id: organizationId,
          name: organizationName,
        })),
    }),
    getOrganizationsSelectWithTypes: build.query({
      query: () => ({
        url: `/api/organizations/get-organization-select-elements?types=2&types=3`,
      }),
      providesTags: ["Organizations"],
      transformResponse: (baseQueryReturnValue) =>
        baseQueryReturnValue.map(({ organizationId, organizationName }) => ({
          id: organizationId,
          name: organizationName,
        })),
    }),
    getOrganizationTypes: build.query({
      query: () => ({
        url: "/api/organizations/get-organization-types",
      }),
      providesTags: ["Organizations"],
    }),

    getAllOrganizationsSelect: build.query({
      query: (name = "") => ({
        url: `/api/organizations/get-orgs-select?name=${name}`,
      }),
      providesTags: ["Organizations"],
    }),
    getAllOrganizations: build.query({
      query: ({ searchValue, pageNumber, orgTypeFilter,InnNumber,name,address }) => ({
        url: `/api/organizations/get-organizations-table?searchValue=${searchValue}&pageNumber=${pageNumber}&InnNumber=${InnNumber}&Type=${orgTypeFilter}&Name=${name}${address}`,
      }),
      providesTags: ["Organizations"],
    }),
    getObjectsOrganization: build.query({
      query: (params) => ({
        url: `/api/organizations/get-organization-linked-facilities?organizationId=${params}`,
      }),
      providesTags: (result, error, arg) => [
        { type: "Organizations", id: arg },
      ],
    }),
    addOrganization: build.mutation({
      query: (data) => ({
        url: "/api/organizations/add",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Organizations"],
    }),
    editOrganization: build.mutation({
      query: (data) => ({
        url: "/api/organizations/edit",
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Organizations", id: arg.id },
      ],
    }),
    removeOrganization: build.mutation({
      query: (id) => ({
        url: `/api/organizations/send-deletion-request/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Organizations"],
    }),
    deleteOrganization: build.mutation({
      query: (id) => ({
        url: `/api/organizations/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Organizations"],
    }),
    getResponsibleOrganizations: build.query({
      query: (params) => ({
        url: `api/organizations/get-responsible-orgs?searchValue=${params}`,
      }),
      invalidatesTags: ["Organizations"],
    }),
    getOrganizationByInn: build.query({
      query: (inn) => ({
        url: `api/organizations/get-organization-by-inn?inn=${inn}`,
      }),
      providesTags: ["Organizations"],
      transformResponse: formatAddresses,
    }),
    setOrganizationUserLink: build.mutation({
      query: ({ organizationId, organizationTypeId }) => ({
        url: `api/organizations/set-organization-user-link?organizationId=${organizationId}&organizationTypeId=${organizationTypeId}`,
        method: "POST",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Organizations", id: arg.organizationId },
      ],
    }),
    getOrganizationsOgrn: build.query({
      query: () => ({
        url: `api/organizations/get-organizations-ogrn`,
      }),
    }),
  }),
});

export const {
  useGetOrganizationQuery,
  useGetAllOrganizationsSelectQuery,
  useGetOrganizationTypesQuery,
  useGetOrganizationsSelectQuery,
  useGetOrganizationsSelectWithTypesQuery,
  useLazyGetAllOrganizationsQuery,
  useGetObjectsOrganizationQuery,
  useGetAllOrganizationsQuery,
  useAddOrganizationMutation,
  useEditOrganizationMutation,
  useRemoveOrganizationMutation,
  useDeleteOrganizationMutation,
  useGetResponsibleOrganizationsQuery,
  useGetOrganizationByInnQuery,
  useLazyGetOrganizationByInnQuery,
  useSetOrganizationUserLinkMutation,
  useGetOrganizationsOgrnQuery,
} = organizationsApi;
