import React from "react";
import { AppBar, Box, Toolbar, Link, Typography } from "@mui/material";
import ProfileDropdown from "./ProfileDropdown";
import { NotificationPopUp } from "../../components/NotificationPopUp/NotificationPopUp";
import AdditionalInfo from "./AdditionalInfo";
import { useAppSelector } from "../../../hooks/redux";
import telegramChat from "../../../image/telegramChat.png";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../../constants";
import { useHasAccesTocabinetQuery } from "../../../middleware/usersApi";
import useScreenSize from "../../../hooks/useScreenSize";
import "./style.css";
import "./media.css";

const Header = () => {
  const { openSidebar } = useAppSelector((state) => state.settings);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isSmallDescktop = useScreenSize().width < 1560;
  const { data: isaccess } = useHasAccesTocabinetQuery();

  return (
    <AppBar className={`app_header ${!openSidebar ? "closed-side-bar" : ""}`} position="absolute">
      <Toolbar
        sx={{
          height: isSmallDescktop ? "fit-content" : "var(--heighHeader)",
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "var(--bgWhite)",
          padding: "0px 32px 0 22px !important",
        }}
      >
        <Box className="header-navbar-text">
          <AdditionalInfo />
        </Box>

        <Box className="header-navbar-dropdown" sx={{ alignItems: "center" }}>
          <Box
            sx={{
              marginRight: "24px",
              alignItems: "center",
              alignContent: "center",
              justifyContent: "center",
              height: "52px",
            }}
          >
            {isaccess && (
              <Typography
                onClick={() => navigate(PATHS.CABINET)}
                sx={{ color: "var(--text-color)", cursor: "pointer", fontSize: isSmallDescktop ? "18px" : "20px", fontWeight: "500" }}
              >
                {t("label:cabinet")}
              </Typography>
            )}
          </Box>
          <Box sx={{ marginRight: "24px", alignItems: "center", justifyContent: "center", height: "52px" }}>
            <Link href="https://t.me/VDPOpro" target="_blank" rel="noopener noreferrer">
              <Box
                component="img"
                src={telegramChat}
                sx={{
                  height: isSmallDescktop ? "50px" : "52px",
                }}
              />
            </Link>
          </Box>
          <NotificationPopUp />
          <ProfileDropdown />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
