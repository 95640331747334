import React, { useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useAppSelector } from "../../../../hooks/redux";
import { useAction } from "../../../../hooks/actions";
import TableBasic from "../../../components/TableBasic";
import { ModalConfirmDialog } from "../../../components/Modals/ModalConfirmDialog";
import { useLazyToSignTaskQuery } from "../../../../middleware/signingApi";
import { useLazyGetSignByIdQuery, useLazyGetTaskQuery } from "../../../../middleware/tasksApi";
import { formFields } from "./options";

export const SignaturesTable = ({ config, data, setValue, refetch, closeAndRefethTaskListData }) => {
  const { t } = useTranslation();
  const { pageNumber } = useAppSelector((state) => state.settings);
  const { info } = useAppSelector((state) => state.users);
  const { changePageNumber, setIsErrorSubmit, setIsSuccessSubmit } = useAction();
  const [toSignTask, { errorToSign, isSuccess }] = useLazyToSignTaskQuery();
  const [getTask, { errorGetTask, isSuccessGetTask }] = useLazyGetTaskQuery();
  const { setIsErrorRequest } = useAction();

  const [getSignById, { error: getSignError, isSuccess: getSignSucces }] = useLazyGetSignByIdQuery();
  const [isSigned, setIsSigned] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [signatureValue, setSignatureValue] = useState();
  const [isHaveSignatures, setIsHaveSignatures] = useState(false);

  useEffect(() => {
    const { currentUserSigned, signatures } = data || {};
    setSignatureValue(signatures);
    if (data && data.signatures.length > 0) {
      setIsHaveSignatures(true);
    }
    setIsSigned(currentUserSigned);
  }, [data]);

  const updateResponsibleAndExecutorsInfo = (newData) => {
    setValue(`${formFields.responsibleCustomerUserId}`, newData.responsibleCustomerId);
    setValue(`${formFields.executiveUserId}`, newData.userId);
    setValue(`${formFields.status}`, newData.status);
    if (newData.status === 2) {
      closeAndRefethTaskListData();
      return;
    }
  };

  useEffect(() => {
    setIsErrorRequest(errorToSign);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorToSign]);

  const rows = useMemo(() => {
    if (signatureValue) {
      return (
        signatureValue?.map(({ signatureDateTime, ...rest }) => ({
          signatureDateTime,
          signatureImage: { signatureDateTime, ...rest },
        })) || []
      );
    } else return [];

    // eslint-disable-next-line
  }, [signatureValue]);

  const handleSignBtn = () => {
    setOpenConfirmModal(true);
  };

  const handleAgreeSign = () => {
    const { id: taskId } = data || {};
    toSignTask(taskId).then((data) => {
      setIsSigned(!data.error);
      data.data && updateResponsibleAndExecutorsInfo(data.data);
      setOpenConfirmModal(false);
      getSignById(taskId).then(({ data }) => {
        console.log("данные для подписи", data);
        setSignatureValue(data);
      });
      // !error && getTask(taskId).then(() => setOpenConfirmModal(false));
    });
  };

  const handleDisagreeSign = () => {
    setOpenConfirmModal(false);
  };

  useEffect(() => {
    setIsErrorSubmit(errorGetTask);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorGetTask]);

  useEffect(() => {
    setIsSuccessSubmit(isSuccess);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);
  console.log("ddata",data?.status)
  return (
    <Box className="signatures">
      {data?.userPhoneVerified && (
        <Box className="signatures-button_wrraper">
          <Button className="btn btn-outlined-primary" variant="outlined" onClick={handleSignBtn} disabled={data?.status == 'Выполнено' || isSigned}>
            {t(`button:${isSigned ? "signed" : isHaveSignatures ? "signingAndClose" : "signing"}`)}
          </Button>
        </Box>
      )}
      <TableBasic
        columns={config}
        rows={rows}
        handleClickCell={() => {}}
        withSearchField={false}
        isLoading={false}
        isPagination={false}
        pageNumber={pageNumber}
        changePageNumber={changePageNumber}
        isNotBigTable={true}
      />

      <ModalConfirmDialog
        open={openConfirmModal}
        handleAgreeButton={handleAgreeSign}
        handleDisagreeButton={handleDisagreeSign}
        contentText={t("modal_content_text:confirm_sign_task")}
      />
    </Box>
  );
};
