import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Box, Typography, Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";

import "./styles/style.css";
import { useAction } from "../../../hooks/actions";
import { formConfigQRCode, formConfigSwitcher, sections, formFields } from "./form-config";
import { PATHS } from "../../../constants";
import {
  useEditEquipmentMutation,
  useGetEquipmentQuery,
  useLazyGetEquipmentsCoordinatesQuery,
} from "../../../middleware/equipmentsApi";
import { useLazyGetFloorOptionsSelectByFacilityQuery } from "../../../middleware/facilitiesApi";
import { useLazyGetMessageCodeElementsQuery } from "../../../middleware/messageCodeApi";

import { getRequestEditData, getRequestDataFilesForAdd } from "./helpers";
import ReplaceEquipmentModal from "./ReplaceEquipment";
import AccordionSection from "../../components/AccordionSection";
import FormItem from "../../components/FormItem";

import { useManipulateFilesMutation } from "../../../middleware/apiUploadFile";

const EquipmentDetails = ({ EquiipId, modalClose, refreshEquipment, setNewAddedEquipmentCount }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const [openModal, setOpenModal] = useState(false);
  const [isClickSave, setIsClickSave] = useState(false);
  const [roomAdd, setroomAdd] = useState([]);
  const [floorScheme, setfloorScheme] = useState("");
  const { changeTitlePage, setIsErrorSubmit, setIsSuccessSubmit } = useAction();
  const {
    data: equipment,
    refetch: refetchEquipment,
    isLoading: isLoadingEquipment,
  } = useGetEquipmentQuery(EquiipId ? EquiipId : id, { refetchOnMountOrArgChange: true });
  const [getFloorOptions, { isError: isErrorGetFloorOptions, isLoading: isLoadingGetFloorOptions }] =
    useLazyGetFloorOptionsSelectByFacilityQuery();
  const [manipulateFiles, { error: errorFile, isSuccess: isSuccessFile, isLoading: isLoadingFiles }] =
    useManipulateFilesMutation();
  const [getMessageCodeElements, { error: errorGetMessageElem /* isLoading */ }] = useLazyGetMessageCodeElementsQuery();
  const [getEquipmentsCoordinates, { isFetching: isEquipmentsCoordinatesLoading }] =
    useLazyGetEquipmentsCoordinatesQuery();
  const [editEquipment, { error: errorEdit, isSuccess: isSuccessEdit, isLoading: isLoadingEditEquipment }] =
    useEditEquipmentMutation();
  const isError = errorEdit;
  const isSuccess = isSuccessEdit;
  const isLoading = isLoadingFiles || isLoadingEditEquipment || isLoadingEquipment;
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    setValue,
    getValues,
    watch,
  } = useForm({
    mode: "onChange"
  });

  const floorInfoId = watch(formFields.floor);
  const handleBack = () => {
    const isActive = getValues(formFields.isActive);
    isClickSave ? navigate(-1) : isActive ? navigate(-1) : navigate(PATHS.ADD_EQUIPMENT);
  };

  const clickReplacement = () => {
    const isActive = getValues(formFields.isActive);
    isActive ? setOpenModal(true) : navigate(PATHS.ADD_EQUIPMENT);
  };

  const clickSave = () => {
    setIsClickSave(true);
  };

  // const habdleAgreeReplace = () => {
  //   setValue(formFields.isActive, false);
  //   setOpenModal(false);
  // };

  // const habdleDisagreeReplace = () => {
  //   setOpenModal(false);
  // };

  useEffect(() => {
    getMessageCodeElements();
  }, [getMessageCodeElements]);
  
  const onSubmit = async (data) => {
    const { entities, files } = getRequestEditData(data);
    editEquipment(entities).then(({ data, error }) => {
      if (!error) {
        const entityId = equipment.id;
        const entityType = "Equipment";
        const requestDataFiles = getRequestDataFilesForAdd(entityId, entityType, files);
        manipulateFiles(requestDataFiles).then(() => {
          if (error) {
            return;
          }
          if (!EquiipId) {
            refetchEquipment();
            handleBack();
          } else {
            refetchEquipment();
            modalClose(false);
            refreshEquipment(true, true);
          }
          setNewAddedEquipmentCount({});
        });
      }
    });
  };

  useEffect(() => {
    equipment?.name && !EquiipId && changeTitlePage(equipment?.name || "");
  }, [equipment?.name, changeTitlePage]);

  useEffect(() => () => changeTitlePage(""), [changeTitlePage]);

  useEffect(() => {
    if (equipment) {
      const {
        id,
        equipmentCoordinateX,
        equipmentCoordinateY,
        workingCorrectly,
        jobChart,
        typeId,
        placement,
        facilityName,
        fireCompartmentName,
        equipmentPassportFileLink,
        equipmentPassportFileName,
      } = equipment || {};
      console.log("passport", equipmentPassportFileLink);
      const getPassportFile = () => {
        let fileName = equipmentPassportFileName;
        let fileLink = equipmentPassportFileLink;

        if (fileName === "default.png") {
          fileName = "";
          fileLink = "/unnamed.png";
        }

        return {
          filesPrev: [{ fileName, fileLink }],
          filesToAdd: [],
          filesToDelete: [],
        };
      };
      const facilityAndFireCompartment = `${facilityName ? facilityName + ", " : ""}${fireCompartmentName || ""}`;
      const equipmentsCoordinates = [
        {
          id,
          floorSchemeCoordinateX: equipmentCoordinateX,
          floorSchemeCoordinateY: equipmentCoordinateY,
          workingCorrectly,
          jobChart,
          typeId,
        },
      ];
      reset({
        ...equipment,
        equipmentPassportFile: getPassportFile(),
        placement:
          placement == 0
            ? "Запотолочное пространство"
            : placement == 1
              ? "Подпольное пространство"
              : "Основное помещение",
        equipmentsCoordinates,
        keyType: equipment?.keyType?.toString(),
        facilityAndFireCompartment,
      });
      console.log("equipment", equipment);
      setValue(formFields.plume, equipment.plume);
      setValue(formFields.equipmentsCoordinates, equipmentsCoordinates);
      // setValue(formFields.plumeKeyType, "1")
    }
  }, [equipment, reset]);

  useEffect(() => {
    setIsErrorSubmit(isError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    setIsSuccessSubmit(isSuccess);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (equipment?.facilityId) {
      getFloorOptions(equipment.facilityId).then(({ data, isSuccess }) => {
        if (!!data?.length) {
          setValue(formFields.floor, equipment.floorInfoId);
        }
      });
    }
  }, [equipment]);
  
  useEffect(() => {
    if (!floorInfoId) {
      return;
    }

    (async function () {
      const res = await getEquipmentsCoordinates(
        `id=${floorInfoId}&returnEquipments=${false}&returnRooms=${true}&returnJobCharts=${false}`
      );
      if (res?.data) {
        const { floorSchemeFileLink, roomAddresses } = res.data;
        // setFloorScheme(floorSchemeFileLink);
        setfloorScheme(floorSchemeFileLink);
        setroomAdd(roomAddresses);
        const { id, equipmentCoordinateX, equipmentCoordinateY, workingCorrectly, jobChart, typeId } = equipment || {};

        const equipmentsCoordinates = [
          {
            id,
            floorSchemeCoordinateX: floorInfoId != equipment?.floorInfoId ? 1 : equipmentCoordinateX,
            floorSchemeCoordinateY: floorInfoId != equipment?.floorInfoId ? 1 : equipmentCoordinateY,
            workingCorrectly,
            jobChart,
            typeId,
          },
        ];
        setValue(formFields.equipmentsCoordinates, equipmentsCoordinates);
        // setValue(formFields.floorSchemeFileLink, floorSchemeFileLink);
        // setValue(formFields.roomAddresses, roomAddresses);

        /*  const coordinates = equipmentCoordinates.map((item) => ({
            ...item,
            floorInfoId: floor,
          }));
  */
        //
      }
    })();
  }, [floorInfoId]);

  return (
    <Box className="equipment_details">
      <form id="editEquipment" onSubmit={handleSubmit(onSubmit)}>
        <Box className="equipment_details-header">
          <Box>
            <Typography className="title-page">{equipment?.name || ""}</Typography>
          </Box>
          <Box className="equipment_details-header-buttons">
            <Button className="btn btn-red-fill" onClick={clickReplacement}>
              {t("button:replacement")}
            </Button>
            {!EquiipId && (
              <Button className="btn btn-outlined-grey" onClick={navigate.bind(null, -1)}>
                {t("button:comeback")}
              </Button>
            )}

            <Button
              form="editEquipment"
              className="btn btn-primary-fill save"
              type="submit"
              disabled={isSubmitSuccessful && !isError}
              onClick={clickSave}
            >
              {t("button:save")}
            </Button>
          </Box>
        </Box>

        <Box className="equipment_details-switcher_field">
          {formConfigSwitcher.map(({ name, label, input, ...rest }, i) => (
            <FormItem key={name} control={control} errors={errors} name={name} label={label} input={input} {...rest} />
          ))}
        </Box>

        <Box className="equipment_details-form scrolled_equipment_form" sx={{ overflowY: "initial !important" }}>
          {sections.map(({ id, title, Component, config }) => (
            <AccordionSection key={id} title={title} withoutPadding={true}>
              {Component && (
                <Component
                  control={control}
                  errors={errors}
                  config={config}
                  data={equipment}
                  getValues={getValues}
                  setValue={setValue}
                  watch={watch}
                  roomAdd={roomAdd}
                  floorScheme={floorScheme}
                />
              )}
            </AccordionSection>
          ))}

          {formConfigQRCode.map(({ name, label, input, ...rest }, i) => (
            <FormItem key={name} control={control} errors={errors} name={name} label={label} input={input} {...rest} />
          ))}
        </Box>
      </form>
      <Backdrop sx={{ color: "var(--white)", zIndex: 9999 }} open={isLoadingEquipment || isLoadingEditEquipment}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ReplaceEquipmentModal
        equipdata={equipment}
        modalClose={modalClose}
        refreshEquipment={refreshEquipment}
        setOpen={setOpenModal}
        isOpen={openModal}
      />
    </Box>
  );
};

export default EquipmentDetails;
