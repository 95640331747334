import React, { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Typography, Box, Tooltip } from "@mui/material";
import { useGetResponsibleOrganizationsUsersQuery } from "../../../middleware/usersApi";
import SelectSimpleSearch from "../../components/SelectSimple/SelectSimpleSearch";
import { messages } from "./constants";
import "./styles/style.css";
import { getTooltipByStatus } from "./helpers";

export const ClickableActionNameCell = ({ item }) => {
  const { name, action } = item || {};

  return (
    <Typography
      className={name ? "table-row-cell-link" : ""}
      onClick={action ? action : () => {}}
      sx={{ textAlign: "center" }}
    >
      {name || ""}
    </Typography>
  );
};
export const ObjectStatusCell = ({ item }) => {
  const { t } = useTranslation();

  const status = useMemo(() => messages?.find((mess) => mess.status === item.status), [item]);
  const statusName = t(`label:objectStatusTooltips:${status.message}`)

  return (
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Tooltip title={getTooltipByStatus(item, status, statusName,)}>
        <img src={status.iconSrc} alt="Иконка статуса объекта" />
      </Tooltip>
    </Box>
  );
};
export const ClickableActionListCell = ({ item }) => {
  return item?.map((e) => <ClickableActionNameCell key={e.id} item={e} />);
};

export const RatingCell = (percent) => {
  let reallPercent = percent;
  if (typeof percent === "object") {
    reallPercent = percent.item;
  }

  const getColorByPercent = (reallPercent) => {
    if (reallPercent >= 0 && reallPercent <= 40) return "var(--error)";
    if (reallPercent > 40 && reallPercent <= 80) return "var(--orange)";
    if (reallPercent > 81) return "var(--succes)";
  };

  const containerStyle = {
    position: "relative",
    height: "35px",
    width: "100%",
    backgroundColor: "white",
    border: "1px solid #CFD9E8",
    borderRadius: "32px",
    overflow: "hidden",
  };

  const fillerStyle = {
    height: "100%",
    width: `${Math.round(+reallPercent)}%`,
    backgroundColor: getColorByPercent(Math.round(+reallPercent)),
    borderRadius: "inherit",
    textAlign: "right",
    transition: "width 0.3s ease-in-out",
  };

  return (
    <div style={containerStyle}>
      <div style={fillerStyle}>
        <span className="percent-text">{`${Math.round(+reallPercent)} %`}</span>
      </div>
    </div>
  );
};

// return (
//   <div className="rating-cell">
//     <meter
//       min={0}
//       low={50}
//       high={80}
//       // optimum={0}
//       max={100}
//       // value={Math.round(+item)}
//       value={10}
//     >{`${Math.round(+item)} %`}</meter>
//     <p>{`${Math.round(+item)} %`}</p>
//   </div>
// );
// };

export const ExecutiveUserNameSelectSimple = (props) => {
  const { control, objectId } = props;
  console.log("props", props);
  const [searchValue, setSearchValue] = useState("");
  const { data: executives } = useGetResponsibleOrganizationsUsersQuery({
    searchValue: searchValue,
    filters: `&protectionObjectId=${objectId}`,
  });

  const options = useMemo(() => {
    return executives ? executives.map(({ id, name }) => ({ value: id, name })) : [];
  }, [executives]);

  return (
    <Box sx={{}}>
      <SelectSimpleSearch {...props} searchValue={searchValue} setSearchValue={setSearchValue} options={options} />
    </Box>
  );
};
