import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import { Button, Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { sideBarOptionsNewVersion, BIG_SCREEN } from "../../../data";
import { useAction } from "../../../hooks/actions";
import { useAppSelector } from "../../../hooks/redux";
import { PATHS } from "../../../constants";
import { parseJsonToken } from "../../../helpers/helper";
import SidebarListItem from "./SidebarListItem";
import LogoLabelIcon from "../../components/icons/LogoLabelIcon";
import { ArrowIconLeftBlue } from "../../components/icons/ArrowIcon";
import { useGetAbleToAddUsersQuery, useHasAccesToSystemLogQuery } from "../../../middleware/usersApi";
import useScreenSize from "../../../hooks/useScreenSize";
import ChatBot from "../../components/ChatBot";
import chatBotImg from "../../../image/ChatBot/chatBot.png";
import chatBotIcon from "../../../image/ChatBot/chatBotIcon.png";
import "./styles/style.css";
import "./styles/media.css";

const SideBar = () => {
  const location = useLocation();
  const token = useAppSelector((state) => state.users.token);
  const isSmallDescktop = useScreenSize().width < 1560;
  const [permissions, setPermissions] = useState([]);
  const [isOpenChat, setIsOpenChat] = useState(false);
  const navigate = useNavigate();
  const { roleId } = useAppSelector((state) => state.users.info);
  const { openSidebar } = useAppSelector((state) => state.settings);
  const { setOpenSidebar } = useAction();
  const [openIndex, setOpenIndex] = useState(null);
  useEffect(() => {
    parseJsonToken({ token, setPermissions });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const { data: isHaveAccessByRole } = useGetAbleToAddUsersQuery();
  const { data: isHaveAccessToSystemLog } = useHasAccesToSystemLogQuery();

  const options = sideBarOptionsNewVersion.filter((item) => permissions.includes(item.name));

  if (options[1] && isHaveAccessByRole === false) {
    options[1].subItems[1] = {};
  }
  if (options[4] && isHaveAccessToSystemLog === false) {
    options[4].subItems[2] = {};
  }
  function hasEquipments(data) {
    return data.some((obj) => obj.name === "equipments");
  }

  const equipments = hasEquipments(options);

  useEffect(() => {
    setOpenSidebar(window.innerWidth > BIG_SCREEN);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleDrawer = () => setOpenSidebar(!openSidebar);

  return (
    <Box className={`sidebar ${openSidebar ? "sidebar-opened" : `sidebar-closed`}`}>
      <IconButton onClick={toggleDrawer} className="sidebar-tablet-close-icon-button">
        <CloseIcon />
      </IconButton>

      <Box
        className={`logo-container ${!openSidebar ? "closed" : ""}`}
        onClick={() => (roleId !== 4 && equipments ? navigate(PATHS.OBJECTS_MAP) : navigate(PATHS.LIST_TASKS))}
      >
        <LogoLabelIcon isFullIcon={openSidebar} />
      </Box>

      <Button
        onClick={toggleDrawer}
        className="sidebar-arrow-button"
        sx={{
          transform: openSidebar ? "rotate(0deg)" : "rotate(-180deg)",
          minWidth: "0",
          left: openSidebar ? "80%" : "40px",
        }}
      >
        <ArrowIconLeftBlue />
      </Button>

      <Box className="scrolled-sidebar" position={"relative"}>
        {options.map(({ id, title, subItems, icon, rootPath, path, expanded, level }, index) => (
          <SidebarListItem
            key={id}
            text={title}
            icon={icon}
            index={index}
            openIndex={openIndex}
            setCurrentOpen={setOpenIndex}
            open={openSidebar}
            permissions={permissions}
            selected={location?.pathname?.includes(rootPath) || location?.pathname?.includes(path)}
            to={path}
            subItems={subItems}
            expanded={expanded}
            level={level}
            // action={() => width <= 900 && toggleDrawer()}
          />
        ))}
      </Box>

      <Box sx={{ position: "absolute", bottom: "24px", left: "24px" }}>
        <div className={openSidebar ? "chat-img chat-img-big" : "chat-img chat-img-small"} onClick={() => setIsOpenChat(true)}>
          <img src={openSidebar ? chatBotImg : chatBotIcon} alt="Чат бот" />
        </div>

        {isOpenChat && <ChatBot setIsOpenChat={setIsOpenChat} />}
      </Box>
    </Box>
  );
};

export default SideBar;
