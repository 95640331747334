import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  useEditMessageCodeMutation,
  useLazyGetMessageCodeQuery,
  useLazyGetMessageCodeElementsQuery,
  useLazyGetMessageCodeTypesQuery,
  useLazyResetMessageCodesQuery,
} from "../../../middleware/messageCodeApi";
import { useAction } from "../../../hooks/actions";
import { Box, IconButton, TablePagination, Typography } from "@mui/material";
import { useAppSelector } from "../../../hooks/redux";
import { useForm, useWatch } from "react-hook-form";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Button,
  Tooltip,
  Paper,
  Select,
  CircularProgress,
  MenuItem,
} from "@mui/material";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import TableContainer from "@mui/material/TableContainer";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import InfiniteScroll from "react-infinite-scroll-component";
import { elementOptions, messageCodeTypeOptions } from "./constants";
import { WarningValidationForm } from "../../components/Notifications";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import FormItem from "../../components/FormItem";
import { SimpleHeader } from "../TasksList/Components";
import { SearchInbuilt } from "../../components/SearchInbuilt";
// import RefreshIcon from "@mui/icons-material/Refresh";
import { PATHS } from "../../../constants";
import "./styles/style.css";
import RefreshIcon from "../../components/icons/RefreshIcon";
import InputText from "../../components/InputText";

export const MessageCodePage = () => {
  const { t } = useTranslation();
  const { messageCodeTypes } = useAppSelector((state) => state.messageCode);
  const { messageCodeElems } = useAppSelector((state) => state.messageCode);
  // const [searchValue /* changeSearchValue */] = useState("");
  const [pageNumber, changePageNumber] = useState(0);
  const [selectedValues, setSelectedValues] = useState({});
  // const [ranOnce, setRanOnce] = useState(false);
  // const [messElem, setmessElem] = useState([]);
  // const [messTypes, setmessTypes] = useState([]);
  const [getParams, setSearchParams] = useSearchParams();
  const protectionObjectId = getParams.has("protectionObjectId") ? getParams.get("protectionObjectId") : null;
  const [searchName, setSearchName] = useState("");
  const [searchCode, setSearchCode] = useState("");

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const params = useWatch({
    control,
    name: "search",
  });
  const isWarningValidation = !!Object.keys(errors).length;
  const [messag, setData] = useState({});
  const { id } = useParams();
  const [controlConsoleName, setControlConsoleName] = useState("");
  const [protectionObjecName, setprotectionObjecName] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { setIsErrorRequest, setIsErrorSubmit, setIsSuccessSubmit, changeTitlePage } = useAction();

  const [getMessageCode, { error: errorGetMessage /* isLoading */ }] = useLazyGetMessageCodeQuery();
  const [getMessageCodeElements, { error: errorGetMessageElem /* isLoading */ }] = useLazyGetMessageCodeElementsQuery();
  const [getMessageCodeTypes, { error: errorGetMessageTypes /* isLoading */ }] = useLazyGetMessageCodeTypesQuery();
  const [editMessageCode, { error: errorEdit, isSuccess: isSuccessEdit }] = useEditMessageCodeMutation();

  const [resetMessageTemplate, { error: errorresetMessageTemplate /* isLoading */ }] = useLazyResetMessageCodesQuery();

  useEffect(() => {
    getMessageCodeElements();
    getMessageCodeTypes();
  }, []);

  useEffect(() => {
    setIsErrorSubmit(errorEdit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorEdit]);

  useEffect(() => {
    setIsErrorRequest(errorGetMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorGetMessage]);

  useEffect(() => {
    setIsSuccessSubmit(isSuccessEdit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessEdit]);

  useEffect(() => {
    controlConsoleName && changeTitlePage(controlConsoleName || "");
  }, [controlConsoleName, changeTitlePage]);

  useEffect(() => () => changeTitlePage(""), [changeTitlePage]);

  const fetchData = async () => {
    try {
      let dd = {};
      let res = "";

      if (searchName != "") dd["name"] = searchName;
      if (searchCode != "") dd["code"] = searchCode;
      if (dd != {}) {
        res =
          "&" +
          Object.keys(dd)
            .map((key) => `${key}=${dd[key]}`)
            .join("&");
      }
      const response = await getMessageCode({
        controlConsoleId: id,
        pageNumber: 0,
        protectionObjectId: protectionObjectId != null ? `&protectionObjectId=${protectionObjectId}` : "",
        filters: res,
      });
      console.log("error", response?.data?.messageCodes);
      setData({
        ...response?.data,
        hasMore: response?.data?.messageCodes?.data.length < response?.data.messageCodes.recordsCount ? true : false,
      });
      if (response?.data?.protectionObjectName != null) setprotectionObjecName(response?.data?.protectionObjectName);

      setControlConsoleName(response?.data?.controlConsoleName);
    } catch (error) {
      console.log("error");
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchName, searchCode]);

  const recordsCount = useMemo(
    () => messag && messag?.messageCodes?.recordsCount,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [messag]
  );

  const onSubmit = () => {
    let res = {};
    if (protectionObjectId) res = { protectionObjectId: protectionObjectId };
    const newData = messag?.messageCodes.data;
    editMessageCode({
      messageCodes: [...newData],
      controlConsoleId: id,
      ...res,
    });
  };

  const handleFieldChange = (event, field, id) => {
    const updatedData = messag?.messageCodes.data.map((item) => {
      if (item.id === id) {
        return { ...item, [field]: event.target.value };
      }
      return item;
    });
    setData({
      ...messag,
      messageCodes: { ...messag?.messageCodes, data: updatedData },
    });
  };

  const handleCancel = () => {
    navigate(-1);
  };
  const handleClose = () => {
    navigate(PATHS.CONTROLLERS);
  };

  const messagefetchData = () => {
    setLoading(true);
    let dd = {};
    let res = "";

    if (searchName != "") dd["name"] = searchName;
    if (searchCode != "") dd["code"] = searchCode;
    if (dd != {}) {
      res =
        "&" +
        Object.keys(dd)
          .map((key) => `${key}=${dd[key]}`)
          .join("&");
    }
    getMessageCode({
      controlConsoleId: id,
      pageNumber: pageNumber,
      protectionObjectId: protectionObjectId != null ? `&protectionObjectId=${protectionObjectId}` : "",
      filters: res,
    }).then(({ data }) => {
      setData({
        hasMore:
          data?.messageCodes.data.length + messag?.messageCodes.data.length < data.messageCodes.recordsCount
            ? true
            : false,
        messageCodes: {
          ...messag?.messageCodes,
          data: data.messageCodes.data,
        },
      });
      setLoading(false);
    });
  };

  useEffect(() => {
    messagefetchData();
  }, [pageNumber]);

  const handleMouseEnter = (id) => {
    setSelectedValues({});
    setSelectedValues((prevState) => {
      return { ...prevState, [id]: true };
    });
  };

  const handleMouseLeave = (id) => {
    setSelectedValues({});
  };

  const resetMesageTemplate = () => {
    resetMessageTemplate({
      controlConsoleId: id,
      protectionObjectId: protectionObjectId != null ? `&protectionObjectId=${protectionObjectId}` : "",
    }).then(() => {
      fetchData();
    });
  };

  useEffect(() => {
    changePageNumber(pageNumber);
    setValue("paginationTextField", pageNumber + 1);
    // savePageNumberToSessionStorage(pageNumber)
  }, [messag]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => setValue("paginationTextField", pageNumber + 1), []);

  const handleLastPageButtonClick = () => {
    const newPage = Math.max(0, Math.ceil(recordsCount / 15) - 1);
    changePageNumber(newPage);
    // savePageNumberToSessionStorage(newPage)
    setValue("paginationTextField", newPage);
  };

  const handleFirstPageButtonClick = () => {
    // setIsChoosenFirstPage && setIsChoosenFirstPage(true);
    changePageNumber(0);
    setValue("paginationTextField", 1);
    // savePageNumberToSessionStorage(0)
  };

  const handlePrevPageButtonClick = () => {
    changePageNumber((prev) => {
      return Number(prev) - 1;
    });
    // savePageNumberToSessionStorage(pageNumber - 1)
    setValue("paginationTextField", Number(pageNumber) - 1);
  };

  const handleNextPageButtonClick = () => {
    changePageNumber((prev) => Number(prev) + 1);
    // savePageNumberToSessionStorage(pageNumber + 1)
    setValue("paginationTextField", Number(pageNumber) + 2);
  };
  const handleKeyPress = async (event) => {
    if (event.key === "Enter") {
      if (Number(event.target.value) + 2 > Math.max(0, Math.ceil(recordsCount / 15) - 1)) {
        const numberOfPage = Math.max(0, Math.ceil(recordsCount / 15) - 1);
        changePageNumber(numberOfPage);
        // savePageNumberToSessionStorage(numberOfPage)
        setValue("paginationTextField", Math.max(0, Math.ceil(recordsCount / 15)));
      } else if (event.target.value == "" || event.target.value == "0") {
        changePageNumber(0);
        // savePageNumberToSessionStorage(0)
        setValue("paginationTextField", 1);
      } else {
        changePageNumber(Number(event.target.value) - 1);
        // savePageNumberToSessionStorage(Number(event.target.value) - 1)
      }
    } else {
      if (
        event.key === "e" ||
        event.key === "E" ||
        event.key === "*" ||
        event.key === "/" ||
        event.key === "-" ||
        event.key === "+" ||
        event.key === "." ||
        event.key === ","
      ) {
        event.preventDefault();
      } else {
        const input = event.target.value;
        const filteredInput = input.replace(/[^\d]/g, "").slice(0, 4);
        setValue("paginationTextField", filteredInput);
      }
    }
  };

  return (
    <Box className="object">
      <Box>
        <Box className="object-header">
          <Typography className="title-page">{t("title_page:message_template")}</Typography>
          <Box sx={{ display: "inherit" }}>
            <Typography
              className="title-page"
              sx={{ marginRight: "40px" }}
            >{`Пульт: ${controlConsoleName}`}</Typography>
            <Typography className="title-page">
              {protectionObjecName != "" ? `Объект: ${protectionObjecName}` : ""}
            </Typography>
          </Box>

          <Box className="object-header-buttons">
            <Box className="full_screen_icon-wrapper">
              {/* <RefreshIcon
                className="full_screen_icon"
                sx={{
                  marginTop: "7px",
                  marginLeft: "30px",
                }}
                onClick={() => resetMesageTemplate()}
              /> */}
              <RefreshIcon className="full_screen_icon" onClick={() => resetMesageTemplate()} />
            </Box>
            <Button className="btn btn-outlined-grey" onClick={handleClose}>
              {t("button:to_back")}
            </Button>
            <Button className="btn btn-outlined-grey" onClick={handleCancel}>
              {t("button:comeback")}
            </Button>
            <Button form="myform" className="btn btn-primary-fill" onClick={onSubmit}>
              {t("button:save")}
            </Button>
          </Box>
        </Box>

        <Box sx={{ marginTop: "30px", background: "#FFF", borderRadius: "32px", padding: "24px 0 0 24px" }}>
          {messag && (
            <>
              <TableContainer component={Paper} style={{ maxHeight: "70vh", overflow: "auto" }}>
                <Table stickyHeader className="table">
                  <TableHead className="table-head">
                    <TableRow
                      sx={{
                        backgroundColor: "#e2e8f0",
                        height: "40px",
                      }}
                    >
                      <TableCell sx={{ padding: "8px 16px" }} className={`table-head-cell`}>
                        <Box sx={{ width: "110px" }}>
                          <FormItem
                            control={control}
                            name={`search.code`}
                            sx={{ padding: "12px 0" }}
                            input={(props) => (
                              <SearchInbuilt
                                placeholder={t("tableHeader:message_code")}
                                className="select_in_built small-light"
                                searchValue={searchCode}
                                disabled
                                changeSearchValue={setSearchCode}
                                {...props}
                              />
                            )}
                          />
                        </Box>
                      </TableCell>
                      {/*<TableCell
                          sx={{ padding: "8px" }}
                          style={{ backgroundColor: "#e2e8f0" }}
                        >
                          Шаблон сообщений
                      </TableCell>*/}
                      <TableCell sx={{ padding: "8px" }} className={`table-head-cell sticky`}>
                        <Box sx={{ width: "210px" }}>
                          <FormItem
                            control={control}
                            name={`search.name`}
                            sx={{ padding: "12px 0" }}
                            input={(props) => (
                              <SearchInbuilt
                                placeholder={t("tableHeader:event_name")}
                                className="select_in_built small-light"
                                searchValue={searchName}
                                disabled
                                changeSearchValue={setSearchName}
                                {...props}
                              />
                            )}
                          />
                        </Box>
                      </TableCell>

                      <TableCell sx={{ padding: "8px 16px" }} className={`table-head-cell sticky`}>
                        <SimpleHeader name={t("tableHeader:event")} />
                      </TableCell>
                      <TableCell sx={{ padding: "8px 16px", width: "250px" }} className={`table-head-cell sticky`}>
                        <SimpleHeader name={t("tableHeader:element")} />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {messag?.messageCodes?.data?.length > 0 &&
                      messag.messageCodes.data.map((item, index) => (
                        <TableRow
                          key={item.id}
                          sx={{
                            backgroundColor: messageCodeTypes.find((itemm) => itemm.value === item.messageCodeTypeId)
                              ?.color,
                          }}
                        >
                          <TableCell
                            sx={{
                              width: "50px",
                              border: `1px solid ${messageCodeTypes.find((itemm) => itemm.value === item.messageCodeTypeId)?.borderColor}`,
                              borderRight: "0px",
                            }}
                          >
                            {item.code}
                          </TableCell>
                          <TableCell
                            sx={{
                              width: "750px",
                              padding: "8px",
                              border: `1px solid ${messageCodeTypes.find((itemm) => itemm.value === item.messageCodeTypeId)?.borderColor}`,
                              borderLeft: "0px",
                              borderRight: "0px",
                            }}
                          >
                            <Box
                              sx={{ width: "750px" }}
                              onMouseEnter={() => handleMouseEnter(`name${item.id}`)}
                              onMouseLeave={() => handleMouseLeave(`name${item.id}`)}
                            >
                              {selectedValues[`name${item.id}`] ? (
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                  onMouseLeave={() => handleMouseLeave(`name${item.id}`)}
                                >
                                  <TextField
                                    fullWidth
                                    variant="standard"
                                    value={item.name}
                                    onChange={(event) => handleFieldChange(event, "name", item.id)}
                                  />
                                  <EditOutlinedIcon />
                                </Box>
                              ) : (
                                <Typography onMouseEnter={() => handleMouseEnter(`name${item.id}`)}>
                                  {item.name}
                                </Typography>
                              )}
                            </Box>
                          </TableCell>

                          <TableCell
                            sx={{
                              width: "250px",
                              padding: 0,
                              border: `1px solid ${messageCodeTypes.find((itemm) => itemm.value === item.messageCodeTypeId)?.borderColor}`,
                              borderLeft: "0px",
                              borderRight: "0px",
                            }}
                          >
                            <Box
                              onMouseEnter={() => handleMouseEnter(`codetypes${item.id}`)}
                              onMouseLeave={() => handleMouseLeave(`codetypes${item.id}`)}
                            >
                              {selectedValues[`codetypes${item.id}`] ? (
                                <Select
                                  sx={{
                                    "& .MuiOutlinedInput-notchedOutline": {
                                      border: "none",
                                    },
                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                      border: "none",
                                    },
                                    "& .MuiSelect-select:focus": {
                                      backgroundColor: "transparent",
                                    },
                                    width: "100%",
                                  }}
                                  onMouseLeave={() => handleMouseLeave(`codetypes${item.id}`)}
                                  value={item.messageCodeTypeId}
                                  onChange={(event) => handleFieldChange(event, "messageCodeTypeId", item.id)}
                                >
                                  {messageCodeTypes.length > 0 &&
                                    messageCodeTypes?.map((option) => (
                                      <MenuItem key={option.value} value={option.value}>
                                        {option.name}
                                      </MenuItem>
                                    ))}
                                </Select>
                              ) : (
                                <Typography
                                  sx={{ padding: "12.5px 32px 12.5px 24px" }}
                                  onMouseEnter={() => handleMouseEnter(`codetypes${item.id}`)}
                                >
                                  {messageCodeTypes.find((itemm) => itemm.value === item.messageCodeTypeId)?.name}
                                </Typography>
                              )}
                            </Box>
                          </TableCell>

                          <TableCell
                            sx={{
                              // width: "250px",
                              height: "49px",
                              padding: 0,
                              border: `1px solid ${messageCodeTypes.find((itemm) => itemm.value === item.messageCodeTypeId)?.borderColor}`,
                              borderLeft: "0px",
                            }}
                          >
                            <Box
                              // sx={{ width: "250px" }}
                              onMouseEnter={() => handleMouseEnter(`element${item.id}`)}
                              onMouseLeave={() => handleMouseLeave(`element${item.id}`)}
                            >
                              {selectedValues[`element${item.id}`] ? (
                                <Select
                                  sx={{
                                    "& .MuiOutlinedInput-notchedOutline": {
                                      border: "none",
                                    },
                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                      border: "none",
                                    },
                                    "& .MuiSelect-select:focus": {
                                      backgroundColor: "transparent",
                                    },
                                    width: "100%",
                                  }}
                                  onMouseLeave={() => handleMouseLeave(`element${item.id}`)}
                                  value={item.elementId}
                                  onChange={(event) => handleFieldChange(event, "elementId", item.id)}
                                >
                                  {messageCodeElems.length > 0 &&
                                    messageCodeElems?.map((option) => (
                                      <MenuItem key={option.value} value={option.value}>
                                        {option.name}
                                      </MenuItem>
                                    ))}
                                </Select>
                              ) : (
                                <Typography
                                  sx={{ padding: "12.5px 32px 12.5px 24px" }}
                                  onMouseEnter={() => handleMouseEnter(`element${item.id}`)}
                                >
                                  {messageCodeElems.find((itemm) => itemm.value === item.elementId)?.name}
                                </Typography>
                              )}
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {messag?.messageCodes?.data.length > 0 && (
                <TablePagination
                  rowsPerPageOptions={[10]}
                  component="div"
                  count={recordsCount}
                  rowsPerPage={15}
                  page={pageNumber}
                  labelRowsPerPage={t("table:labelRowsPerPage")}
                  labelDisplayedRows={({ from, to, count }) => {
                    return (
                      <div
                        style={{ fontSize: "16px" }}
                      >{`${from > to ? to : from}–${to} из ${count !== -1 ? count : `больше чем ${to}`}`}</div>
                    );
                  }}
                  ActionsComponent={(subprops) => {
                    return (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <IconButton
                          onClick={handleFirstPageButtonClick}
                          aria-label="last page"
                          sx={{ height: "40px" }}
                          disabled={pageNumber === 0}
                        >
                          <FirstPageIcon />
                        </IconButton>
                        <IconButton
                          onClick={handlePrevPageButtonClick}
                          sx={{ height: "40px" }}
                          disabled={pageNumber === 0}
                          aria-label="last page"
                        >
                          <NavigateBeforeIcon />
                        </IconButton>
                        <FormItem
                          control={control}
                          name={`paginationTextField`}
                          placeholder="Страница"
                          size="small"
                          defaultValue={pageNumber || 1}
                          sx={{ width: "100px" }}
                          type={"number"}
                          InputProps={{ type: "number" }}
                          sx={{
                            "& input[type=number]": {
                              "-moz-appearance": "textfield",
                              padding: "8px 10px",
                              width: "100%",
                              textAlign: "center",
                            },
                            "& input[type=number]::-webkit-outer-spin-button": {
                              "-webkit-appearance": "none",
                              margin: 0,
                            },
                            "& input[type=number]::-webkit-inner-spin-button": {
                              "-webkit-appearance": "none",
                              margin: 0,
                            },
                            "& input[type=number]::placeholder": {
                              paddingLeft: "0px",
                            },
                          }}
                          input={(props) => <InputText onKeyPress={handleKeyPress} {...props} />}
                        />

                        <IconButton
                          onClick={handleNextPageButtonClick}
                          sx={{ height: "40px" }}
                          disabled={pageNumber === Math.max(0, Math.ceil(recordsCount / 15) - 1)}
                          aria-label="last page"
                        >
                          <NavigateNextIcon />
                        </IconButton>
                        <IconButton
                          onClick={handleLastPageButtonClick}
                          sx={{ height: "40px" }}
                          disabled={pageNumber === Math.max(0, Math.ceil(recordsCount / 15) - 1)}
                          aria-label="last page"
                        >
                          <LastPageIcon />
                        </IconButton>
                      </Box>
                    );
                  }}
                />
              )}
            </>
          )}
        </Box>
      </Box>
      {isWarningValidation && <WarningValidationForm isWarning={isWarningValidation} />}
    </Box>
  );
};
