import React, { useRef, } from "react";
import { Stage, Image, Rect, Layer } from "react-konva";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../../constants";
import { Box } from "@mui/material";

export const MiniMapCanvas = ({ img, stageposition, canvas, getequipmentsInRegion, scale, width, height, setStagePosition }) => {
    const miniMapScale = 0.2; // масштаб для мини-карты (можно изменить по необходимости)


    const handleDragMiniEnd = (e) => {
        setStagePosition({ x: -e.target.x() * scale / miniMapScale, y: -e.target.y() * scale / miniMapScale });
        const coordinates = [-canvas.x() / scale, -canvas.y() / scale, (width / scale - canvas.x() / scale), (height / scale - canvas.y() / scale)];
        getequipmentsInRegion(coordinates, null);
    };

    const handleDragMiniMove = (e) => {
        const speedMultiplier = 0.5;
        canvas.position({ x: -e.target.x() * scale / miniMapScale, y: -e.target.y() * scale / miniMapScale })
    };
    
    const dragBoundFunc = (pos) => {
        const rectWidth = (width / scale) * miniMapScale; // Ширина прямоугольника
        const rectHeight = (height / scale) * miniMapScale; // Высота прямоугольника

        // Границы для перемещения
        const boundaryX = img.width * miniMapScale  - rectWidth; // Максимальное значение по X
        const boundaryY = img.height * miniMapScale - rectHeight; // Максимальное значение по Y

        // Ограничение по X
        let newX = Math.max(0, Math.min(boundaryX, pos.x));

        // Ограничение по Y
        let newY = Math.max(0, Math.min(boundaryY, pos.y));

        return { x: newX, y: newY };
    };
    return (
        <Box
            sx={{
                position: "absolute",
                bottom: 35,
                right: 35,
                zIndex: 99999999,
                border: "1px solid #CFD9E8",
                borderRadius: "8px",
                overflow: "hidden",
                width: img?.width * miniMapScale, // ширина мини-карты
                height: img?.height * miniMapScale // высота мини-карты
            }}
        >
            <Stage
                width={img?.width * miniMapScale}
                height={img?.height * miniMapScale}
            >
                <Layer
                >
                    <Image
                        image={img}
                        x={0}
                        y={0}
                        width={img?.width * miniMapScale}
                        height={img?.height * miniMapScale}
                    />
                    <Rect
                        x={-(canvas?.x() / scale * miniMapScale)} // Позиция x для прямоугольника
                        y={-(canvas?.y() / scale * miniMapScale)} // Позиция y для прямоугольника
                        width={(width / scale) * miniMapScale} // ширина отображаемой области на мини-карте
                        height={(height / scale) * miniMapScale} // высота отображаемой области на мини-карте
                        fill="rgba(24, 118, 210, 0.5)" // цвет прямоугольника
                        stroke={"rgba(24, 118, 210, 1)"}
                        strokeWidth={2}
                        draggable
                        onDragMove={handleDragMiniMove}
                        onDragEnd={handleDragMiniEnd}
                        dragBoundFunc={dragBoundFunc}
                    />
                </Layer>
            </Stage>
        </Box>
    );
}
