import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Box, Typography, Backdrop, CircularProgress, Button } from "@mui/material";

import "../styles/styles.css";
import {
  formFields,
  STEP,
  stepsParams,
} from "../form-config";

import { useLazyGetEquipmentReglamentsQuery, useUpdateEquipmentsCoordinatesMutation } from "../../../../middleware/equipmentsApi";
import {
  useUpdateRoomAddressesCoordinatesMutation,
  useLazyGetEquipmentsCoordinatesQuery,
} from "../../../../middleware/equipmentsApi";
import { useAction } from "../../../../hooks/actions";
import { getRoomAddressForRequest, getEquipmentsCoordinatesWithRoomId } from "../../../../helpers/helper";
import { useGetFacFireCompSelectElementsQuery } from "../../../../middleware/facilitiesApi";
import useScreenSize from "../../../../hooks/useScreenSize";
import { PATHS, screenSizes } from "../../../../constants";
import {
  getEquipmentTypeIdOptionsGroup,
  getFacilityAndFireCompartmentOptionsGroup,
} from "../helpers";
import AddEquipmentLiteTree from '../AddEquipmentLiteTree'
import EditReglamentModal from '../AddEquipmentLiteTree/EditReglamentModal'
import { useNavigate } from 'react-router-dom'
import { useWatch } from "react-hook-form";
import PageheaderNav from "../../../components/PageheaderNav";

const AddEquipmentPage = ({ isLite }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setIsErrorSubmit, setIsSuccessSubmit, setErrorNotificationParams } =
    useAction();
  const mainContainer = useRef(null)
  const [getEquipmentReglaments] = useLazyGetEquipmentReglamentsQuery();
  const screenSize = useScreenSize();
  const isActive = true; // mock data. Wait from backend
  const [isTurnDrag, setisTurnDrag] = useState(false);
  const [turnOnEdit, setTurnOnEdit] = useState(false);
  const [selectedEquipmentGroup, setSelectedEquipmentGroup] = useState('')
  const [activeStep, setActiveStep] = useState(STEP.DEFAULT);
  const [objectId, setObjectId] = useState("");
  const [removedAddress, setRemovedAddress] = useState([]);
  const [isDrawPoligon, setIsDrawPoligon] = useState(false);
  const [deletedAddress, setdeletedAddress] = useState([]);
  const [newRoomAddresses, setNewRoomAddresses] = useState([]);
  const [equipmentCoordinatesChange, setequipmentCoordinatesChange] = useState([]);
  const [addplume, setaddplume] = useState(false);
  const [editReglamentOpen, setEditReglamentOpen] = useState(false);
  const [xyGroup, setxyGroup] = useState([]);
  const [ranOnce, setRanOnce] = useState(false);
  const [regulations, setRegulations] = useState([]);
  const [showHeader, setshowHeader] = useState(true);
  const [isReplaceShape, setisReplaceShape] = useState(false);
  const [selectedId, selectShape] = useState(null);
  const { label: currentStepLabel } =
    stepsParams.find(({ step }) => step === activeStep) || {};
  const [
    updateRoomAddressesCoordinates,
    {
      error: errorUpdateRoom,
      isSuccess: isSuccessUpdateRoom,
      isLoading,
      isError: isErrorUpdateRoom,
    },
  ] = useUpdateRoomAddressesCoordinatesMutation();
  const [updateEquipmentCoordinates, {
    isSuccess: isSuccessUpdateEquipment,
    isLoading: isLoadingUpdateEquipment,
    isError: isErrorUpdateEquipment
  }] = useUpdateEquipmentsCoordinatesMutation();
  const [getEquipmentsCoordinates, { isFetching: isEquipmentsCoordinatesLoading }] = useLazyGetEquipmentsCoordinatesQuery();
  const isError = isErrorUpdateRoom || isErrorUpdateEquipment;
  const isSuccess = (isSuccessUpdateRoom) || (isSuccessUpdateEquipment && isSuccessUpdateRoom);
  const isLoadingRequest = isLoading || isLoadingUpdateEquipment;

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
    register,
    unregister,
    setValue,
    watch,
    getValues,
    trigger,
    reset,
    resetField,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      [formFields.regulations]: [],
      [formFields.havingServiceContract]: true,
    },
  });
  const {
    roomAddresses,
    floor
  } = useWatch({
    control,
  });
  const object = watch(formFields.generalInformation.fireCompartmentId);
  // eslint-disable-next-line react-hooks/exhaustive-deps

  const handleBackToManageEquipments = () => {
    setTurnOnEdit(false);
    setaddplume(false);
    selectShape(null)
    setisReplaceShape(false)
    //setisDragabble(true)
    const prevRoomAddresses = roomAddresses?.filter(({ id }) => id);
    const newRoomAddressesRequest = getRoomAddressForRequest(newRoomAddresses);
    const addresses = prevRoomAddresses
      ? [...prevRoomAddresses, ...newRoomAddressesRequest]
      : newRoomAddressesRequest;

    setValue(formFields.roomAddresses, addresses);
    setshowHeader(true)
    return addresses
  };
  const cancelEditorAdd = () => {
    setTurnOnEdit(false);
    setIsDrawPoligon(false);
    setNewRoomAddresses([]);
    setRemovedAddress([]);
    setxyGroup([])
    setaddplume(false);
    selectShape(null)
    setisReplaceShape(false)
  }
  function rotateCoordinates(x, y, angle) {
    // Преобразуем угол в радианы
    const radians = angle * (Math.PI / 180);
    
    // Пересчитываем координаты
    const xNew = x * Math.cos(radians) - y * Math.sin(radians);
    const yNew = x * Math.sin(radians) + y * Math.cos(radians);
    
    return { coordinateX: xNew, coordinateY: yNew };
  }

  const handleSubmitLite = (data) => {

    const {
      floor: floorInfoId,
      roomAddresses,
      equipmentsCoordinates,
    } = data || {};
    const rooms = handleBackToManageEquipments()
    const newArray = rooms.map(item => {
      // item.protectionObjectIntegrNumberAltId = item.protectionObjectIntegrNumberAltId == '' ? null : item.protectionObjectIntegrNumberAltId;
      // item.plumes = typeof item.plumes[0] === 'string' ? item.plumes : item.plumes.map(el => el.name);
      const foundItem = xyGroup.find(i => i.id === item.id);
      
      if (foundItem) {
        return {
          ...item, coordinates: item.coordinates.sort((a, b) => a.order - b.order).map((res, index) => {
            const newCoor = rotateCoordinates(res.coordinateX * foundItem.scaleX,res.coordinateY * foundItem.scaleY,foundItem?.rotation)
            console.log("newArray", foundItem)
            return { ...res, coordinateX:  newCoor?.coordinateX+foundItem.x / foundItem.scaleRoom.x, coordinateY:  newCoor?.coordinateY+foundItem.y / foundItem.scaleRoom.y }
          })
        };
      } else {
        return item;
      }
    });

    updateRoomAddressesCoordinates({ floorInfoId, roomAddressesIdsToDelete: deletedAddress?.map(obj => obj.id), roomAddresses: newArray }).then(
      ({ data, error }) => {
        setValue(formFields.roomAddresses, data)
        const equipChange = equipmentsCoordinates.filter(obj => equipmentCoordinatesChange.includes(obj.id))
        const requestData = getEquipmentsCoordinatesWithRoomId({
          equipmentsCoordinates: equipChange,
          rooms: data,
        });

        if (!error) {
          requestData?.length && updateEquipmentCoordinates(requestData);
          setequipmentCoordinatesChange([])
        }
        if (addplume || turnOnEdit) {
          getEquipmentsCoordinates(`id=${floor}&returnEquipments=${true}&returnRooms=${true}&returnJobCharts=${false}`).then(({ data, error }) => {
            if (data) {
              const { equipmentCoordinates } =
                data;
              const coordinates = equipmentCoordinates.map((item) => ({
                ...item,
                floorInfoId: floor,
              }));

              setValue(formFields.equipmentsCoordinates, coordinates);
              setTurnOnEdit(false);
              setIsDrawPoligon(false);
              setNewRoomAddresses([]);
              setRemovedAddress([]);
              setxyGroup([])
            }
          })
        }
      }
    );

  }

  useEffect(() => {
    errorUpdateRoom?.originalStatus === 409
      ? setErrorNotificationParams({
        isOpen: true,
        title: t("modal_error_plume:title"),
        content: t("modal_error_plume:text"),
      })
      : setIsErrorSubmit(isError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    setIsSuccessSubmit(isSuccess);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const isDisabled = !!errors[currentStepLabel];

  const NAV_ITEMS = [
    { text: "add_equipment_lite", permissions: "equipmentsadd-edit" },
    { text: "list_equipment", permissions: "equipments" },
    { text: "equipment_reglaments", permissions: "equipments" },
  ];
  return (
    <Box className="addEquipment" sx={{ marginTop: "0px" }}>
      <Box>
        <Box className="addEquipment-header">
          <Typography className="title-page" sx={{ marginTop: "0px" }}>
            {t(isLite ? "title_page:digitization" : "title_page:digitization")}
          </Typography>
        </Box>

      </Box>
      <Box className="addEquipment-footer" sx={{ justifyContent: "space-between", alignItems: "center" }}>

        <PageheaderNav itemsNav={NAV_ITEMS} />
        <Box sx={{ display: "flex" }}>
          {(turnOnEdit || addplume) &&
            <Button
              className="btn btn-outlined-primary"
              onClick={() => cancelEditorAdd()}
              sx={{ height: "52px", marginRight: "16px" }}
            >
              {t("button:cancel")}
            </Button>
          }
          <Button
            form="myform"
            className="btn btn-primary-fill"
            type="submit"
            sx={{ height: "52px" }}
          >
            {t("button:save")}
          </Button>
        </Box>


      </Box>


      <Box ref={mainContainer} className="addEquipment-form">
        <>
          <EditReglamentModal
            selectedEquipmentGroup={selectedEquipmentGroup}
            isOpen={editReglamentOpen}
            onClose={() => setEditReglamentOpen(false)}
          />
          <AddEquipmentLiteTree
            turnOnEdit={turnOnEdit}
            setTurnOnEdit={setTurnOnEdit}
            onOpenEditReglament={(id) => {
              setSelectedEquipmentGroup(id)
              setEditReglamentOpen(true)
            }}
            mainContainer={mainContainer}
            register={register}
            unregister={unregister}
            handleSubmit={handleSubmit}
            trigger={trigger}
            isActive={isActive}
            showHeader={showHeader}
            setshowHeader={setshowHeader}
            selectedId={selectedId}
            selectShape={selectShape}
            removedAddress={removedAddress}
            setRemovedAddress={setRemovedAddress}
            isDrawPoligon={isDrawPoligon}
            setisTurnDrag={setisTurnDrag}
            isTurnDrag={isTurnDrag}
            setIsDrawPoligon={setIsDrawPoligon}
            addplume={addplume}
            setaddplume={setaddplume}
            setdeletedAddress={setdeletedAddress}
            setError={setError}
            handleSubmitLite={handleSubmitLite}
            setNewRoomAddresses={setNewRoomAddresses}
            newRoomAddresses={newRoomAddresses}
            setisReplaceShape={setisReplaceShape}
            isReplaceShape={isReplaceShape}
            regulations={regulations}
            isSuccess={isSuccess}
            xyGroup={xyGroup}
            setxyGroup={setxyGroup}
            setSelectedEquipmentGroup={setSelectedEquipmentGroup}
            setequipmentCoordinatesChange={setequipmentCoordinatesChange}
            equipmentCoordinatesChange={equipmentCoordinatesChange}
            control={control}
            errors={errors}
            watch={watch}
            setValue={setValue}
            getValues={getValues}
            resetField={resetField}
            objectId={objectId}
          />
        </>
      </Box>
      <Backdrop
        sx={{ color: "var(--white)", zIndex: 9999 }}
        open={isLoadingRequest}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box >
  );
};

export default AddEquipmentPage;
