import React from "react";
import { useNavigate } from "react-router-dom";
import { Box,Typography } from '@mui/material';
import { PATHS } from '../../../constants'
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../hooks/redux";

const PageheaderNav = ({ itemsNav }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const permissions = useAppSelector((state) => state.permissions.permissions);
  const filteredByPermissions = itemsNav.filter(item => permissions.includes(item.permissions))

  return (
    <Box sx={{ display: "flex",padding:"8px",borderRadius:"48px",gap:"32px",backgroundColor: "#ffffff", width: 'fit-content'}}>
      {filteredByPermissions.map((item) => (
        <Box className={`pageNavHeader ${(window.location.href.indexOf(item.text) > -1) ? "pageNavHeaderCureent" : ""}`}>
          <Typography onClick={() => navigate(PATHS[item.text.toUpperCase()])}>
            {t(`title_page:${item.text}`)}
          </Typography>
        </Box>

      ))}
    </Box>
  );
};

export default PageheaderNav;
