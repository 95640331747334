import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "./constants";

export const billingApi = createApi({
  reducerPath: "billing/api",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const { token } = getState().users;
      headers.set("Content-Type", "application/json");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (build) => ({
    getBillingInfo: build.query({
      query: ({ id, typeId }) => ({
        url: `api/billing/get-billing-info?organizationId=${id}&organizationTypeId=${typeId}`,
      }),
    }),
    addPayment: build.mutation({
      query: (data) => ({
        url: "/api/billing/add-payment",
        method: "POST",
        body: data,
      }),
    }),
    updateOptionDiscount: build.mutation({
      query: (data) => ({
        url: "/api/billing/update-option-discount",
        method: "POST",
        body: data,
      }),
    }),
    getBalance: build.query({
      query: () => ({
        url: `/api/billing/get-balance`,
      }),
    }),
    getdateRangeTariff: build.query({
      query: ({facilityId}) => ({
        url: `/api/billing/get-date-change-tariff?facilityId=${facilityId}`,
      }),
    }),
    
    markInvoiceAsExcluded: build.mutation({
      query: (data) => ({
        url: `/api/billing/mark-invoice-as-excluded`,
        method: "POST",
        body: data,
      }),
    }),

    markInvoiceAsIncluded: build.mutation({
      query: (data) => ({
        url: `/api/billing/mark-invoice-as-included`,
        method: "POST",
        body: data,
      }),
    }),

    generateCommonBillDocument: build.mutation({
      query: () => ({
        url: `/api/billing/generate-common-billing-document`,
        method: "POST",
      }),
    }),
    editBillingInfo: build.mutation({
      query: (data) => ({
        url: "/api/billing/edit-billing-info",
        method: "POST",
        body: data,
      }),
    }),
    generateFullfillBillingDocument: build.mutation({
      query: (data) => ({
        url: "/api/billing/generate-fullfill-billing-document",
        method: "POST",
        body: data,
      }),
    }),
    blockUnblockOrganization: build.mutation({
      query: (data) => ({
        url: "/api/billing/block-unblock-organization",
        method: "POST",
        body: data,
      }),
    }),
    isOrganizationBlocked: build.mutation({
      query: (data) => ({
        url: "/api/billing/is-organization-blocked",
        method: "POST",
        body: data,
      }),
    }),
    changeOrgBalance: build.mutation({
      query: (data) => ({
        url: "/api/billing/change-organization-balance",
        method: "POST",
        body: data,
      }),
    }),
  }),
});



export const {
  useUpdateOptionDiscountMutation,
  useGetdateRangeTariffQuery,
  useGenerateFullfillBillingDocumentMutation,
  useBlockUnblockOrganizationMutation,
  useIsOrganizationBlockedMutation,
  useLazyGetBalanceQuery,
  useChangeOrgBalanceMutation,
  useGenerateCommonBillDocumentMutation,
  useMarkInvoiceAsExcludedMutation,
  useMarkInvoiceAsIncludedMutation,
  useLazyGetBillingInfoQuery,
  useAddPaymentMutation,
  useGetBillingInfoQuery,
  useEditBillingInfoMutation,
} = billingApi;
