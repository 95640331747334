import FireIcon from "../../../image/ObjectStatuses/Fire.svg";
import NormalIcon from "../../../image/ObjectStatuses/Normal.svg";
import NoLinkIcon from "../../../image/ObjectStatuses/NoLink.svg";
import MaintenanceIcon from "../../../image/ObjectStatuses/Maintenance.svg";
import AlertIcon from "../../../image/ObjectStatuses/Alert.svg";
import UndefinedIcon from "../../../image/ObjectStatuses/Undefined.svg";
import MalfunctionsIcon from "../../../image/ObjectStatuses/Malfunctions.svg";
import AutomationIcon from "../../../image/ObjectStatuses/Automation.svg";
import RemovalIcon from "../../../image/ObjectStatuses/Removal.svg";

export const INIT_VALUE_TAB = 0;
export const LAST_VALUE_TAB = 2;

export const messages = [
    { status: 0, message: "Undefined", color: "#e2e8f0", iconSrc: UndefinedIcon },
    { status: 1, message: "Alert", color: "red", iconSrc: AlertIcon },
    { status: 2, message: "Malfunctions", color: "yellow", iconSrc: MalfunctionsIcon },
    { status: 3, message: "Automation", color: "blue", iconSrc: AutomationIcon },
    { status: 4, message: "Maintenance", color: "#e2e8f0", iconSrc: MaintenanceIcon },
    { status: 5, message: "Normal", color: "#5BCF48", iconSrc: NormalIcon },
    { status: 6, message: "NoLink", color: "#e2e8f0", iconSrc: NoLinkIcon },
    { status: 7, message: "Fire", color: "red", iconSrc: FireIcon },
    { status: 8, message: "Removal", color: "blue", iconSrc: RemovalIcon },
  ];