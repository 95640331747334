import { getEquipmentsCoordinatesWithRoomId } from "../../../../helpers/helper";

export const getRequestEditData = (data) => {
  const {
    id,
    workingCorrectly,
    equipmentsCoordinates,
    roomAddresses = [],
    isActive,
    name,
    plume,
    integrationNumber,
    manufactureDate,
    installationDate,
  } = data || {};
  const { floorSchemeCoordinateX, floorSchemeCoordinateY, typeId } = equipmentsCoordinates[0] || {};

  const { roomAddressId = null } =
    getEquipmentsCoordinatesWithRoomId({
      equipmentsCoordinates,
      rooms: roomAddresses,
    })[0] || {};

  return {
    name,
    plume,
    integrationNumber: integrationNumber,
    manufactureDate: manufactureDate,
    installationDate: installationDate,
    id,
    workingCorrectly,
    equipmentCoordinateX: floorSchemeCoordinateX,
    equipmentCoordinateY: floorSchemeCoordinateY,
    roomAddressId,
    typeId,
    isActive,
  };
};

export const getKeyNameByKeyType = {
  1: "Вход\\Зона\\Шлейф",
  2: "Выход\\Реле",
  3: "Считыватель",
  4: "Прибор",
  5: "Пользователь",
  6: "Расширитель",
  7: "Раздел",
  8: "Неопределенный Элемент",
  9: "Линия Связи",
};
