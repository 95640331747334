import React from "react";
import Box from "@mui/material/Box";

import "./styles/style.css";
import { sections } from "./form-config";

import Header from "./Header";
import AccordionSection from "../../components/AccordionSection";
import PageheaderNav from "../../components/PageheaderNav";

const Acts = () => {

  const ITEMS_NAV = [
    { text: "plume_and_address", permissions: "facility_plan" },
    { text: "func_tests", permissions: "documentation" },
    { text: "documents", permissions: "documentation" },
    { text: "planning", permissions: "planning" },
  ];

  return (
    <Box className="acts-container">
      <Header nameForm="acts" />
      <PageheaderNav itemsNav={ITEMS_NAV} />
      <Box className="scrolled-form scrolled-acts_panel" sx={{mt: '25px'}}>
        {
          sections.map(({ id, title, Component }) => (
            <AccordionSection key={id} title={title}>
              {Component && (
                <Component section={title}  />
              )}
            </AccordionSection>
          ))
        }
      </Box>
    </Box>
  );
};

export default Acts;
