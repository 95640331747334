import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    permissions: []
};

export const permissionsSlice = createSlice({
  name: "permissions",
  initialState,
  reducers: {
    updatePermissions(state, action) {
      state.permissions = action.payload;
    },
  },
});

export const permissionsActions = permissionsSlice.actions;
export const permissionsReducer = permissionsSlice.reducer;
