import {fileEntityFields} from "./constants";

export const getRequestData = (data,isFullForm) => {
  
    const fileFields = ["workContract", "equipmentPassportFile"]
    const files = {}
    let placement = ""
    switch (data.placement) {
        case "Запотолочное пространство":
            placement = 0;
            break;
        case "Подпольное пространство":
            placement = 1;
            break;
        case "Основное помещение":
            placement = 2;
            break;
        default:
            placement = Number(data.placement);
            break;
    }
    fileFields.forEach(fieldName => {
        if(data[fieldName]) {
            files[fieldName] = data[fieldName];
        }
    })
    if(data.startNumber){
       data.number = data.startNumber
       delete data.startNumber
    }
    delete data.equipmentPassportFile;
    delete data.workContract;
    data.placement = placement
   if(isFullForm) data.fireCompartmentId = JSON.parse(data.fireCompartmentId).id;
   else {
    data.floorInfoId = data.floorMain
    delete data.fireCompartmentId
    delete data.floor
    delete data.floorMain
}
    data.equipmentTypeId = data.typeId = JSON.parse(data.equipmentTypeId)?.id;
    data.equipmentsCoordinates = data.equipmentsCoordinates ? data.equipmentsCoordinates
        .map(({id, workingCorrectly, floorSchemeCoordinateX, floorSchemeCoordinateY, floorInfoId}) => {
            const item = {
                floorInfoId,
                floorSchemeCoordinateX,
                floorSchemeCoordinateY,
            }

            if (!id.includes("new")) {
               item.id = id
            }

            if (workingCorrectly) {
                item.workingCorrectly = workingCorrectly
            }

            return item;
        })
        : []

    const untouchedEquipmentsCount = data.equipmentsCount - data.equipmentsCoordinates.length;
    if ( untouchedEquipmentsCount > 0) {
        const untouchedEquipmentsList = Array(untouchedEquipmentsCount).fill({floorInfoId: data.floor});
        data.equipmentsCoordinates.push(...untouchedEquipmentsList);
    }
    if (data.equipmentContractFileId) {
        const prevWorkContractData = JSON.parse(data.equipmentContractFileId)
        if (prevWorkContractData) {
            const {id, contractDate, contractNumber} = prevWorkContractData;
            data.equipmentContractFileId = id || "";
            data.contractDate = contractDate || null;
            data.contractNumber = contractNumber || 0;
        }
    } else {
        data.equipmentContractFileId = null;
    }

    const entities = {
      ...data,
      isEnableMultiPlume: !!data.isEnableMultiPlume,
      responsibleOrganizationId: data?.responsibleOrganizationId || null,
    }

    return { entities, files };
};

export const getRequestDataFilesForAdd = (entityId, entityType, files) => {
    const formData = new FormData();

    if(!files) {
        return formData;
    }

    let fileConfigIndex = 0
    Object.entries(files).filter(([, value]) => value)
        .forEach(([key, {filesToAdd}], index) => {
            if(filesToAdd?.length) {
                filesToAdd.forEach((file, index) => {
                    fileConfigIndex += index
                    formData.append(`entityFileList[${fileConfigIndex}].EntityId`, entityId);
                    formData.append(`entityFileList[${fileConfigIndex}].EntityType`, entityType);
                    formData.append(`entityFileList[${fileConfigIndex}].EntityField`, fileEntityFields[key]);
                    formData.append(`entityFileList[${fileConfigIndex}].File`, file);
                    formData.append(`entityFileList[${fileConfigIndex}].ActionType`, "Add");
                })
                fileConfigIndex++
            }
        })

    return formData;
};