import React, { useMemo, useEffect, useState } from "react";
import { contentFormConfig, contentFormConfigData, formConfigPlumesInformation } from "../../form-config";
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { getRequestData } from "../helpers";
import { getRequestDataFilesForAdd } from "../helpers";
import { useForm } from "react-hook-form";
import {
  useAddEquipmentExtMutation,
  useDeleteEquipmentMutation,
  useLazyGetEquipmentKindSelectElementsQuery,
  useValidateEquipmentNumbersMutation,
} from "../../../../../middleware/equipmentsApi";
import { useManipulateFilesMutation } from "../../../../../middleware/apiUploadFile";
import { useAction } from "../../../../../hooks/actions";
import FormItem from "../../../../components/FormItem";
import SelectSimple from "../../../../components/SelectSimple";
import FormItemCheckboxControlled from "../../../../components/FormItem/FormItemCheckboxControlled";
import { t } from "i18next";
import { SelectSimpleObject, SelectSimpleFloor } from "../../AddEquipmentLiteTree/Components";
import ManyPlumes from "../../../EquipmentDetails/ManyPlumes";
import { useLazyGetMessageCodeElementsQuery } from "../../../../../middleware/messageCodeApi";

const AddEquipmentModal = ({
  formFields,
  isActive,
  setselectedGroup,
  setselectedGroupId,
  selectedGroupId,
  selectedGroup,
  refreshTree,
  setselectedSystem,
  selectedSystem,
  selectedKind,
  setselectedKind,

  regulations,
  isSuccess,
  objectId,
  isOpen,
  setOpen,

  addNewEquipmentCountForTree,

  FloorId,
}) => {
  const { setIsErrorSubmit, setIsSuccessSubmit, setErrorNotificationParams } = useAction();
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
    register,
    unregister,
    setValue,
    watch,
    getValues,
    trigger,
    reset,
    resetField,
    clearErrors,
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    reset();
  }, [selectedKind]);

  const config = [
    {
      id: 1,
      name: formFields.objectMain,
      label: `label:${formFields.object}`,
      type: "select-text",
      rules: {},
      defaultValue: objectId,
      width: "49%",
      sx: { padding: "0px" },
      isCanDisabled: true,
      input: (props) => <SelectSimpleObject {...props} />,
    },

    {
      id: 2,
      name: formFields.floorMain,
      label: `label:${formFields.floor}`,
      type: "select-number",
      rules: {},
      defaultValue: FloorId,
      width: "49%",
      sx: { padding: "0px" },
      isCanDisabled: true,
      input: (props) => <SelectSimpleFloor {...props} />,
    },
  ];

  const [searchObject, setsearchObject] = useState("");
  const [equipTypesitems, setequipTypesitems] = useState([]);
  const [equipTypesnames, setequipTypesnames] = useState([]);
  const [isErrorMultiPlume, setIsErrorMultiPlume] = useState(false);

  const [manipulateFiles, { error: errorFile, isSuccess: isSuccessFile, isLoading: isLoadingFiles }] =
    useManipulateFilesMutation();
  const [validateEquipmentNumbers, { error: validateError, data: isSuccessValidate }] =
    useValidateEquipmentNumbersMutation();
  const [addEquipmentExt, { error: errorAdd, isSuccess: isSuccessAdd, isLoading: isLoadingAddEquipment }] =
    useAddEquipmentExtMutation();
  const [getMessageCodeElements, { error: errorGetMessageElem /* isLoading */ }] = useLazyGetMessageCodeElementsQuery();

  const [deleteEquipment] = useDeleteEquipmentMutation();
  const [GetEquipmentKindSelectElements] = useLazyGetEquipmentKindSelectElementsQuery();

  const isError = errorAdd || errorFile || isErrorMultiPlume;
  const isLoadingRequest = isLoadingAddEquipment || isLoadingFiles;
  const Equipmentype = watch(formFields.equipmentTypeId);
  const hasEquipmentGroup = watch(formFields.hasEquipmentGroup);
  const equipmentTypeId = watch(formFields.equipmentTypeId);
  const isEnableMultiPlume = watch(formFields.isEnableMultiPlume);

  useEffect(() => {
    if(!Equipmentype) return
    const isEnableMultiPlume = JSON.parse(Equipmentype).isEnableMultiPlume;
    setValue(formFields.isEnableMultiPlume, isEnableMultiPlume);
  }, [Equipmentype]);

  useEffect(() => {
    if (selectedKind != null && equipTypesitems.length > 0) {
      const foundObject = equipTypesitems.find((obj) => obj.id == selectedKind);
      const foundValue = foundObject ? foundObject.items.find((obj) => obj.id == selectedSystem) : null;
      console.log("Equipmentype", foundValue);
      setValue(
        formFields.equipmentTypeId,
        JSON.stringify({
          parentId: selectedKind,
          name: `${foundObject.name},${foundValue?.name}`,
          id: selectedSystem,
          hasIntegration: foundValue?.hasIntegration,
        })
      );
    }
  }, [equipTypesitems]);

  useEffect(() => {
    setValue(formFields.objectMain, objectId);
  }, [objectId]);

  useEffect(() => {
    setValue(formFields.floorMain, FloorId);
  }, [FloorId]);

  const addEquipmentFormItems = useMemo(
    () =>
      contentFormConfig(
        isActive,
        regulations,
        isSuccess,
        objectId,
        setValue,
        getValues,
        resetField,
        setError,
        errors,
        clearErrors,
        isEnableMultiPlume
      )[0],
    [isActive, regulations, isSuccess, objectId, selectedGroup, getValues, setValue, resetField, isEnableMultiPlume]
  );

  const addEquipmentFormItemsData = useMemo(
    () => contentFormConfigData(isSuccess)[0],
    [isActive, regulations, isSuccess, objectId, selectedGroup, getValues, setValue, resetField]
  );

  const addEquip = (data) => {
    const { entities, files } = getRequestData(data, false);
    const newEntities = {
      ...entities,
      equipmentsCoordinates: [],
    };
    addEquipmentExt(newEntities).then(({ data, error }) => {
      if (!error) {
        const resData = data;
        if (!resData.isNew) {
          addNewEquipmentCountForTree({
            id: resData.equipmentGroup || resData.id,
            count: +newEntities.equipmentsCount,
          });
        }

        const entityId = resData.equipmentIds.length > 1 ? resData.id : resData.equipmentIds[0];
        const entityType = resData.equipmentIds.length > 1 ? "EquipmentGroup" : "Equipment";
        const requestDataFiles = getRequestDataFilesForAdd(entityId, entityType, files);
        manipulateFiles(requestDataFiles).then(() => {
          if (error) {
            const allItemsIds = resData.map(({ id }) => id);
            for (const itemId of allItemsIds) {
              deleteEquipment(itemId);
            }
          }
          setOpen(false);
          reset();
          if (selectedGroupId != null) {
            refreshTree(false, false, [
              `kind_${selectedSystem}_system_${selectedKind}`,
              `group_${selectedGroupId}_kind_${selectedSystem}_system_${selectedKind}`,
            ]);
          } else if (selectedSystem != null && selectedGroupId == null)
            refreshTree(false, false, [`kind_${selectedSystem}_system_${selectedKind}`]);
          else if (selectedSystem == null && selectedKind != null && selectedGroup == null) refreshTree(true, false);

          resetField(formFields.objectMain);
          resetField(formFields.floorMain);

          setselectedKind(null);
          setselectedGroup(null);
          setselectedGroupId(null);
          setselectedSystem(null);
        });
      }
    });
  };

  const onSubmit = async (data) => {
    let isErrorUniq = false;
    const addEquipdata = {
      ...data,
    };
    const equipmentsCountValue = getValues(formFields.equipmentsCount);
    if (getValues(formFields.hasEquipmentGroup)) {
      validateEquipmentNumbers({
        number: getValues(formFields.startNumber),
        EquipmentsCount: Number(getValues(formFields.equipmentsCount) || 1),
        ProtectionObjectId: objectId,
      }).then((data) => {
        if (data?.error) {
          isErrorUniq = true;
          setError("root.serverError", {
            typeMulti: data?.error.data.StatusCode,
            messageMulti: data?.error.data.Message,
          });
        } else {
          addEquip(addEquipdata);
        }
      });
    } else {
      if (!equipmentsCountValue) {
        setValue(formFields.equipmentsCount, 1);
      }
      validateEquipmentNumbers({
        number: getValues(formFields.number),
        protectionObjectId: objectId,
      }).then((data) => {
        if (data?.error) {
          isErrorUniq = true;
          setError("root.serverError", {
            type: data?.error.data.StatusCode,
            message: data?.error.data.Message,
          });
        } else {
          addEquip(addEquipdata);
        }
      });
    }
  };

  useEffect(() => {
    setIsErrorSubmit(isError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    setIsSuccessSubmit(isSuccessAdd || isSuccessFile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessAdd, isSuccessFile]);

  const handleClose = () => {
    reset();
    resetField(formFields.objectMain);
    resetField(formFields.floorMain);
    setOpen(false);
    setselectedKind(null);
    setselectedSystem(null);
  };

  const renderFormItem = () => {};

  useEffect(() => {
    getMessageCodeElements();
  }, [getMessageCodeElements]);

  return (
    <Dialog
      sx={{ padding: 10, width: "100%", alignItems: "center", maxWidth: "100%" }}
      fullWidth
      maxWidth="lg"
      className="modal"
      open={isOpen}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <DialogTitle>Добавить оборудование</DialogTitle>
        <DialogTitle className="modal-title-right">
          <IconButton onClick={() => handleClose()}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
      </Box>
      <DialogContent sx={{ padding: "20px 24px 0" }}>
        <form id="addEquipmentForm" onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              display: "flex",
              gap: "15px",
              mb: "12px",
            }}
          >
            {config.map(({ id, name, label, type, isCanDisabled, ...rest }) => (
              <FormItem
                key={id}
                name={name}
                control={control}
                label={label}
                type={type}
                disabled={true}
                searchObject={searchObject}
                setsearchObject={setsearchObject}
                input={(props) => <SelectSimple sx={{ marginRight: "20px" }} {...props} />}
                {...rest}
              />
            ))}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              columnGap: "15px",
            }}
          >
            {addEquipmentFormItems.items.map(
              ({ id, name, label, input, expended, flexBasis, CustomFormItem, ...rest }) =>
                name != "fireCompartmentId" &&
                (CustomFormItem ? (
                  <Box key={id} className="resizing_basic_forms">
                    <CustomFormItem
                      key={name || id}
                      control={control}
                      errors={errors}
                      errorTyp={errors}
                      register={register}
                      unregister={unregister}
                      setValue={setValue}
                      name={name}
                      label={label}
                      input={input}
                      expended={expended}
                      trigger={trigger}
                      {...rest}
                    />
                  </Box>
                ) : expended && rest.type === "checkbox" ? (
                  <FormItemCheckboxControlled
                    key={name}
                    control={control}
                    errors={errors}
                    errorTyp={errors}
                    register={register}
                    unregister={unregister}
                    setValue={setValue}
                    name={name}
                    label={label}
                    input={input}
                    expended={expended}
                    trigger={trigger}
                    {...rest}
                  />
                ) : (
                  ((!hasEquipmentGroup && name === "number") ||
                    ((equipmentTypeId ? JSON.parse(equipmentTypeId)?.hasIntegration : false) &&
                      (name === "plume" || name === "integrationNumber" || name === "isEnableMultiPlume")) ||
                    (name !== "number" &&
                      name !== "plume" &&
                      name !== "integrationNumber" &&
                      name !== "isEnableMultiPlume")) && (
                    <Box
                      key={id}
                      sx={{
                        flexBasis: flexBasis,
                      }}
                    >
                      <FormItem
                        key={name}
                        control={control}
                        errors={errors}
                        errorTyp={errors}
                        objectId={objectId}
                        selectedKind={selectedKind}
                        setequipTypesitems={setequipTypesitems}
                        setequipTypesnames={setequipTypesnames}
                        selectedSystem={selectedSystem}
                        selectedGroup={selectedGroup}
                        Equipmentype={Equipmentype}
                        islite={true}
                        name={name}
                        disabled={
                          name === "fireCompartmentId" ||
                          (selectedKind != null && selectedSystem != null && name === "equipmentTypeId") ||
                          (selectedGroup != null && name === "name")
                            ? true
                            : false
                        }
                        label={label}
                        input={input}
                        trigger={trigger}
                        {...rest}
                      />
                    </Box>
                  )
                ))
            )}
          </Box>

          {isEnableMultiPlume && (
            <ManyPlumes
              data={{ equipmentPlumes: [], protectionObjectId: objectId, keyType: 1 }}
              control={control}
              setValue={setValue}
              watch={watch}
              setIsErrorMultiPlume={setIsErrorMultiPlume}
            />
          )}

          <Box>
            {addEquipmentFormItemsData.items.map(
              ({ id, name, label, input, expended, flexBasis, CustomFormItem, ...rest }) =>
                CustomFormItem ? (
                  <Box key={id} className="resizing_basic_forms">
                    <CustomFormItem
                      key={name || id}
                      control={control}
                      errors={errors}
                      errorTyp={errors}
                      register={register}
                      unregister={unregister}
                      setValue={setValue}
                      name={name}
                      label={label}
                      input={input}
                      expended={expended}
                      trigger={trigger}
                      {...rest}
                    />
                  </Box>
                ) : (
                  <Box
                    key={id}
                    sx={{
                      flexBasis: flexBasis,
                    }}
                  >
                    <FormItem
                      key={name}
                      control={control}
                      errors={errors}
                      errorTyp={errors}
                      objectId={objectId}
                      selectedKind={selectedKind}
                      setequipTypesitems={setequipTypesitems}
                      setequipTypesnames={setequipTypesnames}
                      selectedSystem={selectedSystem}
                      selectedGroup={selectedGroup}
                      Equipmentype={Equipmentype}
                      islite={true}
                      name={name}
                      disabled={
                        name === "fireCompartmentId" ||
                        (selectedKind != null && selectedSystem != null && name === "equipmentTypeId") ||
                        (selectedGroup != null && name === "name")
                          ? true
                          : false
                      }
                      label={label}
                      input={input}
                      trigger={trigger}
                      {...rest}
                    />
                  </Box>
                )
            )}
          </Box>
        </form>
      </DialogContent>

      <Button
        form="addEquipmentForm"
        className="btn btn-primary-fill"
        sx={{
          width: "150px",
          alignSelf: "end",
          margin: "20px",
          marginRight: "40px",
        }}
        disabled={isLoadingRequest}
        type="submit"
      >
        {t("button:save")}
      </Button>
    </Dialog>
  );
};

export default AddEquipmentModal;
