export const chatBotApi = async (message) => {
  try {
    const response = await fetch('https://ml.dozor.agency/chat', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ message })
    });

    if (!response.ok) {
      throw new Error(`Ошибка сервера: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Ошибка при запросе к API:', error);
    throw error;
  }
};

