import React, { useState, useEffect } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import { useGetUserJobTitlesSelectQuery } from "../../../middleware/usersApi";
import SelectSimple from "../../components/SelectSimple";
import SelectSimpleInbuilt from "../../components/SelectSimpleInbuilt";
import { getUserJobTitlesOptions } from "./helpers";
import { useGetOrganizationTypesQuery } from "../../../middleware/apiOrganizations";
import { useGetEquipmentSystemsWithSearchQuery } from "../../../middleware/equipmentsApi";
import DefaultAvatarIcon from "../../components/icons/DefaultAvatarIcon";

const TooltipProps = {
  tooltip: {
    sx: {
      color: "var(--black)",
      backgroundColor: "var(--white)",
      borderRadius: 0,
      maxWidth: 300,
      fontSize: "14px",
      border: "1px solid #CFD9E8"
    },
  },
};

export const BlockAvatar = ({ avatarLink }) => {
  return (
    <Box className="avatar_block">
      {avatarLink ? <img src={avatarLink} alt="Аватар пользователя" /> : <DefaultAvatarIcon />}
    </Box>
  );
};
export const SelectSimpleEquipmentKinds = (props) => {
  const [searchValue, setSearchValue] = useState("");
  const { data, refetch } = useGetEquipmentSystemsWithSearchQuery({ name: searchValue });

  useEffect(() => {
    searchValue && refetch();
  }, [searchValue, refetch]);

  return (
    <SelectSimpleInbuilt
      {...props}
      setSearchValue={setSearchValue}
      searchValue={searchValue}
      options={data || []}
      name={props.name}
      setValue={props.setValue}
    />
  );
};
export const ClickableActionNameCell = ({ item }) => {
  const { name, isActive, action } = item || {};
  return (
    <Tooltip title={name} componentsProps={TooltipProps}>
      <Typography
      sx={{ color: isActive ? "var(--main)" : "red" }}
      className={name ? "table-row-cell-link" : ""}
      onClick={action ? action : () => {}}
    >
      {name || ""}
    </Typography>
    </Tooltip>
    
  );
};

export const ClickableActionListCell = ({ item }) => {
  return item?.map((element, index) => (
    <ClickableActionNameCell key={index} item={element} />
    
  ));
};

export const SimpleTextCell = ({ item }) => {
  return <Typography>{item}</Typography>;
};

export const SimpleTextCellShort = ({ item }) => {
  return (
    <Tooltip title={item} componentsProps={TooltipProps}>
      <Typography className="common-cell-text">{item}</Typography>
    </Tooltip>
  );
};

export const SimpleActiveTextCell = ({ item }) => {
  let color;
  switch (item) {
    case "Активен":
      color = "#396c4b";
      break;
    case "Активен/требуется подтверждение":
      color = "#0070c0";
      break;
    case "Заблокирован":
      color = "#ff0000";
      break;
    default:
      color = "#00b050";
      break;
  }
  return <Typography sx={{ color: color }}>{item}</Typography>;
};

export const SimpleBlockTextCell = ({ item }) => {
  const blockText = item.split(", ");
  return blockText?.map((text, i) => (
    <Typography key={i} sx={{ lineHeight: "17px !important", marginBottom: "10px" }}>
      {text || ""}
    </Typography>
  ));
};

export const SelectSimpleJobTitles = (props) => {
  const { data: jobTitlesSelectOptions = [] } = useGetUserJobTitlesSelectQuery();

  return <SelectSimple {...props} options={getUserJobTitlesOptions(jobTitlesSelectOptions)} />;
};

export const SelectOrganizationTypes = (props) => {
  const { data: organizationTypes = [] } = useGetOrganizationTypesQuery();

  return <SelectSimple {...props} options={getUserJobTitlesOptions(organizationTypes)} />;
};
