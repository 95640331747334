import React from "react";

const MagazinesIcon = () => {
  return (
    <svg width="24" height="30" viewBox="0 0 24 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.5 27.0929C17.5 27.3866 17.4349 27.6766 17.3095 27.9399C17.1839 28.2031 17.0018 28.4327 16.7769 28.6105C16.5522 28.7885 16.2909 28.91 16.0134 28.9656C15.7361 29.0211 15.4501 29.0093 15.1778 28.931L2.34445 25.2229C1.95869 25.112 1.61844 24.8726 1.37569 24.5417C1.13293 24.2107 1.00098 23.8062 1 23.3899V2.90719C0.999939 2.61337 1.06517 2.32351 1.19059 2.06023C1.31602 1.79696 1.49824 1.56739 1.72304 1.38944C1.94785 1.2115 2.20915 1.09 2.48655 1.03442C2.76395 0.978846 3.04995 0.990695 3.32222 1.06905L16.1556 5.37965C16.5419 5.49083 16.8827 5.73067 17.1254 6.06244C17.3683 6.39419 17.4999 6.79957 17.5 7.21652V27.0929Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.8335 1.00049H21.1668C21.653 1.00049 22.1194 1.20139 22.4633 1.55897C22.8071 1.91656 23.0002 2.40157 23.0002 2.90727V22.9286C23.0002 23.4342 22.8071 23.9192 22.4633 24.2768C22.1194 24.6344 21.653 24.8354 21.1668 24.8354H17.5002"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MagazinesIcon;
