import React, { useEffect, useMemo, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useForm, useWatch } from "react-hook-form";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import { formFields, getConfigFormModalSelect } from "./form-config";
import { useAppSelector } from "../../../hooks/redux";
import { useAction } from "../../../hooks/actions";
import useScreenSize from "../../../hooks/useScreenSize";
import { useLazyPrintEventLogsListQuery, useLazyGetSystemLogListQuery } from "../../../middleware/logsApi";
import { EmployeeDialog } from "../EmployeeList/EmployeeDialog";
import { getColumns } from "./columns";
import TableBasic from "../../components/TableBasic";
import { ModalSelectColumnsServer } from "../../components/Modals/ModalSelectColumnsServer";
import { useGetUserGridSettingQuery, useLazyGetUserGridSettingQuery } from "../../../middleware/usersApi";
import PageheaderNav from "../../components/PageheaderNav";
import { dateFormatForSubmit } from "../../../constants";
import { getTableHieght } from "../../../helpers/getTableHieght";
import ObjectDialog from "../ObjectsList/ObjectDialog";
import EquipmentDialog from "../EquipmentList/EquipDialog";
import TaskDialog from "../TasksList/TaskDialog/TaskDialog";
import MyOrganizationDetails from "../MyOrganizationDetails";
import "./styles/style.css";

export const MagazineSystemEvents = () => {
  const { t } = useTranslation();

  const { control, setValue, getValues, watch, reset, resetField } = useForm({
    mode: "onChange",
  });

  const params = useWatch({
    control,
    name: formFields.searchParams,
  });

  const ProtectionObjectId = watch(`${formFields.searchParams}.${formFields.ProtectionObjectId}`);
  const action = watch(`${formFields.searchParams}.${formFields.action}`);
  const EntityTypeId = watch(`${formFields.searchParams}.${formFields.EntityTypeId}`);
  const EntityFieldId = watch(`${formFields.searchParams}.${formFields.EntityFieldId}`);
  const equipmentKindName = watch(`${formFields.searchParams}.${formFields.equipmentKindName}`);
  const screenSize = useScreenSize();

  const { searchFullNameStorage, dateRangeStorage, pageNumberStorage, searchKeyFieldStorage } =
    JSON.parse(sessionStorage.getItem("magazineSystemEventsListSearchParams")) || {};

  const { systemLogs } = useAppSelector((state) => state.logs);
  const { setIsErrorRequest } = useAction();

  const [searchName, setsearchName] = useState(searchFullNameStorage || "");
  const [searchKeyField, setSearchKeyField] = useState(searchKeyFieldStorage || "");
  const [dateRange, setDateRange] = useState(dateRangeStorage || [null, null]);
  const [openSelectModal, setOpenSelectModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [printloading, setPrintLoading] = useState(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(false);
  const [openEmployeeDialog, setOpenEmployeeDialog] = useState(false);
  const [openEquipDialog, setOpenEquipDialog] = useState(false);
  const [openObjectDialog, setOpenObjectDialog] = useState(false);
  const [openTaskDialog, setOpenTaskDialog] = useState(false);
  const [openOrganizationDialog, setOpenOrganizationDialog] = useState(false);
  const [selectedEquipId, setSelectedEquipId] = useState(null);
  const [selectedObjectId, setSelectedObjectId] = useState(null);
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [selectedOrganizationId, setSelectedOrganizationId] = useState(null);
  const [pageNumber, changePageNumber] = useState(pageNumberStorage || 0);

  const {
    data: gridcolumns,
    // error: errorgridColumns,
    // isLoading: isLoadingerrorColumns,
  } = useGetUserGridSettingQuery({ gridSettingName: "EventSystemJournal" });

  const [
    getUserGrid,
    // { error: errorgrid, refetch: refetchUserGrid /* isLoading */ },
  ] = useLazyGetUserGridSettingQuery();
  const [getSystemLogsList, { error, isLoading }] = useLazyGetSystemLogListQuery();
  const [printLogsList, { errorPrint, isLoadingPrint }] = useLazyPrintEventLogsListQuery();

  function handlerOnClickOnLinkInField(itemId, linkType) {
    switch (linkType) {
      case 0:
        break;

      case 1:
        setSelectedObjectId(itemId);
        setOpenObjectDialog(true);
        break;

      case 2:
        setSelectedOrganizationId(itemId);
        setOpenOrganizationDialog(true);
        break;

      case 3:
        setSelectedEquipId(itemId);
        setOpenEquipDialog(true);
        break;

      case 4:
        setSelectedEmployeeId(itemId);
        setOpenEmployeeDialog(true);
        break;

      case 5:
        setSelectedTaskId(itemId);
        setOpenTaskDialog(true);
        break;

      default:
        break;
    }
  }

  function writeSearchDataToLocalStorage(newSearchParam, searchValue) {
    const getStorage = JSON.parse(sessionStorage.getItem("magazineSystemEventsListSearchParams")) || {};
    const searchParamsForLocalStorage = {
      ...getStorage,
      [newSearchParam]: searchValue,
    };
    sessionStorage.setItem("magazineSystemEventsListSearchParams", JSON.stringify(searchParamsForLocalStorage));
  }

  function savePageNumberToSessionStorage(currentPageNumber) {
    const getStorage = JSON.parse(sessionStorage.getItem("magazineFinishedEventsListSearchParams")) || {};
    const searchParamsForLocalStorage = {
      ...getStorage,
      pageNumberStorage: currentPageNumber,
    };
    sessionStorage.setItem("magazineFinishedEventsListSearchParams", JSON.stringify(searchParamsForLocalStorage));
  }

  function clearFiltersHandler() {
    setsearchName("");
    setDateRange([null, null]);
    changePageNumber(0);
    reset();
    sessionStorage.removeItem("magazineSystemEventsListSearchParams");
  }

  const rows = useMemo(() => {
    const { data } = systemLogs || {};

    return data?.length
      ? data.map(
          ({
            actionName,
            originalValue,
            fieldName,
            currentValue,
            userId,
            protectionObjectName,
            protectionObjectId,
            dateTime,
            entityTypeName,
            keyFields,
            userName,
            entityFieldId,
            equipmentKindName,
          }) => {
            return {
              dateRange: dateTime,
              userName: {
                name: userName,
                action: () => userId && handleOpenEmployeeDialogState(userId),
              },
              facilityName: {
                name: protectionObjectName,
                action: () => handleOpenObjectDialogState(protectionObjectId),
              },
              actionName,
              entityName: entityTypeName,
              fieldName,
              equipmentKindName,
              keyFields: {
                fields: keyFields,
                action: handlerOnClickOnLinkInField,
              },
              changedFields: {
                fields: [{ ...originalValue, linkType: originalValue.linkType }],
                action: handlerOnClickOnLinkInField,
              },
              currentFields: {
                fields: [{ ...currentValue, linkType: currentValue.linkType }],
                action: handlerOnClickOnLinkInField,
              },
            };
          }
        )
      : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemLogs]);

  const columnsFromStorage = (() => {
    const storageData = gridcolumns?.columnSettings || [];
    return storageData?.reduce((acc, { name, visible }) => ({ ...acc, [name]: visible }), {});
  })();

  const columns = useMemo(
    () =>
      getColumns({
        control,
        dateRange,
        setDateRange,
        setsearchName,
        searchName,
        columnsFromStorage,
        writeSearchDataToLocalStorage,
        setValue,
        searchKeyField,
        setSearchKeyField,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnsFromStorage]
  );

  const recordsCount = useMemo(() => systemLogs && systemLogs?.recordsCount, [systemLogs]);

  const handleOpenSelectColumnsModal = () => {
    setOpenSelectModal(true);
  };

  const handleOpenEmployeeDialogState = (id) => {
    if (!openEmployeeDialog) {
      setOpenEmployeeDialog(true);
      setSelectedEmployeeId(id);
      return;
    }
    setOpenEmployeeDialog(false);
    setSelectedEmployeeId(null);
  };

  const handleOpenObjectDialogState = (objectId) => {
    if (!openObjectDialog) {
      setOpenObjectDialog(true);
      setSelectedObjectId(objectId);
      return;
    }
    setOpenObjectDialog(false);
    setSelectedObjectId(null);
  };

  useEffect(() => {
    setValue(`${formFields.searchParams}.${formFields.employeeFullName}`, searchName);
  }, [searchName, setValue]);

  useEffect(() => {
    const {
      ProtectionObjectIdStorage,
      actionStorage,
      EntityTypeIdStorage,
      EntityFieldIdStorage,
      equipmentKindNameStorage,
    } = JSON.parse(sessionStorage.getItem("magazineSystemEventsListSearchParams")) || {};
    console.log("storage ", sessionStorage.getItem("magazineSystemEventsListSearchParams"));
    setValue(`${formFields.searchParams}.${formFields.ProtectionObjectId}`, ProtectionObjectIdStorage || "");
    setValue(`${formFields.searchParams}.${formFields.action}`, actionStorage || "");
    setValue(`${formFields.searchParams}.${formFields.EntityTypeId}`, EntityTypeIdStorage || "");
    setValue(`${formFields.searchParams}.${formFields.EntityFieldId}`, EntityFieldIdStorage || "");
    setValue(`${formFields.searchParams}.${formFields.equipmentKindName}`, equipmentKindNameStorage || "");
  }, []);

  useEffect(() => {
    writeSearchDataToLocalStorage("equipmentKindNameStorage", equipmentKindName);
  }, [equipmentKindName]);

  useEffect(() => {
    writeSearchDataToLocalStorage("ProtectionObjectIdStorage", ProtectionObjectId);
  }, [ProtectionObjectId]);

  useEffect(() => {
    writeSearchDataToLocalStorage("actionStorage", action);
  }, [action]);

  useEffect(() => {
    writeSearchDataToLocalStorage("EntityTypeIdStorage", EntityTypeId);
  }, [EntityTypeId]);

  useEffect(() => {
    writeSearchDataToLocalStorage("EntityFieldIdStorage", EntityFieldId);
  }, [EntityFieldId]);

  useEffect(() => {
    writeSearchDataToLocalStorage("dateRangeStorage", dateRange);
    setValue(`${formFields.searchParams}.dateRange`, dateRange);
  }, [dateRange, setValue]);

  useEffect(() => {
    writeSearchDataToLocalStorage("searchFullNameStorage", searchName);
    setValue(`${formFields.searchParams}.userName`, searchName);
  }, [searchName, setValue]);

  useEffect(() => {
    setValue(`${formFields.searchParams}.${formFields.keyDisp}`, searchKeyField);
  }, [searchKeyField, setValue]);

  useEffect(() => {
    const createdDate = getValues(`${formFields.searchParams}.dateRange`);
    delete params?.dateRange;
    let dd = {};
    if (createdDate) {
      if (createdDate[1] != null) dd["dateRange.MaxValue"] = dayjs(createdDate[1]).format(dateFormatForSubmit);
      if (createdDate[0] != null) dd["dateRange.MinValue"] = dayjs(createdDate[0]).format(dateFormatForSubmit);
    }

    changePageNumber(0);
    getSystemLogsList({
      ...dd,
      ...params,
      equipmentKindId: equipmentKindName || "",
      pageNumber: 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    const createdDate = getValues(`${formFields.searchParams}.dateRange`);
    delete params?.dateRange;

    let dd = {};
    if (createdDate) {
      if (createdDate[1] != null) dd["dateRange.MaxValue"] = dayjs(createdDate[1]).format(dateFormatForSubmit);
      if (createdDate[0] != null) dd["dateRange.MinValue"] = dayjs(createdDate[0]).format(dateFormatForSubmit);
    }

    getSystemLogsList({
      ...dd,
      ...params,
      equipmentKindId: equipmentKindName || "",
      pageNumber,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  useEffect(() => {
    setIsErrorRequest(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  // const printLogs = (isPrint) => {
  //   if (isPrint) setPrintLoading(true);
  //   else setLoading(true);
  //   const createdDate = getValues(`${formFields.searchParams}.ReactionDateRange`);
  //   const facilId = getValues(`${formFields.searchParams}.${formFields.ProtectionObjectId}`);
  //   const actionId = action == "все" || !action ? { action: "" } : { action: action };
  //   const facility = facilId == "все" || !facilId ? { ProtectionObjectId: "" } : { ProtectionObjectId: facilId };
  //   delete params?.dateRange;
  //   delete params?.ProtectionObjectId;
  //   delete params?.action;
  //   let dd = {};
  //   if (createdDate) {
  //     if (createdDate[1] != null) dd["ReactionDateRange.MaxValue"] = dayjs(createdDate[1]).format(dateFormatForSubmit);
  //     if (createdDate[0] != null) dd["ReactionDateRange.MinValue"] = dayjs(createdDate[0]).format(dateFormatForSubmit);
  //   }
  //   printLogsList({
  //     ...dd,
  //     ...actionId,
  //     ...facility,
  //     ...params,
  //     pageNumber,
  //   }).then((data) => {
  //     const { name, content } = data.data;
  //     if (isPrint) printBase64(content, setPrintLoading);
  //     else base64toPDF(name, content, setPrintLoading);
  //   });
  // };
  const ITEMS_NAV = [
    { text: "magazines_events", permissions: "logs" },
    { text: "magazines_finished_tasks", permissions: "logs" },
    { text: "magazines_system_events", permissions: "logs" },
  ];

  return (
    <Box className="template_page magazine_events">
      <Typography className="title-page">{t("title_page:magazines")}</Typography>
      <Box className="template_page-header">
        <PageheaderNav itemsNav={ITEMS_NAV} />
        <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
          <Button className="btn btn-primary-fill" onClick={clearFiltersHandler}>
            Очистить все фильтры
          </Button>
        </Box>
        {/* <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
          {printloading ? (
            <Box className="full_screen_icon-wrapper">
              <CircularProgress
                className="full_screen_icon"
                sx={{
                  width: "30px",
                  height: "30px",
                  marginTop: "5px",
                }}
              />
            </Box>
          ) : (
            <Box className="full_screen_icon-wrapper">
              <div
                onClick={() => printLogs(true)}
                style={{
                  marginTop: "5px",
                  border: "0px !important",
                }}
                className="full_screen_icon"
              >
                <PrintIcon />
              </div>
            </Box>
          )}
          <Button
            className="btn btn-primary-fill"
            onClick={clearFiltersHandler}
          >
            Очистить все фильтры
          </Button>
          <Button
            className="btn btn-primary-fill magazine_events-select_container-btn"
            variant="outlined"
            onClick={() => printLogs(false)}
            disabled={loading}
            sx={{ height: "49px" }}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              <>
                <Box
                  component="img"
                  src={exporticon}
                  sx={{ marginRight: "16px" }}
                />
                {t("button:create_act_pdf")}
              </>
            )}
          </Button>
          <Button
            className="btn btn-primary-fill magazine_events-select_container-btn"
            variant="outlined"
            onClick={handleOpenSelectColumnsModal}
            sx={{ height: "52px" }}
          >
            <Box
              component="img"
              src={settingsicon}
              sx={{ marginRight: "16px" }}
            />
            {t("button:customize_fields_to_display")}
          </Button>
        </Box> */}
      </Box>
      {/* <Box className="magazine_events-select_container">
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.eventsFilter}`}
            label={t("label:events")}
            type={"select-text"}
            options={eventsOptions}
            input={(props) => (
              <Box className="medium_container">
                <SelectSimple {...props} />
              </Box>
            )}
          />
        </Box>
            */}
      <TableBasic
        columns={columns}
        rows={rows}
        handleClickCell={() => {}}
        withSearchField={false}
        isLoading={isLoading}
        pageNumber={pageNumber}
        changePageNumber={changePageNumber}
        count={recordsCount}
        clearFiltersHandler={clearFiltersHandler}
        minHeight={getTableHieght(screenSize).min}
        maxHeight={getTableHieght(screenSize).max}
        savePageNumberToSessionStorage={savePageNumberToSessionStorage}
      />
      <ModalSelectColumnsServer
        open={openSelectModal}
        setOpen={setOpenSelectModal}
        data={columnsFromStorage}
        refetch={() => getUserGrid({ gridSettingName: "EventSystemJournal" })}
        nameStorage={"EventSystemJournal"}
        config={getConfigFormModalSelect(gridcolumns?.columnSettings || [])}
      />

      {openEmployeeDialog && (
        <EmployeeDialog
          open={openEmployeeDialog}
          onClose={handleOpenEmployeeDialogState}
          employeeId={selectedEmployeeId}
        />
      )}

      {openObjectDialog && (
        <ObjectDialog open={openObjectDialog} onClose={handleOpenObjectDialogState} objectId={selectedObjectId} />
      )}

      {openEquipDialog && (
        <EquipmentDialog
          open={openEquipDialog}
          setOpen={setOpenEquipDialog}
          equipId={selectedEquipId}
          refetch={() => {}}
        />
      )}

      {openTaskDialog && (
        <TaskDialog
          open={openTaskDialog}
          setOpen={setOpenTaskDialog}
          taskId={selectedTaskId}
          refetchTaskList={() => {}}
        />
      )}

      {openOrganizationDialog && (
        <MyOrganizationDetails
          open={openOrganizationDialog}
          setOpen={setOpenOrganizationDialog}
          id={selectedOrganizationId}
        />
      )}
    </Box>
  );
};
