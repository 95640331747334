import React from "react";
import { t } from "i18next";
import { Box } from "@mui/material";
import { formFields } from "./form-config";
import { SearchInbuilt } from "../../components/SearchInbuilt";
import FormItem from "../../components/FormItem";
import { SelectSimpleObject } from "../EquipmentList/Components";
import DateRangePickerNew from "../../components/DateRangePickerNew/DateRangePickerNew";
import {
  ClickableActionNameCell,
  SimpleTextCell,
  KeyFieldsList,
  SelectAction,
  SelectEntityField,
  SelectEntity,
  DateFormatCell,
} from "./Components";
import { SelectSimpleEquipmentKinds } from "../EmployeeList/Components";

export const getColumns = ({
  control,
  dateRange,
  setDateRange,
  setsearchName,
  searchName,
  searchKeyField,
  setSearchKeyField,
  writeSearchDataToLocalStorage,
  setValue,
}) => {
  return [
    {
      id: formFields.dateRange,
      numeric: false,
      enableSorting: false,
      size: 200,
      clickable: false,
      isVisible: true, //columnsFromStorage[formFields.reportTime],
      RowCell: (props) => <DateFormatCell {...props} type="dateTime" />,
      HeaderCell: () => (
        <div
          style={{
            width: "277px",
          }}
        >
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.dateRange}`}
            sx={{
              padding: "9px 0 9px 16px",
              width: "277px",
            }}
            input={(props) => (
              <DateRangePickerNew
                placeholder={t("placeholder:dateRange")}
                setdaterange={setDateRange}
                daterange={dateRange}
                {...props}
              />
            )}
          />
        </div>
      ),
    },
    {
      id: formFields.facilityName,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:facilityName"),
      size: "164px",
      clickable: false,
      RowCell: (props) => <ClickableActionNameCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          label={t("label:object")}
          name={`${formFields.searchParams}.${formFields.ProtectionObjectId}`}
          sx={{
            padding: "13px 0 13px 16px",
          }}
          input={(props) => (
            <SelectSimpleObject
              {...props}
              IsTableheader={true}
              className="select_in_built small-light"
              name={`${formFields.searchParams}.${formFields.ProtectionObjectId}`}
              setValue={setValue}
              sx={{ width: "100%" }}
            />
          )}
        />
      ),
      isVisible: true, //columnsFromStorage[formFields.facilityName],
    },
    {
      id: formFields.equipmentKindName,
      numeric: false,
      enableSorting: false,
      size: 200,
      clickable: false,
      isVisible: true, //columnsFromStorage[formFields.equipmentKindName],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.${formFields.equipmentKindName}`}
          sx={{ padding: "13px 0 13px 16px", width: "100%" }}
          label={t("label:equipKinds")}
          input={(props) => (
            <SelectSimpleEquipmentKinds
              {...props}
              isTableheader={true}
              className="select_in_built small-light"
              name={`${formFields.searchParams}.${formFields.equipmentKindName}`}
              setValue={setValue}
            />
          )}
        />
      ),
    },
    {
      id: formFields.userName,
      numeric: false,
      enableSorting: false,
      label: t("label:FIO_employee"),
      size: "270px",
      isVisible: true, //columnsFromStorage[formFields.employeeFullName],
      RowCell: (props) => <ClickableActionNameCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.${formFields.userName}`}
          sx={{
            borderLeft: "none",
            width: "100%",
            borderRight: "none",
          }}
          input={(props) => (
            <SearchInbuilt
              placeholder={t("label:fullName")}
              className="select_in_built small-light"
              searchValue={searchName}
              changeSearchValue={setsearchName}
              writeSearchDataToLocalStorage={writeSearchDataToLocalStorage}
              storageValue="searchFullNameStorage"
              {...props}
            />
          )}
        />
      ),
    },

    {
      id: formFields.actionName,
      numeric: false,
      enableSorting: false,
      size: "150px",
      clickable: false,
      isVisible: true, //columnsFromStorage[formFields.reportingPlace],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.${formFields.action}`}
          label={t("label:events")}
          input={(props) => (
            <Box className="medium_container">
              <SelectAction
                className="select_in_built small-light"
                writeSearchDataToLocalStorage={writeSearchDataToLocalStorage}
                storageValue="actionStorage"
                storageName="magazineSystemEventsListSearchParams"
                {...props}
              />
            </Box>
          )}
        />
      ),
    },
    {
      id: formFields.entityName,
      numeric: false,
      enableSorting: false,
      size: "260px",
      clickable: false,
      isVisible: true, //columnsFromStorage[formFields.triggerCause],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.${formFields.EntityTypeId}`}
          placeholder={t("tableHeader:objectType")}
          type={"select-text"}
          sx={{
            padding: "9px 0 9px 16px",
            width: "260px",
          }}
          input={(props) => (
            <SelectEntity
              {...props}
              setValue={setValue}
              name={`${formFields.searchParams}.${formFields.EntityTypeId}`}
              className="select_in_built small-light"
            />
          )}
        />
      ),
    },
    {
      id: formFields.keyFields,
      numeric: false,
      enableSorting: false,
      label: t("label:FIO_employee"),
      size: "250px",
      sx: { padding: "0 !important" },
      isVisible: true, //columnsFromStorage[formFields.employeeFullName],
      RowCell: (props) => <KeyFieldsList {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.${formFields.keyDisp}`}
          sx={{
            borderLeft: "none",
            width: "100%",
            borderRight: "none",
          }}
          input={(props) => (
            <SearchInbuilt
              placeholder={t("tableHeader:keyFields")}
              className="select_in_built small-light"
              searchValue={searchKeyField}
              changeSearchValue={setSearchKeyField}
              writeSearchDataToLocalStorage={writeSearchDataToLocalStorage}
              storageValue="searchKeyFieldStorage"
              {...props}
            />
          )}
        />
      ),
    },
    {
      id: formFields.fieldName,
      numeric: false,
      enableSorting: false,
      label: t("label:FIO_employee"),
      size: "150px",
      isVisible: true, //columnsFromStorage[formFields.employeeFullName],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.${formFields.EntityFieldId}`}
          placeholder={t("tableHeader:changedFields")}
          type={"select-text"}
          sx={{
            padding: "9px 0 9px 16px",
            width: "260px",
          }}
          input={(props) => <SelectEntityField {...props} className="select_in_built small-light" />}
        />
      ),
    },
    {
      id: formFields.changedFields,
      numeric: false,
      enableSorting: false,
      label: t("label:FIO_employee"),
      size: "250px",
      isVisible: true, //columnsFromStorage[formFields.employeeFullName],
      RowCell: (props) => <KeyFieldsList {...props} />,
      HeaderCell: () => (
        <div
          style={{
            color: "var(--headers)",
            fontSize: "16px",
            borderLeft: "none",
            textAlign: "center",
          }}
        >
          {t("tableHeader:originalFields")}
        </div>
      ),
    },
    {
      id: formFields.currentFields,
      numeric: false,
      enableSorting: false,
      label: t("label:FIO_employee"),
      size: "250px",
      isVisible: true, //columnsFromStorage[formFields.employeeFullName],
      RowCell: (props) => <KeyFieldsList {...props} />,
      HeaderCell: () => (
        <div
          style={{
            color: "var(--headers)",
            fontSize: "16px",
            borderLeft: "none",
            textAlign: "center",
          }}
        >
          {t("tableHeader:currentFields")}
        </div>
      ),
    },
  ].filter(({ isVisible }) => isVisible);
};
