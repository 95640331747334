import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { PATHS } from "../../../constants";
import { useAction } from "../../../hooks/actions";
import plusicon from "../../../image/icons/plusBtnWhite.png";
import EditBtnIcon from "../../components/icons/EditBtnIcon";
import { useAppSelector } from "../../../hooks/redux";
import { ModalConfirmDialog } from "./EditDiscountDialog";

const Objects = ({ objects, organization }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openEditDiscountModal, setOpenEditDiscountModal] = useState(false);
  const [selectedFacilityOptionId, setSelectedFacilityOptionId] = useState("")
  const { roleId } = useAppSelector((state) => state.users.info);

  const { setSortParamsResponsibleOrganization } = useAction();

  useEffect(() => {
    setSortParamsResponsibleOrganization && setSortParamsResponsibleOrganization(organization?.name || "");
  }, [organization, setSortParamsResponsibleOrganization]);

  return (
    <Box className="objects">
      <Box className="objects-btn-field">
        <Button className="btn btn-primary-fill" variant="outlined" onClick={navigate.bind(null, PATHS.TASKS)}>
          {t("button:tasks")}
        </Button>
        <Button
          className="btn btn-primary-fill"
          disabled={!organization?.enableForEdit}
          variant="outlined"
          onClick={() => {
            navigate(PATHS.ADD_OBJECT);
          }}
        >
          <Box component="img" src={plusicon} sx={{ marginRight: "16px" }} />
          {t("button:add")}
        </Button>
      </Box>

      {objects?.map(({ id, name: objectName, protectionObjectOptionId, discount, price, tariffName, tariffPrice }) => (
        <Accordion key={id} square={true} disableGutters={true} defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ flexDirection: "row-reverse" }}>
            <Typography className="objects-item">{objectName}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ width: "97% !important", padding: "0px 40px 10px" }}>
            <Box className="organization-object-table">
              <Box className="organization-object-table__header">
                <Box className="organization-object-table__header-item">Объект</Box>
                <Box className="organization-object-table__header-item">Скидка</Box>
                <Box className="organization-object-table__header-item">Ежемесячная абонентская плата</Box>
                <Box className="organization-object-table__header-item">Тариф</Box>
              </Box>
              <Box className="organization-object-table__row">
                <Typography className="organization-object-table__row-item">
                  {objectName}
                </Typography>
                <Box
                  className="organization-object-table__row-item"
                  sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "10px" }}
                >
                  <Box>{discount || 0} %</Box>
                  {roleId === 1 && (
                    <Box onClick={() => {
                      setSelectedFacilityOptionId(protectionObjectOptionId)
                      setOpenEditDiscountModal(true)
                    }}>
                      <EditBtnIcon />
                    </Box>
                  )}
                </Box>
                <Box className="organization-object-table__row-item">{price}</Box>
                <Box className="organization-object-table__row-item">{tariffName ? `${tariffName} ${tariffPrice}` : 'Нет тарифа' }</Box>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}

      {openEditDiscountModal && (
        <ModalConfirmDialog
          open={openEditDiscountModal}
          setOpen={setOpenEditDiscountModal}
          facilityOptionId={selectedFacilityOptionId}
        />
      )}
    </Box>
  );
};

export default Objects;
