import React from "react";

const LogoutIcon = () => {
  return (
    <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.6083 2.47714C16.0463 1.91943 15.2887 1.55216 14.4335 1.48662C12.8339 1.36405 11.1863 1.30005 9.50354 1.30005C7.82076 1.30005 6.17318 1.36405 4.57361 1.48662C2.81345 1.6215 1.46692 3.03453 1.38742 4.75509C1.26462 7.41239 1.19995 10.1709 1.19995 13.0001C1.19995 15.8293 1.26462 18.5878 1.38742 21.2451C1.46692 22.9656 2.81345 24.3786 4.57361 24.5136C6.17318 24.6362 7.82076 24.7001 9.50354 24.7001C11.1863 24.7001 12.8339 24.6362 14.4335 24.5136C15.2887 24.4481 16.0463 24.0808 16.6083 23.5231"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.38867 12.9995L22.7999 12.9995"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.8552 7.67603C19.8332 8.74073 21.8112 10.8701 22.8001 12.9995C21.8112 15.129 19.8332 17.2584 17.8552 18.3231"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LogoutIcon;
