import CheckboxSimple from "../../../components/CheckboxSimple";
import InputText from "../../../components/InputText";
import { InputAutocompletePlume, SelectSimpleMessageCodes } from "../Components";

export const formFields = {
  plume: "plume",
  keyType: "keyType",
  technicalPlume: "technicalPlume",
  generateConsoleJobs: "generateConsoleJobs",
  ignoreTechnicalConnection: "ignoreTechnicalConnection",
  messageCodeName: "messageCodeName",
  messageCodeId: "messageCodeId",
  newMessageCodeName: "newMessageCodeName"
};

export const templatePlume = {
  [formFields.plume]: "",
  [formFields.keyType]: "",
  [formFields.technicalPlume]: false,
  [formFields.generateConsoleJobs]: false,
  [formFields.ignoreTechnicalConnection]: false,
};

export const getInitTemplates = (plume, keyType) => {
  return {
    [formFields.plume]: plume || "",
    [formFields.keyType]: keyType,
    [formFields.technicalPlume]: false,
    [formFields.generateConsoleJobs]: true,
    [formFields.ignoreTechnicalConnection]: false,
  };
};

export const formConfigPlumesInformation = [
  {
    name: formFields.plume,
    label: "label:plume",
    type: "select-number",
    width: "300px",
    displayEmpty: true,
    rules: {},
    input: (props) => <InputAutocompletePlume {...props} />,
  },
  {
    name: formFields.keyType,
    label: "label:keyplume",
    type: "select",
    width: "300px",
    rules: {},
    input: (props) => <SelectSimpleMessageCodes {...props} />,
  },
  {
    name: formFields.technicalPlume,
    label: "Технологический шлеф",
    type: "checkboxTechPlume",
    sx: { m: "0 0 12px 12px", width: "fit-content", p: "0" },
    rules: {},
    input: (props) => <CheckboxSimple {...props} />,
  },
  {
    name: formFields.generateConsoleJobs,
    label: "Создавать пультовые задачи",
    type: "checkboxConsoleJobs",
    sx: { m: "0 0 12px 12px", width: "fit-content", p: "0" },
    rules: {},
    input: (props) => <CheckboxSimple {...props} />,
  },
  {
    name: formFields.ignoreTechnicalConnection,
    label: "Игнорировать события по технологическому шлейфу",
    type: "checkboxIgnoreTech",
    sx: { m: "0px 0 12px 12px", width: "fit-content", p: "0" },
    rules: {},
    input: (props) => <CheckboxSimple {...props} />,
  },
];


export const formConfigTechnicalPlume = [
  {
    name: formFields.messageCodeName,
    label: "",
    type: "text",
    width: "45%",
    displayEmpty: true,
    rules: {},
    disabled: true,
    input: (props) => <InputText {...props} />,
  },
  {
    name: formFields.newMessageCodeName,
    label: "",
    type: "text",
    width: "45%",
    rules: {},
    input: (props) => <InputText {...props} />,
  },
];
