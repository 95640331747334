import React, { useEffect, useMemo, useState } from "react";
import { Box, Typography, Button, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useForm, useWatch } from "react-hook-form";
import dayjs from "dayjs";
import "dayjs/locale/ru";

import useScreenSize from "../../../hooks/useScreenSize";
import { useAction } from "../../../hooks/actions";
import { useAppSelector } from "../../../hooks/redux";
import { useGetUserGridSettingQuery, useLazyGetUserGridSettingQuery } from "../../../middleware/usersApi";
import { useLazyPrintEventLogsListQuery } from "../../../middleware/logsApi";
import { useLazyGetAllMagazineEventsQuery } from "../../../middleware/magazineApi";
import { formFields, getConfigFormModalSelect, optionFields } from "./form-config";
import EquipmentDialog from "../EquipmentList/EquipDialog";
import TableBasic from "../../components/TableBasic";
import { ModalSelectColumnsServer } from "../../components/Modals/ModalSelectColumnsServer";
import TaskDialog from "../TasksList/TaskDialog/TaskDialog";
import PageheaderNav from "../../components/PageheaderNav";
import { bgColors } from "./options";
import { getColumns } from "./columns";
import { getBgColor, getBorderColor } from "./helpers";
import settingsicon from "../../../image/icons/settingsButtonWhite.png";
import exporticon from "../../../image/icons/exportBtnWhite.png";
import PrintIcon from "../../components/icons/PrintIcon";
import { dateFormatForSubmit } from "../../../constants";
import { getTableHieght } from "../../../helpers/getTableHieght";
import { EmployeeDialog } from "../EmployeeList/EmployeeDialog";
import "./styles/style.css";

export const MagazineEvents = () => {
  const { t } = useTranslation();
  const {
    messageClassSearchStorage,
    roomPlumeStorage,
    equipNameStorage,
    triggerCauseStorage,
    executiveUserStorage,
    responsibleUserStorage,
    taskNameStorage,
    controlConsoleNameStorage,
    dateRangeStorage,
    facilityIdStorage,
    pageNumberStorage,
  } = JSON.parse(sessionStorage.getItem("magazineEventsListSearchParams")) || {};

  const { control, setValue, getValues, watch, reset, resetField } = useForm({
    mode: "onChange",
  });

  const params = useWatch({
    control,
    name: formFields.searchParams,
  });

  useEffect(() => {
    setValue(`${formFields.searchParams}.${formFields.FacilityId}`, facilityIdStorage || "");
  }, []);

  const facilityId = watch(`${formFields.searchParams}.${formFields.FacilityId}`);
  const screenSize = useScreenSize();

  const { allMagazineEvents } = useAppSelector((state) => state.magazine);
  const { setIsErrorRequest } = useAction();
  const [facilitySearch, setFacilitySearch] = useState("");
  const [messageClassSearch, setMessageClassSearch] = useState(messageClassSearchStorage || "");
  const [roomPlume, setroomPlume] = useState(roomPlumeStorage || "");
  const [equipName, setequipName] = useState(equipNameStorage || "");
  const [triggerCause, settriggerCause] = useState(triggerCauseStorage || "");
  const [executiveUser, setexecutiveUser] = useState(executiveUserStorage || "");
  const [responsibleUser, setresponsibleUser] = useState(responsibleUserStorage || "");
  const [taskName, setTaskName] = useState(taskNameStorage || "");
  const [controlConsoleName, setControlConsoleName] = useState(controlConsoleNameStorage || "");
  const [dateRange, setDateRange] = useState(dateRangeStorage || [null, null]);

  const [selectedEquipId, setSelectedEquipId] = useState(null);
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [openSelectModal, setOpenSelectModal] = useState(false);
  const [openEquipDialog, setopenEquipDialog] = useState(false);
  const [openTaskDialog, setOpenTaskDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [printloading, setPrintLoading] = useState(false);
  const [pageNumber, changePageNumber] = useState(pageNumberStorage || 0);
  const [openEmployeeDialog, setOpenEmployeeDialog] = useState(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);

  const handleOpenEmployeeDialogState = (id) => {
    if (!openEmployeeDialog) {
      setOpenEmployeeDialog(true);
      setSelectedEmployeeId(id);
      return;
    }
    setOpenEmployeeDialog(false);
    setSelectedEmployeeId(null);
  };

  const {
    data: gridcolumns,
    // error: errorgridColumns,
    // isLoading: isLoadingerrorColumns,
  } = useGetUserGridSettingQuery({ gridSettingName: "EventLogJournal" });

  const [
    getUserGrid,
    // { error: errorgrid, refetch: refetchUserGrid /* isLoading */ },
  ] = useLazyGetUserGridSettingQuery();
  const [getAllMagazineEvents, { error, isLoading }] = useLazyGetAllMagazineEventsQuery();
  const [printLogsList, { errorPrint, isLoadingPrint }] = useLazyPrintEventLogsListQuery();

  const handleOpenTaskDialogState = (id) => {
    setOpenTaskDialog(true);
    setSelectedTaskId(id);
  };

  function writeSearchDataToLocalStorage(newSearchParam, searchValue) {
    const getStorage = JSON.parse(sessionStorage.getItem("magazineEventsListSearchParams")) || {};
    const searchParamsForLocalStorage = {
      ...getStorage,
      [newSearchParam]: searchValue,
    };
    sessionStorage.setItem("magazineEventsListSearchParams", JSON.stringify(searchParamsForLocalStorage));
  }

  function savePageNumberToSessionStorage(currentPageNumber) {
    const getStorage = JSON.parse(sessionStorage.getItem("magazineEventsListSearchParams")) || {};
    const searchParamsForLocalStorage = {
      ...getStorage,
      pageNumberStorage: currentPageNumber,
    };
    sessionStorage.setItem("magazineEventsListSearchParams", JSON.stringify(searchParamsForLocalStorage));
  }

  function clearFiltersHandler() {
    setMessageClassSearch("");
    setroomPlume("");
    setequipName("");
    settriggerCause("");
    setexecutiveUser("");
    setresponsibleUser("");
    setTaskName("");
    setControlConsoleName("");
    setDateRange([null, null]);
    changePageNumber(0);
    reset();
    sessionStorage.removeItem("magazineEventsListSearchParams");
  }

  const rows = useMemo(() => {
    const { data } = allMagazineEvents || {};

    return data?.length
      ? data.map(({ jobId, jobName, messageClass, messageCodeCode, executive, responsible, ...item }) => {
          return {
            responsible: {
              ...responsible, 
              action: () => handleOpenEmployeeDialogState(responsible.userId)
            },
            executive: {
              ...executive,
              action: () => handleOpenEmployeeDialogState(executive.userId)
            },
            messageClass: `${messageClass}, ${messageCodeCode}`,
            jobName:
              jobId && jobId != null ? (
                <Button
                  className="table-row-cell-link"
                  sx={{ verticalAlign: "initial" }}
                  onClick={() => handleOpenTaskDialogState(jobId)}
                >
                  {`${jobName}`}
                </Button>
              ) : (
                <></>
              ),
            ...item,
          };
        })
      : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allMagazineEvents]);

  const columnsFromStorage = (() => {
    const storageData = gridcolumns?.columnSettings || [];
    return storageData?.reduce((acc, { name, visible }) => ({ ...acc, [name]: visible }), {});
  })();

  const handleOpenEquipment = (id) => {
    setopenEquipDialog(true);
    setSelectedEquipId(id);
  };

  const columns = useMemo(
    () =>
      getColumns({
        control,
        facilitySearch,
        setFacilitySearch,
        messageClassSearch,
        setMessageClassSearch,
        taskName,
        setTaskName,
        equipName,
        setequipName,
        roomPlume,
        setroomPlume,
        daterange: dateRange,
        setdaterange: setDateRange,
        triggerCause,
        settriggerCause,
        executiveUser,
        setexecutiveUser,
        controlConsoleName,
        setControlConsoleName,
        responsibleUser,
        setresponsibleUser,
        handleOpenEquipment,
        columnsFromStorage,
        writeSearchDataToLocalStorage,
        setValue,
        resetField,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnsFromStorage]
  );

  const recordsCount = useMemo(() => allMagazineEvents && allMagazineEvents?.recordsCount, [allMagazineEvents]);

  const handleOpenSelectColumnsModal = () => {
    setOpenSelectModal(true);
  };

  useEffect(() => {
    setValue(`${formFields.searchParams}.${formFields.facilitySearch}`, facilitySearch);
  }, [facilitySearch, setValue]);

  useEffect(() => {
    writeSearchDataToLocalStorage("facilityIdStorage", facilityId || "");
  }, [facilityId]);

  useEffect(() => {
    setValue(`${formFields.searchParams}.${formFields.TaskName}`, taskName);
  }, [taskName, setValue]);

  useEffect(() => {
    setValue(`${formFields.searchParams}.RoomPlume`, roomPlume);
  }, [roomPlume, setValue]);
  useEffect(() => {
    setValue(`${formFields.searchParams}.EquipmentNameAndNumber`, equipName);
  }, [equipName, setValue]);

  useEffect(() => {
    writeSearchDataToLocalStorage("dateRangeStorage", dateRange);
    setValue(`${formFields.searchParams}.ReactionDateRange`, dateRange);
  }, [dateRange, setValue]);

  useEffect(() => {
    setValue(`${formFields.searchParams}.TriggerCause`, triggerCause);
  }, [triggerCause, setValue]);

  useEffect(() => {
    setValue(`${formFields.searchParams}.MessageClass`, messageClassSearch);
  }, [messageClassSearch, setValue]);

  useEffect(() => {
    setValue(`${formFields.searchParams}.ExecutiveUser`, executiveUser);
  }, [executiveUser, setValue]);

  useEffect(() => {
    setValue(`${formFields.searchParams}.controlConsoleName`, controlConsoleName);
  }, [controlConsoleName, setValue]);
  useEffect(() => {
    setValue(`${formFields.searchParams}.ResponsibleCustomerUser`, responsibleUser);
  }, [responsibleUser, setValue]);

  useEffect(() => {
    if (!params) return;
    const equipmentTypeorKind = getValues(`${formFields.searchParams}.${formFields.equipmentKindAndType}`);
    let equipmentType = "";
    let equipmentKind = "";
    delete params.equipmentKindAndType;
    const createdDate = getValues(`${formFields.searchParams}.ReactionDateRange`);

    if (equipmentTypeorKind) {
      if (equipmentTypeorKind != "все")
        if (JSON.parse(equipmentTypeorKind).id != 0) {
          if (JSON.parse(equipmentTypeorKind)?.isType) {
            equipmentKind = Number(JSON.parse(equipmentTypeorKind).id.replace("parent", ""));
          } else {
            equipmentType = JSON.parse(equipmentTypeorKind).id;
          }
        }
    }
    delete params.ReactionDateRange;
    let dd = {};
    if (createdDate) {
      if (createdDate[1] != null) dd["ReactionDateRange.MaxValue"] = dayjs(createdDate[1]).format(dateFormatForSubmit);
      if (createdDate[0] != null) dd["ReactionDateRange.MinValue"] = dayjs(createdDate[0]).format(dateFormatForSubmit);
    }

    const getEventsFilterValue = () => {
      if (!params.eventsFilter) return optionFields.allEvents;
      if (params.eventsFilter === 111) return 0;

      return params.eventsFilter;
    };

    changePageNumber(0);
    getAllMagazineEvents({
      EquipmentKindId: equipmentKind,
      EquipmentTypeId: equipmentType,
      ...dd,
      ...params,
      eventsFilter: getEventsFilterValue(),
      pageNumber: 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (!params) return;
    const equipmentTypeorKind = getValues(`${formFields.searchParams}.${formFields.equipmentKindAndType}`);
    let equipmentType = "";
    let equipmentKind = "";
    delete params.equipmentKindAndType;
    const createdDate = getValues(`${formFields.searchParams}.ReactionDateRange`);

    if (equipmentTypeorKind) {
      if (equipmentTypeorKind != "все")
        if (JSON.parse(equipmentTypeorKind).id != 0) {
          if (JSON.parse(equipmentTypeorKind)?.isType) {
            equipmentKind = Number(JSON.parse(equipmentTypeorKind).id.replace("parent", ""));
          } else {
            equipmentType = JSON.parse(equipmentTypeorKind).id;
          }
        }
    }

    delete params.ReactionDateRange;
    let dd = {};
    if (createdDate) {
      if (createdDate[1] != null) dd["ReactionDateRange.MaxValue"] = dayjs(createdDate[1]).format(dateFormatForSubmit);
      if (createdDate[0] != null) dd["ReactionDateRange.MinValue"] = dayjs(createdDate[0]).format(dateFormatForSubmit);
    }

    const getEventsFilterValue = () => {
      if (!params.eventsFilter) return optionFields.allEvents;
      if (params.eventsFilter === 111) return 0;

      return params.eventsFilter;
    };

    getAllMagazineEvents({
      EquipmentKindId: equipmentKind,
      EquipmentTypeId: equipmentType,
      ...dd,
      ...params,
      eventsFilter: getEventsFilterValue(),
      pageNumber,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  useEffect(() => {
    setIsErrorRequest(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const base64toPDF = (name, content) => {
    var bufferArray = base64ToArrayBuffer(content);
    var blobStore = new Blob([bufferArray], { type: "application/pdf" });
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blobStore);
      return;
    }
    var data = window.URL.createObjectURL(blobStore);
    var link = document.createElement("a");
    document.body.appendChild(link);
    link.href = data;
    link.download = name;
    link.click();
    window.URL.revokeObjectURL(data);
    link.remove();
    setLoading(false);
  };

  const printBase64 = (content) => {
    var bufferArray = base64ToArrayBuffer(content);
    var blob = new Blob([bufferArray], { type: "application/pdf" }); //this make the magic
    var blobURL = URL.createObjectURL(blob);

    let iframe = document.createElement("iframe"); //load content in an iframe to print later
    document.body.appendChild(iframe);

    iframe.style.display = "none";
    iframe.src = blobURL;
    iframe.onload = function () {
      setTimeout(function () {
        iframe.focus();
        iframe.contentWindow.print();
      }, 1);
    };
    setPrintLoading(false);
  };

  const base64ToArrayBuffer = (data) => {
    var bString = window.atob(data);
    var bLength = bString.length;
    var bytes = new Uint8Array(bLength);
    for (var i = 0; i < bLength; i++) {
      var ascii = bString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  };

  const printLogs = (isPrint) => {
    if (isPrint) setPrintLoading(true);
    else setLoading(true);
    const equipmentTypeorKind = getValues(`${formFields.searchParams}.${formFields.equipmentKindAndType}`);
    let equipmentType = "";
    let equipmentKind = "";
    const createdDate = getValues(`${formFields.searchParams}.ReactionDateRange`);
    const facilId = getValues(`${formFields.searchParams}.${formFields.FacilityId}`);
    if (equipmentTypeorKind) {
      if (equipmentTypeorKind != "все")
        if (JSON.parse(equipmentTypeorKind).id != 0) {
          if (JSON.parse(equipmentTypeorKind)?.isType) {
            equipmentKind = Number(JSON.parse(equipmentTypeorKind).id.replace("parent", ""));
          } else {
            equipmentType = JSON.parse(equipmentTypeorKind).id;
          }
        }
    }
    const facility = facilId == "все" || !facilId ? { FacilityId: "" } : { FacilityId: facilId };
    delete params.ReactionDateRange;
    delete params.FacilityId;
    let dd = {};
    if (createdDate) {
      if (createdDate[1] != null) dd["ReactionDateRange.MaxValue"] = dayjs(createdDate[1]).format(dateFormatForSubmit);
      if (createdDate[0] != null) dd["ReactionDateRange.MinValue"] = dayjs(createdDate[0]).format(dateFormatForSubmit);
    }
    printLogsList({
      EquipmentKindId: equipmentKind,
      EquipmentTypeId: equipmentType,
      ...dd,
      ...facility,
      ...params,
      pageNumber,
    }).then((data) => {
      const res = data.data;
      if (isPrint) printBase64(data.data.content);
      else base64toPDF(data.data.name, data.data.content);
    });
  };

  const ITEMS_NAV = [
    { text: "magazines_events", permissions: "logs" },
    { text: "magazines_finished_tasks", permissions: "logs" },
    { text: "magazines_system_events", permissions: "logs" },
  ];

  return (
    <>
      <Box className="template_page magazine_events">
        <Typography className="title-page">{t("title_page:magazines")}</Typography>
        <Box className="template_page-header">
          <PageheaderNav itemsNav={ITEMS_NAV} />
          <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
            {printloading ? (
              <Box className="full_screen_icon-wrapper">
                <CircularProgress
                  className="full_screen_icon"
                  sx={{
                    width: "30px",
                    height: "30px",
                    marginTop: "5px",
                  }}
                />
              </Box>
            ) : (
              <Box className="full_screen_icon-wrapper">
                <div
                  onClick={() => printLogs(true)}
                  style={{
                    marginTop: "5px",
                    border: "0px !important",
                  }}
                  className="full_screen_icon"
                >
                  <PrintIcon />
                </div>
              </Box>
            )}
            <Button className="btn btn-primary-fill" onClick={clearFiltersHandler}>
              Очистить все фильтры
            </Button>
            <Button
              className="btn btn-primary-fill magazine_events-select_container-btn"
              variant="outlined"
              onClick={() => printLogs(false)}
              disabled={loading}
              sx={{ height: "49px" }}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <>
                  <Box component="img" src={exporticon} sx={{ marginRight: "16px" }} />
                  {t("button:create_act_pdf")}
                </>
              )}
            </Button>
            <Button
              className="btn btn-primary-fill magazine_events-select_container-btn"
              variant="outlined"
              onClick={handleOpenSelectColumnsModal}
              sx={{ height: "52px" }}
            >
              <Box component="img" src={settingsicon} sx={{ marginRight: "16px" }} />
              {t("button:customize_fields_to_display")}
            </Button>
          </Box>
        </Box>
        {/* <Box className="magazine_events-select_container">
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.eventsFilter}`}
            label={t("label:events")}
            type={"select-text"}
            options={eventsOptions}
            input={(props) => (
              <Box className="medium_container">
                <SelectSimple {...props} />
              </Box>
            )}
          />
        </Box>
            */}
        <TableBasic
          columns={columns}
          rows={rows}
          handleClickCell={() => {}}
          withSearchField={false}
          isLoading={isLoading}
          pageNumber={pageNumber}
          changePageNumber={changePageNumber}
          count={recordsCount}
          getCustomBgColorRow={getBgColor(bgColors)}
          getCustomBorderRow={getBorderColor()}
          clearFiltersHandler={clearFiltersHandler}
          minHeight={getTableHieght(screenSize).min}
          maxHeight={getTableHieght(screenSize).max}
          savePageNumberToSessionStorage={savePageNumberToSessionStorage}
        />

        <ModalSelectColumnsServer
          open={openSelectModal}
          setOpen={setOpenSelectModal}
          data={columnsFromStorage}
          refetch={() => getUserGrid({ gridSettingName: "EventLogJournal" })}
          nameStorage={"EventLogJournal"}
          config={getConfigFormModalSelect(gridcolumns?.columnSettings || [])}
        />
      </Box>
      {openTaskDialog && <TaskDialog open={openTaskDialog} setOpen={setOpenTaskDialog} taskId={selectedTaskId} />}

      {openEquipDialog && (
        <EquipmentDialog open={openEquipDialog} setOpen={setopenEquipDialog} equipId={selectedEquipId} />
      )}

      {openEmployeeDialog && (
        <EmployeeDialog
          open={openEmployeeDialog}
          onClose={handleOpenEmployeeDialogState}
          employeeId={selectedEmployeeId}
        />
      )}
    </>
  );
};
