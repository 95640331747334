import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Box, Button, Typography, Tooltip } from "@mui/material";
import { useForm } from "react-hook-form";
import { PATHS, ROLES } from "../../../constants";
import { useAppSelector } from "../../../hooks/redux";
import { useAction } from "../../../hooks/actions";
import { useLazyGetAllFacilitiesQuery } from "../../../middleware/facilitiesApi";
import { getDataToChart } from "../../../helpers/helper";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import TableBasic from "../../components/TableBasic";
import ObjectDialog from "./ObjectDialog";
import ObjectSettings from "./ObjectSettings";
import EditButton from "../../components/EditButton";
import EquipmentRegistry from "./EquipmentRegistry";
import { ModalSelectColumnsServer } from "../../components/Modals/ModalSelectColumnsServer";
import { useGetUserGridSettingQuery, useLazyGetUserGridSettingQuery } from "../../../middleware/usersApi";
import { getConfigFormModalSelect } from "./form-config";
import { getColumns } from "./columns";
import "./style.css";
import { ArrowInWidgets } from "../../components/icons/NewArrowIcons/ArrowInWidgets";
import settingsicon from "../../../image/icons/settingsButtonWhite.png";
import plusicon from "../../../image/icons/plusBtnWhite.png";
import useScreenSize from "../../../hooks/useScreenSize";
import { getTableHieght } from "../../../helpers/getTableHieght";
const ObjectsList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { control, setValue, watch, handleSubmit, resetField, reset, getValues } = useForm();
  const {pageNumberStorage} = JSON.parse(sessionStorage.getItem("objectListSearchParams")) || {};
  const { roleId } = useAppSelector((state) => state.users.info);
  const roleUser = ROLES[roleId];
  const { allFacilities } = useAppSelector((state) => state.facilities);
  const { setIsErrorRequest } = useAction();
  const [openObjectDialog, setOpenObjectDialog] = useState(false);
  const [selectedObjectId, setSelectedObjectId] = useState(null);
  const [selectedObjectName, setselectedObjectName] = useState(null);
  const [pageNumber, changePageNumber] = useState(pageNumberStorage || 0);
  const [searchValue, changeSearchValue] = useState("");
  const [equipmentRegistryDialog, setEquipmentRegistryDialog] = useState(false);
  const [openSelectModal, setOpenSelectModal] = useState(false);
  const [openSettingsModal, setOpenSettingsModal] = useState(false);
  const [searchAddress, setSearchAddress] = useState();
  const [searchObject, setSearchObject] = useState();
  const [searchIntNumber, setSearchIntNumber] = useState();
  const [phone, setPhone] = useState();
  const [isClearFilters, setIsClearFilters] = useState(false);
  const [isChoosenFirstPage, setIsChoosenFirstPage] = useState(false);
  const screenSize = useScreenSize();

  const {
    data: gridcolumns,
    // error: errorgridColumns,
    // isLoading: isLoadingerrorColumns,
  } = useGetUserGridSettingQuery({ gridSettingName: "ProtectionObjectList" });

  const [getUserGrid, { error: errorgrid, refetch: refetchUserGrid /* isLoading */ }] =
    useLazyGetUserGridSettingQuery();

  const [getFacilitiesTable, { error, isLoading }] = useLazyGetAllFacilitiesQuery();

  function writeSearchDataToLocalStorage(newSearchParam, searchValue, isClearFilter) {
    const getStorage = JSON.parse(sessionStorage.getItem("objectListSearchParams")) || {};
    const searchParamsForLocalStorage = {
      ...getStorage,
      [newSearchParam]: searchValue,
    };
    sessionStorage.setItem("objectListSearchParams", JSON.stringify(searchParamsForLocalStorage));
    isClearFilter && setIsClearFilters(true);
  }

  function savePageNumberToSessionStorage(currentPageNumber) {
    const getStorage = JSON.parse(sessionStorage.getItem("objectListSearchParams")) || {};
    const searchParamsForLocalStorage = {
      ...getStorage,
      pageNumberStorage: currentPageNumber,
    };
    sessionStorage.setItem("objectListSearchParams", JSON.stringify(searchParamsForLocalStorage));
  }

  useEffect(() => {
    const { searchAddress, searchObject, searchIntNumber, phone } =
      JSON.parse(sessionStorage.getItem("objectListSearchParams")) || {};
    setSearchAddress(searchAddress);
    setSearchObject(searchObject);
    setSearchIntNumber(searchIntNumber);
    setPhone(phone);
  }, []);

  function clearFiltersHandler() {
    setSearchAddress();
    setSearchObject();
    setSearchIntNumber();
    setPhone();
    setIsClearFilters(true);
    changePageNumber(0)
    const searchParamsForLocalStorage = {
      searchAddress: "",
      searchObject: "",
      searchIntNumber: "",
      phone: "",
    };
    sessionStorage.setItem("objectListSearchParams", JSON.stringify(searchParamsForLocalStorage));
  }

  const handleOpenObjectDialogState = (id) => {
    if (!openObjectDialog) {
      setOpenObjectDialog(true);
      setSelectedObjectId(id);
      return;
    }
    setOpenObjectDialog(false);
    setSelectedObjectId(null);
  };

  const handleOpenSettingsDialog = (id, name) => {
    if (!openSettingsModal) {
      setOpenSettingsModal(true);
      setSelectedObjectId(id);
      setselectedObjectName(name);
      return;
    }
    setOpenSettingsModal(false);
    setSelectedObjectId(null);
    setselectedObjectName(null);
  };

  const handleEquipmentRegistryDialog = (id) => {
    if (!equipmentRegistryDialog) {
      setEquipmentRegistryDialog(true);
      setSelectedObjectId(id);
      return;
    }
    setEquipmentRegistryDialog(false);
    setSelectedObjectId(null);
  };

  const columnsFromStorage = (() => {
    const storageData = gridcolumns?.columnSettings || [];
    return storageData?.reduce((acc, { name, visible }) => ({ ...acc, [name]: visible }), {});
  })();

  const columns = useMemo(
    () =>
      getColumns({
        control,
        columnsFromStorage,
        searchAddress,
        setsearchAddress: setSearchAddress,
        phone,
        setphone: setPhone,
        searchintNumber: searchIntNumber,
        setsearchintNumber: setSearchIntNumber,
        searchObject,
        setSearchObject,
        writeSearchDataToLocalStorage,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnsFromStorage]
  );

  const rows = useMemo(() => {
    const { data } = allFacilities;
    return data?.length
      ? data.map(
          ({
            id,
            name,
            facilityOverallRating,
            integrationNumber,
            fireCompartments,
            address,
            phoneNumber,
            jobChart,
            enableForEdit,
            status,
            statusMessage,
            isInTestTime, 
            isInTestUserFullName
          }) => {
            let formattedAddress = "";
            if (address) {
              const { city, street, building, extension } = address;
              formattedAddress = [city, street, building, extension].filter((item) => !!item).join(", ");
            }
            return {
              id,
              numberAndNameObjects: {
                name: `${name}`,
                action: () => handleOpenObjectDialogState(id),
              },
              facilityOverallRating,
              phone: phoneNumber,
              integrationNumber: <Typography>{integrationNumber}</Typography>,
              equipmentRegistry: {
                name: t("tableHeader:equipment"),
                action: () => navigate(PATHS.LIST_EQUIPMENT + `?objectId=${id}`),
              },
              fireCompartments: fireCompartments?.length
                ? fireCompartments.map(({ id, compartmentNumber, name }) => ({
                    id,
                    name: `${compartmentNumber || ""} ${name || ""}`,
                    action: () => navigate(PATHS.GET_EDIT_FIRE_COMPARTMENT(id)),
                  }))
                : [],
              address: formattedAddress,
              status: {
                status,
                statusMessage,
                isInTestData: {
                  isInTestTime,
                  isInTestUserFullName
                }
              },
              settings: enableForEdit ? (
                <Tooltip title={"Настройка назначения задач"}>
                  <Button
                    className="table-row-cell-link"
                    sx={{ textTransform: "none", whiteSpace: "nowrap" }}
                    onClick={() => handleOpenSettingsDialog(id, name)}
                    startIcon={
                      <SettingsOutlinedIcon
                        sx={{
                          marginLeft: "10px",
                          width: "30px",
                          height: "30px",
                        }}
                      />
                    }
                  ></Button>
                </Tooltip>
              ) : (
                <></>
              ),

              edit: enableForEdit ? <EditButton handleClick={() => navigate(PATHS.GET_EDIT_OBJECT(id))} /> : <></>,
              tasks: getDataToChart({
                jobChart,
                action: `?facilityName=${id}`,
              }),
            };
          }
        )
      : [];
    // eslint-disable-next-line
  }, [allFacilities]);

  const recordsCount = useMemo(() => allFacilities && allFacilities?.recordsCount, [allFacilities]);

  useEffect(() => {
    const dataFromStorage = JSON.parse(sessionStorage.getItem("objectListSearchParams")) || {};
    const {
      searchObject: searchObjectStorage,
      searchAddress: searchAddressStorage,
      searchIntNumber: searchIntNumberStorage,
    } = dataFromStorage;
    const city = searchAddressStorage?.city ? `&AddressFilter.City=${searchAddressStorage?.city}` : "";
    const street = searchAddressStorage?.street ? `&AddressFilter.Street=${searchAddressStorage?.street}` : "";
    const building = searchAddressStorage?.building ? `&AddressFilter.Building=${searchAddressStorage?.building}` : "";
    const address = city + street + building;
    if (pageNumber === 0 && !Object.values(dataFromStorage).filter(Boolean).length && !isClearFilters) return;
    getFacilitiesTable({
      pageNumber,
      Name: searchObjectStorage || "",
      Address: address,
      phoneNumber: phone || "",
      IntegrationNumber: searchIntNumberStorage || "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, searchAddress, searchObject, phone, searchIntNumber]);

  useEffect(() => {
    const dataFromStorage = Object.values(JSON.parse(sessionStorage.getItem("objectListSearchParams")) || {}).filter(
      Boolean
    );
    const city = searchAddress?.city ? `&AddressFilter.City=${searchAddress?.city}` : "";
    const street = searchAddress?.street ? `&AddressFilter.Street=${searchAddress?.street}` : "";
    const building = searchAddress?.building ? `&AddressFilter.Building=${searchAddress?.building}` : "";
    const address = city + street + building;
    if (dataFromStorage.length && pageNumber === 0 && !isChoosenFirstPage) return;
    getFacilitiesTable({
      pageNumber,
      Name: searchObject || "",
      Address: address,
      phoneNumber: phone || "",
      IntegrationNumber: searchIntNumber || "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  useEffect(() => {
    setIsErrorRequest(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  const handleOpenSelectColumnsModal = () => {
    setOpenSelectModal(true);
  };
  return (
    <Box className="legal-entity">
      <ModalSelectColumnsServer
        open={openSelectModal}
        setOpen={setOpenSelectModal}
        data={columnsFromStorage}
        refetch={() => getUserGrid({ gridSettingName: "ProtectionObjectList" })}
        nameStorage={"ProtectionObjectList"}
        config={getConfigFormModalSelect(gridcolumns?.columnSettings || [])}
      />
      {openObjectDialog && (
        <ObjectDialog open={openObjectDialog} onClose={handleOpenObjectDialogState} objectId={selectedObjectId} />
      )}
      {openSettingsModal && (
        <ObjectSettings
          open={openSettingsModal}
          onClose={handleOpenSettingsDialog}
          objectName={selectedObjectName}
          objectId={selectedObjectId}
        />
      )}
      {equipmentRegistryDialog && (
        <EquipmentRegistry
          open={equipmentRegistryDialog}
          onClose={handleEquipmentRegistryDialog}
          objectId={selectedObjectId}
        />
      )}
      <Box className="legal-entity-header">
        <Typography className="title-page">{t("title_page:list_objects")}</Typography>
        <div className="objects-on-map-url">
          <Link to={PATHS.OBJECTS_MAP}>
            {t("title_page:show_objects_on_map")} <ArrowInWidgets />
          </Link>
        </div>
      </Box>

      <Box sx={{ display: "flex", gap: "16px", justifyContent: "flex-end", paddingBottom: "20px" }}>
        <Button className="btn btn-primary-fill" onClick={clearFiltersHandler}>
          Очистить все фильтры
        </Button>
        <Button
          className="btn btn-primary-fill"
          variant="outlined"
          onClick={() => {
            navigate(PATHS.ADD_OBJECT);
          }}
        >
          <Box component="img" src={plusicon} sx={{ marginRight: "16px" }} />
          {t("button:add")}
        </Button>

        <Button
          className="btn btn-primary-fill"
          variant="outlined"
          onClick={handleOpenSelectColumnsModal}
          sx={{ height: "52px" }}
        >
          <Box component="img" src={settingsicon} sx={{ marginRight: "16px" }} />
          {t("button:customize_fields_to_display")}
        </Button>
      </Box>
      <TableBasic
        columns={columns}
        rows={rows}
        handleClickCell={() => {}}
        withSearchField={false}
        pageNumber={pageNumber}
        changePageNumber={changePageNumber}
        setIsChoosenFirstPage={setIsChoosenFirstPage}
        isLoading={isLoading}
        count={recordsCount}
        clearFiltersHandler={clearFiltersHandler}
        minHeight={getTableHieght(screenSize).min}
        maxHeight={getTableHieght(screenSize).max}
        savePageNumberToSessionStorage={savePageNumberToSessionStorage}
      />
    </Box>
  );
};

export default ObjectsList;
