import React from "react";

const PlusIcon = () => {
  return (
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 2.5L9 18.5" stroke="#051D39" strokeWidth="2" strokeLinecap="round" />
      <path d="M1 10.5L17 10.5" stroke="#051D39" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};

export default PlusIcon;
