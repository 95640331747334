import React, { useMemo, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Box, Typography, Modal, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useAction } from "../../../../hooks/actions";
import { formConfigQRCode, sections } from "./form-config";
import { useGetEquipmentQuery } from "../../../../middleware/equipmentsApi";
import { getDataToChart } from "../../../../helpers/helper";
import AccordionSection from "../../../components/AccordionSection";
import FormItem from "../../../components/FormItem";
import TaskStateList from "../../../components/TaskStateList/TaskStateList";
import { dateFormat, dateFormatWithTimeWithoutA } from "../../../../constants";
import "./styles/style.css";
import { getKeyNameByKeyType } from "./helpers";

const EquipmentDialog = ({ open, setOpen, equipId }) => {
  const { t } = useTranslation();
  const { setSortParamsFacilityName } = useAction();
  const { data: equipment, refetch: refetchEquipment, isLoading: isLoadingEquipment } = useGetEquipmentQuery(equipId);
  const [expanded, setExpanded] = useState(JSON.parse(localStorage.getItem("accordionEquipPopUpExpanded")) || []);
  const {
    control,
    setValue,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    mode: "onChange",
  });

  const handleChange = (panel) => (event, isExpanded) => {
    let res = [];
    if (isExpanded) {
      res = [...expanded, panel];
    } else {
      res = expanded.filter((item) => item !== panel);
    }
    localStorage.setItem("accordionEquipPopUpExpanded", JSON.stringify(res));
    setExpanded(res);
  };

  useEffect(() => {
    if (equipment) {
      const {
        id,
        equipmentCoordinateX,
        equipmentCoordinateY,
        workingCorrectly,
        jobChart,
        typeId,
        facilityName,
        fireCompartmentName,
        hasIntegration,
        typeName,
        kindName,
      } = equipment || {};
      const facilityAndFireCompartment = `${facilityName ? facilityName + ", " : ""}${fireCompartmentName || ""}`;
      const equipmentsCoordinates = [
        {
          id,
          floorSchemeCoordinateX: equipmentCoordinateX,
          floorSchemeCoordinateY: equipmentCoordinateY,
          workingCorrectly,
          jobChart,
          typeId,
        },
      ];
      reset({
        ...equipment,
        equipmentsCoordinates,
        facilityAndFireCompartment,
      });
    }
  }, [equipment, reset]);

  const handleClose = () => {
    setOpen(false);
  };
  const dataToolTip = useMemo(
    () =>
      equipment?.jobChart
        ? getDataToChart({
            jobChart: equipment?.jobChart,
            action: `?equipmentId=${equipment?.id}&equipmentName=${equipment?.name}`,
          })
        : [],
    [equipment?.jobChart, setSortParamsFacilityName]
  );
  return (
    <Modal
      open={open}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      className="modal_address"
    >
      <Box className="modal_equipdialog-body">
        <Box>
          <Box className="modal_address-header_right">
            <Typography className="modal_address-body-title">{equipment?.name || ""}</Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Box className="equipment_details">
          <form id="myform" className="scrolled-form-view">
            <Box className="equipment_details-switcher_field">
              <Typography sx={{ margin: "20px 0px", fontWeight: 600 }}>
                {equipment?.workingCorrectly ? "Работоспособность: Да" : "Работоспособность: Нет" || ""}
              </Typography>
            </Box>
            {/* <Box className="equipment_details-switcher_field">
              <Typography sx={{ margin: "15px 0px", fontWeight: 600 }}>
                {equipment?.isReplaced ? "Заменено: Да" : "Заменено: Нет" || ""}
              </Typography>
            </Box>
            */}
            <Box className="equipment_details-switcher_field">
              {equipment?.replacementDate && (
                <Typography
                  sx={{ margin: "20px 0px", fontWeight: 600, width: "100%" }}
                >{`${t("label:replacementDate")}: ${dayjs(equipment?.replacementDate).format(dateFormatWithTimeWithoutA)}`}</Typography>
              )}
            </Box>
            <Box className="equipment_details-form scrolled_equipmentdialog_form" sx={{ padding: "20px 0px" }}>
              <AccordionSection
                key={1}
                title={"basic_information"}
                idd={"basic"}
                expandedAccordions={expanded}
                withoutPadding={true}
                handleAccordionChange={handleChange}
              >
                <Box className="block_form-wrapper">
                  <Typography
                    sx={{ fontWeight: 600, width: "100%" }}
                  >{`${t("label:nameEquipment")}: ${equipment?.name || "Не задано"}`}</Typography>
                  <Typography
                    sx={{ fontWeight: 600, width: "100%" }}
                  >{`${t("label:number")}: ${equipment?.number || "Не задано"}`}</Typography>
                  {equipment?.hasIntegration && (
                    <Typography
                      sx={{ fontWeight: 600, width: "100%" }}
                    >{`${t("label:plume")}: ${equipment?.plume || "Не задано"}`}</Typography>
                  )}
                  {equipment?.hasIntegration && (
                    <Typography
                      sx={{ fontWeight: 600, width: "100%" }}
                    >{`${t("label:equipmentIntegrationNumber")}: ${equipment?.integrationNumber || "Не задано"}`}</Typography>
                  )}
                  <Typography
                    sx={{ fontWeight: 600, width: "100%" }}
                  >{`${t("label:placement")}: ${equipment?.placement == 0 ? "Запотолочное пространство" : equipment?.placement == 1 ? "Подпольное пространство" : "Основное помещение"}`}</Typography>
                  <Typography
                    sx={{ fontWeight: 600, width: "100%" }}
                  >{`${t("label:equipTypeName")}: ${equipment?.typeName || "Не задано"}`}</Typography>
                  <Typography
                    sx={{ fontWeight: 600, width: "100%" }}
                  >{`${t("label:equipKindName")}: ${equipment?.kindName || "Не задано"}`}</Typography>
                  {equipment?.isReplaced && (
                    <Typography
                      sx={{ fontWeight: 600, width: "100%" }}
                    >{`${t("label:installationDate")}: ${equipment?.replacementDate ? dayjs(equipment?.replacementDate).format(dateFormatWithTimeWithoutA) : "Не задано"}`}</Typography>
                  )}
                  <Typography
                    sx={{ fontWeight: 600, width: "100%" }}
                  >{`${t("label:manufactureDate")}: ${equipment?.manufactureDate ? dayjs(equipment?.manufactureDate).format(dateFormat) : "Не задано"}`}</Typography>
                  <Typography
                    sx={{ fontWeight: 600, width: "100%" }}
                  >{`${t("label:object")}: ${equipment?.facilityName || "Не задано"}`}</Typography>
                  <Typography
                    sx={{ fontWeight: 600, width: "100%" }}
                  >{`${t("label:floor")}: ${equipment?.floorInfoText || "Не задано"}`}</Typography>
                  <Typography
                    sx={{ fontWeight: 600, width: "100%" }}
                  >{`${t("label:roomAddressName")}: ${equipment?.roomAddressName || "Не задано"}`}</Typography>
                  <Typography sx={{ fontWeight: 600, width: "100%" }}>
                    Поддержка множественных шлефов: {equipment?.isEnableMultiPlume ? "Да" : "Нет"}
                  </Typography>
                  {equipment?.isEnableMultiPlume && (
                    <Box>
                      {equipment?.equipmentPlumes.map((item) => (
                        <Box key={item.id} sx={{ padding: "0 0 0 20px" }}>
                          <Typography sx={{ fontWeight: 600, width: "100%" }}>Номер шлейфа: {item.plume}</Typography>
                          <Typography sx={{ fontWeight: 600, width: "100%" }}>
                            Ключ шлейфа: {getKeyNameByKeyType[item.keyType]}
                          </Typography>
                          <Typography sx={{ fontWeight: 600, width: "100%" }}>
                            Технологический шлеф: {item.technicalPlume ? "Да" : "Нет"}
                          </Typography>
                          <Typography sx={{ fontWeight: 600, width: "100%" }}>
                            Создавать пультовые задачи: {item.generateConsoleJobs ? "Да" : "Нет"}
                          </Typography>
                          <Typography sx={{ fontWeight: 600, width: "100%" }}>
                            Игнорировать события по технологическому шлейфу:{" "}
                            {item.ignoreTechnicalConnection ? "Да" : "Нет"}
                          </Typography>
                          {item?.equipmentPlumeMessageCodes.length &&
                            item?.equipmentPlumeMessageCodes.map((item) => (
                              <Box key={item.id} sx={{ padding: "20px 0 0 20px" }}>
                                <Typography sx={{ fontWeight: 600, width: "100%" }}>
                                  Стандартное название сообщения: {item.messageCodeName}, {item.messageCodeCode}
                                </Typography>
                                <Typography sx={{ fontWeight: 600, width: "100%" }}>
                                  Новое название сообщения: {item.newMessageCodeName}, {item.messageCodeCode}
                                </Typography>
                              </Box>
                            ))}
                        </Box>
                      ))}
                    </Box>
                  )}

                  <Box sx={{ marginTop: "20px" }}>
                    <TaskStateList
                      data={dataToolTip}
                      lineHeight="24px"
                      height="8px"
                      close={handleClose}
                      width="8px"
                      fontWeight="500"
                      isShowAlternativeName={false}
                    />
                  </Box>
                </Box>
              </AccordionSection>

              {sections.map(
                ({ id, title, Component, config }) =>
                  ((equipment?.hasIntegration && title == "automation_messages") || title != "automation_messages") && (
                    <AccordionSection
                      key={id}
                      title={title}
                      idd={title + id}
                      expandedAccordions={expanded}
                      withoutPadding={true}
                      handleAccordionChange={handleChange}
                    >
                      {Component && (
                        <Component
                          control={control}
                          errors={errors}
                          config={config}
                          data={equipment}
                          setValue={setValue}
                        />
                      )}
                    </AccordionSection>
                  )
              )}

              {formConfigQRCode.map(({ name, label, input, ...rest }, i) => (
                <FormItem
                  key={name}
                  control={control}
                  errors={errors}
                  name={name}
                  label={label}
                  input={input}
                  {...rest}
                />
              ))}
            </Box>
          </form>
        </Box>
      </Box>
    </Modal>
  );
};

export default EquipmentDialog;
