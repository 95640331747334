import { useEffect } from "react";
import { useFieldArray } from "react-hook-form";
import { Box, Button } from "@mui/material";
import { t } from "i18next";
import { useLazyGetEquipmentPlumeMessageCodesQuery } from "../../../../middleware/messageCodeApi";
import { formConfigPlumesInformation, formConfigTechnicalPlume, getInitTemplates } from "./form-config";
import FormItem from "../../../components/FormItem";
import DeleteIcon from "../../../components/icons/DeleteIcon";
import plusicon from "../../../../image/icons/plusBtnWhite.png";
import "./styles.css";

const ManyPlumes = ({ control, errors, data, setValue, watch, setIsErrorMultiPlume }) => {
  const { plume, keyType, equipmentPlumes, protectionObjectId, facilityId } = data;

  const [getEquipmentPlumeMessageCodes] = useLazyGetEquipmentPlumeMessageCodesQuery();
  const equipmentPlumesData = watch("equipmentPlumes");

  const {
    fields: fieldsPlumes,
    append,
    remove,
    update,
  } = useFieldArray({
    control,
    name: "equipmentPlumes",
  });

  const onChangeTechnicalPlume = (e, index, name) => {
    if (name !== "technicalPlume") return;
    if (!e.target.checked) return;
    getPlumeMessageCodes(index);
  };

  const onChangeKeyType = (e, index) => {
    if (equipmentPlumesData[index].technicalPlume) {
      getPlumeMessageCodes(index, e.target.value);
    }
  };

  useEffect(() => {
    if (!fieldsPlumes.length && !equipmentPlumes?.length) {
      append(getInitTemplates(plume, keyType));
    }
  }, [fieldsPlumes]);

  const getPlumeMessageCodes = (index, keyType) => {
    const prevData = equipmentPlumesData[index];

    getEquipmentPlumeMessageCodes({
      protectionObjectId: protectionObjectId || facilityId,
      keyType: keyType || prevData.keyType,
      equipmentPlumeId: prevData?.id || "",
    }).then((data) => {
      if (data?.error) {
        setIsErrorMultiPlume(data?.error);
        return;
      }
      prevData.equipmentPlumeMessageCodes = data.data;
      update(index, prevData);
    });
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      {fieldsPlumes.map((item, index) => (
        <Box sx={{ display: "flex", flexDirection: "column" }} key={index}>
          <Box sx={{ display: "flex", gap: "20px" }}>
            <Box key={item.id} sx={{ display: "flex", gap: "20px", flexWrap: "wrap", alignItems: "flex-end" }}>
              {formConfigPlumesInformation.map(({ name, title, label, input, Component, flexBasis, type, ...rest }) => (
                <FormItem
                  control={control}
                  key={name}
                  errors={errors}
                  name={`equipmentPlumes.${index}.${name}`}
                  setValue={setValue}
                  label={label}
                  input={input}
                  onChangeTechnicalPlume={(e) => onChangeTechnicalPlume(e, index, name)}
                  onChangeKeyType={(e) => onChangeKeyType(e, index)}
                  {...rest}
                />
              ))}
            </Box>

            {index !== 0 && (
              <Button
                className={`btn btn-basket-small`}
                sx={{ borderColor: "red", marginTop: "10px", background: "red" }}
                onClick={() => remove(index)}
              >
                <DeleteIcon />
                <p>Удалить</p>
              </Button>
            )}
          </Box>

          {fieldsPlumes[index].technicalPlume && fieldsPlumes[index].equipmentPlumeMessageCodes && (
            <Box sx={{ display: "flex", gap: "20px", flexWrap: "wrap", alignItems: "flex-end" }}>
              {fieldsPlumes[index].equipmentPlumeMessageCodes.map((item, plumeIndex) => {
                return formConfigTechnicalPlume.map(({ name, title, label, input, Component, flexBasis, ...rest }) => (
                  <FormItem
                    key={plumeIndex}
                    control={control}
                    errors={errors}
                    name={`equipmentPlumes.${index}.equipmentPlumeMessageCodes.${plumeIndex}.${name}`}
                    setValue={setValue}
                    label={label}
                    input={input}
                    {...rest}
                  />
                ));
              })}
            </Box>
          )}
        </Box>
      ))}

      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
        <Button
          className="btn btn-primary-fill"
          variant="outlined"
          onClick={() => append(getInitTemplates(plume, keyType))}
        >
          <Box component="img" src={plusicon} sx={{ marginRight: "16px" }} />
          {t("button:add")}
        </Button>
      </Box>
    </Box>
  );
};

export default ManyPlumes;
