import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import { editDiscount } from "./constant";
import FormItem from "../../components/FormItem";
import { useForm } from "react-hook-form";
import { useUpdateOptionDiscountMutation } from "../../../middleware/billingApi";
import { useAction } from "../../../hooks/actions";

export const ModalConfirmDialog = ({ open, setOpen, facilityOptionId }) => {
  const { t } = useTranslation();
  const [updateOptionDiscount, { error, isSuccess }] = useUpdateOptionDiscountMutation();
  const { setIsErrorSubmit, setIsSuccessSubmit } = useAction();
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = (data) => {
    data.discount = +data.discount;
    data.facilityOptionId = facilityOptionId;
    updateOptionDiscount(data).then((result) => {
      if (!result.error) setOpen(false);
    });
  };

  useEffect(() => {
    setIsErrorSubmit(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    setIsSuccessSubmit(isSuccess);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return (
    <Dialog open={open} className="confirm_modal">
      <DialogContent className="confirm_modal-content_field">
        <Typography className="confirm_modal-content_field-text" sx={{ mb: "12px" }}>
          Введите новую скидку
        </Typography>

        <form id="editDiscount" onSubmit={handleSubmit(onSubmit)}>
          {editDiscount.map(({ name, label, input, type, ...rest }) => (
            <FormItem
              key={name}
              control={control}
              errors={errors}
              name={name}
              label={label}
              input={input}
              type={type}
              setValue={setValue}
              {...rest}
            />
          ))}
        </form>
      </DialogContent>
      <DialogActions className="confirm_modal-btn_field">
        <Button className="btn btn-outlined-primary " variant="outlined" onClick={() => setOpen(false)}>
          {t("button:to_back")}
        </Button>
        <Button className="btn btn-primary-fill" form="editDiscount" type="submit" onClick={() => {}} autoFocus>
          {t("button:save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
