import React from "react";

const TasksIcon = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.285 22.0058L2.01198 14.4008C1.35045 13.759 0.987494 12.9053 1.00033 12.0213C1.01316 11.1372 1.40078 10.2926 2.08071 9.66734C2.76065 9.04193 3.67924 8.68507 4.64107 8.67264C5.60289 8.66021 6.53203 8.99319 7.23082 9.60072L12.4934 14.4375L22.3497 2.3593C22.6405 2.00248 23.005 1.70183 23.4224 1.47448C23.8397 1.24715 24.3017 1.09758 24.7819 1.03433C25.2621 0.971089 25.7511 0.995388 26.2212 1.10585C26.6912 1.21632 27.1328 1.41079 27.5211 1.67816C27.9092 1.94552 28.2364 2.28056 28.4838 2.66412C28.731 3.04769 28.8939 3.47227 28.9626 3.91364C29.0315 4.355 29.005 4.8045 28.8849 5.23648C28.7646 5.66845 28.553 6.07443 28.2622 6.43125L15.852 21.6423C15.5343 22.0313 15.1296 22.3531 14.6649 22.5859C14.2003 22.8187 13.6866 22.957 13.1587 22.9915C12.6309 23.0259 12.1011 22.9558 11.6053 22.7858C11.1095 22.6157 10.6593 22.3497 10.285 22.0058Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TasksIcon;
