import { bindActionCreators } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { settingsActions } from "../redux/reducers/settings.slice";
import { organizationsActions } from "../redux/reducers/organizations.slice";
import { uploadFileActions } from "../redux/reducers/uploadFile.slice";
import { usersActions } from "../redux/reducers/users.slice";
import { logsActions } from "../redux/reducers/logs.slice";
import { tasksActions } from "../redux/reducers/tasks.slice";
import { documentationActions } from "../redux/reducers/documentation.slice";
import { facilitiesActions } from "../redux/reducers/facilities.slice";
import { controllersActions } from "../redux/reducers/controllers.slice";
import { signingActions } from "../redux/reducers/signing.slice";
import { equipmentsActions } from "../redux/reducers/equipments.slice";
import { permissionsActions } from "../redux/reducers/permissions.slice";

const actions = {
  ...settingsActions,
  ...organizationsActions,
  ...uploadFileActions,
  ...equipmentsActions,
  ...usersActions,
  ...logsActions,
  ...tasksActions,
  ...documentationActions,
  ...facilitiesActions,
  ...controllersActions,
  ...signingActions,
  ...permissionsActions
};

export const useAction = () => {
  const dispatch = useDispatch();
  return bindActionCreators(actions, dispatch);
};
