

import React, { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Routes, Route, Navigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "./hooks/redux";
import { Can } from "./casl/Can";
import { AppRoutes, blockedOrganizationRoutes, NonAuthRoutes } from "./views/AppRoutes";
import { useKeyPress } from "./hooks/useFocusOnEnter";
import { parseJsonToken } from "./helpers/helper";
import { useIsOrganizationBlockedMutation } from "./middleware/billingApi";
import { APP_VERSION } from "./middleware/constants";
import AlertNewVersion from "./views/AlertNewVersion";
import { ErrorFallback } from "./views/errorsPages/ErrorFallBack";
import PageNotFound from "./views/errorsPages/PageNotFound";
import Layout from "./views/containers/Layout";
import { PATHS } from "./constants";
import "./styles/globalStyles/globalstyles.css";
import "./styles/variablesStyles/colors/colors.css";
import "./styles/variablesStyles/fonts/fonts.css";
import "./styles/variablesStyles/sizes/sizes.css";
import { useLazyIsUserBlockedQuery, useLazyIsValidTokenQuery } from "./middleware/usersApi";
import { useAction } from "./hooks/actions";
import { persistor } from "./redux/store";
function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const { setAuthStep } = useAction();
  const token = useAppSelector((state) => state.users.token);
  const [permissions, setPermissions] = useState([]);
  const { updatePermissions } = useAction();
  const { roleId } = useAppSelector((state) => state.users.info);
  const [open, setOpen] = useState(false);
  const [currentMessage, setCurrentMessage] = useState("");
  const [currentHeader, setCurrentHeader] = useState("");
  const [isBlockState, setisBlockState] = useState(false);
  const [isValidTokenState, setisValidTokenState] = useState(true);
  const [isOrganizationBlockedState, setIsOrganizationBlockedState] = useState();
  const [isOrganizationBlocked] = useIsOrganizationBlockedMutation();
  const [isBlockedUser, { refetch: refetchIsBlockedUser }] = useLazyIsUserBlockedQuery();
  const [isValidToken, { refetch: refetchIsValidToken }] = useLazyIsValidTokenQuery();

  function checkFacilitiesAvailability(array) {
    return array.includes("facilities");
  }
  useKeyPress();
  const facilitiesAvailable = checkFacilitiesAvailability(permissions);
  useEffect(() => {

    if (isBlockState) {
      setOpen(true)
      setCurrentMessage("Ваш аккаунт был заблокирован")
      setCurrentHeader("Ваш аккаунт был заблокирован")
    }

  }, [isBlockState]);
  useEffect(() => {
    //console.log("LOGG",)
    if (isValidTokenState == false) {
      alert("Вышла новая версия требуется войти в систему заново")
      setAuthStep(0);
      window.location.reload();
      console.log("cookies", persistor)
      localStorage.clear();

    }

  }, [isValidTokenState]);
  // Save the current location in the state object
  const saveCurrentLocation = () => {
    navigate(location.pathname, { state: { from: location } });
  };

  // Call saveCurrentLocation when the component mounts
  useEffect(() => {
    saveCurrentLocation();
  }, []);

  useEffect(() => {
    const storedPath = localStorage.getItem("currentPath");
    if (storedPath) {
      localStorage("currentPath"); // Remove the stored path once used
      navigate(storedPath);
    }
  }, [navigate]);

  useEffect(() => {
    if (!permissions) return;
    updatePermissions(permissions);
  }, [permissions]);

  useEffect(() => {
    token && parseJsonToken({ token, setPermissions });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, setPermissions]);

  const protectedRoutes = AppRoutes.filter(
    (item) => permissions.includes(item.permission) || item.permission === "profile"
  );

  const theme = createTheme({
    typography: {
      fontFamily: "var(--INTER)",
    },
    components: {
      MuiMenuItem: {
        styleOverrides: {
          root: {
            borderBottom: "1px solid #CFD9E8",
            margin: "0px 20px",
            alignContent: "center",
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {},
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: "24px", // Устанавливаем желаемый радиус границы
          },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            width: "100% !important", // Устанавливаем желаемый радиус границы
          },
        },
      },
      MuiModal: {
        styleOverrides: {
          paper: {
            borderRadius: "24px", // Устанавливаем желаемый радиус границы
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          root: {
            alignContent: "center",
            "& .MuiTypography-root": {
              fontSize: "16px !important",
              lineHeight: "20px !important", // Изменяем размер шрифта внутреннего MuiTypography
            },
          },
        },
      },
      MuiListSubheader: {
        styleOverrides: {
          root: {
            margin: "0px 12px",
            padding: "12px 0px",
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          input: {
            padding: "3.5px 0px 3.5px 15px !important",
          },
        },
      },
      MuiTreeItem2: {
        styleOverrides: {
          // Указываем стили для классов компонента
          groupTransition: {
            padding: 0, // Убираем padding для группы дочерних элементов
            // Если вам нужно изменить этот конкретный класс, добавьте селектор
            "&.MuiCollapse-root": {
              padding: 0, // Убираем padding для класса MuiCollapse-root
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          input: {
            fontSize: "16px", // Изменяем размер шрифта
            "&:active": {
              color: "#051d39",
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              paddingRight: "24px !important",
              height: "48px !important",
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          input: {
            color: "#d1d8e0",
            width: "calc(100% - 80px)",
            fontSize: "16px",
            lineHeight: "16px",
            padding: "12.5px 0px 12.5px 24px",
            "&:focus": {
              color: "#051d39",
              "& .MuiSelect-select": {
                alignContent: "center",
                color: "#051d39", // Изменяем размер шрифта внутреннего MuiTypography
              },
            },

            "&:hover": {
              color: "#051d39",
              borderColor: "#c1c1c4",
              outline: "none",
            },
          },
          root: {
            padding: "0px",
            fontSize: "16px",
            borderRadius: "32px",
            "&:active": {
              color: "#051d39",
              "& .MuiSelect-select": {
                color: "#051d39", // Изменяем размер шрифта внутреннего MuiTypography
              },
            },
            "& .MuiSelect-select": {
              alignContent: "center", // Изменяем размер шрифта внутреннего MuiTypography
            },
            "&:hover": {
              borderColor: "#c1c1c4",
            },
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          input: {
            color: "#051d39",
            padding: "14.5px 24px",
          },
          root: {
            fontSize: "14px",
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            boxShadow: "none",
          },
        },
      },

      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            flexDirection: "row !important",
            color: "#051D39",
            boxShadow: "none",
            height: "32px",
          },
          content: {
            margin: 0,
          },
          "&.Mui-expanded": {
            backgroundColor: "darkgrey", // Цвет фона при раскрытии
          },
        },
      },
      MuiSlider: {
        styleOverrides: {
          thumb: {
            borderRadius: "50%",
            backgroundColor: "#fff",
            border: "1px solid var(--main)",
            width: 20,
            height: 20,
          },
          rail: {
            backgroundColor: "#CFD9E8",
            opacity: 1, // Цвет левой части
          },
          track: {
            backgroundColor: "#1876D2",
            opacity: 1, // Цвет правой части
          },
        },
      },
      "& .MuiSlider-thumb": {
        height: 24,
        width: 24,
        backgroundColor: "#fff",
        border: "2px solid currentColor",
        "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
          boxShadow: "inherit",
        },
        "&::before": {
          display: "none",
        },
      },
      "& .MuiSlider-valueLabel": {
        lineHeight: 1.2,
        fontSize: 12,
        background: "unset",
        padding: 0,
        width: 32,
        height: 32,
        borderRadius: "50% 50% 50% 0",
        backgroundColor: "#52af77",
        transformOrigin: "bottom left",
        transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
        "&::before": { display: "none" },
        "&.MuiSlider-valueLabelOpen": {
          transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
        },
        "& > *": {
          transform: "rotate(45deg)",
        },
      },
    },
  });

  useEffect(() => {
    if (!token) return;
    isOrganizationBlocked().then((data) => {
      setIsOrganizationBlockedState(data.data);
    });
    isBlockedUser().then((data) => setisBlockState(data.data))
    isValidToken().then((data) => setisValidTokenState(data.data))
  }, [token]);

  useEffect(() => {
    const intervalGetBlockedUser = setInterval(() => {

      isBlockedUser().then(({ data }) => setisBlockState(data))
      isValidToken().then(({ data }) => setisValidTokenState(data))
    }, 60000); // 60000 мс = 1 минута

    return () => clearInterval(intervalGetBlockedUser);
  }, [refetchIsBlockedUser, refetchIsValidToken]);

  const renderNewRoutes = () => {
    return (
      <Routes>
        {blockedOrganizationRoutes.map((routeConfig) => (
          <Route key={Date.now()} exact={routeConfig.exact} path={routeConfig.path} element={routeConfig.component} />
        ))}
        <Route path={PATHS.ALL_ALIENS} element={<Navigate to={PATHS.CABINET} />} />
      </Routes>
    );
  };

  const renderProtectedRoutes = () => {
    return (
      <Routes>
        {protectedRoutes.map((routeConfig) => (
          <Route key={Date.now()} exact={routeConfig.exact} path={routeConfig.path} element={routeConfig.component} />
        ))}
        <Route
          path={PATHS.SLASH}
          element={<Navigate to={roleId !== 4 && facilitiesAvailable ? PATHS.OBJECTS_MAP : PATHS.LIST_TASKS} />}
        />
        <Route path={PATHS.ALL_ALIENS} element={<Navigate to={"/"} />} />
      </Routes>
    );
  };

  const isMobile = /(?=.*Android)(?=.*Mobile).*iPhone|.*iPod.*|BlackBerry.*|IEMobile|Fennec/i.test(navigator.userAgent);
  
  function detectDevice() {
    const userAgent = navigator.userAgent;

    if (/mobile/i.test(userAgent)) {
      // Устройство мобильное
      return 'mobile';
    } else if (/tablet/i.test(userAgent)) {
      // Устройство планшет
      return 'tablet';
    } else {
      // Устройство настольное
      return 'desktop';
    }
  }
  const deviceType = detectDevice();

  console.log('Вы зашли с устройства: ', deviceType);
  if (deviceType === 'mobile') {
    return (
      <ErrorFallback
        error={new Error("Для авторизации с мобильных устройств необходимо использовать мобильное приложение")}
      />
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <AlertNewVersion header={currentHeader} message={currentMessage} setOpen={setOpen} open={open} />
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Can I="manage" a="auth">
          <Layout>{isOrganizationBlockedState ? renderNewRoutes() : renderProtectedRoutes()}</Layout>
        </Can>

        <Can I="manage" a="notAuth">
          <Routes>
            {NonAuthRoutes.map((routeConfig) => (
              <Route
                key={Date.now()}
                exact={routeConfig.exact}
                path={routeConfig.path}
                element={routeConfig.component}
              />
            ))}
            <Route path={PATHS.ALL_ALIENS} element={<PageNotFound />} />
            <Route path={PATHS.SLASH} element={<Navigate to={PATHS.LOGIN_ROUTE} />} />
          </Routes>
        </Can>
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export default App;

