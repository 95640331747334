import React, { useEffect, useState } from "react";
import { Button, Tooltip, Typography } from "@mui/material";
import SelectSimpleSearch from "../../components/SelectSimple/SelectSimpleSearch";
import { useLazyGetOrganizationByInnQuery } from "../../../middleware/apiOrganizations";
import { useLazyGetOrgInnQuery } from "../../../middleware/addressDataApi";
import useScreenSize from "../../../hooks/useScreenSize";

const TooltipProps = {
  tooltip: {
    sx: {
      color: "var(--black)",
      backgroundColor: "var(--white)",
      borderRadius: 0,
      maxWidth: 200,
      fontSize: "14px",
      border: "1px solid #CFD9E8"
    },
  },
};
export const ClickableNameCell = ({ item }) => {
  const { name, action } = item || {};
  return (
    <Tooltip title={name || ""} componentsProps={TooltipProps}>
      <Typography
        className="table-row-cell-link"
        onClick={action ? action : () => {}}
      >
        {name || ""}
      </Typography>
    </Tooltip>
  );
};

export const IsBlockedOrganizationCell = ({ item }) => {
  const { isBlocked, action } = item || {};
  const screenSize = useScreenSize();
  return (
    <Button
      className={`btn ${isBlocked ? "btn-primary-fill" : "btn-red-fill"}`}
      sx={{
        fontSize: screenSize.width < 1560 ? "14px !important" : "16px",
        padding: screenSize.width < 1560 ? "12.5px 20px !important" : "16.5px 24px",
      }}
      onClick={action ? action : () => {}}
    >
      {isBlocked ? "Разблокировать" : "Заблокировать"}
    </Button>
  );
};
export const InnNumberSearchInput = (props) => {
  const [getOrganizationByInn] = useLazyGetOrgInnQuery();
  const [searchValue, setSearchValue] = useState("");
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (!searchValue.length) {
      return;
    }
    getOrganizationByInn(searchValue)
      .then((res) => {
        const suggests = res?.data?.suggestions;
        if (suggests && suggests?.length > 0) {
          let suggestArray = suggests.map((item) => {
            return { value: item.data.inn, name: item.value };
          });
          setOptions(suggestArray);
        } else {
          setOptions([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [getOrganizationByInn, searchValue]);

  return <SelectSimpleSearch {...props} searchValue={searchValue} setSearchValue={setSearchValue} options={options} />;
};

export const InnNumber = (props) => {
  const [getOrganizationByInn] = useLazyGetOrganizationByInnQuery();
  const [searchValue, setSearchValue] = useState("");
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (!searchValue.length) {
      return;
    }
    getOrganizationByInn(searchValue)
      .then((res) => {
        setOptions(res?.data || []);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [getOrganizationByInn, searchValue]);

  return <SelectSimpleSearch {...props} searchValue={searchValue} setSearchValue={setSearchValue} options={options} />;
};
