import React, { useEffect, useMemo, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { PATHS } from "../../../../constants";
import {
  useAddOrganizationMutation,
  useDeleteOrganizationMutation,
  useSetOrganizationUserLinkMutation,
} from "../../../../middleware/apiOrganizations";
import { useAddFileMutation } from "../../../../middleware/apiUploadFile";
import { useAction } from "../../../../hooks/actions";
import { DEFAULT_VALUE, fields } from "../constants";
import BasicInformation from "../BasicInformation";
import { WarningValidationForm } from "../../../components/Notifications";
// import FormItem from "../../../components/FormItem";
// import { listOfOrganisationsConfig } from "../form-config";
import BasicInformationResponsibleOrganizations from "../../ResponsibleOrganizationsList/BasicInformation";
// import ContractEntities from "../../ResponsibleOrganizationsList/ContractEntities";
import BankDetails from "../../ResponsibleOrganizationsList/BankDetails";
import { useAddResponsibleOrganizationMutation } from "../../../../middleware/responsibleOrganizationsApi";
import { useDeleteResponsibleOrganizationMutation } from "../../../../middleware/responsibleOrganizationsApi";
import { useManipulateFilesMutation } from "../../../../middleware/apiUploadFile";
import { TYPE_ID } from "../constants";
import "./styles/style.css";

import {
  getRequestData,
  getRequestDataFilesForAdd,
} from "../../ResponsibleOrganizationsList/helpers";
import { useWatchOrganizationInnNumber, useWatchBicNumber } from "./hooks";

const AddOrganizationPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [navigationPath, setNavigationPath] = useState(null);
  const [isGotoObject, setisGotoObject] = useState(false);
  const { setIsErrorSubmit, setIsSuccessSubmit, setIsErrorRequest } = useAction();
  const {
    control,
    handleSubmit,
    reset,
    resetField,
    formState: { errors, isSubmitSuccessful },
    getValues,
    setValue,
    trigger,
  } = useForm({
    mode: "onChange",
    defaultValues: DEFAULT_VALUE,
  });

  const { typeId } = useWatch({ control });
  const {
    innNumberValue,
    shouldDisableAllFields,
    okvedCode,
    indCheck,
    addressFromDadata,
    setaddressFromDadata,
    resAddressFromDadata,
    setresAddressFromDadata,
  } = useWatchOrganizationInnNumber({
    errors,
    control,
    reset,
    setValue,
    resetField,
  });
  
  const { BankInfo } = useWatchBicNumber({
    errors,
    control,
    setValue,
    typeId,
  });

  const isToDoBankReset = useMemo(() => {
    return !BankInfo || BankInfo?.length < 10;
  }, [BankInfo]);

  const isToDoReset = useMemo(() => {
    return !innNumberValue || innNumberValue?.length < 10;
  }, [innNumberValue]);

  useEffect(() => {
    isToDoReset &&
      reset({
        ...DEFAULT_VALUE,
        typeId,
        innNumber: innNumberValue,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isToDoReset, typeId]);

  const isWarningValidation = !!Object.keys(errors).length;
  const [addOrganization, { error: isErrorAdd, isSuccess: isSuccessAdd }] =
    useAddOrganizationMutation();
  const [addFile, { error: isErrorFile, isSuccess: isSuccessFile }] =
    useAddFileMutation();
  const [deleteOrganization] = useDeleteOrganizationMutation();

  const [
    addResponsibleOrganization,
    {
      error: isErrorAddResponsibleOrganization,
      isSuccess: isSuccessAddResponsibleOrganization,
    },
  ] = useAddResponsibleOrganizationMutation();
  const [deleteResponsibleOrganization] =
    useDeleteResponsibleOrganizationMutation();
  const [
    manipulateFiles,
    {
      error: isErrorFileResponsibleOrganization,
      isSuccess: isSuccessFileResponsibleOrganization,
    },
  ] = useManipulateFilesMutation();

  const [
    setOrganizationUserLink,
    {
      isSuccess: isSuccessSetOrganizationUserLink,
      error: isErrorSetOrganizationUserLink,
    },
  ] = useSetOrganizationUserLinkMutation();

  const isError =
    isErrorAddResponsibleOrganization ||
    isErrorFileResponsibleOrganization ||
    isErrorAdd ||
    isErrorFile ||
    isErrorSetOrganizationUserLink;
  const isSuccess =
    (isSuccessAddResponsibleOrganization &&
      isSuccessFileResponsibleOrganization) ||
    (isSuccessAdd && isSuccessFile) ||
    isSuccessSetOrganizationUserLink;

  const responsibleOrganizationSubmit = async (data) => {
    const { entities, files } = getRequestData(data, resAddressFromDadata);

    const requestData = {
      typeId: entities.typeId,
      innNumber: entities.innNumber,
      name: entities.name,
      ogrnNumber: entities.ogrnNumber,
      kppCode: entities.kppCode,
      okpoCode: entities.okpoCode,
      okvedCode: entities.okvedCode,
      licenseNumber: entities.licenseNumber,
      licenseExpirationDate: entities.licenseExpirationDate,
      organizationResponsibleBankDetails:
        entities.organizationResponsibleBankDetails,
      organizationResponsibleAddresses:
        entities.organizationResponsibleAddresses,
      // organizationResponsibleContracts:
      //entities.organizationResponsibleContracts,
    };

    addResponsibleOrganization(requestData).then((responseAdd) => {
      if (responseAdd.error) {
        return;
      }
      const formData = getRequestDataFilesForAdd(responseAdd?.data, files);

      manipulateFiles(formData).then((responseFiles) => {
        if (responseFiles.error) {
          deleteResponsibleOrganization(responseAdd?.data?.id);
          return;
        }
      });
    });
  };

  const organizationSubmit = async (data) => {
    if (data?.organizationResponsibleContracts)
      delete data.organizationResponsibleContracts;
    if (data?.organizationResponsibleBankDetails)
      delete data.organizationResponsibleBankDetails;
    if (data?.organizationResponsibleBankDetails)
      delete data.organizationResponsibleAddresses;
    if (addressFromDadata) {
      data.organizationAddresses = data.organizationAddresses.map(
        (item, index) => {
          item = {
            ...item,
            city: addressFromDadata[index]?.data.city,
            street: addressFromDadata[index]?.data.street,
            building: addressFromDadata[index]?.data.house,
          };
          delete item.address;
          return item;
        }
      );
    } else {
      data.organizationAddresses = data.organizationAddresses.map((item) => {
        item = {
          ...item,
          ...item.address,
        };
        delete item.address;
        return item;
      });
    }

    const {
      file,
      typeId,
      licenseNumber,
      licenseExpirationDate,
      organizationResponsibleAddresses,
      // organizationResponsibleContracts,
      ...rest
    } = data || {};

    const requestData = {
      typeId,
      ...rest,
    };

    addOrganization(requestData).then((result) => {
      console.log("result?.error", result?.error)
      if (result?.error) {
        return;
      } else if (!file.filesToAdd?.length) {
        return;
      }
      const formData = new FormData();
      // заглушка (ожиается изменение на стороне сервера)
      file.filesToAdd.forEach((fileItem) => formData.append(`File`, fileItem));
      formData.append("OrganizationId", result?.data?.id);
      addFile(formData).then((response) => {
        if ("error" in response) {
          deleteOrganization(result?.data?.id);
          return;
        }
        setOrganizationUserLink({
          organizationId: result?.data.id,
          organizationTypeId: result?.data.typeId,
        });
      });
    });
  };

  useEffect(() => {
    if (isSuccess && navigationPath) {
      navigate(navigationPath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, navigationPath]);

  const onSubmit = async (data) => {
    /*if (data.id) {
      await setOrganizationUserLink({
        organizationId: data?.id,
        organizationTypeId: data?.typeId,
      });

      return;
    }*/
    if (data?.typeId === TYPE_ID[fields.organization_contractor]) {
      await responsibleOrganizationSubmit(data);
      return;
    }
    await organizationSubmit(data);
  };

  const handleCancel = () => navigate(-1);

  const handleFormSubmit = async (data) => {
    console.log('data', data)
    await onSubmit(data);

  };

  const handleSubmitAndNavigateToFacility = async () => {
    const isDataCorrect = await trigger();
    if (!isDataCorrect) {
      return;
    }
    setisGotoObject(true)
    const data = getValues();
    await onSubmit(data);
  };

  useEffect(() => {
    if (isSuccessAdd || isSuccessAddResponsibleOrganization) {
      if (isGotoObject)
        navigate(PATHS.ADD_OBJECT)
      else
        navigate(PATHS.MY_ORGANIZATIONS)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessAdd, isSuccessAddResponsibleOrganization]);

  useEffect(() => {
    setIsErrorSubmit(isError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);
  useEffect(() => {
    setIsSuccessSubmit(isSuccess);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  console.log("okved", okvedCode);
  return (
    <Box className="legal-entity">
      <Box className="legal-entity-header">
        <Typography className="title-page" sx={{ pl: "6px" }}>
          {t("title_page:add_organization")}
        </Typography>
        <Box className="legal-entity-header-buttons">
          <Button className="btn btn-outlined-grey" onClick={handleCancel}>
            {t("button:comeback")}
          </Button>
          <Button
            form="myform"
            className="btn btn-primary-fill"
            type="submit"
            disabled={isSubmitSuccessful && !isError}
          >
            {t("button:save")}
          </Button>
        </Box>
      </Box>

      <form
        className="scrolled-form"
        id="myform"
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        {typeId === TYPE_ID[fields.organization_contractor] ? (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
            <BasicInformationResponsibleOrganizations
              control={control}
              okved={okvedCode}
              errors={errors}
              disabled={!!shouldDisableAllFields}
              resAddressFromDadata={resAddressFromDadata}
              setresAddressFromDadata={setresAddressFromDadata}
              isLicense={true}
              indCheck={indCheck}
              setValue={setValue}
            />
            {/*<ContractEntities control={control} errors={errors} disabled={!!shouldDisableAllFields} /> */}
            <BankDetails
              control={control}
              errors={errors}
              disabled={!!shouldDisableAllFields}
            />
          </div>
        ) : (
          <>
            <BasicInformation
              control={control}
              okved={okvedCode}
              errors={errors}
              addressFromDadata={addressFromDadata}
              setaddressFromDadata={setaddressFromDadata}
              disabled={!!shouldDisableAllFields}
              isLicense={false}
              indCheck={indCheck}
              setValue={setValue}
            />
          </>
        )}
        <Button
          type="button"
          sx={{ position: "absolute", bottom: 25, right: 45 }}
          className="btn btn-primary-fill"
          onClick={handleSubmitAndNavigateToFacility}
          disabled={isSubmitSuccessful && !isError}
        >
          {t("button:saveAndContinue")}
        </Button>
      </form>

      {isWarningValidation && (
        <WarningValidationForm isWarning={isWarningValidation} />
      )}
    </Box>
  );
};

export default AddOrganizationPage;
