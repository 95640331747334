import React, { useState, useEffect, useMemo } from "react";
import { Stage, Layer, Group, Image, Text } from "react-konva";
import { useWatch } from "react-hook-form";
import { getRoomAddressForScheme } from "../../../helpers/helper";
import { Box, CircularProgress } from "@mui/material";
import { loadImg } from "../../../helpers/helper";
// import { ICON_SIZE } from "../../../constants";
import { getEquipmentIcon } from "../../../helpers/getEquipmentIcon";
import { useChartsIcons } from "../../pages/EquipmentDetails/hooks";
import alarm from "../../components/icons/alarm.svg";
import ModalFullScreen from "../../pages/Plume/ImageSchema/ModalFullScreen";
import DonutChart from "../DonutChart";
// import { useNavigate } from "react-router-dom";
// import { PATHS } from "../../../constants";
import { useAction } from "../../../hooks/actions";
import { useAppSelector } from "../../../hooks/redux";
import { ScrollCanvas } from "./ScrollCanvas";
import { RoomCanvas } from "./RoomCanvas";
import { IconsCanvas } from "./IconsCanvas";
import { MiniMapCanvas } from "./MiniMapCanvas";
let timeScale = null;
let timer;
export const StagingToCanvas = ({
  // name,
  width,
  height,
  schemaImg,
  getequipmentsInRegion,
  // setValue,
  control,
  // draggable = false,
  // equipGroup = false,
  widthWorkplace,
  heightWorkplace,
  canvasRef,
  imageRef,
  image,
  setImage,
  // maxScale,
  minScale,
  title,
  loadScheme,
  fullscreen = false,
  scalecanvas,
  selectedEvent = {},
  setFullScreen,
  scale,
  setScale,
  stageposition,
  setstageposition,
  stageRef,
  transformCanvasData,
  changeScrollsPositionandSize,
  isChangeFloor,
  setopenEquipDialog,
  setSelectedEquipId,
  roomsColors,
  lineColor,
  // stopBlinking,
  // startBlinking
}) => {
  //const canvasRef = useRef(null);
  const { equipmentsCoordinates: data, roomAddresses } = useWatch({
    control,
  });
  const [icons, setIcons] = useState([]);
  const [iconsSize, seticonsSize] = useState({ width: 25, height: 25 });
  const [ChartSize, setChartSize] = useState({ width: 22, height: 22 });
  const [alarmSize, setalarmSize] = useState({ width: 17, height: 17 });
  const [tooltipText, setTooltipText] = useState("");
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [miniMapCoords, setminiMapCoords] = useState({ x: 0, y: 0 });
  const { SCHEMA_CANVAS } = useAppSelector((state) => state.settings);
  const { setCanvasSize } = useAction();

  const [ChartsList, getSrcSVG] = useChartsIcons({
    Chart: DonutChart,
    equipments: data,
  });

  const screenRelatedRoomAddresses = useMemo(
    () => getRoomAddressForScheme(roomAddresses, scalecanvas),
    [roomAddresses, scalecanvas]
  );
  console.log("screenRelatedRoomAddresses",screenRelatedRoomAddresses)
  const handleDragMove = (e) => {
    changeScrollsPositionandSize();
  };

  useEffect(() => {
    seticonsSize({ width: 25 / window.devicePixelRatio, height: 25 / window.devicePixelRatio });
    setalarmSize({ width: 17 / window.devicePixelRatio, height: 17 / window.devicePixelRatio });
    setChartSize({ width: 22 / window.devicePixelRatio, height: 22 / window.devicePixelRatio });
  }, [window.devicePixelRatio]);

  const drawIcons = async (data, height = SCHEMA_CANVAS.HEIGHT, width = SCHEMA_CANVAS.WIDTH) => {
    if (scale >= minScale && data?.length) {
      const response = await Promise.all(
        data.map(
          async (
            { id, floorSchemeCoordinateX: x, floorSchemeCoordinateY: y, typeId, workingCorrectly, jobChart },
            index
          ) => {
            return loadImg(getEquipmentIcon(typeId)).then(async (icon) => {
              const scaleX = width / SCHEMA_CANVAS.WIDTH;
              const scaleY = height / SCHEMA_CANVAS.HEIGHT;
              icon.width = iconsSize.width;
              icon.height = iconsSize.height;
              const sizeIcon = icon.width;
              return loadImg(getSrcSVG(index)).then(async (chartIcons) => {
                return loadImg(alarm).then(async (alarmIcon) => {
                  alarmIcon.width = alarmSize.width;
                  alarmIcon.height = alarmSize.height;
                  let chartWidth = ChartSize.width;
                  let chartHeight = ChartSize.height;
                  const iconWithChart = {
                    id,
                    x: x * scaleX,
                    y: y * scaleY,
                    img: icon,
                    chart: {
                      x: icon.width - icon.width / 2,
                      y: -((icon.width * 2) / 5),
                      img: chartIcons,
                      width: jobChart.totalJobsAmount > 0 ? chartWidth : 0,
                      height: jobChart.totalJobsAmount > 0 ? chartHeight : 0,
                    },
                  };

                  const alarm = {
                    x: -(alarmIcon.width / 2),
                    y: sizeIcon - alarmIcon.width / 1.5,
                    img: alarmIcon,
                  };

                  return !workingCorrectly ? { ...iconWithChart, alarm } : iconWithChart;
                });
              });
            });
          }
        )
      );
      setIcons(response);
    } else {
      setIcons([]);
    }
  };
  const handleShapeMouseMove = (e, text, coordinates) => {
    const mousePos = e.target.getStage().getPointerPosition();
    setTooltipText(text);
    setTooltipPosition({ x: mousePos.x + 15, y: mousePos.y + 15 }); // Обновляем позицию tooltip
    setTooltipVisible(true);
  };

  const handleShapeMouseOut = () => {
    setTooltipVisible(false);
  };
  const drawScheme = async (schemaImg, height, width) => {
    await loadImg(schemaImg).then((image) => {
      setCanvasSize({
        WIDTH: image.width,
        HEIGHT: image.height,
      });
      image.width = width;
      image.height = height;
      setImage(image);
    });
  };

  useEffect(() => {
    schemaImg && drawScheme(schemaImg, height, width);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schemaImg, height, width]);

  useEffect(() => {
    if (data) {
      const newData = data?.filter(
        ({ floorSchemeCoordinateX, floorSchemeCoordinateY }) =>
          floorSchemeCoordinateX !== null && floorSchemeCoordinateY !== null
      );
      drawIcons(newData, height, width);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height, width, data, SCHEMA_CANVAS]);

  const handleWheel = (event) => {
    event.evt.preventDefault();
    const stage = stageRef.current;
    const canvas = canvasRef.current;
    let newScale = scale;
    let stepzoom = 0.1;

    const isPinchGesture = event.evt.ctrlKey || (event.evt.touches && event.evt.touches.length === 2);

    if (scale > 20 / 3 + 0.1) stepzoom = 0.5;
    if (scale > 10) stepzoom = 1;
    if (scale > 50) stepzoom = 5;

    if (isPinchGesture) {
      // Для жестов на тачпаде используем более плавную анимацию
      const scaleChange = event.evt.deltaY > 0 ? -stepzoom : stepzoom;
      newScale = Math.max(1, Math.min(20, scale + scaleChange));
    } else {
      // Для колесика мыши используем исходную логику
      if (event.evt.deltaY < 0) {
        newScale = scale < 20 ? scale + stepzoom : scale;
      } else {
        newScale = scale > 1 ? scale - stepzoom : scale;
      }
    }

    const pointTo = {
      x: stage.getPointerPosition().x / scale - canvas.x() / scale,
      y: stage.getPointerPosition().y / scale - canvas.y() / scale,
    };

    setstageposition({
      x: -(pointTo.x - stage.getPointerPosition().x / newScale) * newScale,
      y: -(pointTo.y - stage.getPointerPosition().y / newScale) * newScale,
    });

    changeScrollsPositionandSize();
    setScale(newScale);
    clearTimeout(timeScale);

    timeScale = setTimeout(() => {
      if (newScale <= 1 && scale > 1) loadScheme();
      if (newScale >= minScale) {
        const coordinates = [
          -canvas.x() / newScale,
          -canvas.y() / newScale,
          widthWorkplace / newScale - canvas.x() / newScale,
          heightWorkplace / newScale - canvas.y() / scale,
        ];
        getequipmentsInRegion(coordinates, null);
      } else if (newScale < minScale && data?.length > 0) {
        loadScheme(false);
      }
    }, 250);
  };

  const handleOpenEquipment = (id) => {
    setopenEquipDialog(true);
    setSelectedEquipId(id);
  };

  const handleDragEndStage = (e) => {
    console.log("canvas.y", canvasRef.current.y(), canvasRef.current.x());
    if (scale < minScale) return;
    const canvas = canvasRef.current;

    const coordinates = [
      -canvas.x() / scale,
      -canvas.y() / scale,
      widthWorkplace / scale - canvas.x() / scale,
      heightWorkplace / scale - canvas.y() / scale,
    ];
    getequipmentsInRegion(coordinates, null);
  };
  useEffect(() => {
    if (canvasRef.current !== null) {
      const canvas = canvasRef.current;
      const scale = canvas.scaleX();
      const newXPos = miniMapCoords.x * scale;
      const newYPos = miniMapCoords.y * scale;
      setstageposition({ x: newXPos, y: newYPos });
    }
  }, [miniMapCoords]);

  return (
    <Box
      sx={{
        position: "relative",
        border: "1px solid #CFD9E8",
        borderRadius: "16px",
        padding: "10px",
        width: widthWorkplace - 20,
        height: heightWorkplace - 20,
      }}
    >
      <MiniMapCanvas
        img={image}
        setminiMapCoords={setminiMapCoords}
        stageposition={stageposition} // текущие координаты просмотра
        setStagePosition={setstageposition}
        scale={scale}
        getequipmentsInRegion={getequipmentsInRegion}
        canvas={canvasRef.current}
        width={widthWorkplace}
        height={heightWorkplace}
      />
      <ChartsList />

      {!!setFullScreen && (
        <ModalFullScreen
          open={fullscreen}
          setOpen={setFullScreen}
          title={title || ""}
          // canvasRef={canvasRef.current?.canvas._canvas || null}
          SCHEMA_CANVAS={SCHEMA_CANVAS}
        />
      )}
      {isChangeFloor ? (
        <Box sx={{ height: "100%", alignContent: "center", textAlign: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <Stage
          width={widthWorkplace - 20}
          ref={stageRef}
          onMouseEnter={(e) => {
            const container = e.target.getStage().container();
            container.style.cursor = "grab";
          }}
          // onWheel={handleWheel}
          height={heightWorkplace - 20}
          config={{ enableWebGL: true }}
        >
          <Layer
            ref={canvasRef}
            draggable
            onWheel={handleWheel}
            onDragMove={handleDragMove}
            onDragEnd={handleDragEndStage}
            dragBoundFunc={(pos) => {
              //   console.log("pos",pos)
              const offsetX = widthWorkplace - image.width * scale;
              const offsetY = heightWorkplace - image.height * scale;
              //  console.log("stage",pos,image.width*scale,offsetX)

              if (offsetX <= 0) {
                if (pos.x <= offsetX) pos.x = offsetX;
                if (pos.x > 0) pos.x = 0;
              } else {
                if (pos.x > offsetX) pos.x = offsetX;
                if (pos.x <= 0) pos.x = 0;
              }

              if (offsetY <= 0) {
                if (pos.y <= offsetY) pos.y = offsetY;
                if (pos.y > 0) pos.y = 0;
              } else {
                if (pos.y > offsetY) pos.y = offsetY;
                if (pos.y <= 0) pos.y = 0;
              }
              return pos;
            }}
            x={stageposition.x}
            y={stageposition.y}
          >
            <Group scaleX={scale} scaleY={scale}>
              <Image ref={imageRef} image={image} x={0} y={0} />

              {screenRelatedRoomAddresses?.map(({ id, coordinates, name }, index) => (
                <RoomCanvas
                  id={id}
                  name={name}
                  coordinates={coordinates}
                  index={index}
                  timer={timer}
                  getequipmentsInRegion={getequipmentsInRegion}
                  loadScheme={loadScheme}
                  selectedEvent={selectedEvent}
                  scale={scale}
                  setScale={setScale}
                  roomsColors={roomsColors}
                  lineColor={lineColor}
                  mouseMove={handleShapeMouseMove}
                  mouseOut={handleShapeMouseOut}
                />
              ))}
            </Group>

            {!!icons?.length &&
              scale >= minScale &&
              icons.map(({ id, x, y, img, chart, alarm }, index) => (
                <IconsCanvas
                  id={id}
                  x={x}
                  y={y}
                  img={img}
                  chart={chart}
                  alarm={alarm}
                  index={index}
                  scale={scale}
                  openEquipment={handleOpenEquipment}
                />
              ))}
          </Layer>
          <ScrollCanvas
            getequipmentsInRegion={getequipmentsInRegion}
            widthWorkplace={widthWorkplace}
            heightWorkplace={heightWorkplace}
            canvasRef={canvasRef}
            image={image}
            scale={scale}
            stageposition={stageposition}
            setstageposition={setstageposition}
            transformCanvasData={transformCanvasData}
          />
          <Layer>
            {tooltipVisible && (
              <Text
                text={tooltipText}
                fontSize={14}
                padding={10}
                textFill="white"
                fill="black"
                fontStyle="bold"
                alpha={1}
                visible={tooltipVisible}
                x={tooltipPosition.x} // Устанавливаем позицию по x
                y={tooltipPosition.y} // Устанавливаем позицию по y
              />
            )}
          </Layer>
        </Stage>
      )}
    </Box>
  );
};
