import { configureStore } from "@reduxjs/toolkit";
import { persistStore } from "redux-persist";

import { settingsReducer } from "./reducers/settings.slice";
import { usersReducer } from "./reducers/users.slice";
import { usersApi } from "../middleware/usersApi";
import { organizationsApi } from "../middleware/apiOrganizations";
import { facilitiesApi } from "../middleware/facilitiesApi";
import { organizationsReducer } from "./reducers/organizations.slice";
import { uploadFileApi } from "../middleware/apiUploadFile";
import { uploadFileReducer } from "./reducers/uploadFile.slice";
import { facilitiesReducer } from "./reducers/facilities.slice";
import { fireCompartmentReducer } from "./reducers/fireCompartment.slice";
import { fireCompartmentApi } from "../middleware/fireCompartmentApi";
import { equipmentsReducer } from "./reducers/equipments.slice";
import { equipmentsApi } from "../middleware/equipmentsApi";
import { regulationsReducer } from "./reducers/regulations.slice";
import { regulationsApi } from "../middleware/regulationsApi";
import { tasksApi } from "../middleware/tasksApi";
import { tasksReducer } from "./reducers/tasks.slice";
import { groupJobsApi } from "../middleware/groupJobsApi";
import { grouptasksReducer } from "./reducers/groupTasks.slice";
import { executiveApi } from "../middleware/executiveApi";
import { executiveReducer } from "./reducers/executive.slice";
import { responsibleOrganizationsApi } from "../middleware/responsibleOrganizationsApi";
import { responsibleOrganizationsReducer } from "./reducers/responsibleOrganizations.slice";
import { analyticsReducer } from "./reducers/analytics.slice";
import { analyticsApi } from "../middleware/analyticsApi";
import { logsReducer } from "./reducers/logs.slice";
import { logsApi } from "../middleware/logsApi";
import { employeeReducer } from "./reducers/employee.slice";
import { employeeApi } from "../middleware/employeeApi";
import { magazineReducer } from "./reducers/magazine.slice";
import { magazineApi } from "../middleware/magazineApi";
import { calendarPlanApi } from "../middleware/calendarPlanApi";
import { calendarReducer } from "./reducers/calendar.slice";
import { billingReducer } from "./reducers/billing.slice";
import { billingApi } from "../middleware/billingApi";
import { messageCodeReducer } from "./reducers/messageCode.slice";
import { messageCodeApi } from "../middleware/messageCodeApi";
import { notificationReducer } from "./reducers/norification.slice";
import { notificationApi } from "../middleware/notificationApi";
import { documentationApi } from "../middleware/documentationApi";
import { documentationReducer } from "./reducers/documentation.slice";
import { controllersApi } from "../middleware/controllersApi";
import { controllersReducer } from "./reducers/controllers.slice";
import { addressDataReducer } from "./reducers/addressData.slice";
import { addressDataApi } from "../middleware/addressDataApi";
import { signingReducer } from "./reducers/signing.slice";
import { signingApi } from "../middleware/signingApi";
import { permissionsReducer } from "./reducers/permissions.slice";

export const store = configureStore({
  reducer: {
    users: usersReducer,
    notification: notificationReducer,
    settings: settingsReducer,
    organizations: organizationsReducer,
    uploadFile: uploadFileReducer,
    facilities: facilitiesReducer,
    fireCompartment: fireCompartmentReducer,
    equipments: equipmentsReducer,
    regulations: regulationsReducer,
    tasks: tasksReducer,
    grouptasks: grouptasksReducer,
    executive: executiveReducer,
    analytics: analyticsReducer,
    responsibleOrganizations: responsibleOrganizationsReducer,
    logs: logsReducer,
    employee: employeeReducer,
    magazine: magazineReducer,
    calendar: calendarReducer,
    billing: billingReducer,
    messageCode: messageCodeReducer,
    signing: signingReducer,
    documentation: documentationReducer,
    controllers: controllersReducer,
    addressData: addressDataReducer,
    permissions: permissionsReducer,
    [employeeApi.reducerPath]: employeeApi.reducer,
    [magazineApi.reducerPath]: magazineApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [organizationsApi.reducerPath]: organizationsApi.reducer,
    [uploadFileApi.reducerPath]: uploadFileApi.reducer,
    [facilitiesApi.reducerPath]: facilitiesApi.reducer,
    [fireCompartmentApi.reducerPath]: fireCompartmentApi.reducer,
    [equipmentsApi.reducerPath]: equipmentsApi.reducer,
    [regulationsApi.reducerPath]: regulationsApi.reducer,
    [tasksApi.reducerPath]: tasksApi.reducer,
    [groupJobsApi.reducerPath]: groupJobsApi.reducer,
    [calendarPlanApi.reducerPath]: calendarPlanApi.reducer,
    [executiveApi.reducerPath]: executiveApi.reducer,
    [analyticsApi.reducerPath]: analyticsApi.reducer,
    [logsApi.reducerPath]: logsApi.reducer,
    [messageCodeApi.reducerPath]: messageCodeApi.reducer,
    [notificationApi.reducerPath]: notificationApi.reducer,
    [billingApi.reducerPath]: billingApi.reducer,
    [responsibleOrganizationsApi.reducerPath]:
      responsibleOrganizationsApi.reducer,
    [documentationApi.reducerPath]: documentationApi.reducer,
    [controllersApi.reducerPath]: controllersApi.reducer,
    [addressDataApi.reducerPath]: addressDataApi.reducer,
    [signingApi.reducerPath]: signingApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      usersApi.middleware,
      organizationsApi.middleware,
      uploadFileApi.middleware,
      facilitiesApi.middleware,
      fireCompartmentApi.middleware,
      equipmentsApi.middleware,
      regulationsApi.middleware,
      tasksApi.middleware,
      groupJobsApi.middleware,
      executiveApi.middleware,
      responsibleOrganizationsApi.middleware,
      analyticsApi.middleware,
      logsApi.middleware,
      employeeApi.middleware,
      magazineApi.middleware,
      calendarPlanApi.middleware,
      billingApi.middleware,
      messageCodeApi.middleware,
      notificationApi.middleware,
      documentationApi.middleware,
      controllersApi.middleware,
      addressDataApi.middleware,
      signingApi.middleware,
    ),
});

export const persistor = persistStore(store);
