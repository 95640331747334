import React from "react";

const ObjectsIcon = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.6924 5.30769C10.6924 6.45016 11.1462 7.54583 11.9541 8.35368C12.7619 9.16153 13.8576 9.61537 15.0001 9.61537C16.1425 9.61537 17.2382 9.16153 18.0461 8.35368C18.8539 7.54583 19.3078 6.45016 19.3078 5.30769C19.3078 4.16522 18.8539 3.06954 18.0461 2.26169C17.2382 1.45384 16.1425 1 15.0001 1C13.8576 1 12.7619 1.45384 11.9541 2.26169C11.1462 3.06954 10.6924 4.16522 10.6924 5.30769Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.6923 10.6924C23.5498 10.6924 22.4542 11.1462 21.6463 11.9541C20.8385 12.7619 20.3846 13.8576 20.3846 15.0001C20.3846 16.1425 20.8385 17.2382 21.6463 18.0461C22.4542 18.8539 23.5498 19.3078 24.6923 19.3078C25.8348 19.3078 26.9305 18.8539 27.7383 18.0461C28.5462 17.2382 29 16.1425 29 15.0001C29 13.8576 28.5462 12.7619 27.7383 11.9541C26.9305 11.1462 25.8348 10.6924 24.6923 10.6924Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6924 24.6922C10.6924 25.8347 11.1462 26.9304 11.9541 27.7382C12.7619 28.546 13.8576 28.9999 15.0001 28.9999C16.1425 28.9999 17.2382 28.546 18.0461 27.7382C18.8539 26.9304 19.3078 25.8347 19.3078 24.6922C19.3078 23.5497 18.8539 22.4541 18.0461 21.6462C17.2382 20.8384 16.1425 20.3845 15.0001 20.3845C13.8576 20.3845 12.7619 20.8384 11.9541 21.6462C11.1462 22.4541 10.6924 23.5497 10.6924 24.6922Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9999 25.231C14.7025 25.231 14.4614 24.99 14.4614 24.6925C14.4614 24.3951 14.7025 24.1541 14.9999 24.1541"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 25.231C15.2974 25.231 15.5385 24.99 15.5385 24.6925C15.5385 24.3951 15.2974 24.1541 15 24.1541"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.30754 10.6924C4.16507 10.6924 3.0694 11.1462 2.26155 11.9541C1.4537 12.7619 0.999854 13.8576 0.999854 15.0001C0.999854 16.1425 1.4537 17.2382 2.26155 18.0461C3.0694 18.8539 4.16507 19.3078 5.30754 19.3078C6.45001 19.3078 7.54569 18.8539 8.35354 18.0461C9.16139 17.2382 9.61523 16.1425 9.61523 15.0001C9.61523 13.8576 9.16139 12.7619 8.35354 11.9541C7.54569 11.1462 6.45001 10.6924 5.30754 10.6924Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ObjectsIcon;
