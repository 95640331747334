import React, { useRef, useState, useEffect } from "react";
import FormItem from "../../../components/FormItem";

import { Box } from "@mui/material";
import EquipmentDialog from "../../EquipmentList/EquipDialog";

const EquipmentsForm = ({ control, errors, data, config }) => {
  const $container = useRef(null);
  const [width, setWidth] = useState(null);
  const [openEquipDialog, setopenEquipDialog] = useState(false);
  const [selectedEquipId, setSelectedEquipId] = useState(null);

  useEffect(() => {
    $container && setWidth($container.current.offsetWidth);
  }, [$container]);

  window.addEventListener(
    "resize",
    function () {
      setWidth($container.current?.offsetWidth);
    },
    false
  );

  return (
    <>
      <Box ref={$container}>
        {config.map(({ name, label, input, Component, ...rest }, i) =>
          Component ? (
            <Component key={i} data={data} control={control} widthContainer={width} {...rest} />
          ) : (
            <FormItem
              key={name}
              control={control}
              errors={errors}
              name={name}
              label={label}
              input={input}
              data={data}
              setSelectedEquipId={setSelectedEquipId}
              setopenEquipDialog={setopenEquipDialog}
              {...rest}
            />
          )
        )}
      </Box>

      {openEquipDialog && (
        <EquipmentDialog
          open={openEquipDialog}
          setOpen={setopenEquipDialog}
          equipId={selectedEquipId}
          refetch={() => {}}
        />
      )}
    </>
  );
};

export default EquipmentsForm;
