import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import NewsTape from "./NewsTape";
import VideosTree from "./VideosTree";
import TrainingApplication from "./TrainingApplication";
import { useAppSelector } from "../../../hooks/redux";
import AddInformationBlock from "./AddInformationBlock";
const InfoCenter = () => {
  const { t } = useTranslation();
  const { roleId } = useAppSelector((state) => state.users.info);
  const [isRefetchNewsListFlag, setIsRefetchNewsListFlag] = useState(false);
  const [isRefetchOperativeNewsFlag, setIsRefetchOperativeNewsFlag] = useState(false);

  const refetchNewsList = () => {
    setIsRefetchNewsListFlag((prev) => !prev);
  };

  const refetchOperativeNews = () => {
    setIsRefetchOperativeNewsFlag((prev) => !prev);
  };

  return (
    <>
      <Box>
        <Typography className="title-page">{t("label:infocenter")}</Typography>
      </Box>
      <Box className="infocenter-blocks-wrapper">
        <NewsTape
          isRefetchFlag={isRefetchNewsListFlag}
          isRefetchOperativeNewsFlag={isRefetchOperativeNewsFlag}
        />
        {roleId === 1 && (
          <AddInformationBlock refetchNewsList={refetchNewsList} refetchOperativeNews={refetchOperativeNews} />
        )}
        <VideosTree />
        <TrainingApplication />
      </Box>
    </>
  );
};

export default InfoCenter;
