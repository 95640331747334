import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../constants";
import { useAddLogClientErrorMutation } from "../../middleware/usersApi";

export function ErrorFallback({error}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [addLogClientError] = useAddLogClientErrorMutation();
  const currentUrl = window.location.href;

  useEffect(() => {
    const errorData = {
      link: currentUrl,
      title: error.stack,
    };
    addLogClientError(errorData);
  }, [error]);

  const handleLogout = () => {
    navigate(PATHS.LOGIN_ROUTE);
  };

  return (
    <Box role="alert" sx={{width: "100vw", height: '100vh', display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: '30px'}}>
      <Box sx={{padding: "0px 20px"}}>
        <Typography variant="h1" component="h2" sx={{mb: "20px", textAlign: "center"}}>
          {t("notification:error_fall_back")}
        </Typography>
        <Typography sx={{textAlign: "center"}}>
          {error.message}
        </Typography>
        
      </Box>
      <Button variant="contained" size="large" sx={{width: "fit-content"}} onClick={handleLogout}>
        {t("label:logout")}
      </Button>
    </Box>
  );
}
