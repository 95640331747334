import React from "react";
import { Group, Image, Rect } from "react-konva";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../../constants";

export const IconsCanvas = React.memo(({
    id,
    x,
    y,
    img,
    chart,
    alarm,
    index,
    scale,
    openEquipment
}) => {

    const navigate = useNavigate();

    const centerX = 25 / 2;
    const centerY = 25 / 2;
    return (
        <Group
            key={id || index}
            scaleX={1}
            scaleY={1}

            onMouseEnter={e => {
                const container = e.target.getStage().container();
                container.style.cursor = 'pointer';

            }}
            onMouseLeave={e => {

                const container = e.target.getStage().container();
                container.style.cursor = 'grab';

            }}
            x={x * scale}
            y={y * scale}
        >
            <Rect
                x={0}
                y={0}
                width={img.width}
                height={img.height}
                fill="rgba(57,57,242,0.4)"

            />
            <Image image={img} x={0} y={0} scaleX={1} scaleY={1} onClick={() => { openEquipment(id) }} />
            <Image
                image={chart.img}
                onClick={() => { navigate(PATHS.LIST_TASKS + `?equip=${id}`) }}
                x={!isNaN(chart.x) ? chart.x : 0}
                y={!isNaN(chart.y) ? chart.y : 0}
                width={chart.width}
                height={chart.height}
            />
            {!!alarm &&
                <Image
                    image={alarm.img}
                    x={!isNaN(alarm.x) ? alarm.x : 0}
                    y={!isNaN(alarm.y) ? alarm.y : 0}
                />}
        </Group>

    );
});
