import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "./constants";

export const equipmentsApi = createApi({
  reducerPath: "equipments/api",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const { token } = getState().users;
      headers.set("Content-Type", "application/json");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (build) => ({
    // getEquipmentSelectElements: build.query({
    //   query: ({ searchValue, objectId, equipmentKindId = "" }) => ({
    //     url: `api/equipments/get-equipment-select-elements?searchValue=${searchValue}&protectionObjectId=${objectId}&equipmentKindId=${equipmentKindId}`,
    //   }),
    // }),
    getEquipmentsForTree: build.query({
      query: (params) => ({
        url: `/api/equipments/get-equipments-by-groupid-for-tree`,
        params,
      }),
    }),
    getEquipmentKindSelectElements: build.query({
      query: ({
        searchValue = "",
        useShortTitle = true,
        protectionObjectId = "",
        hasEquipmentOnly = false,
        removeEmptyKinds = false,
        equipmentTypeId="",
        equipmentKindId=""
      }) => ({
        url: `api/equipments/get-all-equipment-kinds-and-types?searchValue=${searchValue}&useShortTitle=${useShortTitle}&protectionObjectId=${protectionObjectId}&hasEquipmentOnly=${hasEquipmentOnly}&removeEmptyKinds=${removeEmptyKinds}&equipmentTypeId=${equipmentTypeId}&equipmentKindId=${equipmentKindId}`,
      }),
    }),
    getEquipmentKindSelectElementsS: build.query({
      query: ({
        params = "",
        useShortTitle,
        hasEquipmentOnly = false,
        removeEmptyKinds = false,
      }) => ({
        url: `api/equipments/get-all-equipment-kinds-and-types?searchValue=${params}&useShortTitle=${useShortTitle}&hasEquipmentOnly=${hasEquipmentOnly}&removeEmptyKinds=${removeEmptyKinds}`,
      }),
    }),
    getRoomsAddressSelection: build.query({
      query: ({ floorInfoId, searchName }) => ({
        url: `api/equipments/get-rooom-address-selection?floorInfoId=${floorInfoId}&searchName=${searchName}&searchObjectIntegrationNumber=`,
      }),
      transformResponse: (baseQueryReturnValue) =>
        baseQueryReturnValue.map(({ id, name, objectIntegrationNumber }) => ({
          value: id,
          integrationNumber:objectIntegrationNumber,
          name:name
        })),
    }),
    getRoomsPlumes: build.query({
      query: ({ roomAddressId, plume }) => ({
        url: `api/equipments/get-rooom-plumes?roomAddressId=${roomAddressId}&plume=${plume}`,
      }),
      transformResponse: (baseQueryReturnValue) =>
        baseQueryReturnValue.map((item) => ({
          value: item,
          name: item,
        })),
    }),
    getEquipmentReglaments: build.query({
      query: ({
        equipmentTypeId,
        equipmentKindId,
        fireCompartmentId,
        equipmentName,
      }) => ({
        url: `api/equipments/get-equipment-regulations?equipmentTypeId=${equipmentTypeId}&equipmentKindId=${equipmentKindId}&fireCompartmentId=${fireCompartmentId}&equipmentName=${equipmentName}`,
      }),
    }),

    calculateFloorSchemeParams: build.mutation({
      query: (data) => ({
        url: "/api/equipments/calculate-floorscheme-params-post",
        method: "POST",
        body: data,
      }),
    }),
    importEquipmentList: build.mutation({
      query: (data) => ({
        url: "/api/equipments/import-equipment-list",
        method: "POST",
        body: data,
      }),
    }),
    exportEquipmentList: build.query({
      query: (params = "") => ({
        url: `api/equipments/export-equipment-list?${params}`,
      }),
    }),
    getEquipmentsCoordinates: build.query({
      query: (params = "") => ({
        url: `api/equipments/get-equipments-and-rooms-coordinates?${params}`,
      }),
    }),
    getEquipmentKindSelect: build.query({
      query: (params = "") => ({
        url: `api/equipments/get-equipment-kind-select-elements?${params}`,
      }),
    }),
    getEquipmentsCoordinatesPost: build.mutation({
      query: (data) => ({
        url: "/api/equipments/get-equipments-and-rooms-coordinates-post",
        method: "POST",
        body: data,
      }),
    }),
    deleteEquipment: build.mutation({
      query: (id) => ({
        url: `/api/equipments/delete/${id}`,
        method: "DELETE",
      }),
    }),
    addEquipment: build.mutation({
      query: (data) => ({
        url: "/api/equipments/add",
        method: "POST",
        body: data,
      }),
    }),
    addEquipmentExt: build.mutation({
      query: (data) => ({
        url: "/api/equipments/add-ext",
        method: "POST",
        body: data,
      }),
    }),
    replaceEquipment: build.mutation({
      query: (data) => ({
        url: "/api/equipments/replace",
        method: "POST",
        body: data,
      }),
    }),
    editEquipment: build.mutation({
      query: (data) => ({
        url: "/api/equipments/edit",
        method: "PUT",
        body: data,
      }),
    }),
    getEquipment: build.query({
      query: (id) => ({
        url: `/api/equipments/get/${id}`,
      }),
    }),
    getEquipmentNumbers: build.query({
      query: ({ startNumber, count, protectionObjectId }) => ({
        url: `/api/equipments/get-equipment-numbers?startNumber=${startNumber}&count=${count}&protectionObjectId=${protectionObjectId}`,
      }),
    }),
    getEquipmentsByGroupIdForSelection: build.query({
      query: ({
        equipmentGroupId,
        searchNumber,
        floorInfoId,
        hasPlacement,
      }) => ({
        url: `/api/equipments/get-equipments-by-groupid-for-selection?&equipmentGroupId=${equipmentGroupId}&searchNumber=${searchNumber}&floorInfoId=${floorInfoId}`,
      }),
    }),
    getEquipmentNames: build.query({
      query: ({ searchValue, equipmentTypeId }) => ({
        url: `/api/equipments/get-equipment-names?searchValue=${searchValue}&equipmentTypeId=${equipmentTypeId}`,
      }),
    }),
    getLastNumberandPrefix: build.query({
      query: ({ startNumber, count }) => ({
        url: `/api/equipments/get-last-number-and-prefix?startNumber=${startNumber}&count=${count}`,
      }),
    }),
    validateEquipmentNumbers: build.mutation({
      query: (data) => ({
        url: "/api/equipments/validate-equipment-number",
        method: "POST",
        body: data,
      }),
    }),
    updateEquipmentsCoordinates: build.mutation({
      query: (data) => ({
        url: "/api/equipments/update-equipments-coordinates",
        method: "POST",
        body: data,
      }),
    }),
    updateRoomAddressesCoordinates: build.mutation({
      query: (data) => ({
        url: "/api/equipments/update-room-addresses-coordinates",
        method: "POST",
        body: data,
      }),
    }),
    getEquipmentSelectElementsByFacilityId: build.query({
      query: ({ searchValue, facilityId }) => ({
        url: `api/equipments/get-equipment-select-elements-by-facility-id-groupped?actType=0&searchValue=${searchValue}&facilityId=${facilityId}`,
      }),
    }),
    getEquipmentSelectElementsByFacilityIdSNNME: build.query({
      query: ({ searchValue, facilityId }) => ({
        url: `api/equipments/get-equipment-select-elements-by-facility-id-groupped?actType=1&searchValue=${searchValue}&facilityId=${facilityId}`,
      }),
    }),
    getEquipmentRegistry: build.query({
      query: (protectionObjectId) => ({
        url: `/api/equipments/get-equipment-registry/${protectionObjectId}`,
      }),
    }),
    getRoomsColors: build.query({
      query: ({ floorId, messageId = "" }) => ({
        url: `/api/equipments/get-rooms-colors${floorId}&messageId=${messageId}`,
      }),
    }),
    getRoomsColorsWithCurrentMsg: build.query({
      query: ({ floorId, messageId = "" }) => ({
        url: `/api/equipments/get-rooms-colors-with-current-msg?floorId${floorId}&messageId=${messageId}`,
      }),
    }),
    getEquipmentList: build.query({
      query: ({ PageNumber, filters }) => ({
        url: `api/equipments/get-equipment-list?PageNumber=${PageNumber}${filters}`,
      }),
    }),
    getEquipmentSystems: build.query({
      query: () => ({
        url: `/api/equipments/get-equipment-systems`,
      }),
    }),
    getEquipmentGroups: build.query({
      query: (search) => ({
        url: `/api/equipments/get-equipment-groups?${search}`,
      }),
      transformResponse: (baseQueryReturnValue) =>
        baseQueryReturnValue.map(({ id, name, ...rest }) => ({
          value: id,
          name: name,
          ...rest,
        })),
    }),
    getEquipmentRegulations: build.query({
      query: (search) => ({
        url: `/api/equipments/get-equipment-regulations?${search}`,
      }),
      transformResponse: (baseQueryReturnValue) =>
        baseQueryReturnValue.map(({ regulationId, name }) => ({
          value: regulationId,
          name: name,
        })),
    }),
    getEquipmentSystemsWithSearch: build.query({
      query: ({ name }) => ({
        url: `/api/equipments/get-equipment-systems?name=${name}`,
      }),
      transformResponse: (baseQueryReturnValue) =>
        baseQueryReturnValue.map(({ id, name }) => ({
          value: id,
          name: name,
        })),
    }),
    distributeEquipmentsForPlan: build.mutation({
      query: (data) => ({
        url: "/api/equipments/distribute-equipments-for-plan-periods",
        method: "POST",
        body: data,
      }),
    }),
    getEquipForPlanPeriod: build.query({
      query: ({
        pageNumber,
        protectionObjectId,
        equipmentKindIds,
        planPeriodId,
        filters,
      }) => ({
        url: `api/equipments/get-equipments-for-plan-period?pageNumber=${pageNumber}&protectionObjectId=${protectionObjectId}&equipmentKindIds=${equipmentKindIds}&planPeriodId=${planPeriodId}${filters}`,
      }),
    }),
    getAvailableEquipmentsForPlanning: build.query({
      query: ({
        pageNumber,
        protectionObjectId,
        equipmentKindIds,
        filters,
      }) => ({
        url: `api/equipments/get-available-equipments-for-planning?pageNumber=${pageNumber}&protectionObjectId=${protectionObjectId}&equipmentKindIds=${equipmentKindIds}${filters}`,
      }),
    }),
    printEquipmentList: build.query({
      query: (params) => {
        const stringParams = Object.entries(params).reduce(
          (acc, [key, value]) => `${acc ? acc + "&" : ""}${key}=${value}`,
          ""
        );
        return {
          url: `api/equipments/print-equipment-list?${stringParams}`,
        };
      }
    }),
    getPlanPeriodsSelection: build.query({
      query: ({ protectionObjectId,equipmentKindId }) => ({
        url: `api/equipments/get-plan-periods-selection?protectionObjectId=${protectionObjectId}&equipmentKindId=${equipmentKindId}`,
      }),
      transformResponse: (baseQueryReturnValue) =>
        baseQueryReturnValue.map(({ id, periodName }) => ({
          value: id,
          name: periodName,
        })),
    }),
    getPlanDataForKind: build.query({
      query: ({ protectionObjectId,startDate,endTime, equipmentKindId,regulationId }) => ({
        url: `api/equipments/get-planning-data-for-kinds?protectionObjectId=${protectionObjectId}&regulationId=${regulationId}&startDate=${startDate}&endDate=${endTime}&equipmentKindId=${equipmentKindId}`,
      }),
    }),
    getPlanDataForType: build.query({
      query: ({ protectionObjectId,startDate,endTime, equipmentKindId,equipmentTypeId,regulationId }) => ({
        url: `api/equipments/get-planning-data-for-types?protectionObjectId=${protectionObjectId}&regulationId=${regulationId}&startDate=${startDate}&endDate=${endTime}&equipmentKindId=${equipmentKindId}&equipmentTypeId=${equipmentTypeId}`,
      }),
    }),
    getPlanDataForGroup: build.query({
      query: ({ protectionObjectId,startDate,endTime, equipmentKindId,equipmentTypeId,equipmentGroupId,regulationId }) => ({
        url: `api/equipments/get-planning-data-for-groups?protectionObjectId=${protectionObjectId}&regulationId=${regulationId}&startDate=${startDate}&endDate=${endTime}&equipmentKindId=${equipmentKindId}&equipmentTypeId=${equipmentTypeId}&equipmentGroupId=${equipmentGroupId}`,
      }),
    }),
    getPlanDataForGroup: build.query({
      query: ({ protectionObjectId,startDate,endTime, equipmentKindId,equipmentTypeId,equipmentGroupId,regulationId }) => ({
        url: `api/equipments/get-planning-data-for-groups?protectionObjectId=${protectionObjectId}&regulationId=${regulationId}&startDate=${startDate}&endDate=${endTime}&equipmentKindId=${equipmentKindId}&equipmentTypeId=${equipmentTypeId}&equipmentGroupId=${equipmentGroupId}`,
      }),
    }),
    getPlanDataForEquipmentPost: build.mutation({
      query: (data) => ({
        url: "/api/equipments/get-planning-data-for-equipments-post",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Equipments"],
    }),
    updateEquipmentPlanPeriodRegulations: build.mutation({
      query: (data) => ({
        url: "/api/equipments/update-equipment-plan-period-regulations",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Equipments"],
    }),
    
    
  })
});

export const {
  useAddEquipmentExtMutation,
  useImportEquipmentListMutation,
  useLazyGetEquipmentRegulationsQuery,
  useLazyExportEquipmentListQuery,
  useGetEquipmentsByGroupIdForSelectionQuery,
  useLazyGetEquipmentsByGroupIdForSelectionQuery,
  useGetEquipmentKindSelectElementsQuery,
  useGetEquipmentKindSelectElementsSQuery,
  useLazyGetRoomsAddressSelectionQuery,
  useLazyGetEquipmentKindSelectElementsQuery,
  useGetRoomsPlumesQuery,
  useLazyGetRoomsPlumesQuery,
  useGetEquipmentKindSelectQuery,
  useGetEquipmentSystemsQuery,
  useGetEquipmentSystemsWithSearchQuery,
  useLazyGetPlanDataForKindQuery,
  useLazyGetPlanDataForTypeQuery,
  useLazyGetPlanDataForGroupQuery,
  useGetPlanDataForEquipmentPostMutation,
  useUpdateEquipmentPlanPeriodRegulationsMutation,
  useLazyGetPlanPeriodsSelectionQuery,
  useLazyGetEquipmentSystemsQuery,
  useLazyGetEquipmentReglamentsQuery,
  useGetEquipmentNumbersQuery,
  useLazyGetEquipmentsCoordinatesQuery,
  useLazyGetEquipmentGroupsQuery,
  useLazyGetRoomsColorsQuery,
  useDeleteEquipmentMutation,
  useLazyGetEquipmentListQuery,
  useGetEquipmentsCoordinatesPostMutation,
  useAddEquipmentMutation,
  useReplaceEquipmentMutation,
  useLazyGetLastNumberandPrefixQuery,
  useValidateEquipmentNumbersMutation,
  useLazyGetAvailableEquipmentsForPlanningQuery,
  useDistributeEquipmentsForPlanMutation,
  useCalculateFloorSchemeParamsMutation,
  useLazyGetEquipForPlanPeriodQuery,
  useEditEquipmentMutation,
  useGetEquipmentQuery,
  useGetEquipmentNamesQuery,
  useLazyGetEquipmentNamesQuery,
  useUpdateEquipmentsCoordinatesMutation,
  useUpdateRoomAddressesCoordinatesMutation,
  useLazyGetEquipmentSelectElementsByFacilityIdQuery,
  useLazyGetEquipmentSelectElementsByFacilityIdSNNMEQuery,
  useGetEquipmentRegistryQuery,
  useLazyGetEquipmentsForTreeQuery,
  useLazyPrintEquipmentListQuery,
  useLazyGetRoomsColorsWithCurrentMsgQuery
} = equipmentsApi;
