import chatBotIcon from "../../../image/ChatBot/chatBotIcon.png";
import arrow from "../../../image/ChatBot/arrow.svg";
import "./styles.css";

const CustomTextarea = ({ value, onChange, handleSendMessage, isLoading }) => {
  return (
    <div className="textarea-container">
      <textarea
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter" && !e.shiftKey) {
            handleSendMessage()
          }
        }}
        className="textarea"
        placeholder="Введите ваш вопрос"
        rows={5}
      />
      <div className="textarea-footer">
        <div className="textarea-ai-container">
          <div className="textarea-ai-img">
            <img src={chatBotIcon} alt="Иконка бота" />
          </div>
          <div>AI Powered</div>
        </div>
        <button className="send-button" disabled={isLoading} onClick={handleSendMessage}>
          <img src={arrow} alt="Иконка стрелки" />
        </button>
      </div>
    </div>
  );
};

export default CustomTextarea;
