import CheckboxSimple from "../../components/CheckboxSimple";


export const formFields = {
  searchParams: "searchParams",
  dateRange:"dateRange",
  facilityName:"facilityName",
  ProtectionObjectId:"ProtectionObjectId",
  entityName:"entityName",
  EntityTypeId:"EntityTypeId",
  actionName:"actionName",
  action :"action",
  fieldName:"fieldName",
  EntityFieldId:"EntityFieldId",
  userName:"userName",
  changedFields:"changedFields",
  currentFields:"currentFields",
  keyFields:"keyFields",
  equipmentKindName: "equipmentKindName",
  keyDisp: "keyDisp"
};

export const DEFAULT_PARAMS = {
};



export const getConfigFormModalSelect = (columns) =>
  columns?.map(({ name }) => ({
    name,
    label: `tableHeader:${name}`,
    type: "checkbox",
    rules: {},
    flexBasis: "50%",
    input: (props) => <CheckboxSimple {...props} />,
  }));
